import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService
    ) { }

    canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<boolean> {
        return this._authenticationService.tokenValueObservable.pipe(
            map(token => {
                if (this._authenticationService.isValidToken(token, activatedRouteSnapshot.data)) {
                    return true;
                } else if (this._authenticationService.userIsClient(token)) {
                    this._router.navigate(['oauth/client-area']);
                    return false;
                } else if (!token) {
                    this._router.navigate(['oauth/login']);
                    this._authenticationService.removeToken();
                    return false;
                }
            })
        );
    }

    canActivateChild(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(activatedRouteSnapshot, routerStateSnapshot);
    }

}
