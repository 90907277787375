import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';

@Component({
    selector: 'gipi-update-tables-ncm',
    templateUrl: './update-tables-ncm.component.html',
    styleUrls: ['./update-tables-ncm.component.scss']
})
export class UpdateTablesNcmComponent implements OnInit {

    constructor(private _router: Router) { }

    ngOnInit(): void { }

    public onClickRefreshNcmVersion(): void {
        EventEmitterService.get('EventRefreshNcmVersion').emit(true);
    }

    public onClickNavigateNewRegister(): void {
        this._router.navigate(['update-tables/ncm/synchronize']);
    }

    public onClickOpenDownloadTables(): void {
        window.open('svn://svn.code.sf.net/p/acbr/code/trunk2/Exemplos/ACBrTCP/ACBrIBPTax/tabela');
    }

}
