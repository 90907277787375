<div class="datetime-wrapper">

    <label *ngIf="label"
           class="datetime-label"
           [attr.for]="id">
        {{ label }}
        <span *ngIf="required"> * </span>
    </label>

    <div class="datetime-content">

        <input inputRef
               class="datetime-element"
               [attr.id]="id"
               [attr.name]="name"
               [placeholder]="placeholder"
               [min]="minDate"
               [max]="maxDate"
               [disabled]="disabled"
               [required]="required"
               [readOnly]="true"
               [autocomplete]="'off'"
               [(ngModel)]="value"
               [value]="value"
               [matDatetimepicker]="datetimePicker"
               (click)="toggleDatetimePicker($event)" />

        <div class="suffix">
            <mat-datetimepicker-toggle #dateTimePickerToggle
                                       [for]="datetimePicker">
            </mat-datetimepicker-toggle>
        </div>

        <mat-datetimepicker #datetimePicker
                            type="datetime"
                            openOnFocus="false"
                            mode="portrait">
        </mat-datetimepicker>
    </div>

    <small *ngIf="help"
           class="datetime-help">
        {{ help }}
    </small>
</div>