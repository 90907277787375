import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AbstractService } from '../../abstract/services/abstract.service';
import { NotificationTypeFilterDTO } from '../models/dto/notification-type-filter.dto';
import { NotificationTypeModel } from '../models/notification-type.model';

@Injectable({ providedIn: 'root' })
export class NotificationTypeService extends AbstractService<NotificationTypeModel, NotificationTypeFilterDTO> {

    constructor(protected httpClient: HttpClient) {
        super('APOIO', 'notification-type', httpClient);
    }

}
