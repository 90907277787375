import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogConfirmComponent } from '../../../../shared/dialog-confirm/dialog-confirm.component';
import { TYPE_BUTTONS, TYPE_DIALOG } from '../../../../shared/dialog-confirm/models/dialog-confirm.model';
import { PersonModel } from '../../models/person.model';
import { TerminalAppModel } from '../../models/terminal-app.model';
import { TerminalModel } from '../../models/terminal.model';
import { TerminalsService } from '../../services/terminals.service';
import { DialogClientInformationComponent } from '../dialog-client-information/dialog-client-information.component';

@Component({
    selector: 'gipi-list-computer-clients',
    templateUrl: './list-computer-clients.component.html',
    styleUrls: ['./list-computer-clients.component.scss'],
})
export class ListComputerClientsComponent implements OnInit {

    public hoverbutton: number;

    @Input() terminalList: TerminalModel[];

    @Input() loading: boolean = false;

    @Input() inputPersonClient: PersonModel = {};

    @Input() inputIdClient: number = -1;

    @Output() outputRefreshOneClient: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        private _terminalsService: TerminalsService,
        private _dialog: MatDialog
    ) { }

    ngOnInit(): void { }

    public onClickOpenDialogClientInformation(id: number, apps: TerminalAppModel[], nameTerminal: string, terminal: TerminalModel): void {
        const lCnpjOrCpfClient: string = (this.inputPersonClient.legalPerson ? this.inputPersonClient.legalPerson.cnpj : this.inputPersonClient.naturalPerson.cpf);
        this._dialog.open(
            DialogClientInformationComponent,
            {
                width: '90%',
                data: {
                    id: id,
                    apps: apps,
                    nameTerminal: nameTerminal,
                    terminal: terminal,
                    idClient: this.inputIdClient,
                    nameClient: this.inputPersonClient.name,
                    cnpjOrCpfClient: lCnpjOrCpfClient
                }
            }
        ).afterClosed().toPromise().then((idClient: number) => {
            if (idClient > 0) {
                this.outputRefreshOneClient.emit(idClient);
            }
        });
    }

    public async onClickDeleteTerminal(id: number): Promise<void> {
        const resultOpenDialog: boolean = await this.openDialogConfirm();
        if (resultOpenDialog) {
            this._terminalsService.deleteById(id).then(result => {
                if (result) {
                    this.outputRefreshOneClient.emit(this.inputIdClient);
                }
            });
        }
    }

    public openDialogConfirm(): Promise<boolean> {
        return new Promise(resolve => {
            this._dialog.open(DialogConfirmComponent, {
                width: '300px',
                data: { type: TYPE_DIALOG.CONFIRMATION, message: 'Deseja realmente excluir este registro?', buttons: TYPE_BUTTONS.SI_NO }
            }).afterClosed().toPromise().then((result) => {
                resolve(result);
            });
        });
    }

    public loadStatus(updateStatus: string): string {
        let text: string;
        switch (updateStatus) {
            // Atualizado
            case 'UPDATED': {
                text = 'Atualizado';
                break;
            }
            // Desatualizado
            case 'NOTUPDATED': {
                text = 'Desatualizado';
                break;
            }
            // A atualizar
            case 'UPDATE': {
                text = 'A atualizar';
                break;
            }
            // A liberar
            case 'RELEASE': {
                text = 'A liberar';
                break;
            }
            // Sem terminal
            case 'NOTERMINAL': {
                text = 'Sem terminal';
                break;
            }
            // Sem applicativos
            case 'NOAPPLICATION': {
                text = 'Sem aplicativos';
                break;
            }
            // Versão superior
            case 'TOPVERSION': {
                text = 'Versão superior';
                break;
            }
        }
        return text;
    }

    public getTypeStatus(status: string): string {
        switch (status) {
            case 'UPDATED': return 'success';
            case 'NOTUPDATED': return 'error';
            case 'UPDATE': return 'information';
            case 'RELEASE': return 'warning';
            case 'NOTERMINAL': return 'disabled';
            case 'NOAPPLICATION': return 'new';
            case 'TOPVERSION': return 'advance';
            default: return 'default';
        }
    }

    public loadStatusColor(updateStatus: string): string {
        let color: string;
        switch (updateStatus) {
            // Atualizado
            case 'UPDATED': {
                color = 'green';
                break;
            }
            // Desatualizado
            case 'NOTUPDATED': {
                color = 'red';
                break;
            }
            // A atualizar
            case 'UPDATE': {
                color = 'blue';
                break;
            }
            // A liberar
            case 'RELEASE': {
                color = 'orange';
                break;
            }
            // Sem terminal
            case 'NOTERMINAL': {
                color = 'gray';
                break;
            }
            // Sem applicativos
            case 'NOAPPLICATION': {
                color = 'purple';
                break;
            }
            // Versão Superior
            case 'TOPVERSION': {
                color = 'dark-green';
                break;
            }
        }
        return color;
    }

}
