<mat-card>
    <mat-card-header class="card-header">
        <mat-car-title class="card-title"> Notificações </mat-car-title>
    </mat-card-header>
    <mat-card-content>
        <mat-list style="padding: 1rem !important;">
            <mat-card class="card-item"
                      *ngFor="let item of listNotificationModel; let lIndex = index"
                      mat-list-item>

                <mat-icon svgIcon="table_notification"> </mat-icon>

                <div class="item-info">
                    <div mat-line
                         class="item-info-title">
                        {{ 'Tabela IBPTax - ('+ item.ncmVersion.state.acronym +')' }}
                    </div>
                    <div mat-line
                         class="item-info-text">
                        {{ ' ► ' + loadTextNotification(item) }}
                    </div>
                </div>
            </mat-card>
        </mat-list>
    </mat-card-content>
</mat-card>