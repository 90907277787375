import { StringUtil } from './string.util';


export class PasswordUtil {

    public static isValidCharacter(password: string): boolean {
        if (!StringUtil.isEmpty(password.trim())) {
            if (String(password).includes('%') ||
                String(password).includes('+') ||
                String(password).includes('-') ||
                String(password).includes('*') ||
                String(password).includes('/')
            ) {
                return false;
            }
        }

        return true;
    }

}
