import { AbstractModel } from "@gipi-pages/abstract/models/abstract.model";
import { NotificationTypeModel } from "@gipi-pages/notification-type/models/notification-type.model";
import { NotificationClientFilterModel } from "./notification-client-filter.model";

export class NotificationModel extends AbstractModel {

    /** @Required */
    description: string;

    detailUrl: string;

    /** @Required */
    endDate: Date;

    notificationClientFilter: NotificationClientFilterModel;

    /** @Required */
    prioritizeVisualization: boolean;

    /** @Required */
    startDate: Date;

    /** @Required */
    title: string;

    /** @Required */
    type: NotificationTypeModel;

}
