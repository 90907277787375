<gipi-abstract id="dialogRegisterVersion"
               name="dialogRegisterVersion"
               [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterVersion">
        Cadastrar versão
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-text label="Descrição"
                             id="versionDescription"
                             name="versionDescription"
                             [required]="true"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [(ngModel)]="entity.description"
                             [min]="3"
                             [max]="100"
                             [maxLength]="100"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-input-text>
            <gipi-input-text label="Versão"
                             id="versionNumberVersion"
                             name="versionNumberVersion"
                             [required]="true"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [(ngModel)]="entity.version"
                             [min]="1"
                             [max]="100"
                             [maxLength]="100"
                             fxFlex="33"
                             fxFlex.lt-md="100">
            </gipi-input-text>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-select label="Tipo de versão"
                               id="versionTypeVersion"
                               name="versionTypeVersion"
                               [disabled]="loading || (typeOperation === 'VIEW')"
                               [required]="true"
                               [valuesExclud]="[]"
                               [options]="_optionsTypeVersion"
                               [property]="'description'"
                               [(ngModel)]="entity.typeVersion"
                               fxFlex
                               fxFlex.lt-md="100">
            </gipi-input-select>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract>