import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ValidateAccessService } from '@gipi-ui/services/validate-access.service';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { NotificationModel } from './models/notification.model';
import { NotificationService } from './services/notification.service';

@Component({
    selector: 'gipi-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

    public listNotificationModel: NotificationModel[] = [];

    @Output() outputQuantityNoitfication: EventEmitter<number> = new EventEmitter<number>();

    constructor(private _notificationService: NotificationService, private _validateAccessService: ValidateAccessService) { }

    ngOnInit(): void {
        setTimeout(() => {
            this.findNotifications();

            EventEmitterService.get('EventUpdateNotifications').subscribe((data) => {
                this.findNotifications();
            });
        });
    }

    private async findNotifications(): Promise<void> {
        if (this._validateAccessService.validateAccess('ROLE_DEVELOPERS')) {
            await this._notificationService.getNotifications().then((result) => {
                if (result) {
                    this.listNotificationModel = result;
                    this.outputQuantityNoitfication.emit(this.listNotificationModel.length);
                }
            });
        } else {
            this.outputQuantityNoitfication.emit(0);
        }
    }

    public loadTextNotification(notificationModel: NotificationModel): string {
        const lDaysToEnd: number = notificationModel.ncmVersion.daysToEnd;
        if (lDaysToEnd === 0) {
            return 'Hoje é o último dia vigente';
        } else if (lDaysToEnd === 1) {
            return 'Resta 1 dia para o fim da vigência';
        } if (lDaysToEnd > 5) {
            return lDaysToEnd.toString() + ' dias para o fim da vigência';
        } else if ((lDaysToEnd >= 0) && (lDaysToEnd <= 5)) {
            return lDaysToEnd.toString() + ' dias para o fim da vigência';
        } else if (lDaysToEnd < 0) {
            return 'Tabela expirou a ' + (lDaysToEnd * -1).toString() + ' ' + ((lDaysToEnd * -1) === 1 ? 'dia' : 'dias');
        }
    }

}
