import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as moment_ from 'moment';
const moment = moment_;

import { DecodedToken } from '@gipi-components/screens/login/models/decoded-token.model';
import { AbstractFormDialogComponent } from '@gipi-pages/abstract/abstract-form-dialog.component';
import { ClientModel } from '@gipi-pages/clients/models/client.model';
import { DelayBlockingModel } from '@gipi-pages/delay-blocking/models/delay-blocking.model';
import { DelayBlockingService } from '@gipi-pages/delay-blocking/services/delay-blocking.service';
import { TypeAuthorities } from '@gipi-ui/enums/enum-authorities.enum';
import { TypeOperationDialog } from '@gipi-ui/enums/type-operation-dialog.enum';
import { AlertService } from '@gipi-ui/services/alert.service';
import { ValidateAccessService } from '@gipi-ui/services/validate-access.service';
import { DateUtil } from '@gipi-ui/utils/date.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

export interface DelayBlockingFormData {
    typeOperation: TypeOperationDialog;
    entity?: DelayBlockingModel;
    client: ClientModel;
}

@Component({
    selector: 'gipi-delay-blocking-form-dialog',
    templateUrl: './delay-blocking-form-dialog.component.html',
    styleUrls: ['./delay-blocking-form-dialog.component.scss']
})
export class DelayBlockingFormDialogComponent extends AbstractFormDialogComponent<DelayBlockingModel> implements OnInit {

    private _decodedToken: DecodedToken = new DecodedToken();

    public minDate: Date = this._getMinDate();
    public maxDate: Date = new Date();

    constructor(
        protected service: DelayBlockingService,
        protected dialogRef: MatDialogRef<DelayBlockingFormDialogComponent>,
        private _alertService: AlertService,
        private _authenticationService: AuthenticationService,
        private _validateAccessService: ValidateAccessService,
        @Inject(MAT_DIALOG_DATA) public data: DelayBlockingFormData = { typeOperation: 'NEW', entity: null, client: null },
    ) {
        super(service, dialogRef, data);
        this.dialogRef.disableClose = true;
        this._decodedToken = this._authenticationService.decryptToken(this._authenticationService.tokenValue);
        this._validateMaxAndMinDate();
    }

    ngOnInit(): void {
        console.log(this.minDate);

        this.typeOperation = this.data ? (this.data.typeOperation || 'NEW') : 'NEW';

        if (this.typeOperation === 'NEW') {
            this.entity.clientName = this._nameClient();
            this.entity.cnpj = this._documentClient();
            this.entity.nameUser = this._decodedToken.name;
            this.entity.deadLine = this.minDate;
        }

        if ((this.typeOperation !== 'NEW') && (!ObjectUtil.isNull(this.data.entity))) {
            this.entity = this.data.entity;

            if (!this.entity.enabled) {
                this.optionSituationValue = 'DISABLED';
            }
        }
    }

    protected newEntity(): DelayBlockingModel {
        return new DelayBlockingModel();
    }

    protected isValid(): boolean {
        if (ObjectUtil.isNull(this.entity)) {
            return false;
        }
        if (ObjectUtil.isNull(this.entity.deadLine)) {
            this._alertService.addWarningMessage('Campo data de vencimento é obrigatório e não foi informado');
            return false;
        }
        if (!DateUtil.isValid(this.entity.deadLine)) {
            this._alertService.addWarningMessage('A data de vencimento informada não é válida');
            return false;
        }
        if (this.validateAccess('ROLE_SUPPORT')) {
            if (StringUtil.isEmpty(this.entity.reason)) {
                this._alertService.addWarningMessage('Campo observação é obrigatório e não foi informado');
                return false;
            }
        }
        if (!StringUtil.isEmpty(this.entity.reason) && this.entity.reason.length > 250) {
            this._alertService.addWarningMessage('Campo observação deve conter no máximo 250 caracteres');
            return false;
        }

        return true;
    }

    private _getMinDate(): Date {
        const DayWeek: number = new Date().getDay();
        const isMidWeek: boolean = (DayWeek === 0) || (DayWeek === 6);
        return isMidWeek ? moment().add(1, 'day').toDate() : new Date();
    }

    private _nameClient(): string {
        if (!ObjectUtil.isNull(this.data.client) && !ObjectUtil.isNull(this.data.client.person)) {
            const isLegalPerson: boolean = !ObjectUtil.isNull(this.data.client.person.legalPerson) && !ObjectUtil.isNewModel(this.data.client.person.legalPerson);
            const name: string = isLegalPerson ? this.data.client.person.name : this.data.client.person.fantasyName;

            if (StringUtil.isEmpty(name)) {
                return this.data.client.person.name;
            }

            return name;
        }

        return '';
    }

    private _documentClient(): string {
        if (!ObjectUtil.isNull(this.data.client) && !ObjectUtil.isNull(this.data.client.person)) {
            const isLegalPerson: boolean = !ObjectUtil.isNull(this.data.client.person.legalPerson) && !ObjectUtil.isNewModel(this.data.client.person.legalPerson);
            const document: string = isLegalPerson ? this.data.client.person.legalPerson.cnpj : this.data.client.person.naturalPerson.cpf;

            if (StringUtil.isEmpty(document)) {
                return '';
            }

            return document;
        }

        return '';
    }

    public formattedDocumentClient(document: string): string {
        if (StringUtil.isEmpty(document)) {
            return '';
        }
        return StringUtil.format(document, (document.length > 11) ? '00.000.000/0000-00' : '000.000.000-00')
    }

    public dateFilter = (date: Date | null): boolean => {
        if (ObjectUtil.isNull(date)) {
            date = new Date()
        }
        const dateValue: Date = (date instanceof Date) ? date : new Date(date);

        // se digitar uma data que seja sábado ou domingo vai retornar false, no caso bloquear o dia
        const DayWeek: number = (dateValue).getDay();
        return (DayWeek !== 0) && (DayWeek !== 6);
    }

    private _validateMaxAndMinDate(): void {
        const maxDateAux: Date = new Date();
        this.minDate = this._getMinDate();
        this.maxDate = moment(this.minDate).toDate();
        this.maxDate = moment(this.minDate).add(this._getMaxDayLimitByRoleUser(), 'days').toDate();
    }

    private _getMaxDayLimitByRoleUser(): number {
        const currentUserIsSupport: boolean = this._authenticationService.tokenValue.authorities.filter(role => role === 'ROLE_SUPPORT').length > 0;
        const currentUserIsFinancial: boolean = this._authenticationService.tokenValue.authorities.filter(role => role === 'ROLE_FINANCIAL').length > 0;
        const currentUserIsDeveloper: boolean = this._authenticationService.tokenValue.authorities.filter(role => role === 'ROLE_DEVELOPERS').length > 0;

        if (currentUserIsSupport) {
            return 3;
        } else if (currentUserIsFinancial || currentUserIsDeveloper) {
            return 6;
        }

        return 6;
    }

    public validateAccess(authorities: TypeAuthorities): boolean {
        return this._validateAccessService.validateAccess(authorities);
    }

    public confirm(): void {
        try {
            if (!this.isValid()) {
                return;
            }

            this.loading = true;

            this.entity.deleted = false;
            this.entity.reason = !StringUtil.isEmpty(this.entity.reason) ? this.entity.reason : '';

            this.service.save(this.entity).toPromise().then(() => {
                this._alertService.addSuccessMessage('Operação realizada com sucesso');
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        }
    }

}
