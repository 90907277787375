import { Component, ContentChildren, HostBinding, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ActionRowComponent } from '../action-row/action-row.component';

@Component({
    selector: 'gipi-abstract',
    exportAs: 'gipiAbstract',
    templateUrl: './abstract.component.html',
    styleUrls: ['./abstract.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof AbstractComponent => AbstractComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-abstract-form',
    }
})
export class AbstractComponent implements OnInit {

    @HostBinding('attr.id') attrId: string;

    @HostBinding('attr.name') attrName: string;

    @HostBinding('style.margin')
    get attrMargin() {
        return this.dialog ? '0' : '0 auto';
    }

    @ContentChildren(ActionRowComponent) actionRowComponent: ActionRowComponent;

    @Input() set id(value: string) {
        if (!value) {
            this.attrId = value;
        }
    }

    @Input() set name(value: string) {
        if (value) {
            this.attrName = value;
        }
    }

    @Input() flexDirection: 'row' | 'column' = 'column';

    @Input() gap: string | number = 32;

    @Input() alignItems: 'start' | 'center' | 'end' | 'flex-start' | 'flex-end' | 'stretch' = 'stretch';

    @Input() justifyContent: 'start' | 'center' | 'end' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly' | 'stretch' = 'flex-start';

    @Input() padding: string | number = 16;

    @Input() dialog: boolean = false;

    @Input() loading: boolean = false;

    get getPadding(): string {
        if (typeof this.padding === 'string' && this.padding.includes('px')) {
            return this.padding;
        }

        return this.padding + 'px';
    }

    get getGap(): string {
        if (typeof this.gap === 'string' && this.gap.includes('px')) {
            return this.gap;
        }

        return this.gap + 'px';
    }

    constructor() { }

    ngOnInit(): void { }

}
