import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { LicenseGeneratorModel } from '../models/license-generator.model';
import { GenericService } from 'src/app/shared/services/generic.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseGeneratorService extends GenericService<LicenseGeneratorModel> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this._baseUrl = HttpClientBase.baseURLLicense;
    this._resource = 'license-generator'; // Não pode remover a barra, pois a rota não é encontrada sem ela no endpoint do save
    this._url = `${this._baseUrl}${this._resource}`;
  }


  public licenseGenerator(cnpj_or_cpf: string, typeLicense: number): Promise<LicenseGeneratorModel> {

    return new Promise((resolve) => {
      this._licenseGenerator(cnpj_or_cpf, typeLicense).subscribe((result) => {
        resolve(result);
      }, (error) => {
        resolve(null);
      });
    });

  }

  // GET - Gera a licença offline
  // TypeLicense === 0 ---> SUMMARIZED
  // TypeLicense === 1 ---> COMPLETE
  private _licenseGenerator(cnpj_or_cpf: string, typeLicense: number): Observable<LicenseGeneratorModel> {
    let lTypeLicense: string = (typeLicense === 0 ? 'SUMMARIZED' : 'COMPLETE');
    let lUrl = `${this._url}/${cnpj_or_cpf}?typeLicense=${lTypeLicense}`;
    return this._httpClient.get<LicenseGeneratorModel>(lUrl).pipe(
      map((object) => object));
  }

}
