import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnInit } from '@angular/core';

export type LozengeType = 'default' | 'new' | 'information' | 'warning' | 'error' | 'success' | 'advance' | 'disabled';

@Component({
    selector: `gipi-lozenge,gipi-lozenge[gipi-default],gipi-lozenge[gipi-new],gipi-lozenge[gipi-information],
               gipi-lozenge[gipi-warning],gipi-lozenge[gipi-error],gipi-lozenge[gipi-success],gipi-lozenge[gipi-advance],gipi-lozenge[gipi-disabled],`,
    templateUrl: './lozenge.component.html',
    styleUrls: ['./lozenge.component.scss']
})
export class LozengeComponent implements OnInit {

    readonly isLozengeDefault: boolean = this._hasHostAttributes('gipi-default');
    readonly isLozengeNew: boolean = this._hasHostAttributes('gipi-new');
    readonly isLozengeInformation: boolean = this._hasHostAttributes('gipi-information');
    readonly isLozengeWarning: boolean = this._hasHostAttributes('gipi-warning');
    readonly isLozengeError: boolean = this._hasHostAttributes('gipi-error');
    readonly isLozengeSuccess: boolean = this._hasHostAttributes('gipi-success');
    readonly isLozengeAdvance: boolean = this._hasHostAttributes('gipi-advance');
    readonly isLozengeDisabled: boolean = this._hasHostAttributes('gipi-disabled');

    private _label: string = '';
    @Input() get label(): string {
        return this._label;
    }
    set label(value: string) {
        this._label = value;
    }

    private _tooltip: string = '';
    @Input() get tooltip(): string {
        return this._tooltip;
    }
    set tooltip(value: string) {
        this._tooltip = value;
    }

    private _type: LozengeType = 'default';
    @Input() get type(): LozengeType {
        return this._type;
    }
    set type(value: LozengeType) {
        this._type = value;
    }

    private _filled: boolean = false;
    @Input() get filled(): boolean {
        return this._filled;
    }
    set filled(value: boolean) {
        this._filled = coerceBooleanProperty(value);
    }

    constructor(public elementRef: ElementRef) { }

    ngOnInit(): void { }

    public getHostElement(): any {
        return this.elementRef.nativeElement;
    }

    private _hasHostAttributes(...attributes: string[]): boolean {
        return attributes.some(attribute => this.getHostElement().hasAttribute(attribute));
    }

}
