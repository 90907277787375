import { EnumContentType } from '../../shared/enums/enum-content-type.enum';

export class Library {

    static RemoveCharacter(value: string, removeAll: boolean = false): string {

        let lValue = value;

        lValue = lValue.replace(/[ÀÁÂÃÄÅ]/g, "A");
        lValue = lValue.replace(/[àáâãäå]/g, "a");

        lValue = lValue.replace(/[ÈÉÊË]/g, "E");
        lValue = lValue.replace(/[éèêë]/g, "e");

        lValue = lValue.replace(/[ÍÌÎÏ]/g, "I");
        lValue = lValue.replace(/[íìîï]/g, "i");

        lValue = lValue.replace(/[ÓÒÕÔÖ]/g, "O");
        lValue = lValue.replace(/[óòõôö]/g, "o");

        lValue = lValue.replace(/[ÚÙÛÜ]/g, "U");
        lValue = lValue.replace(/[úùûü]/g, "u");

        lValue = lValue.replace(/[Ç]/g, "C");
        lValue = lValue.replace(/[ç]/g, "c");

        if (removeAll) {
            lValue = lValue.replace(/[^a-z0-9]/gi, '');
        }

        return lValue;

    }

    /**
     * Modo de usar: this.downloadToFile(passa o conteudo a ser salvo, 'nome_arquivo.txt', 'tipo_do_conteudo');
     * ContentTypes: text/plain || aplication/json
     * @param content Conteúdo que será salvo.
     * @param filename Nome do arquivo juntamente com a extensão. Ex: arquivo.txt
     * @param contentType Tipo de conteudo. Ex: text/plain para arquivos .txt, etc.
     */
    static downloadToFile(content, filename, contentType: EnumContentType) {

        const a = document.createElement('a');
        const file = new Blob([content], { type: contentType });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();

        URL.revokeObjectURL(a.href);
    }

    /**
     * Função utiliazada na comparação de campos dos objetos para realizar ordenação na tabela.
     * @param a Campo do objeto A
     * @param b Campo do objeto B
     * @param isAsk Tipo de ordenação ASK | DESC
     * @returns retorna um number
     */
    static compareSort(a: number | string, b: number | string, isAsk: boolean): number {
        return (a < b ? -1 : 1) * (isAsk ? 1 : -1);
    }

    /**
     * Função utiliazada na comparação de campos do tipo data dos objetos para realizar ordenação na tabela.
     * @param a Campo do objeto A
     * @param b Campo do objeto B
     * @param isAsk Tipo de ordenação ASK | DESC
     * @returns retorna um number
     */
    static compareSortDate(a: Date, b: Date, isAsk: boolean): number {
        return (new Date(a) < new Date(b) ? -1 : 1) * (isAsk ? 1 : -1);
    }

    static debounceTime(delay: number, callback: Function) {
        let timeoutId: number;
        return function () {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(callback, delay);
        };
    }

}
