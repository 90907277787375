<div class="background">
    <div
         style="flex-direction: column; box-sizing: border-box; display: flex; place-content: center; align-items: center;">
        <div
             style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center; align-items: center; flex: 1 1 100vh; max-height: 100vh; min-height: 100vh;">
            <div style="flex-direction: row; box-sizing: border-box; display: flex;">
                <mat-card class="mat-card"
                          style="flex: 1 1 0%; box-sizing: border-box;">
                    <mat-card-content>
                        <div
                             style="flex-direction: column; box-sizing: border-box; display: flex; place-content: center; align-items: center;">
                            <img src="assets/login/logo-login.png"
                                 alt="logo"
                                 width="180">
                        </div>
                        <div style="margin-bottom: 10px; margin-top: 30px;">
                            <p class="text-confirm-access"
                               *ngIf="!wasSent">Para recuperar sua senha, digite abaixo o
                                e-mail que você usa para acessar o sistema. <br> Enviaremos um e-mail com as instruções.
                                Se você não utiliza mais o e-mail cadastrado, fale com seu supervisor.</p>
                            <p class="text-confirm-access"
                               *ngIf="wasSent">Enviamos um link para você recuperar a sua
                                senha no: "{{ emailUser }}". <br><br> Não recebeu o e-mail? <br><br>
                                Confira sua caixa de spam. Se também não estiver lá, solicite o link novamente, ou fale
                                com seu superior.</p>
                        </div>

                        <div style="flex-direction: column; box-sizing: border-box; display: flex;  margin-top: 25px;">
                            <!--Input Username-->
                            <div style="margin-bottom: 0px !important; margin-top: 25px;"
                                 *ngIf="!wasSent">
                                <div style="width: 100%;">
                                    <mat-label class="mat-label"
                                               style="margin: 0px 0px 0px 0px;">
                                        E-mail <span style="color: #f44336!important;"> * </span>
                                    </mat-label>
                                    <mat-form-field floatLabel="always"
                                                    appearance="outline"
                                                    style="width: 100%;">
                                        <input matInput
                                               tabindex="0"
                                               type="email"
                                               id="email"
                                               style="text-transform: lowercase;"
                                               autocomplete="off"
                                               maxlength="100"
                                               [formControl]="inputTextEmail" />
                                        <mat-error
                                                   *ngIf="inputTextEmail.errors && (inputTextEmail.dirty || inputTextEmail.touched)">
                                            <p *ngIf="inputTextEmail.errors.required">
                                                O e-mail é obrigatório e não foi informado
                                            </p>
                                            <p *ngIf="inputTextEmail.errors.email">
                                                O e-mail informado não é válido
                                            </p>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div
                             style="margin-bottom: 10px; margin-top: 20px; flex-direction: row; display: flex; align-items: center; justify-content: center;">

                            <!--Button recuperar senha-->
                            <button mat-raised-button
                                    class="button-recovery button-text"
                                    [disabled]="submitted"
                                    (click)="( wasSent ? resendEmail() : recoverPassword() )">
                                <div class="div-spinner-text">
                                    <mat-spinner style="margin-right: 5px;"
                                                 [ngClass]="{'custom-spinner': submitted}"
                                                 [diameter]="22"
                                                 *ngIf="submitted"></mat-spinner>
                                    <span>{{ wasSent ? 'Reenviar e-mail' : 'Recuperar senha' }}</span>
                                </div>
                            </button>

                            <!--Button cancelar-->
                            <button mat-raised-button
                                    class="button-cancel button-text"
                                    style="margin-left: 15px;"
                                    (click)="goBack()">
                                <span> {{ wasSent ? 'Voltar ao login' : 'Cancelar' }} </span>
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>