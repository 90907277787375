import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TypeAuthorities } from '@gipi-ui/enums/enum-authorities.enum';
import { SidenavMenuModel } from '@gipi-ui/models/sidenav-menu.model';
import { BreakpointObserverService } from '@gipi-ui/services/breakpoint-observer.service';
import { SidenavMenuService } from '@gipi-ui/services/sidenav-menu.service';
import { ValidateAccessService } from '@gipi-ui/services/validate-access.service';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';

@Component({
    selector: `gipi-sidenav`,
    exportAs: 'gipiSidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof SidenavComponent => SidenavComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-sidenav',
    },
    animations: [
        trigger('indicatorExpanded', [
            state('collapsed', style({ transform: 'rotate(0deg)' })),
            state('expanded', style({ transform: 'rotate(180deg)' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('indicatorOpened', [
            state('closed', style({ transform: 'rotate(0deg)' })),
            state('opened', style({ transform: 'rotate(90deg)' })),
            transition('opened <=> closed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('indicatorMenuOpened', [
            state('closed', style({ height: '0px', minHeight: '0' })),
            state('opened', style({ height: '*' })),
            transition('opened <=> closed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class SidenavComponent implements OnInit, AfterViewInit {

    @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;

    @Input() menuList: SidenavMenuModel[] = [];

    @Input() labelToggle: string = 'Retrair menu';

    @Input() labelSyncFinancial: string;
    @Input() iconSyncFinancial: string;
    @Input() svgIconSyncFinancial: string;
    @Input() showSyncFinancial: boolean = false;
    @Input() disabledSync: boolean = false;

    @Input() labelConfiguration: string;
    @Input() iconConfiguration: string;
    @Input() svgIconConfiguration: string;
    @Input() showConfiguration: boolean = false;

    @Output() onClickMenuItem: EventEmitter<SidenavMenuModel> = new EventEmitter<SidenavMenuModel>();
    @Output() onClickMenuSync: EventEmitter<void> = new EventEmitter<void>();
    @Output() onClickMenuConfiguration: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _validateAccessService: ValidateAccessService,
        private _sidenavMenuService: SidenavMenuService,
        private _breakpointObserverService: BreakpointObserverService,
        private _router: Router,
    ) { }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        this._sidenavMenuService.sidenavMenuList = this.menuList;
    }

    public get isExpanded(): Observable<boolean> {
        return this._sidenavMenuService.isExpanded;
    }

    public get isScreenLarge(): Observable<boolean> {
        return this._breakpointObserverService.size$.pipe(
            map(size => (size !== 'xs') && (size !== 'sm') && (size !== 'md'))
        );
    }

    public toggleSidenav(): void {
        this._sidenavMenuService.toggle();
    }

    public isMenuActive(menu: SidenavMenuModel): boolean {
        const routeUrl = this._router.url.split('/');
        const menuRoute = menu.route.replace('/', '');
        return (routeUrl.findIndex(r => r === menuRoute) >= 0);
    }

    public isMenuChildrenActive(menu: SidenavMenuModel): boolean {
        const routeUrl = this._router.url.replace('/', '');
        const menuRoute = menu.route.replace('/', '');
        return (routeUrl === menuRoute);
    }

    public isMenuCollapsableActive(menu: SidenavMenuModel): boolean {
        if (ArrayUtil.isEmpty(menu.childrenList)) {
            return false;
        }

        const routeUrl = this._router.url.replace('/', '');
        const hasActive: SidenavMenuModel = menu.childrenList.find(mc => routeUrl === mc.route.replace('/', ''));
        return !ObjectUtil.isNull(hasActive);
    }

    public onMenuCollapsableSelected(menu: SidenavMenuModel): void {
        this.isExpanded.subscribe(expanded => {
            if (expanded) {
                this.menuList.filter(m => m !== menu).map(m => m.expanded = false);
                menu.expanded = !menu.expanded;
            }
        });
    }

    public onMenuItemSelected(menu: SidenavMenuModel = null, closeNav: boolean = true): void {
        if (closeNav) {
            this.isScreenLarge.subscribe(isScreenLarge => {
                if (!isScreenLarge) {
                    this._sidenavMenuService.retract();
                }
            });
        }

        this.onClickMenuItem.emit(menu);
    }

    public onClickMenuItemSync(): void {
        if (this.disabledSync) {
            return;
        }
        this.onMenuItemSelected(null);
        this.onClickMenuSync.emit();
    }

    public validateAccess(authorities: TypeAuthorities[]): boolean {
        const hasAccess: TypeAuthorities[] = authorities.filter(authority => this._validateAccessService.validateAccess(authority));
        return !ArrayUtil.isEmpty(hasAccess);
    }

}
