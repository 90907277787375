<div class="input-wrapper">

    <label *ngIf="label"
           class="input-label"
           [attr.for]="'dateRangeInput'">
        {{ label }}
        <span *ngIf="required"> * </span>
    </label>

    <div class="input-content">
        <mat-date-range-input id="dateRangeInput"
                              [min]="minDate"
                              [max]="maxDate"
                              [disabled]="disabled"
                              [required]="required"
                              [separator]="separator"
                              [rangePicker]="rangepicker">
            <input inputRef
                   matStartDate
                   class="input-element"
                   [attr.id]="idStartDate"
                   [attr.name]="nameStartDate"
                   [placeholder]="placeholderStartDate"
                   [disabled]="disabled"
                   [required]="required"
                   [readOnly]="true"
                   [autocomplete]="'off'"
                   [autofocus]="false"
                   [(ngModel)]="startDate"
                   (ngModelChange)="setStartValue($event)" />

            <input inputRef
                   matEndDate
                   class="input-element"
                   [attr.id]="idEndDate"
                   [attr.name]="nameEndDate"
                   [placeholder]="placeholderEndDate"
                   [disabled]="disabled"
                   [required]="required"
                   [readOnly]="true"
                   [autocomplete]="'off'"
                   [autofocus]="false"
                   [(ngModel)]="endDate"
                   (ngModelChange)="setEndValue($event)" />
        </mat-date-range-input>

        <div class="suffix">
            <mat-datepicker-toggle matSuffix
                                   [for]="rangepicker">
            </mat-datepicker-toggle>
        </div>
    </div>

    <small *ngIf="help"
           class="input-help">
        {{ help }}
    </small>
</div>

<mat-date-range-picker #rangepicker
                       [maxRange]="maxRange">
</mat-date-range-picker>