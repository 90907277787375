import { ObjectUtil } from './object.util';


export class StringUtil {

    public static EMPTY: string = '';

    public static replaceAccents(value: string): string {
        return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    public static removeAccents(value: string): string {
        return value.replace(/[^\w\s]/gi, '');
    }

    public static isEmpty(value: string): boolean {
        return ObjectUtil.isNull(value) || value.length === 0;
    }

    public static format(value: string, mask: string): string {
        let formatted: string = value.replace(/\D/g, '');
        const pad: string = mask.replace(/\D/g, '').replace(/9/g, '_');
        const valueMask: string = formatted + pad.substring(0, pad.length - pad.length);

        let valorMaskPos: number = 0;
        formatted = '';
        for (let i: number = 0; i < mask.length; i++) {
            // tslint:disable-next-line:radix
            if (isNaN(parseInt(mask.charAt(i)))) {
                formatted += mask.charAt(i);
            } else {
                formatted += valueMask[valorMaskPos++];
            }
        }

        if (formatted.indexOf('_') > -1) {
            formatted = formatted.substring(0, formatted.indexOf('_'));
        }

        return formatted;
    }

    public static truncateWithEllipses(text: string, max: number) {
        return text.trim().substring(0, max - 1) + (text.trim().length > max) ? '...' : '';
    }
}
