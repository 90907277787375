import { ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlockedClient } from './../../enums/blocked-client.enum';

import { AbstractListDialogComponent, ListDialogData } from '@gipi-pages/abstract/abstract-list-dialog.component';
import { SortModel } from '@gipi-pages/abstract/models/sort.model';
import { BlockedClientEnum } from '@gipi-pages/clients/enums/blocked-client.enum';
import { MigratedClient, MigratedClientEnum } from '@gipi-pages/clients/enums/migrated-client.enum';
import { ClientConsultDTO } from '@gipi-pages/clients/models/dto/client-consult.dto';
import { ClientFilterDTO } from '@gipi-pages/clients/models/dto/client-filter.dto';
import { ClientService } from '@gipi-pages/clients/services/client.service';
import { CheckboxComponent } from '@gipi-ui/components/checkbox/checkbox.component';
import { LozengeComponent } from '@gipi-ui/components/lozenge/lozenge.component';
import { TablePaginatorEvent } from '@gipi-ui/components/mat-table/mat-table.component';
import { TableColumn } from '@gipi-ui/components/table/shared/table-column';
import { TableColumnBuilder } from '@gipi-ui/components/table/shared/table-column-builder';
import { AlertService } from '@gipi-ui/services/alert.service';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';

@Component({
    selector: 'gipi-client-list-dialog',
    templateUrl: './client-list-dialog.component.html',
    styleUrls: ['./client-list-dialog.component.scss']
})
export class ClientListDialogComponent extends AbstractListDialogComponent<ClientConsultDTO, ClientFilterDTO> implements OnInit {

    @ViewChild('launchTemplate', { static: true }) launchTemplate: TemplateRef<HTMLElement>;
    @ViewChild('checkboxAllTemplate', { static: true }) checkboxAllTemplate: TemplateRef<CheckboxComponent>;
    @ViewChild('checkboxRowTemplate', { static: true }) checkboxRowTemplate: TemplateRef<CheckboxComponent>;
    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<LozengeComponent>;

    public blockedClientEnum: typeof BlockedClientEnum = BlockedClientEnum;
    public blockedClientValue: BlockedClient = 'ALL_CLIENTS';

    public migratedClientEnum: typeof MigratedClientEnum = MigratedClientEnum;
    public migratedClientValue: MigratedClient = 'ALL_CLIENTS';

    public allRowsChecked: boolean = false;
    public indeterminateRowsChecked: boolean = false;

    public entitiesSelected: ClientConsultDTO[] = [];

    public get entitiesSelectedIsEmpty(): boolean {
        return ArrayUtil.isEmpty(this.entitiesSelected);
    }

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _alertService: AlertService,
        protected service: ClientService,
        protected dialogRef: MatDialogRef<ClientListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ListDialogData<ClientConsultDTO> = { entitiesSelected: [], selectSingle: false },
    ) {
        super(service, dialogRef, data);
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (!ObjectUtil.isNull(this.data) && !ArrayUtil.isEmpty(this.data.entitiesSelected)) {
            this.entitiesSelected = ArrayUtil.clone(this.data.entitiesSelected);
        }
    }

    protected _newFilter(): ClientFilterDTO {
        const filter: ClientFilterDTO = new ClientFilterDTO();
        filter.blocked = false;
        filter.enabled = true;
        filter.hasFinancial = true;
        filter.clientIdsExcluded = [];
        filter.researchField = '';
        return filter;
    }

    protected _createTableColumns(): TableColumn[] {
        return [
            TableColumnBuilder
                .instance()
                .property('launch')
                .template(this.launchTemplate)
                .action(() => { })
                .width(20)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('checkbox')
                .templateHeader(this.checkboxAllTemplate)
                .template(this.checkboxRowTemplate)
                .action(() => { })
                .width(60)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('cpfOrCnpj')
                .description('CPF | CNPJ')
                .value((obj: ClientConsultDTO) => !StringUtil.isEmpty(obj.cpfOrCnpj) ? StringUtil.format(obj.cpfOrCnpj, (obj.type === 'LEGAL_PERSON') ? '00.000.000/0000-00' : '000.000.000-00') : '')
                .width(150)
                .build(),
            TableColumnBuilder
                .instance()
                .property('person.name')
                .description('Razão social')
                .sliceLength(50)
                .sortable(true)
                .value((obj: ClientConsultDTO) => !StringUtil.isEmpty(obj.name) ? obj.name : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('person.fantasyName')
                .description('Nome fantasia')
                .sliceLength(50)
                .sortable(true)
                .value((obj: ClientConsultDTO) => !StringUtil.isEmpty(obj.fantasyName) ? obj.fantasyName : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('blocked')
                .description('Bloqueado')
                .sortable(true)
                .value((obj: ClientConsultDTO) => obj.blocked ? 'Sim' : 'Não')
                .width(110)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('status')
                .description('Status')
                .template(this.statusTemplate)
                .width(130)
                .align('center')
                .build(),
        ];
    }

    public confirm(): void {
        this.dialogRef.close(this.entitiesSelected);
    }

    public close(): void {
        this.dialogRef.close(this.data.entitiesSelected);
    }

    public findAll(pageEvent?: TablePaginatorEvent): void {
        try {
            this.loading = true;

            this.page = this._newPage();
            if (ObjectUtil.isNull(this.filter)) {
                this.filter = this._newFilter();
            }

            this.filter.blocked = null;
            if (this.blockedClientValue === 'BLOCKED') {
                this.filter.blocked = true;
            } else if (this.blockedClientValue === 'NOT_BLOCKED') {
                this.filter.blocked = false;
            }

            this.filter.hasFinancial = null;
            if (this.migratedClientValue === 'HAS_FINANCIAL') {
                this.filter.hasFinancial = true;
            } else if (this.migratedClientValue === 'NOT_HAS_FINANCIAL') {
                this.filter.hasFinancial = false;
            }

            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;

                if (!ObjectUtil.isNull(pageEvent.sort) && !StringUtil.isEmpty(pageEvent.sort.field)) {
                    this.filter.sorts = [new SortModel(pageEvent.sort.field, (pageEvent.sort.direction || 'DESC'))];
                }
            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 10;
            }

            this.service.findAll(this.filter).toPromise().then(page => {
                this.page = page;
                this._resetRowsSelected();
                this._remarkRowsSelected();

                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            throw new Error(e);
        } finally {
            this._changeDetectorRef.detectChanges();
        }
    }

    private _resetRowsSelected(): void {
        this.allRowsChecked = false;
        this.indeterminateRowsChecked = false;

        for (let i = 0; i < this.page.content.length; i++) {
            this.page.content[i].selected = false;
        }

        this.validateAllRowsSelected();
    }

    private _remarkRowsSelected(): void {
        setTimeout(() => {
            if (!this.entitiesSelectedIsEmpty && !ArrayUtil.isEmpty(this.page.content)) {
                for (let i = 0; i < this.page.content.length; i++) {
                    const isSelected = this.entitiesSelected.findIndex(e => e.id === this.page.content[i].id) >= 0;
                    if (isSelected) {
                        this.page.content[i].selected = true;
                    }
                }

                this.validateAllRowsSelected();
            }
        });
    }

    public validateAllRowsSelected(): void {
        this.allRowsChecked = this.entitiesSelected.length > 0;
        this.indeterminateRowsChecked = this.entitiesSelected.length > 0 && this.entitiesSelected.length !== this.page.totalElements;
    }

    public checkAllRows(): void {
        this.entitiesSelected = [];
        for (let i = 0; i < this.page.content.length; i++) {
            this.page.content[i].selected = this.allRowsChecked;
            this.selectRow(this.page.content[i], false);
        }

        this.validateAllRowsSelected();
    }

    public selectRow(entity: ClientConsultDTO, validateAllSelected: boolean): void {
        if (entity.selected) {
            this.entitiesSelected.push(entity);
        } else {
            const index: number = this.entitiesSelected.findIndex((i) => i.id === entity.id);
            this.entitiesSelected.splice(index, 1);
        }

        if (validateAllSelected) {
            this.validateAllRowsSelected();
        }
    }

    public rowChange(entity: ClientConsultDTO): void {
        entity.selected = !entity.selected;
        this.selectRow(entity, true);
    }

}
