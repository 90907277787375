import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

import { PageModel } from '@gipi-pages/abstract/models/page.model';
import { SortModel } from '@gipi-pages/abstract/models/sort.model';
import { AbstractService } from '@gipi-pages/abstract/services/abstract.service';
import { ConfigurationModel } from '@gipi-pages/configuration/models/configuration.model';
import { ConfigurationService } from '@gipi-pages/configuration/services/configuration.service';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { DateUtil } from '@gipi-ui/utils/date.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { DelayBlockingModel } from '../models/delay-blocking.model';
import { DelayBlockingFilterDTO } from '../models/dto/delay-blocking-filter.dto';

@Injectable({ providedIn: 'root' })
export class DelayBlockingService extends AbstractService<DelayBlockingModel, DelayBlockingFilterDTO> {

    constructor(
        protected httpClient: HttpClient,
        private _authenticationService: AuthenticationService,
        private _configurationService: ConfigurationService,
    ) {
        super('LICENSE', 'delay-blocking', httpClient);
    }

    public findByCnpj(cnpj: string, bringDeleted: boolean, pageNumber: number, pageSize: number, sort?: SortModel): Observable<PageModel<DelayBlockingModel>> {
        const pageNumberAux: string = (pageNumber >= 0 ? `&page=${pageNumber}` : '');
        const pageSizeAux: string = `&size=${pageSize || 10}`;

        let sortAux: string = '&sort=createdDate,DESC';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            sortAux = `&sort=${sort.field},${sort.direction}`;
        }

        const urlAux = `cnpj/${cnpj}?bringDeleted=${bringDeleted}${pageNumberAux}${pageSizeAux}${sortAux}`;

        return this.httpClient.get<PageModel<DelayBlockingModel>>(this.url(urlAux, 'LICENSE'), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    public search(filter: DelayBlockingFilterDTO): Observable<PageModel<DelayBlockingModel>> {
        return this.httpClient.post(this.url('search', 'LICENSE'), filter, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    public findByCreatedDateAndCnpj(createdDate: Date, cnpjOrCpf: string): Promise<DelayBlockingModel[]> {
        return new Promise(resolve => {
            this._findByCreatedDateAndCnpj(createdDate, cnpjOrCpf).subscribe((result) => {
                resolve(result);
            }, (error) => {
                resolve(null);
            });
        });
    }

    private _findByCreatedDateAndCnpj(createdDate: Date, cnpj: string): Observable<DelayBlockingModel[]> {
        const urlAux = `find-by-created-date-greater-than-equal-and-cnpj/${DateUtil.format(createdDate, 'yyyy-MM-dd')}/${cnpj}`;

        return this.httpClient.get<DelayBlockingModel[]>(this.url(urlAux, 'LICENSE'), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    public async verifyIfPermittedDelayBlockingSupport(cnpjOrCpf: string): Promise<boolean> {
        const initialPeriod: Date = new Date();
        const startDate: Date = new Date();
        startDate.setDate(1);

        initialPeriod.setDate(startDate.getDate() - 10);

        const lDelayBlockingList: DelayBlockingModel[] = await this.findByCreatedDateAndCnpj(initialPeriod, cnpjOrCpf);
        if (ArrayUtil.isEmpty(lDelayBlockingList)) {
            return true;
        }

        // Se existe uma prorrogação que está ativa, bloqueia para que não seja criada uma nova prorrogação. Agora se existe uma prorrogação inativa e
        // o usuário for do suporte, verifica se a prorrogação é correspondente ao mês atual, caso seja ai bloqueia para não criar uma nova.
        const existDelayActive: boolean = lDelayBlockingList.filter(d => this.delayBlockingIsActive(d.deadLine)).length > 0;
        if (existDelayActive) {
            return false;
        } else {
            if (this._authenticationService.getUserRolesByAuthorities() === 'ROLE_SUPPORT') {
                const configuration: ConfigurationModel = await this._configurationService.findEnabled().toPromise();
                let maximumQuantity: number = 0;
                if (!ObjectUtil.isNull(configuration)) {
                    maximumQuantity = configuration.maximumQuantityOfMonthlyLicenseExtension;
                }

                const delayInactiveList: DelayBlockingModel[] = lDelayBlockingList.filter(d => !this.delayBlockingIsActive(d.deadLine));

                const quantityDelayInactiveInCurrentMonth: number = delayInactiveList.filter(d => {
                    const currentMonth: number = new Date().getMonth();
                    const delayMonth: number = new Date(d.createdDate).getMonth();

                    if (currentMonth === delayMonth) {
                        return d;
                    } else {
                        return null;
                    }
                }).length;

                if ((quantityDelayInactiveInCurrentMonth > 0) && (quantityDelayInactiveInCurrentMonth === maximumQuantity)) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }
    }

    public async verifyIfExistDelayActive(cnpjOrCpf: string): Promise<boolean> {
        const initialPeriod: Date = new Date();
        const startDate: Date = new Date();
        startDate.setDate(1);

        initialPeriod.setDate(startDate.getDate() - 10);

        const delayBlockingList: DelayBlockingModel[] = await this.findByCreatedDateAndCnpj(initialPeriod, cnpjOrCpf);
        if (ArrayUtil.isEmpty(delayBlockingList)) {
            return true;
        }

        // Se existe uma prorrogação que está ativa, bloqueia para que não seja criada uma nova prorrogação
        const existDelayActive: boolean = delayBlockingList.filter(d => this.delayBlockingIsActive(d.deadLine)).length > 0;
        if (existDelayActive) {
            return false;
        }

        return true;
    }

    public delayBlockingIsActive(date: Date): boolean {
        const lDateDeadLine: string = moment(date).format('YYYY/MM/DD');
        const lDateNow: string = moment(new Date()).format('YYYY/MM/DD');

        let dateDeadline: moment.Moment = moment(lDateDeadLine, 'YYYY/MM/DD');
        let dateNow: moment.Moment = moment(lDateNow, 'YYYY/MM/DD');

        if (dateNow.isAfter(dateDeadline)) {
            return false;
        }

        return true;
    }

}
