<gipi-abstract>
    <gipi-toolbar id="toolbarListDelayBlocking"
                  label="Consulta de prorrogação de licença">
    </gipi-toolbar>

    <div fxLayout="column"
         fxLayoutGap="10px"
         fxFlex>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-text label="CPF | CNPJ"
                             id="delayBlockingDocument"
                             name="delayBlockingDocument"
                             [disabled]="true"
                             [value]="documentClient"
                             fxFlex="20"
                             fxFlex.lt-md="100">
            </gipi-input-text>
            <gipi-input-text label="Nome"
                             id="delayBlockingName"
                             name="delayBlockingName"
                             [disabled]="true"
                             [value]="nameClient"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-input-text>
        </div>

        <mat-slide-toggle [disabled]="loading"
                          [(ngModel)]="bringDeleted"
                          (change)="findAll(null)">
            Exibir registros excluídos
        </mat-slide-toggle>
    </div>

    <gipi-mat-table [columns]="columns"
                    [rows]="page.content"
                    [sort]="true"
                    [sortActive]="'createdDate'"
                    [sortDirection]="'DESC'"
                    [paginator]="true"
                    [pageSize]="5"
                    [pageIndex]="page.number"
                    [pageLength]="page.totalElements"
                    [loading]="loading"
                    (pageChange)="findAll($event)"
                    (rowChange)="showDialogNewDelayBlocking('EDIT', $event)">
        <ng-template #actions
                     let-row>
            <gipi-button tooltip="Deletar"
                         gipi-icon
                         id="btnDelete"
                         svgIcon="delete"
                         *ngIf="validateDeleted(row.deleted, row.deadLine)"
                         [forInput]="true"
                         (click)="deleteDelayBlocking(row)">
            </gipi-button>
        </ng-template>
    </gipi-mat-table>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Novo"
                     gipi-primary
                     id="btnNewDelayBlocking"
                     name="btnNewDelayBlocking"
                     [disabled]="loading || !permittedDelayBlocking"
                     [loading]="loading"
                     (click)="showDialogNewDelayBlocking('NEW')"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract>

<ng-template #statusTemplate
             let-row>
    <gipi-lozenge [label]="getLabelStatus(row.deleted, row.deadLine)"
                  [type]="getTypeStatus(row.deleted, row.deadLine)">
    </gipi-lozenge>
</ng-template>
