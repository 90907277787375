import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { GenericService } from 'src/app/shared/services/generic.service';
import { TerminalAppModel } from '../../../models/terminal-app.model';

@Injectable({ providedIn: 'root' })
export class TerminalAppService extends GenericService<TerminalAppModel> {

    constructor(protected httpClient: HttpClient) {
        super(httpClient);
        this._baseUrl = HttpClientBase.baseURLApoio;
        this._resource = 'terminal-app';
        this._url = `${this._baseUrl}${this._resource}`;
    }

    public deleteById(id: number): Promise<boolean> {
        return new Promise((resolve) => {
            this.genericDeleteById(id).subscribe((result) => {
                resolve(result);
            }, (error) => {
                resolve(false);
            });
        });
    }

    public deleteAll(terminalAppList: TerminalAppModel[]): Promise<boolean> {
        return new Promise((resolve) => {
            this.genericDeleteAll(terminalAppList).subscribe((result) => {
                resolve(result);
            }, (error) => {
                resolve(false);
            });
        });
    }

}
