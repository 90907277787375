<div mat-dialog-title
     class="modal-dialog-title">
    <mat-icon *ngIf="data?.type === 'warning'"
              svgIcon="modal_warning">
    </mat-icon>
    <mat-icon *ngIf="data?.type === 'danger'"
              svgIcon="modal_danger">
    </mat-icon>
    <mat-icon *ngIf="data?.type === 'information'"
              svgIcon="modal_information">
    </mat-icon>
    <span>
        {{ data?.title || 'Confirmação' }}
    </span>
</div>
<div mat-dialog-content
     class="modal-dialog-content">
    <p *ngIf="data?.message && !data?.listMessage"
       class="message">
        {{ data?.message }}
    </p>
    <ul *ngIf="!data?.message && data?.listMessage"
        class="list-message">
        <li *ngFor="let message of data?.listMessage">
            {{ message }}
        </li>
    </ul>
</div>
<div mat-dialog-actions
     class="modal-dialog-actions">
    <button id="btnConfirm"
            class="btn-action confirm"
            [class.warning]="data?.type === 'warning'"
            [class.danger]="data?.type === 'danger'"
            [class.information]="data?.type === 'information'"
            *ngIf="data?.showConfirm"
            [mat-dialog-close]="true">
        {{ data?.labelConfirm || 'Confirmar' }}
    </button>
    <button id="btnCancel"
            *ngIf="data?.showCancel"
            class="btn-action cancel"
            [mat-dialog-close]="false">
        {{ data?.labelCancel || 'Cancelar' }}
    </button>
</div>
