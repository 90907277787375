<div class="background">
    <div
         style="flex-direction: column; box-sizing: border-box; display: flex; place-content: center; align-items: center;">
        <div
             style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center; align-items: center; flex: 1 1 100vh; max-height: 100vh; min-height: 100vh;">
            <div style="flex-direction: row; box-sizing: border-box; display: flex;">
                <mat-card class="mat-card"
                          style="flex: 1 1 0%; box-sizing: border-box;">
                    <mat-card-content>
                        <div
                             style="flex-direction: column; box-sizing: border-box; display: flex; place-content: center; align-items: center;">
                            <img src="assets/login/logo-login.png"
                                 alt="logo"
                                 width="180">
                        </div>
                        <div style="margin-bottom: 10px; margin-top: 30px;">
                            <p class="text-confirm-access">Olá bem vindo a <a href=""> GIPI Sistemas </a>
                                e-mail que você usa para acessar o sistema. <br> Enviaremos um e-mail com as instruções.
                                Se você não utiliza mais o e-mail cadastrado, fale com seu supervisor.</p>
                        </div>
                        <div style="flex-direction: column; box-sizing: border-box; display: flex;  margin-top: 25px;">
                        </div>
                        <div
                             style="margin-bottom: 10px; margin-top: 20px; flex-direction: row; display: flex; align-items: center; justify-content: center;">
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
