import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { LozengeType } from '@gipi-ui/components/lozenge/lozenge.component';
import { Library } from '@gipi-ui/global/library';
import { COLOR, SimpleStatusCell } from 'src/app/components/global/simple-status-cell/model/simple-status-cell.model';
import { DialogConfirmComponent } from 'src/app/components/shared/dialog-confirm/dialog-confirm.component';
import { TYPE_BUTTONS, TYPE_DIALOG } from 'src/app/components/shared/dialog-confirm/models/dialog-confirm.model';
import { TerminalAppModel } from '../../../../models/terminal-app.model';
import { TerminalAppService } from '../../services/terminal-app.service';

@Component({
    selector: 'gipi-table-client-information',
    templateUrl: './table-client-information.component.html',
    styleUrls: ['./table-client-information.component.scss']
})
export class TableClientInformationComponent implements OnInit {

    public dataSource: MatTableDataSource<TerminalAppModel>;

    private _dataSourceInitial: TerminalAppModel[] = [];

    public columnsToDisplay: string[] = ['select', 'name', 'softwareVersion', 'currentVersion', 'availableVersion', 'dateTimeLastAccess', 'dateTimeSoftwareUpdate', 'softwarePath', 'updateStatus', 'action'];

    public simpleStatusCell: SimpleStatusCell;

    public apps: TerminalAppModel[] = [];

    public hoverbutton: number;

    public selection: SelectionModel<TerminalAppModel> = new SelectionModel<TerminalAppModel>(true, []);

    public selectedCount: number = 0;

    @Input() inputApps: TerminalAppModel[] = [];

    @Input() inputIdClient: number = -1;

    @Output() outputTerminalAppSelected: EventEmitter<TerminalAppModel[]> = new EventEmitter<TerminalAppModel[]>();

    @Output() outputRefreshOneClient: EventEmitter<number> = new EventEmitter<number>();

    // @Output() dialogClose: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private _terminalAppService: TerminalAppService,
        private _dialogConfirm: MatDialog
    ) { }

    ngOnInit(): void {
        setTimeout(() => {
            this.apps = this.inputApps;
            this.dataSource = new MatTableDataSource(this.apps);
            this._dataSourceInitial = this.dataSource.data.slice();
            this.sortData({ active: 'softwarePath', direction: 'asc' });
        });
    }

    public async onClickDeleteApp(id: number): Promise<void> {
        const resultDialogConfirm: boolean = await this.openDialogConfirm();
        if (resultDialogConfirm) {
            // Aqui emito um evento passando o retorno da função de deletar la para a tela de consulta de clientes (TableClientsUpdate), para dar refresh nos terminais,
            // pois a consulta é realizada lá. Assim sendo, os terminais irão ser atualizados também atualizando os apps.
            const resultDelete: boolean = await this._terminalAppService.deleteById(id);
            if (resultDelete) {
                // EventEmitterService.get('EventRefreshUpdateClientsOne').emit(this.inputIdClient);
                this.outputRefreshOneClient.emit(this.inputIdClient);
            }
        }
    }

    public openDialogConfirm(): Promise<boolean> {
        return new Promise(resolve => {
            const dialogRef: MatDialogRef<DialogConfirmComponent> = this._dialogConfirm.open(DialogConfirmComponent, {
                width: '300px',
                data: { type: TYPE_DIALOG.CONFIRMATION, message: 'Deseja realmente excluir este registro?', buttons: TYPE_BUTTONS.SI_NO }
            });

            dialogRef.afterClosed().subscribe(result => {
                resolve(result);
            });
        });
    }

    public getLabelStatus(status: string): string {
        switch (status) {
            case 'UPDATED': return 'Atualizado';
            case 'NOTUPDATED': return 'Desatualizado';
            case 'UPDATE': return 'A atualizar';
            case 'RELEASE': return 'A liberar';
            case 'NOTERMINAL': return 'Sem terminal';
            case 'NOAPPLICATION': return 'Sem aplicativos';
            case 'TOPVERSION': return 'Versão superior';
            default: return 'Não identificado';
        }
    }

    public getTypeStatus(status: string): LozengeType {
        switch (status) {
            case 'UPDATED': return 'success';
            case 'NOTUPDATED': return 'error';
            case 'UPDATE': return 'information';
            case 'RELEASE': return 'warning';
            case 'NOTERMINAL': return 'disabled';
            case 'NOAPPLICATION': return 'new';
            case 'TOPVERSION': return 'advance';
            default: return 'default';
        }
    }

    public loadStatus(updateStatus: string): void {
        let color: COLOR;
        let text: string;
        switch (updateStatus) {
            // Atualizado
            case 'UPDATED': {
                color = COLOR.green;
                text = 'Atualizado';
                break;
            }
            // Desatualizado
            case 'NOTUPDATED': {
                color = COLOR.red;
                text = 'Desatualizado';
                break;
            }
            // A atualizar
            case 'UPDATE': {
                color = COLOR.blue;
                text = 'A atualizar';
                break;
            }
            // A liberar
            case 'RELEASE': {
                color = COLOR.orange;
                text = 'A liberar';
                break;
            }
            // Sem terminal
            case 'NOTERMINAL': {
                color = COLOR.gray;
                text = 'Sem terminal';
                break;
            }
            // Sem applicativos
            case 'NOAPPLICATION': {
                color = COLOR.purple;
                text = 'Sem aplicativos';
                break;
            }
            // Versão Superior
            case 'TOPVERSION': {
                color = COLOR.darkGreen;
                text = 'Versão superior';
                break;
            }
        }
        this.simpleStatusCell = { color, text };
    }

    public sortData(sort: Sort): void {
        if (!this.dataSource.data) {
            return;
        }

        const lData: TerminalAppModel[] = this.dataSource.data.slice();

        if ((!sort.active) || (!sort.direction)) {
            this.dataSource.data = this._dataSourceInitial.slice();
            return;
        }

        this.dataSource.data = lData.sort((a, b) => {
            const lIsAsk: boolean = sort.direction === 'asc';
            switch (sort.active) {
                case 'name': {
                    const lFieldA: string | number = (a.app ? a.app.name : null);
                    const lFieldB: string | number = (b.app ? b.app.name : null);
                    return Library.compareSort(lFieldA, lFieldB, lIsAsk);
                }
                case 'softwareVersion': {
                    const lFieldA: string | number = a.softwareVersion;
                    const lFieldB: string | number = b.softwareVersion;
                    return Library.compareSort(lFieldA, lFieldB, lIsAsk);
                }
                case 'currentVersion': {
                    const lFieldA: string | number = (a.app ? a.app.currentVersion : null);
                    const lFieldB: string | number = (b.app ? b.app.currentVersion : null);
                    return Library.compareSort(lFieldA, lFieldB, lIsAsk);
                }
                case 'availableVersion': {
                    const lFieldA: string | number = (a.app ? a.softwareVersionForUpdate : null);
                    const lFieldB: string | number = (b.app ? b.softwareVersionForUpdate : null);
                    return Library.compareSort(lFieldA, lFieldB, lIsAsk);
                }
                case 'dateTimeLastAccess': {
                    const lFieldA: Date = (a.dateTimeLastAccess ? a.dateTimeLastAccess : null);
                    const lFieldB: Date = (b.dateTimeLastAccess ? b.dateTimeLastAccess : null);
                    return Library.compareSortDate(lFieldA, lFieldB, lIsAsk);
                }
                case 'dateTimeSoftwareUpdate': {
                    const lFieldA: Date = (a.dateTimeSoftwareUpdate ? a.dateTimeSoftwareUpdate : null);
                    const lFieldB: Date = (b.dateTimeSoftwareUpdate ? b.dateTimeSoftwareUpdate : null);
                    return Library.compareSortDate(lFieldA, lFieldB, lIsAsk);
                }
                case 'softwarePath': {
                    const lFieldA: string | number = a.softwarePath;
                    const lFieldB: string | number = b.softwarePath;
                    return Library.compareSort(lFieldA, lFieldB, lIsAsk);
                }
            }
        });
    }

    public masterToggle(): void {
        this.selectedCount = this.selection.selected.length;

        if (this.selectedCount > 0) {
            this.selection.clear();
        } else {
            this.dataSource.data.forEach((dataRow) => {
                if (this.dataSource.filteredData.indexOf(dataRow) !== -1) {
                    this.selection.select(dataRow)
                }
            });
        }
    }

    public onChangeSelectedCount(): void {
        this.outputTerminalAppSelected.emit(this.selection.selected);
    }

}
