import { AfterViewInit, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { DialogConfirmComponent } from 'src/app/components/shared/dialog-confirm/dialog-confirm.component';
import { TYPE_BUTTONS, TYPE_DIALOG } from 'src/app/components/shared/dialog-confirm/models/dialog-confirm.model';
import { EnumTypeTable } from 'src/app/shared/enums/enum-type-table.enum';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { SnackbarService } from '../../../../../../global/snackbar/services/snackbar.service';
import { FilesModel } from '../../models/files.model';
import { NcmVersionModel } from '../../models/ncm-version.model';
import { NcmModel } from '../../models/ncm.model';
import { StateModel } from '../../models/state.model';
import { UpdateTablesNcmService } from '../../services/update-tables-ncm.service';
import { NcmVersionService } from './../../services/ncm-version.service';

@Component({
    selector: 'gipi-drag-and-drop',
    templateUrl: './drag-and-drop.component.html',
    styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent implements AfterViewInit {

    public listStatesModel: StateModel[] = [];

    public listFilesModel: FilesModel[] = [];

    public isLoading: boolean = false;

    constructor(
        private _matIconRegistry: MatIconRegistry,
        private _domSanitizer: DomSanitizer,
        private _snackbarService: SnackbarService,
        private _updateTablesNcmService: UpdateTablesNcmService,
        private _ncmVersionService: NcmVersionService,
        private _router: Router,
        private _dialogConfirm: MatDialog
    ) {
        this._matIconRegistry.addSvgIcon('upload', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/update-tables/upload.svg'));
        this._matIconRegistry.addSvgIcon('files', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/update-tables/files.svg'));
        this._matIconRegistry.addSvgIcon('send-ncm-all', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/update-tables/send-ncm-all.svg'));
        this._matIconRegistry.addSvgIcon('remove-ncm', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/update-tables/remove-ncm.svg'));
        this._matIconRegistry.addSvgIcon('remove-ncm-all', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/update-tables/remove-ncm-all.svg'));
        this._matIconRegistry.addSvgIcon('release-client', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/update-tables/release-client.svg'));
        this._matIconRegistry.addSvgIcon('release-client-all', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/update-tables/release-client-all.svg'));
    }

    ngAfterViewInit(): void {
        this.findAllStates();
    }

    public onFileDropped(event: any): void {
        this.prepareFilesList(event);
    }

    public fileBrowseHandler(event: any): void {
        this.prepareFilesList(event.target.files);
    }

    private prepareFilesList(files: File[]): void {
        const lFiles: any = files;

        for (let lCount: number = 0; lCount < lFiles.length; lCount++) {
            const lFileModel: FilesModel = {};
            lFileModel.file = lFiles[lCount];
            lFileModel.synchronized = false;
            lFileModel.nameFile = lFiles[lCount].name;
            lFileModel.sizeFile = this.formatBytes(lFiles[lCount].size);
            lFileModel.idState = this.getStateByFileName(lFiles[lCount].name);
            lFileModel.releasedClient = false;
            lFileModel.ncmVersion = {
                endDate: new Date(),
                state: {},
                version: ''
            };

            this.listFilesModel.push(lFileModel);
        }

        const element = document.getElementById('fileDropRef') as HTMLInputElement;
        element.value = '';
    }

    private formatBytes(bytes: number): string {
        if (bytes === 0) {
            return '0 Bytes';
        }

        const Kbytes = 1024;
        const UnitMeasure: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const lSize: number = Math.floor(Math.log(bytes) / Math.log(Kbytes));
        return parseFloat((bytes / Math.pow(Kbytes, lSize)).toFixed(2)) + ' ' + UnitMeasure[lSize];
    }

    private getStateByFileName(fileName: string): number {
        const lDefaultFileName: string = 'TabelaIBPTax';
        const lClippedFileName: string = fileName.substring(0, 12);

        if (lClippedFileName.includes(lDefaultFileName)) {

            const lStateAcronym: string = fileName.substring(12, 14);

            for (let lCount: number = 0; lCount < this.listStatesModel.length; lCount++) {
                if (this.listStatesModel[lCount].acronym === lStateAcronym) {
                    return this.listStatesModel[lCount].id;
                }
            }
        }
    }

    private async findAllStates(): Promise<void> {
        await this._updateTablesNcmService.findAllStates().then(stateModel => {
            if (stateModel) {
                this.listStatesModel = stateModel.content;
            }
        });
    }

    private convertCsvToText(index: number): Promise<string | ArrayBuffer> {
        return new Promise(resolve => {
            const lFileReader = new FileReader();
            lFileReader.readAsText(this.listFilesModel[index].file, 'ISO-8859-1');
            lFileReader.onload = () => {
                const lText: string | ArrayBuffer = lFileReader.result;
                resolve(lText);
            }
        });
    }

    private convertTextToJson(csvText: any, index: number): Promise<NcmModel[]> {
        return new Promise(resolve => {
            let lListNcmConverted: NcmModel[] = [];
            const lLines: any[] = [];
            const lLinesArray: any = csvText.split('\n');

            // Remover espaços extras
            lLinesArray.forEach(element => {
                const lRow: any = element.replace(/\s+/g, ' ').trim();
                lLines.push(lRow);
            });

            // Para remover o registro vazio
            lLines.slice(lLines.length - 1, 1);

            const lListNcmCsv: any[] = [];
            const lHeaders: any = lLines[0].split(';');

            for (let lRow: number = 1; lRow < lLines.length; lRow++) {
                const lObject = {};
                const lRemoveFirstQuotes: any = lLines[lRow].replace(';"', ';');
                const lRemoveLastQuotes: any = lRemoveFirstQuotes.replace('";', ';');
                const lCurrentLine: any = lRemoveLastQuotes.split(';');

                for (let lRowHeader: number = 0; lRowHeader < lHeaders.length; lRowHeader++) {
                    lObject[lHeaders[lRowHeader]] = lCurrentLine[lRowHeader];
                }

                lListNcmCsv.push(lObject);
            }

            for (let lCountObject: number = 0; lCountObject < lListNcmCsv.length - 1; lCountObject++) {
                if ((lListNcmCsv[lCountObject].tipo === '0') && (lListNcmCsv[lCountObject].ex === '')) {
                    /*Extrai a data final da vigência o id do estado e a versão da primeira linha*/
                    if (lCountObject === 0) {
                        const lEndDate: string = lListNcmCsv[lCountObject].vigenciafim.split('/').reverse().join('-');
                        this.listFilesModel[index].ncmVersion.endDate = new Date(lEndDate);
                        this.listFilesModel[index].ncmVersion.state.id = Number(this.listFilesModel[index].idState);
                        this.listFilesModel[index].ncmVersion.version = String(lListNcmCsv[lCountObject].versao);
                    }

                    const lNcm: NcmModel = {
                        codeNcm: '',
                        description: '',
                        federalImportedAliquot: -1,
                        federalNationalAliquot: -1,
                        state: {},
                        stateAliquot: -1,
                        typeTable: EnumTypeTable.NCM,
                    };

                    lNcm.codeNcm = String(lListNcmCsv[lCountObject].codigo).substring(0, 8);
                    lNcm.description = String(lListNcmCsv[lCountObject].descricao).substring(0, 200);
                    lNcm.federalImportedAliquot = Number(lListNcmCsv[lCountObject].importadosfederal);
                    lNcm.federalNationalAliquot = Number(lListNcmCsv[lCountObject].nacionalfederal);
                    lNcm.state.id = Number(this.listFilesModel[index].idState);
                    lNcm.stateAliquot = Number(lListNcmCsv[lCountObject].estadual);
                    lNcm.typeTable = (lListNcmCsv[lCountObject].tipo === '0' ? EnumTypeTable.NCM : EnumTypeTable.NBS);

                    if (lListNcmConverted.filter(ncm => ncm.codeNcm === lNcm.codeNcm).length <= 0) {
                        lListNcmConverted.push(lNcm);
                    } else {
                        this._snackbarService.showMessage(`O NCM código ${lNcm.codeNcm} está repetido, remova o NCM incorreto para prosseguir com a sincronização`, true);
                        lListNcmConverted = [];
                        break;
                    }
                }
            }
            resolve(lListNcmConverted);
        });
    }

    private updateNotifications(): void {
        EventEmitterService.get('EventUpdateNotifications').emit(true);
    }

    private saveNcm(textJson: NcmModel[], index: number): Promise<boolean> {
        return new Promise(async resolve => {
            await this._updateTablesNcmService.saveAll(textJson).then(() => {
                this.listFilesModel[index].synchronized = true;
                resolve(true);
            }, (error) => {
                resolve(false);
            });
        });
    }

    public async onClickSaveNcm(index: number = -1): Promise<void> {
        let lText: string = '';
        if (index < 0) {
            lText = 'Deseja realmente sincronizar todos os arquivos?';
        } else {
            lText = `Deseja realmente sincronizar o arquivo ${this.listFilesModel[index].nameFile}?`;
        }

        const resultDialogConfirm: boolean = await this.openDialogConfirm(-1, TYPE_DIALOG.CONFIRMATION, lText, TYPE_BUTTONS.SI_NO);
        if (resultDialogConfirm) {
            this.isLoading = true;

            if (index < 0) {
                for (let lCount: number = 0; lCount < this.listFilesModel.length; lCount++) {
                    if (this.listFilesModel[lCount].idState < 0) {
                        this._snackbarService.showMessage(`Campo "Estado" do arquivo ${this.listFilesModel[lCount].nameFile} é obrigatório e não foi preenchido`, true);
                        this.isLoading = false;
                        return;
                    }

                    const lCsvText: string | ArrayBuffer = await this.convertCsvToText(lCount);
                    const lTextJson: NcmModel[] = await this.convertTextToJson(lCsvText, lCount);

                    if (lTextJson && lTextJson.length > 0) {
                        const lReturnSave: boolean = await this.saveNcm(lTextJson, lCount).catch(error => {
                            this._snackbarService.showMessage(error, true);
                            return false;
                        });

                        if (lReturnSave) {
                            this.listFilesModel[lCount].synchronized = true;
                            this._snackbarService.showMessage(`Arquivo ${this.listFilesModel[lCount].nameFile} sincronizado com sucesso`, false);
                            continue;
                        } else {
                            this._snackbarService.showMessage(`Erro ao sincronizar o arquivo ${this.listFilesModel[lCount].nameFile}`, true);
                            this.isLoading = false;
                            return;
                        }
                    } else {
                        this.isLoading = false;
                        return;
                    }
                }
            } else {
                const lCsvText: string | ArrayBuffer = await this.convertCsvToText(index);
                const lTextJson: NcmModel[] = await this.convertTextToJson(lCsvText, index);

                if (lTextJson && lTextJson.length > 0) {
                    const lReturnSave: boolean = await this.saveNcm(lTextJson, index).catch(error => {
                        this._snackbarService.showMessage(error, true);
                        return false;
                    });

                    if (lReturnSave) {
                        this.listFilesModel[index].synchronized = true;
                        this._snackbarService.showMessage(`Arquivo ${this.listFilesModel[index].nameFile} sincronizado com sucesso`, false);
                    } else {
                        this._snackbarService.showMessage(`Erro ao sincronizar o arquivo ${this.listFilesModel[index].nameFile}`, true);
                        this.isLoading = false;
                        return;
                    }
                }
            }
            this.isLoading = false;
        }
    }

    private saveNcmVersion(ncmVersion: NcmVersionModel, index: number): Promise<boolean> {
        return new Promise(async resolve => {
            await this._ncmVersionService.save(ncmVersion).then(() => {
                this.listFilesModel[index].releasedClient = true;
                resolve(true);
            })
        });

    }

    public async onClickSaveNcmVersion(index: number = -1): Promise<void> {
        let lText: string = '';
        if (index < 0) {
            lText = 'Deseja realmente liberar todos os arquivos para os clientes?';
        } else {
            lText = `Deseja realmente liberar o arquivo ${this.listFilesModel[index].nameFile} para os clientes?`;
        }

        const resultDialogConfirm: boolean = await this.openDialogConfirm(-1, TYPE_DIALOG.CONFIRMATION, lText, TYPE_BUTTONS.SI_NO)
        if (resultDialogConfirm) {
            this.isLoading = true;
            if (index < 0) {
                for (let lCount: number = 0; lCount < this.listFilesModel.length; lCount++) {
                    if (this.listFilesModel[lCount].ncmVersion.state.id < 0) {
                        this._snackbarService.showMessage(`Campo "Estado" do arquivo ${this.listFilesModel[lCount].nameFile} é obrigatório e não foi preenchido`, true);
                        this.isLoading = false;
                        return;
                    }

                    const lReturnSave: boolean = await this.saveNcmVersion(this.listFilesModel[lCount].ncmVersion, lCount);
                    if (lReturnSave) {
                        this.listFilesModel[lCount].releasedClient = true;
                        this._snackbarService.showMessage(`Arquivo ${this.listFilesModel[lCount].nameFile} liberado aos clientes com sucesso`, false);
                        continue;
                    } else {
                        this._snackbarService.showMessage(`Erro ao liberar o arquivo ${this.listFilesModel[lCount].nameFile} aos clientes`, true);
                        this.isLoading = false;
                        return;
                    }
                }
            } else {
                const lReturnSave: boolean = await this.saveNcmVersion(this.listFilesModel[index].ncmVersion, index);
                if (lReturnSave) {
                    this.listFilesModel[index].releasedClient = true;
                    this._snackbarService.showMessage(`Arquivo ${this.listFilesModel[index].nameFile} liberado aos clientes com sucesso`, false);
                } else {
                    this._snackbarService.showMessage(`Erro ao liberar o arquivo ${this.listFilesModel[index].nameFile} aos clientes`, true);
                    this.isLoading = false;
                    return;
                }
            }

            this.updateNotifications();
            this.isLoading = false;
        }
    }

    public async onClickRemoveFile(index: number = -1): Promise<void> {
        let lText: string = '';
        if (index < 0) {
            lText = 'Deseja realmente remover todos os arquivos?';
        } else {
            lText = `Deseja realmente remover o arquivo ${this.listFilesModel[index].nameFile}?`;
        }

        const resultDialogConfirm: boolean = await this.openDialogConfirm(-1, TYPE_DIALOG.CONFIRMATION, lText, TYPE_BUTTONS.SI_NO)
        if (resultDialogConfirm) {
            const element = document.getElementById('fileDropRef') as HTMLInputElement;
            if (index < 0) {
                element.value = '';
                this.listFilesModel = [];
            } else {
                this.listFilesModel.splice(index, 1);
                element.value = '';
            }
        }
    }

    public async goBack(): Promise<void> {
        const resultDialogConfirm: boolean = await this.openDialogConfirm(-1, TYPE_DIALOG.CONFIRMATION, 'Deseja realmente voltar para a consulta?', TYPE_BUTTONS.SI_NO)
        if (resultDialogConfirm) {
            this._router.navigate(['/update-tables/ncm']);
        }
    }

    private openDialogConfirm(id: number = -1, typeDialog: TYPE_DIALOG, message: string, buttons: TYPE_BUTTONS): Promise<boolean> {
        const dialogRef: MatDialogRef<DialogConfirmComponent> = this._dialogConfirm.open(DialogConfirmComponent, {
            width: '320px',
            data: { type: typeDialog, message: message, buttons: buttons }
        });

        return new Promise(resolve => {
            dialogRef.afterClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

}