import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { InputRefDirective } from './shared/input-ref.directive';

let nextUniqueId = 0;

type InputType = 'text' | 'number' | 'email' | 'search' | 'select';

@Component({
    selector: 'gipi-input-text',
    exportAs: 'gipiInputText',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof InputTextComponent => InputTextComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-input-text'
    }
})
export class InputTextComponent implements OnInit, ControlValueAccessor {

    @ViewChild(InputRefDirective, { static: true }) inputRef!: InputRefDirective;

    private _name: string = `gipi-input-text-${nextUniqueId++}`;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    @Input() label: string = '';

    @Input() placeholder: string = '';

    @Input() type: InputType = 'text';

    @Input() mask: string = '';

    @Input() max: string = '';

    @Input() min: string = '';

    @Input() pattern!: string | RegExp;

    @Input() help: string = '';

    @Input() maxLength: number = 250;

    @Input() minLength: number = 0;

    @Input() iconPrefix: string = '';
    @Input() svgIconPrefix: string = '';

    @Input() iconSuffix: string = '';
    @Input() svgIconSuffix: string = '';

    private _autocomplete: 'on' | 'off' = 'off';
    @Input() get autocomplete(): 'on' | 'off' {
        return this._autocomplete;
    }
    set autocomplete(value: 'on' | 'off') {
        this._autocomplete = value;
    }

    private _autofocus: boolean = false;
    @Input() get autofocus(): boolean {
        return this._autofocus;
    }
    set autofocus(value: boolean) {
        this._autofocus = coerceBooleanProperty(value);
    }

    private _showPrefix: boolean = false;
    @Input() get showPrefix(): boolean {
        return this._showPrefix;
    }
    set showPrefix(value: boolean) {
        this._showPrefix = coerceBooleanProperty(value);
    }

    private _showSuffix: boolean = false;
    @Input() get showSuffix(): boolean {
        return this._showSuffix;
    }
    set showSuffix(value: boolean) {
        this._showSuffix = coerceBooleanProperty(value);
    }

    private _readOnly: boolean = false;
    @Input() get readOnly(): boolean {
        return this._readOnly;
    }
    set readOnly(value: boolean) {
        this._readOnly = coerceBooleanProperty(value);
    }

    private _required: boolean = false;
    @Input() get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    private _disabled: boolean = false;
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _lowerCase: boolean = false;
    @Input() get lowerCase(): boolean {
        return this._lowerCase;
    }
    set lowerCase(value: boolean) {
        this._lowerCase = coerceBooleanProperty(value);
    }

    private _upperCase: boolean = false;
    @Input() get upperCase(): boolean {
        return this._upperCase;
    }
    set upperCase(value: boolean) {
        this._upperCase = coerceBooleanProperty(value);
    }

    private _spaceDrop: boolean = false;
    @Input() get spaceDrop(): boolean {
        return this._spaceDrop;
    }
    set spaceDrop(value: boolean) {
        this._spaceDrop = coerceBooleanProperty(value);
    }

    private _value!: any;
    @Input()
    get value(): any {
        return this._value;
    }
    set value(value: any) {
        let valueAux: any = value;
        valueAux = this._checkSpaceDrop(valueAux);
        valueAux = this._checkLowercase(valueAux);
        valueAux = this._checkUppercase(valueAux);
        this._value = valueAux;

        this.onChange(this._value);
        this.onTouched(this._value);
    }

    onChange: Function = () => { };

    onTouched: Function = () => { };

    @Output('keyup') onKeyup: EventEmitter<MouseEvent> = new EventEmitter();
    @Output('blur') onBlur: EventEmitter<MouseEvent> = new EventEmitter();

    constructor(
        public elementRef: ElementRef<HTMLElement>,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void { }

    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }

    private _checkSpaceDrop(value: any): any {
        if (this.spaceDrop && value) {
            return value.replace(/\s/g, '');
        }
        return value;
    }

    private _checkUppercase(value: any): any {
        if (this.upperCase && value) {
            return value.toUpperCase();
        }
        return value;
    }

    private _checkLowercase(value: any): any {
        if (this.lowerCase && value) {
            return value.toLowerCase();
        }
        return value;
    }

}
