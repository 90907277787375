<div [style.flex-direction]="flexDirection"
     [style.align-items]="alignItems"
     [style.justify-content]="justifyContent"
     [style.gap]="getGap"
     [style.padding]="getPadding"
     [ngClass]="{'form-content': true,
                 'space-bottom': !dialog}">
    <ng-content> </ng-content>
</div>
<div class="form-footer"
     [style.margin-inline]="getPadding"
     *ngIf="actionRowComponent">
    <ng-content select="gipi-action-row"> </ng-content>
</div>