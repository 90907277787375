<div class="radio-group-wrapper">

    <label *ngIf="label"
           class="radio-group-label"
           [attr.for]="id">
        {{ label }}
        <span *ngIf="required"> * </span>
    </label>

    <mat-radio-group class="radio-group-content"
                     [class.align-row]="align === 'row'"
                     [class.align-column]="align === 'column'"
                     [attr.id]="id"
                     [attr.name]="name"
                     [disabled]="disabled"
                     [required]="required"
                     [(ngModel)]="value">
        <ng-container *ngFor="let item of _enumList; let i = index;">
            <mat-radio-button [disabled]="!validateOmitByKey(item.key)"
                              [id]="item.key + i"
                              [name]="item.value"
                              [value]="item.key">
                {{ item.value }}
            </mat-radio-button>
        </ng-container>
    </mat-radio-group>

    <small *ngIf="help"
           class="radio-group-help">
        {{ help }}
    </small>
</div>