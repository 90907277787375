<gipi-abstract>
    <gipi-toolbar id="toolbarRegisterNotification"
                  label="Cadastrar notificação">
    </gipi-toolbar>

    <div fxLayout="row"
         fxLayout.lt-lg="column"
         fxLayoutGap="16px"
         fxFlex>
        <div fxLayout="column"
             fxLayoutGap="16px"
             fxFlex="50">
            <div fxLayout="row wrap"
                 fxLayoutGap="16px"
                 fxLayoutGap.lt-md="0">
                <gipi-input-select label="Tipo de notificação"
                                   id="notificationType"
                                   name="notificationType"
                                   [disabled]="loading || isViewing"
                                   [required]="true"
                                   [valuesExclud]="[]"
                                   [options]="_optionsNotificationType"
                                   [property]="'description'"
                                   [(ngModel)]="entity.type"
                                   fxFlex="20"
                                   fxFlex.lt-md="100">
                </gipi-input-select>
                <gipi-input-text label="Título"
                                 id="notificationTitle"
                                 name="notificationTitle"
                                 [required]="true"
                                 [disabled]="loading || isViewing"
                                 [(ngModel)]="entity.title"
                                 [min]="3"
                                 [max]="80"
                                 [maxLength]="80"
                                 help="Tamanho ideal 50 caracteres"
                                 fxFlex
                                 fxFlex.lt-md="100">
                </gipi-input-text>
                <gipi-input-select-enum label="Situação"
                                        id="notificationEnabled"
                                        name="notificationEnabled"
                                        [disabled]="loading || !isEditing"
                                        [required]="true"
                                        [valuesExclud]="[]"
                                        [enum]="optionSituationEnum"
                                        [(ngModel)]="optionSituationValue"
                                        fxFlex="20"
                                        fxFlex.lt-md="100">
                </gipi-input-select-enum>
            </div>
            <div fxLayout="row wrap"
                 fxLayoutGap="16px"
                 fxLayoutGap.lt-md="0">
                <gipi-textarea label="Descrição"
                               id="notificationDescription"
                               name="notificationDescription"
                               [required]="true"
                               [disabled]="loading || isViewing"
                               [(ngModel)]="entity.description"
                               [minLength]="3"
                               [maxLength]="255"
                               fxFlex
                               fxFlex.lt-md="100">
                </gipi-textarea>
            </div>
            <div fxLayout="row wrap"
                 fxLayoutGap="16px"
                 fxLayoutGap.lt-md="0">
                <gipi-input-text label="URL do anexo (Saiba mais)"
                                 id="notificationDetailUrl"
                                 name="notificationDetailUrl"
                                 [iconSuffix]="!entity?.detailUrl && 'link'"
                                 [showSuffix]="entity?.detailUrl"
                                 [disabled]="loading || isViewing"
                                 [pattern]="'https?://.+'"
                                 [(ngModel)]="entity.detailUrl"
                                 [min]="3"
                                 [max]="255"
                                 [maxLength]="255"
                                 fxFlex
                                 fxFlex.lt-md="100">
                    <gipi-button tooltip="Remover"
                                 gipi-icon
                                 suffix
                                 *ngIf="entity?.detailUrl"
                                 id="btnClearDetailUrl"
                                 icon="close"
                                 [forInput]="true"
                                 [disabled]="loading || isViewing"
                                 (click)="entity.detailUrl = ''">
                    </gipi-button>
                </gipi-input-text>
            </div>
            <div fxLayout="row wrap"
                 fxLayoutGap="16px"
                 fxLayoutGap.lt-md="0">
                <gipi-input-select-listbox label="Clientes"
                                           id="notificationClient"
                                           name="notificationClient"
                                           [disabled]="loading || isViewing"
                                           [required]="true"
                                           [propertyFn]="clientNameFn"
                                           [onSearchFn]="onSearchClientsFn"
                                           [(ngModel)]="clientsSelecteds"
                                           fxFlex
                                           fxFlex.lt-md="100">
                </gipi-input-select-listbox>
            </div>
            <div class="content-period">
                <span> Período </span>

                <div fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="16px"
                     fxLayoutGap.lt-md="0">

                    <gipi-datetime-picker label="Data inicial"
                                          id="notificationStartDate"
                                          name="notificationStartDate"
                                          [required]="true"
                                          [disabled]="loading || isViewing"
                                          [min]="minStartDate"
                                          [(ngModel)]="entity.startDate"
                                          (ngModelChange)="validateEndDate()">
                    </gipi-datetime-picker>

                    <gipi-datetime-picker label="Data final"
                                          id="notificationEndDate"
                                          name="notificationEndDate"
                                          [required]="true"
                                          [disabled]="loading || isViewing"
                                          [min]="entity.startDate"
                                          [(ngModel)]="entity.endDate"
                                          (ngModelChange)="validateStartDate()">
                    </gipi-datetime-picker>
                </div>
            </div>

            <gipi-checkbox label="Fixar notificação"
                           gipi-primary
                           [(ngModel)]="entity.prioritizeVisualization">
            </gipi-checkbox>
        </div>
        <div fxFlex="50"
             fxLayoutAlign="center start">
            <div class="modal-notification">
                <div class="modal-image">
                    <img [attr.src]="entity?.type?.imageUrl || '../../../../../assets/logo/type-notification.svg'"
                         alt="Imagem para notificação"
                         width="550"
                         height="260"
                         style="object-fit: cover;">

                    <button class="button-close">
                        <mat-icon>close</mat-icon>
                    </button>

                    <div class="img-dimension"
                         matTooltip="Imagens de dimensões menores podem ficar pixeladas">
                        550 x 260
                    </div>
                </div>
                <div class="modal-content">
                    <span class="modal-header">
                        {{ entity?.title || 'Experimente a nossa nova tela de notificações' }}
                    </span>
                    <span class="modal-body">
                        {{ entity?.description || predefinedDescription }}
                    </span>
                    <div class="modal-learn-more">
                        <a class="btn-learn-more"
                           [matTooltip]="entity?.detailUrl ? '' : 'Se a URL do anexo não estiver definida o botão não será exibido'"
                           [class.invisible]="!entity?.detailUrl"
                           [href]="entity?.detailUrl || '#'"
                           target="_blank"
                           rel="noopener"
                           (click)="noRedirect($event)">
                            Saiba mais
                            <mat-icon>arrow_forward</mat-icon>
                        </a>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn-paginator"
                            [disabled]="true">
                        Anterior
                    </button>
                    <div class="progress-indicator">
                        <div class="dot active"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                    <button class="btn-paginator">
                        Próximo
                    </button>
                </div>
            </div>
        </div>
    </div>
    <gipi-action-row>
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     *ngIf="!isViewing"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="save()"
                     fxFlex="15"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     icon="arrow_back"
                     (click)="goBack()"
                     fxFlex="15"
                     fxFlex.lt-md="100">
        </gipi-button>
    </gipi-action-row>
</gipi-abstract>