<div class="table-list">

    <mat-progress-bar mode="indeterminate"
                      *ngIf="((isLoading) && (!containsElements))">
    </mat-progress-bar>

    <!-- Tables IBPTax -->
    <div style="padding-left: 50px; padding-top: 30px; overflow: hidden;"
         *ngIf="containsElements">
        <mat-list class="list-item">

            <div mat-list-item
                 class="div-item"
                 *ngFor="let ncmVersion of dataSource">
                <div class="line-header">
                    <mat-icon class="icon-item"
                              class="{{ loadStatusColor(ncmVersion.daysToEnd) }}"
                              matTooltip="{{ loadTooltip(ncmVersion.daysToEnd) }}">
                        {{ loadStatusIcon(ncmVersion.daysToEnd) }}
                    </mat-icon>
                    <p class="line-header-title">
                        {{ 'Tabela IBPTax - (' + ncmVersion.state.acronym + ')' }}
                    </p>
                </div>

                <mat-divider></mat-divider>

                <div class="line-item">
                    <div class="line-item-info">
                        <p class="line-item-description"> Estado </p>
                        <p class="line-item-text">
                            {{ ncmVersion.state.name + ' (' + ncmVersion.state.acronym + ')' }}
                        </p>
                    </div>
                </div>

                <div class="line-item">
                    <div class="line-item-info">
                        <p class="line-item-description"> Sincronização </p>
                        <p class="line-item-text"> {{ ncmVersion.modifiedDate | date:"dd/MM/yyyy" }} </p>
                    </div>

                    <div class="line-item-info">
                        <p class="line-item-description"> Fim da Vigência </p>
                        <p class="line-item-text"> {{ ncmVersion.endDate | date:"dd/MM/yyyy" }} </p>
                    </div>

                    <div class="line-item-info">
                        <p class="line-item-description"> Versão </p>
                        <p class="line-item-text"> {{ ncmVersion.version }} </p>
                    </div>
                </div>
            </div>

        </mat-list>

    </div>

    <!-- No records -->
    <div class="no-records"
         *ngIf="((!isLoading) && (!containsElements))">
        <mat-icon>
            sentiment_very_dissatisfied
        </mat-icon>
        <span class="">Nenhum registro foi encontrado</span>
    </div>

    <mat-paginator class="table-footer"
                   [pageSize]="pageSize"
                   [length]="totalElements"
                   [pageIndex]="pageNumber"
                   showFirstLastButtons
                   (page)="handlePaginator($event)">
    </mat-paginator>

</div>
