import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ClientModel } from './../models/client.model';

import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { GenericService } from 'src/app/shared/services/generic.service';

@Injectable({ providedIn: 'root' })
export class UpdateClientService extends GenericService<ClientModel> {

    constructor(protected httpClient: HttpClient) {
        super(httpClient);
        this._baseUrl = HttpClientBase.baseURLApoio;
        this._resource = 'client';
        this._url = `${this._baseUrl}${this._resource}`;
    }

    // Realiza a atualização dos aplicativos do cliente.
    public releaseVersionClient(clientIds: number[]): Promise<boolean> {
        return new Promise((resolve) => {
            this._releaseVersionClient(clientIds).subscribe((result) => {
                resolve(result);
            }, (error) => {
                resolve(null);
            })
        });
    }

    private _releaseVersionClient(clientIds: number[]): Observable<boolean> {
        const lUrl: string = `${this._url}/update-client`;
        return this._httpClient.post<number[]>(lUrl, clientIds).pipe(
            map(() => {
                return true;
            }),
            catchError(() => {
                return observableOf(false);
            })
        );
    }

    // Cancela a atualização dos aplicativos do cliente.
    public cancelVersionClient(clientIds: number[]): Promise<boolean> {
        return new Promise((resolve) => {
            this._cancelVersionClient(clientIds).subscribe((result) => {
                resolve(result);
            }, (error) => {
                resolve(null);
            })
        });
    }

    private _cancelVersionClient(clientIds: number[]): Observable<boolean> {
        const lUrl: string = `${this._url}/cancel-update-client`;
        return this.httpClient.post<number[]>(lUrl, clientIds).pipe(
            map(() => {
                return true;
            }),
            catchError(() => {
                return observableOf(false);
            })
        );
    }

    public findByTypeEnvironment(typeEnvironment: number, id: number = -1, updateCurrentVersionApp: boolean = false, syncClients: boolean = false): Promise<ClientModel[]> {
        return new Promise((resolve) => {
            this.genericFindByTypeEnvironment(typeEnvironment, id, updateCurrentVersionApp, syncClients).subscribe((listResult) => {
                resolve(listResult);
            }, (error) => {
                resolve(null);
            });
        });
    }

    public findById(id: number): Observable<ClientModel> {
        const lUrl: string = `${this._url}/${id}`;
        return this.httpClient.get<ClientModel>(lUrl).pipe(
            map(object => object)
        );
    }

    /*public updateHasFinancial(cpfOrCnpj: string, hasFinancial: boolean): Observable<boolean> {
        const lUrl: string = `${this._url}/update/has-financial/${cpfOrCnpj}/${hasFinancial}`;
        return this.httpClient.patch(lUrl, null).pipe(
            map(() => {
                return true;
            }),
            catchError(() => {
                return observableOf(false);
            })
        );
    }*/

    public updateHasFinancial(id: number, hasFinancial: boolean): Observable<boolean> {
        const lUrl: string = `${this._url}/update/has-financial/${id}/${hasFinancial}`;
        return this.httpClient.patch(lUrl, null).pipe(
            map(() => {
                return true;
            }),
            catchError(() => {
                return observableOf(false);
            })
        );
    }

}
