import { TemplateRef } from '@angular/core';

export class TableColumn {

    public id!: string;

    public description!: string;

    public property!: string;

    public value!: ((obj: object) => any);

    public sortable!: boolean;

    public visible!: boolean;

    public width!: number;

    public paddingInline!: number;

    public align!: 'left' | 'center' | 'right';

    public sliceLength!: number;

    public templateHeader!: TemplateRef<any>;

    public template!: TemplateRef<any>;

    public action!: (obj: object) => void;

    constructor(
        id: string,
        description: string,
        property: string,
        value: ((obj: object) => any),
        sortable: boolean,
        visible: boolean,
        width: number,
        paddingInline: number,
        align: 'left' | 'center' | 'right',
        sliceLength: number,
        templateHeader: TemplateRef<any>,
        template: TemplateRef<any>,
        action: (obj: object) => void,
    ) {
        this.id = id;
        this.description = description;
        this.property = property;
        this.value = value;
        this.sortable = sortable;
        this.visible = visible;
        this.width = width;
        this.paddingInline = paddingInline;
        this.align = align;
        this.sliceLength = sliceLength;
        this.templateHeader = templateHeader;
        this.template = template;
        this.action = action;
    }
}
