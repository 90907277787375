import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export abstract class GenericService<T> {

    protected _baseUrl: string = '';
    protected _resource: string = '';
    protected _url: string = '';

    constructor(protected _httpClient: HttpClient) { }

    protected genericSave(object: T): Observable<T> {
        return this._httpClient.post<T>(this._url, object).pipe(
            map((result) => {
                if (result) {
                    return result;
                }
            })
        );
    }

    protected genericSaveAll(listObject: T[]): Observable<T[]> {
        const lUrl: string = `${this._url}/save-all`;
        return this._httpClient.post<T[]>(lUrl, listObject).pipe(
            map((result) => {
                if (result) {
                    return result;
                }
            })
        );
    }

    protected genericDeleteById(id): Observable<boolean> {
        const lUrl: string = `${this._url}/${id}`;
        return this._httpClient.delete<T>(lUrl).pipe(
            map(() => {
                return true;
            }),
            catchError(() => {
                return observableOf(false);
            })
        );
    }

    protected genericDeleteAll(listObject: T[]): Observable<boolean> {
        const lUrl: string = `${this._url}/delete-all`;

        const lOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: listObject,
        };

        return this._httpClient.delete<T[]>(lUrl, lOptions).pipe(
            map((r) => {
                return true
            }),
            catchError(() => {
                return observableOf(false)
            })
        );
    }

    protected genericFindById(id: number): Observable<T> {
        const lUrl: string = `${this._url}/${id}`;
        return this._httpClient.get<T>(lUrl).pipe(
            map((result) => {
                if (result) {
                    return result;
                }
            })
        );
    }

    protected genericFindAllLastModifiedDate(lastModifiedDate: string = ''): Observable<T[]> {
        const lLastModifiedDate: string = (lastModifiedDate === '' ? '2000-01-01T00:00:00.763249Z' : lastModifiedDate)
        const lUrl: string = `${this._url}/find-all?lastModifiedDate=${lLastModifiedDate}`;
        return this._httpClient.get<T[]>(lUrl).pipe(
            map((result) => {
                if (result) {
                    return result;
                }
            })
        );
    }

    protected genericFindByTypeEnvironment(typeEnvironment: number, id: number = -1, updateCurrentVersionApp: boolean = false, syncClients: boolean = false): Observable<T[]> {
        const lTypeEnvironment: string = (typeEnvironment === 0 ? 'HOMOLOGATION' : 'PRODUCTION');

        let lId: string = '';
        let lUpdateCurrentVersionApp: string = ``;
        if (id > 0) {
            lId = `?id=${id}`;
            lUpdateCurrentVersionApp = `&updateCurrentVersionApp=${updateCurrentVersionApp}`;
        } else {
            lUpdateCurrentVersionApp = `?updateCurrentVersionApp=${updateCurrentVersionApp}`;
        }

        const lSyncClients: string = `&syncClients=${syncClients}`;

        const lUrl: string = `${this._url}/type-environment/${lTypeEnvironment}${lId}${lUpdateCurrentVersionApp}${lSyncClients}`;
        return this._httpClient.get<T[]>(lUrl).pipe(
            map((object) => object)
        );
    }

}
