import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { AbstractService } from '@gipi-pages/abstract/services/abstract.service';
import { ClientModel } from '@gipi-pages/clients/models/client.model';
import { ClientFilterDTO } from '@gipi-pages/clients/models/dto/client-filter.dto';
import { HttpClientBase } from 'src/app/core/request/httpClientBase';

@Injectable({ providedIn: 'root' })
export class BackupService extends AbstractService<ClientModel, ClientFilterDTO> {

    constructor(protected httpClient: HttpClient) {
        super('APOIO', 'client', httpClient);
    }

    public findByTypeEnvironment(): Observable<ClientModel[]> {
        const typeEnvironment: string = (HttpClientBase.typeEnvironment === 0 ? 'HOMOLOGATION' : 'PRODUCTION');
        const updateCurrentVersionApp = `?updateCurrentVersionApp=false`;
        const syncClients: string = `&syncClients=false`;

        return this.httpClient.get<ClientModel[]>(this.url(`type-environment/${typeEnvironment}${updateCurrentVersionApp}${syncClients}`)).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}

