import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FormDialogData } from '@gipi-pages/abstract/abstract-form-dialog.component';
import { AbstractListComponent } from '@gipi-pages/abstract/abstract-list.component';
import { AbstractFilterModel } from '@gipi-pages/abstract/models/abstract-filter.model';
import { TypeVersionModel } from '@gipi-pages/type-version/models/type-version.model';
import { TypeVersionService } from '@gipi-pages/type-version/services/type-version.service';
import { SortModel } from '@gipi-shared/models/sort.model';
import { TablePaginatorEvent } from '@gipi-ui/components/mat-table/mat-table.component';
import { TableColumn } from '@gipi-ui/components/table/shared/table-column';
import { TableColumnBuilder } from '@gipi-ui/components/table/shared/table-column-builder';
import { TypeOperationCloseDialog } from '@gipi-ui/enums/type-operation-close-dialog.enum';
import { TypeOperationDialog } from '@gipi-ui/enums/type-operation-dialog.enum';
import { Library } from '@gipi-ui/global/library';
import { AlertService } from '@gipi-ui/services/alert.service';
import { DialogService } from '@gipi-ui/services/dialog.service';
import { ModalDialogService } from '@gipi-ui/services/modal-dialog.service';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { DateUtil } from '@gipi-ui/utils/date.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TypeVersionFormDialogComponent } from '../type-version-form-dialog/type-version-form-dialog.component';

@Component({
    selector: 'gipi-type-version-list',
    templateUrl: './type-version-list.component.html',
    styleUrls: ['./type-version-list.component.scss']
})
export class TypeVersionListComponent extends AbstractListComponent<TypeVersionModel, AbstractFilterModel> implements OnInit, OnDestroy {

    private _subject: Subject<string> = new Subject<string>();

    public _dataSource: MatTableDataSource<TypeVersionModel> = null;

    public _entities: TypeVersionModel[] = [];

    public valueInput: string = '';

    constructor(
        protected service: TypeVersionService,
        private _router: Router,
        private _changeDetectorRef: ChangeDetectorRef,
        private _alertService: AlertService,
        private _modalDialogService: ModalDialogService,
        private _dialogService: DialogService,
    ) {
        super(service);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this._subject.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            if (StringUtil.isEmpty(value)) {
                this._dataSource.filter = '*';
            } else {
                this._dataSource.filter = value.trim().toLowerCase();
            }
        });

        this.findAll();
    }

    ngOnDestroy(): void {
        this._subject.unsubscribe();
    }

    protected getPath(): string {
        return `update-tables/type-version`;
    }

    protected _newFilter(): AbstractFilterModel {
        return new AbstractFilterModel();
    }

    protected _createTableColumns(): TableColumn[] {
        return [
            TableColumnBuilder
                .instance()
                .property('createdDate')
                .description('Data do cadastro')
                .value((obj: TypeVersionModel) => !ObjectUtil.isNull(obj.createdDate) ? DateUtil.format(obj.createdDate, DateUtil.DATE_FORMAT) : '')
                .width(160)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('initials')
                .description('Iniciais')
                .sliceLength(50)
                .value((obj: TypeVersionModel) => !StringUtil.isEmpty(obj.initials) ? obj.initials : '')
                .width(130)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('description')
                .description('Descrição')
                .sortable(true)
                .sliceLength(100)
                .value((obj: TypeVersionModel) => !StringUtil.isEmpty(obj.description) ? obj.description : '')
                .build(),
        ];
    }

    public refreshFindAll(): void {
        this._dataSource.data = [];
        this.findAll();
    }

    public findAll(pageEvent?: TablePaginatorEvent): void {
        try {
            this.loading = true;

            this.service.findByModifiedDate().toPromise().then(functionList => {
                this._entities = ArrayUtil.clone(functionList);
                this._dataSource = new MatTableDataSource(functionList);

                this._dataSource.filterPredicate = (data, filter) => this.filterEntities(data, filter);

                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            throw new Error(e);
        }

        this._changeDetectorRef.detectChanges();
    }

    public filterEntitiesByInput(calledButton: boolean = false): void {
        if (calledButton) {
            if (StringUtil.isEmpty(this.valueInput)) {
                this._dataSource.filter = '*';
            } else {
                this._dataSource.filter = this.valueInput.trim().toLowerCase();
            }
        } else {
            this._subject.next(this.valueInput);
        }
    }

    public filterEntities(data: TypeVersionModel, filter: string): boolean {
        try {
            this.loading = true;

            // Se o input estiver vazio, o filtro do datasource não é acionado; por outro lado, ao inserir um "*", o filtro é ativado conforme o esperado.
            if (filter === '*') {
                return true;
            } else {
                let lFilter: string = filter;
                lFilter = lFilter.replace(/[^0-9]/g, '');

                return (
                    data.description.toString().includes(filter) ||
                    data.initials.toLowerCase().includes(filter)
                );
            }
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        } finally {
            this.loading = false;
        }
    }

    public sortData(pageEvent: TablePaginatorEvent): void {
        if (ObjectUtil.isNull(pageEvent)) {
            return;
        }

        const sort: SortModel = ObjectUtil.clone(pageEvent.sort);
        const dataSourceDataAux: TypeVersionModel[] = ArrayUtil.clone(this._dataSource.data);

        if (ObjectUtil.isNull(sort) || (!ObjectUtil.isNull(sort) && (StringUtil.isEmpty(sort.field) || StringUtil.isEmpty(sort.direction)))) {
            this._dataSource.data = ArrayUtil.clone(this._entities);
            return;
        }

        this._dataSource.data = dataSourceDataAux.sort((a, b) => {
            const isAsk: boolean = sort.direction === 'ASC';
            switch (sort.field) {
                case 'description': {
                    const fieldA = (!ObjectUtil.isNull(a.description) ? a.description : -1);
                    const fieldB = (!ObjectUtil.isNull(b.description) ? b.description : -1);
                    return Library.compareSort(fieldA, fieldB, isAsk);
                }
                case 'initials': {
                    const fieldA = (!StringUtil.isEmpty(a.initials) ? a.initials : -1);
                    const fieldB = (!StringUtil.isEmpty(b.initials) ? b.initials : -1);
                    return Library.compareSort(fieldA, fieldB, isAsk);
                }
                case 'createdDate': {
                    const fieldA = (DateUtil.isValid(a.createdDate) ? a.createdDate : null);
                    const fieldB = (DateUtil.isValid(b.createdDate) ? b.createdDate : null);
                    return Library.compareSortDate(fieldA, fieldB, isAsk);
                }
            }
        });
    }

    public async showDialogTypeVersion(typeOperation: TypeOperationDialog, entity?: TypeVersionModel): Promise<void> {
        const typeVersionFormData: FormDialogData<TypeVersionModel> = {
            typeOperation: typeOperation,
            entity: !ObjectUtil.isNull(entity) ? ObjectUtil.clone(entity) : null,
        };

        this._dialogService
            .open(TypeVersionFormDialogComponent, typeVersionFormData, { width: '30%', height: '30%' })
            .afterClosed().toPromise().then((operation: TypeOperationCloseDialog) => {
                if (operation === 'RELOAD_TABLE') {
                    this.refreshFindAll();
                }
            });
    }

}
