import { TemplateRef } from '@angular/core';
import { RandomId } from '@gipi-ui/utils/random-id.util';
import { TableColumn } from './table-column';

export class TableColumnBuilder {

    private _id$: any;

    private _description$!: string;

    private _property$!: string;

    private _value$!: any | ((obj: object) => any);

    private _sortable$: boolean = false;

    private _visible$: boolean = true;

    private _width$!: number;

    private _paddingInline$!: number;

    private _align$: 'left' | 'center' | 'right' = 'left';

    private _sliceLength$: number = 50;

    private _templateHeader$!: TemplateRef<any>;

    private _template$!: TemplateRef<any>;

    private _action$!: (obj: object) => void;

    constructor(id: string) {
        this._id$ = id;
    }

    public static instance(id?: string): TableColumnBuilder {
        return new TableColumnBuilder(id || RandomId());
    }

    public description(description: any): TableColumnBuilder {
        this._description$ = description;
        return this;
    }

    public property(property: any): TableColumnBuilder {
        this._property$ = property;
        return this;
    }

    public value(value: any | ((obj: object) => any)): TableColumnBuilder {
        this._value$ = value;
        return this;
    }

    public sortable(sortable: boolean): TableColumnBuilder {
        this._sortable$ = sortable;
        return this;
    }

    public visible(visible: boolean): TableColumnBuilder {
        this._visible$ = visible;
        return this;
    }

    public width(width: number): TableColumnBuilder {
        this._width$ = width;
        return this;
    }

    public paddingInline(paddingInline: number): TableColumnBuilder {
        this._paddingInline$ = paddingInline;
        return this;
    }

    public align(align: 'left' | 'center' | 'right'): TableColumnBuilder {
        this._align$ = align;
        return this;
    }

    public sliceLength(sliceLength: number): TableColumnBuilder {
        this._sliceLength$ = sliceLength;
        return this;
    }

    public templateHeader(templateHeader: TemplateRef<any>): TableColumnBuilder {
        this._templateHeader$ = templateHeader;
        return this;
    }

    public template(template: TemplateRef<any>): TableColumnBuilder {
        this._template$ = template;
        return this;
    }

    public action(action: (obj: object) => void): TableColumnBuilder {
        this._action$ = action;
        return this;
    }

    public build(): TableColumn {
        return new TableColumn(
            this._id$,
            this._description$,
            this._property$,
            this._value$,
            this._sortable$,
            this._visible$,
            this._width$,
            this._paddingInline$,
            this._align$,
            this._sliceLength$,
            this._templateHeader$,
            this._template$,
            this._action$,
        );
    }

}
