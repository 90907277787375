<gipi-abstract>
    <gipi-toolbar id="toolbarListUser"
                  label="Consulta de usuários">
    </gipi-toolbar>
    <div fxLayout="column"
         fxLayoutGap="10px">
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutAlign="space-between center"
             fxLayoutGap="16px">
            <gipi-input-text placeholder="Pesquisar"
                             showSuffix="true"
                             [(ngModel)]="filter.researchField"
                             (keydown.enter)="findAll()"
                             fxFlex="50"
                             fxFlex.lt-md="100">
                <gipi-button tooltip="Pesquisar"
                             gipi-icon
                             suffix
                             id="btnSearch"
                             icon="search"
                             [forInput]="true"
                             (click)="findAll()">
                </gipi-button>
            </gipi-input-text>
            <div fxFlex="15"
                 fxFlex.lt-md="100"
                 fxLayoutGap="16px">
                <gipi-button label="Novo"
                             gipi-primary
                             id="btnNewRegister"
                             icon="add"
                             [disabled]="false"
                             (click)="showDialogUser('NEW')"
                             fxFlex
                             fxFlex.lt-md="100">
                </gipi-button>
            </div>
        </div>

        <mat-slide-toggle *ngIf="validateAccess('ROLE_SUPPORT') || validateAccess('ROLE_DEVELOPERS')"
                          [disabled]="loading"
                          [(ngModel)]="showOnlyClients"
                          (change)="findAll(null)">
            Exibir somente usuários dos clientes
        </mat-slide-toggle>
    </div>

    <gipi-mat-table [columns]="columns"
                    [rows]="page.content"
                    [sort]="true"
                    [paginator]="true"
                    [pageIndex]="page.number"
                    [pageLength]="page.totalElements"
                    [loading]="loading"
                    (pageChange)="findAll($event)"
                    (rowChange)="showDialogUser('EDIT', $event)">
        <ng-template #actions
                     let-row>
            <gipi-button tooltip="Ativar | Inativar"
                         *ngIf="row.status !== 'NOT_CONFIRMED'"
                         gipi-icon
                         id="btnEnableOrDisable"
                         icon="lock_open"
                         [forInput]="true"
                         (click)="enableOrDisable(row)">
            </gipi-button>
            <gipi-button tooltip="Excluir"
                         *ngIf="row.status === 'NOT_CONFIRMED'"
                         gipi-icon
                         id="btnDelete"
                         svgIcon="delete"
                         [forInput]="true"
                         (click)="delete(row)">
            </gipi-button>
            <gipi-button tooltip="Reenviar convite"
                         *ngIf="row.status === 'NOT_CONFIRMED'"
                         gipi-icon
                         id="btnSendInvitation"
                         svgIcon="send"
                         [forInput]="true"
                         (click)="sendInvitation(row)">
            </gipi-button>
        </ng-template>
    </gipi-mat-table>
</gipi-abstract>

<ng-template #statusTemplate
             let-row>
    <gipi-lozenge [label]="getLabelStatus(row.status)"
                  [type]="getTypeStatus(row.status)"
                  [tooltip]="getTooltipStatus(row.status)">
    </gipi-lozenge>
</ng-template>