import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@gipi-pages/user/services/user.service';

import { SnackbarService } from 'src/app/components/global/snackbar/services/snackbar.service';
import { PasswordUtil } from 'src/app/ui/utils/password.util';

@Component({
    selector: 'gipi-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

    public tokenAccess: string = '';

    public inputTextPassword: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(6),
        Validators.maxLength(16)]
    );

    public inputTextConfirmPassword: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(6),
        Validators.maxLength(16)]
    );

    public hidePassword: boolean = true;

    public hideConfirmPassword: boolean = true;

    public submitted: boolean = false;

    get isValidCharacterPassword(): boolean {
        return PasswordUtil.isValidCharacter(this.inputTextPassword.value);
    }

    get isValidCharacterConfirmPassword(): boolean {
        return PasswordUtil.isValidCharacter(this.inputTextConfirmPassword.value);
    }

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _usersService: UserService,
        private _router: Router,
        private _snackbarService: SnackbarService
    ) { }

    ngOnInit(): void {
        this.tokenAccess = this._activatedRoute.snapshot.paramMap.get('token');
    }

    private updatePassword(): Promise<boolean> {
        return new Promise(resolve => {
            this._usersService.updatePassword(this.inputTextPassword.value, this.tokenAccess).toPromise().then((result) => {
                resolve(result);
            }, (error) => {
                resolve(false);
            });
        });
    }

    public async onClickUpdatePassword(): Promise<void> {
        this.submitted = true;
        if (this.inputTextPassword.value === this.inputTextConfirmPassword.value) {
            if (!PasswordUtil.isValidCharacter(this.inputTextPassword.value) ||
                !PasswordUtil.isValidCharacter(this.inputTextConfirmPassword.value)
            ) {
                this._snackbarService.showMessage('Os caracteres %, +, -, * e / não são permitidos', true);
            } else {
                const resultUpdatePassword: boolean = await this.updatePassword();
                if (resultUpdatePassword) {
                    this._snackbarService.showMessage('Alteração de senha realizada com sucesso');
                    this.submitted = false;
                    this._router.navigate(['oauth/login']);
                }
            }
        } else {
            this._snackbarService.showMessage('A senha não confere', true);
        }
        this.submitted = false;
    }

    public goBack(): void {
        this._router.navigate(['oauth/login']);
    }

}
