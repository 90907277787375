export interface AppMessages {
    SUCCESS: string;
    GO_BACK_CONFIRMATION: string;
    DELETE_CONFIRMATION: string;
    DELETE_ALL_CONFIRMATION: string;
    NOT_PERMISSION: string;
}

export const APP_MESSAGES: AppMessages = {
    SUCCESS: 'Operação realizada com sucesso',
    GO_BACK_CONFIRMATION: 'Deseja retornar para à tela de consulta?',
    DELETE_CONFIRMATION: 'Deseja realmente excluir este registro?',
    DELETE_ALL_CONFIRMATION: 'Deseja realmente excluir os registros selecionados?',
    NOT_PERMISSION: 'Infelizmente, esta ação não é permitida para o seu perfil',
};
