<gipi-abstract>
    <gipi-toolbar id="toolbarListFunction"
                  label="Consulta de funções">
    </gipi-toolbar>
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="space-between center"
         fxLayoutGap="16px">
        <gipi-input-text placeholder="Pesquisar"
                         showSuffix="true"
                         [disabled]="loading"
                         [(ngModel)]="valueInput"
                         fxFlex="40"
                         fxFlex.lt-md="100"
                         (keyup)="filterEntitiesByInput()">
            <gipi-button tooltip="Pesquisar"
                         gipi-icon
                         suffix
                         id="btnSearch"
                         icon="search"
                         [disabled]="loading"
                         [forInput]="true"
                         (click)="filterEntitiesByInput(true)">
            </gipi-button>
        </gipi-input-text>

        <div fxFlex="30"
             fxFlex.lt-md="100"
             fxLayoutAlign="end center"
             fxLayoutGap="16px">
            <gipi-button label="Atualizar"
                         gipi-primary
                         id="btnRefreshTable"
                         icon="sync"
                         [disabled]="loading"
                         (click)="refreshFindAll()"
                         fxFlex
                         fxFlex.lt-md="100">
            </gipi-button>
            <gipi-button label="Novo"
                         gipi-primary
                         id="btnNewRegister"
                         icon="add"
                         [disabled]="loading"
                         (click)="showDialogFunction('NEW')"
                         fxFlex
                         fxFlex.lt-md="100">
            </gipi-button>
        </div>
    </div>

    <gipi-mat-table [(dataSource)]="_dataSource"
                    [controlDataSource]="true"
                    [columns]="columns"
                    [rows]="_entities"
                    [sort]="true"
                    [sortActive]="'code'"
                    [sortDirection]="'DESC'"
                    [paginatorFrontend]="true"
                    [pageSize]="10"
                    [loading]="loading"
                    (pageChange)="sortData($event)"
                    (rowChange)="showDialogFunction('EDIT', $event)">
        <ng-template #actions
                     let-row>
            <gipi-button tooltip="Ativar | Inativar"
                         gipi-icon
                         id="btnEnableOrDisable"
                         icon="lock_open"
                         [forInput]="true"
                         (click)="enableOrDisable(row)">
            </gipi-button>
        </ng-template>
    </gipi-mat-table>
</gipi-abstract>

<ng-template #statusTemplate
             let-row>
    <gipi-lozenge [label]="row.enabled ? 'Ativo' : 'Inativo'"
                  [type]="row.enabled ? 'success' : 'error'">
    </gipi-lozenge>
</ng-template>