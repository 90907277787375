import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { MdePopoverTarget, MdePopoverTrigger } from '@material-extended/mde';
import { Observable, of } from 'rxjs';
import { InputTextComponent } from '../input-text/input-text.component';

let nextUniqueId = 0;

/** A altura dos itens selecionados em unidades `px`. */
const OPTION_HEIGHT_PX = 40;

@Component({
    selector: 'gipi-input-select',
    exportAs: 'gipiInputSelect',
    templateUrl: './input-select.component.html',
    styleUrls: ['./input-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof InputSelectComponent => InputSelectComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-input-select'
    }
})
export class InputSelectComponent<T> implements OnInit, OnDestroy, ControlValueAccessor {

    private _name: string = `gipi-input-select-${nextUniqueId++}`;

    _optionList: T[] = [];

    _itemSizeScrollViewport: number = 5;

    _lastOptionSelected: T = null;

    @ViewChild(InputTextComponent, { static: true }) inputTextRef!: InputTextComponent;

    @ViewChild('popoverTrigger', { static: false }) popoverTrigger: MdePopoverTrigger;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    @Input() label: string = '';

    @Input() placeholder: string = 'Selecione';

    @Input() help: string = '';

    private _required: boolean = false;
    @Input() get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    private _disabled: boolean = false;
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _lowerCase: boolean = false;
    @Input() get lowerCase(): boolean {
        return this._lowerCase;
    }
    set lowerCase(value: boolean) {
        this._lowerCase = coerceBooleanProperty(value);
    }

    private _upperCase: boolean = false;
    @Input() get upperCase(): boolean {
        return this._upperCase;
    }
    set upperCase(value: boolean) {
        this._upperCase = coerceBooleanProperty(value);
    }

    private _hideOnEscape: boolean = true;
    @Input() get hideOnEscape() {
        return this._hideOnEscape;
    }
    set hideOnEscape(value: boolean) {
        this._hideOnEscape = coerceBooleanProperty(value);
    }

    private _value!: T;
    @Input() get value(): T {
        return this._value;
    }
    set value(value: T) {
        this._value = value;
        this.onChange(this._value);
        this.onTouched(this._value);
    }

    private _options: T[] = [];
    @Input() get options(): T[] {
        return this._options;
    }
    set options(value: T[]) {
        this._options = value;

        if (!ArrayUtil.isEmpty(this.valuesExclud) && !ArrayUtil.isEmpty(value)) {
            for (let i = 0; i < value.length; i++) {
                const excludEnum: boolean = this.valuesExclud.some(e => e === value[i]);
                if (!excludEnum) {
                    this._optionList.push(value[i]);
                }
            }
        } else {
            this._optionList = ArrayUtil.clone(value);
        }
    }

    @Input() valuesExclud: T[];

    @Input() valuesOmit: T[];

    @Input() property: string;

    @Input() propertyFn: (obj: T) => string;

    @Input() mdePopoverTargetAt: MdePopoverTarget;

    onChange: Function = () => { };

    onTouched: Function = () => { };

    @Output() onOpenPopover: EventEmitter<void> = new EventEmitter<void>();
    @Output() onClosePopover: EventEmitter<void> = new EventEmitter<void>();
    @Output('click') onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);
    @Output('focus') onFocus: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);
    @Output('blur') onBlur: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);

    get popoverContentWidth(): Observable<string> {
        if (ObjectUtil.isNull(this.inputTextRef)) {
            return of('auto');
        }
        const inputRef: HTMLElement = (this.inputTextRef.elementRef.nativeElement as HTMLElement);

        return of(`${inputRef.clientWidth}px`);
    }

    get scrollViewportHeight(): number {
        let height: number = 0;
        if (this._optionList && this._optionList.length) {
            height = (this._optionList.length * OPTION_HEIGHT_PX);
        }

        return height;
    }

    constructor(
        public elementRef: ElementRef<HTMLElement>,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        if (!ArrayUtil.isEmpty(this.valuesExclud)) {
            for (let i = 0; i < this.options.length; i++) {
                const excludEnum: boolean = this.valuesExclud.some(e => e === this.options[i]);
                if (!excludEnum) {
                    this._optionList.push(this.options[i]);
                }
            }
        } else {
            this._optionList = ArrayUtil.clone(this.options);
        }

        this._changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        if (this.popoverTrigger) {
            this.popoverTrigger.destroyPopover();
        }
    }

    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }

    public validateOmitByKey(option: T): boolean {
        return !ObjectUtil.isNull(this.valuesOmit) ? !this.valuesOmit.some(vo => vo === option) : true;
    }

    public onButtonSelectOptionClick(option: T): void {
        this.value = option;
        this.closePopover();
        this._changeDetectorRef.detectChanges();
    }

    public showPopover(): void {
        if (this.disabled) {
            return;
        } else {
            this.popoverTrigger.openPopover();
        }
    }

    public closePopover(): void {
        if (this.disabled) {
            return;
        } else {
            this.popoverTrigger.closePopover();
        }
    }

    public handleKeydow(event: KeyboardEvent): void {
        if (this.disabled) {
            event.stopImmediatePropagation();
            return;
        }

        if (
            ((event.code.toUpperCase() === 'ESCAPE') && this.hideOnEscape) ||
            ((event.code.toUpperCase() === 'ENTER') || (event.code.toUpperCase() === 'NUMPADENTER')) ||
            (event.code.toUpperCase() === 'TAB')
        ) {
            if (!ObjectUtil.isNull(this.popoverTrigger) && this.popoverTrigger.popoverOpen) {
                this.closePopover();
                event.preventDefault();
            }
        }
    }

    public handleClick(event: MouseEvent): void {
        if (this.disabled) {
            this.closePopover();
            return;
        } else {
            this.onClick.emit(event);
            event.stopPropagation();
        }
    }

    public handleFocus(event: MouseEvent): void {
        if (this.disabled) {
            return;
        } else {
            this.onFocus.emit(event);
            event.stopPropagation();
        }
    }

    public handleBlur(event: MouseEvent): void {
        if (this.disabled) {
            return;
        } else {
            this.onBlur.emit(event);
            this.onTouched();
            event.stopPropagation();
        }
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: UIEvent): void {
        if (this.popoverTrigger) {
            this.popoverTrigger.closePopover();
            event.stopPropagation();
        }
    }

    public getPropertyValue(option: T): string {
        if (!ObjectUtil.isNull(option)) {
            if (!ObjectUtil.isNull(this.propertyFn)) {
                return this.propertyFn(option);
            } else if (this.property) {
                return this._getValue(option, this.property);
            }
        }
        return '';
    }

    private _getValue(option: T, property: string): string {
        const properties: string[] = property.split('.');
        let value: any = option;

        for (let i: number = 0; i < properties.length; i++) {
            value = Reflect.get(value, properties[i]);
            if (value && i === properties.length - 1) {
                return value;
            }
        }
    }

}
