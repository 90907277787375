import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogConfirm } from './models/dialog-confirm.model';

@Component({
    selector: 'gipi-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogConfirm
    ) {
        dialogRef.disableClose = true;
        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close(false);
        });
    }

    ngOnInit(): void { }

    onClickResult(value: boolean): void {
        this.dialogRef.close(value);
    }

    getTitle(): String {
        if (this.data.type === 0 || this.data.type === 2) {
            return 'Confirmação';
        } else if (this.data.type === 1) {
            return 'Aviso';
        } else {
            return '';
        }
    }

}
