<div class="background"
     (window:keydown)="keyDown($event)">
    <div
         style="flex-direction: column; box-sizing: border-box; display: flex; place-content: center; align-items: center;">
        <div
             style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center; align-items: center; flex: 1 1 100vh; max-height: 100vh; min-height: 100vh;">
            <div style="margin-right: 150px; flex: 1 1 100%; box-sizing: border-box; max-width: 20%;">
                <h1 class="h1-title">
                    É NOSSO TRABALHO DE TODOS OS DIAS FAZER COM QUE CADA ASPECTO IMPORTANTE DA EXPERIÊNCIA
                    DO CLIENTE SEJA UM POUCO MELHOR
                    <P class="author-title">JEFF BEZOS</P>
                </h1>
            </div>
            <div style="flex-direction: row; box-sizing: border-box; display: flex;">
                <mat-card class="mat-card"
                          style="flex: 1 1 0%; box-sizing: border-box;">
                    <mat-card-content>
                        <div
                             style="flex-direction: column; box-sizing: border-box; display: flex; place-content: center; align-items: center;">
                            <img src="assets/login/logo-login.png"
                                 alt="logo"
                                 width="180">
                        </div>
                        <div style="flex-direction: column; box-sizing: border-box; display: flex;">
                            <!--Input Username-->
                            <div style="margin-bottom: 0px !important; margin-top: 25px;">
                                <div style="width: 100%;">
                                    <mat-label class="mat-label">
                                        E-mail <span style="color: #f44336!important;"> *</span>
                                    </mat-label>
                                    <mat-form-field floatLabel="always"
                                                    appearance="outline"
                                                    style="width: 100%;">
                                        <input matInput
                                               type="text"
                                               id="email"
                                               style="text-transform: lowercase;"
                                               autofocus
                                               minlength="1"
                                               maxlength="100"
                                               [formControl]="inputTextEmail" />
                                    </mat-form-field>
                                </div>
                            </div>
                            <!--Input Password-->
                            <div style="margin-top: 0px !important;">
                                <div style="width: 100%;">
                                    <mat-label class="mat-label">
                                        Senha <span style="color: #f44336!important;"> *</span>
                                    </mat-label>
                                    <mat-form-field floatLabel="always"
                                                    appearance="outline"
                                                    style="width: 100%;">
                                        <input matInput
                                               [type]="hidePassword ? 'password' : 'text'"
                                               id="password"
                                               autocomplete="off"
                                               minlength="6"
                                               maxlength="16"
                                               required
                                               [formControl]="inputTextPassword" />
                                        <button mat-icon-button
                                                matSuffix
                                                class="button-input"
                                                matTooltip="{{ hidePassword ? 'Mostrar senha' : 'Ocultar senha' }}"
                                                onclick="this.blur()"
                                                (click)="hidePassword = !hidePassword">
                                            <mat-icon style="width: 15px; height: 15px;">
                                                {{ hidePassword ? 'lock' : 'lock_open' }}
                                            </mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div
                                 style="margin-bottom: 10px; flex-direction: row; display: flex; justify-content: space-between !important; place-content: center; align-items: center;">
                                <!--Recuperar senha-->
                                <a routerLink="/oauth/recover-password"
                                   routerLinkActive="active"
                                   class="change-password"
                                   style="margin-right: 0px;"
                                   focused="true">
                                    Esqueci minha senha
                                </a>
                                <!--Button entrar-->
                                <button mat-raised-button
                                        class="button-access button-text"
                                        [disabled]="submitted"
                                        (click)="onClickSubmit()">
                                    <div class="div-spinner-text">
                                        <mat-spinner style="margin-right: 5px;"
                                                     [ngClass]="{'custom-spinner': submitted}"
                                                     [diameter]="22"
                                                     *ngIf="submitted">
                                        </mat-spinner>
                                        <span>Entrar</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>