import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;

@Component({
    selector: 'gipi-top-nav',
    exportAs: 'gipiTopNav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof TopNavComponent => TopNavComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-top-nav',
        '[attr.id]': 'id',
        '[name]': 'name'
    }
})
export class TopNavComponent implements OnInit {

    private _name: string = `gipi-top-nav-${nextUniqueId++}`;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    constructor() { }

    ngOnInit() {
    }

}
