import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';

import { AppUiModule } from '@gipi-ui/app-ui.module';
import { AppMaterialModule } from '../modules/app-material.module';

import { BackupListComponent } from './backup/components/backup-list/backup-list.component';
import { ClientListDialogComponent } from './clients/components/client-list-dialog/client-list-dialog.component';
import { ConfigurationFormDialogComponent } from './configuration/components/configuration-form-dialog/configuration-form-dialog.component';
import { DelayBlockingFormDialogComponent } from './delay-blocking/components/delay-blocking-form-dialog/delay-blocking-form-dialog.component';
import { DelayBlockingListDialogComponent } from './delay-blocking/components/delay-blocking-list-dialog/delay-blocking-list-dialog.component';
import { DelayBlockingListComponent } from './delay-blocking/components/delay-blocking-list/delay-blocking-list.component';
import { FunctionFormDialogComponent } from './function/components/function-form-dialog/function-form-dialog.component';
import { FunctionListComponent } from './function/components/function-list/function-list.component';
import { NotificationTypeFormDialogComponent } from './notification-type/components/notification-type-form-dialog/notification-type-form-dialog.component';
import { NotificationTypeListComponent } from './notification-type/components/notification-type-list/notification-type-list.component';
import { NotificationFormComponent } from './notification/components/notification-form/notification-form.component';
import { NotificationListComponent } from './notification/components/notification-list/notification-list.component';
import { TypeVersionFormDialogComponent } from './type-version/components/type-version-form-dialog/type-version-form-dialog.component';
import { TypeVersionListComponent } from './type-version/components/type-version-list/type-version-list.component';
import { UserFormDialogComponent } from './user/components/user-form-dialog/user-form-dialog.component';
import { UserListComponent } from './user/components/user-list/user-list.component';
import { VersionFormDialogComponent } from './version/components/version-form-dialog/version-form-dialog.component';
import { VersionListComponent } from './version/components/version-list/version-list.component';

@NgModule({
    declarations: [
        NotificationFormComponent,
        NotificationListComponent,
        NotificationTypeFormDialogComponent,
        NotificationTypeListComponent,
        ClientListDialogComponent,
        BackupListComponent,
        DelayBlockingListComponent,
        DelayBlockingFormDialogComponent,
        DelayBlockingListDialogComponent,
        ConfigurationFormDialogComponent,
        FunctionListComponent,
        FunctionFormDialogComponent,
        TypeVersionListComponent,
        TypeVersionFormDialogComponent,
        VersionListComponent,
        VersionFormDialogComponent,
        UserListComponent,
        UserFormDialogComponent,
    ],
    exports: [
        NotificationFormComponent,
        NotificationListComponent,
        NotificationTypeFormDialogComponent,
        NotificationTypeListComponent,
        ClientListDialogComponent,
        BackupListComponent,
        DelayBlockingListComponent,
        DelayBlockingFormDialogComponent,
        DelayBlockingListDialogComponent,
        ConfigurationFormDialogComponent,
        FunctionListComponent,
        FunctionFormDialogComponent,
        TypeVersionListComponent,
        TypeVersionFormDialogComponent,
        VersionListComponent,
        VersionFormDialogComponent,
        UserListComponent,
        UserFormDialogComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgxMaskModule.forChild(),
        AppMaterialModule,
        AppUiModule,
        FlexLayoutModule,
    ],
})

export class AppPagesModule { }
