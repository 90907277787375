import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as moment_ from 'moment';
const moment = moment_;

import { AbstractFormDialogComponent, FormDialogData } from '@gipi-pages/abstract/abstract-form-dialog.component';
import { RoleModel } from '@gipi-pages/user/models/role.model';
import { UserModel } from '@gipi-pages/user/models/user.model';
import { UserService } from '@gipi-pages/user/services/user.service';
import { EnumDepartmentAuthorities, TypeAuthorities } from '@gipi-ui/enums/enum-authorities.enum';
import { AlertService } from '@gipi-ui/services/alert.service';
import { ValidateAccessService } from '@gipi-ui/services/validate-access.service';
import { EmailUtil } from '@gipi-ui/utils/email.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { UUIDUtil } from '@gipi-ui/utils/uuid.util';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
    selector: 'gipi-user-form-dialog',
    templateUrl: './user-form-dialog.component.html',
    styleUrls: ['./user-form-dialog.component.scss']
})
export class UserFormDialogComponent extends AbstractFormDialogComponent<UserModel> implements OnInit {

    public authoritiesEnum: typeof EnumDepartmentAuthorities = EnumDepartmentAuthorities;
    public authoritiesValue: TypeAuthorities | '' = '';

    private _startTimeValue: Date = new Date('2000-01-01 06:40:00');
    get startTimeValue(): Date {
        return this._startTimeValue;
    }
    set startTimeValue(value: Date) {
        this._startTimeValue = value;
        if (!ObjectUtil.isNull(this._startTimeValue) && !ObjectUtil.isNull(this.stopTimeValue)) {
            const stopTime = moment(this.stopTimeValue);
            const isAfter: boolean = moment(this._startTimeValue).isAfter(stopTime);
            if (isAfter) {
                this.stopTimeValue = null;
            }
        }
    }

    private _stopTimeValue: Date = new Date('2000-01-01 18:05:00');
    get stopTimeValue(): Date {
        return this._stopTimeValue;
    }
    set stopTimeValue(value: Date) {
        if (!ObjectUtil.isNull(value) && !ObjectUtil.isNull(this.startTimeValue)) {
            const startTime = moment(this.startTimeValue);
            const isBefore: boolean = moment(value).isBefore(startTime);
            if (isBefore) {
                value = null;
            }
        }

        this._stopTimeValue = value;
    }

    public permissionRegister: boolean = false;

    public get excludedAuthorities(): TypeAuthorities[] {
        const currentRoleUser: TypeAuthorities = this._getUserRolesByAuthorities();
        switch (currentRoleUser) {
            case 'ROLE_DEVELOPERS': return ['ROLE_REGISTER'];
            case 'ROLE_FINANCIAL': return ['ROLE_REGISTER', 'ROLE_DEVELOPERS', 'ROLE_SELLER', 'ROLE_SUPPORT', 'ROLE_CLIENT'];
            case 'ROLE_SUPPORT': return ['ROLE_REGISTER', 'ROLE_DEVELOPERS', 'ROLE_SELLER', 'ROLE_FINANCIAL'];
            case 'ROLE_SELLER': return ['ROLE_REGISTER', 'ROLE_DEVELOPERS', 'ROLE_SUPPORT', 'ROLE_FINANCIAL', 'ROLE_CLIENT'];
        }
    }

    public get isDisabledEmail(): boolean {
        if (ObjectUtil.isNull(this.data) || ObjectUtil.isNull(this.data.entity)) {
            return false;
        }
        return (UUIDUtil.isValid(this.data.entity.id)) && !ObjectUtil.isNull(this.entity) && (this.entity.status === 'ENABLED');
    }

    constructor(
        private _alertService: AlertService,
        private _authenticationService: AuthenticationService,
        private _validateAccessService: ValidateAccessService,
        protected service: UserService,
        protected dialogRef: MatDialogRef<UserFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FormDialogData<UserModel> = { typeOperation: 'NEW', entity: null },
    ) {
        super(service, dialogRef, data);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (!ObjectUtil.isNull(this.data.entity)) {
            for (let lCount: number = 0; lCount < this.data.entity.roles.length; lCount++) {
                if (this.data.entity.roles[lCount].description === 'ROLE_REGISTER') {
                    this.permissionRegister = true;
                } else {
                    this.authoritiesValue = (this.data.entity.roles[lCount].description as TypeAuthorities);
                }
            }

            this.startTimeValue = new Date('2000-01-01 ' + this.entity.startTime);
            this.stopTimeValue = new Date('2000-01-01 ' + this.entity.stopTime);
        }
    }

    protected newEntity(): UserModel {
        const entity: UserModel = new UserModel();
        entity.status = 'NOT_CONFIRMED';
        return entity;
    }

    protected isValid(): boolean { return true; }

    private async _isValid(): Promise<boolean> {
        if (ObjectUtil.isNull(this.entity)) {
            return Promise.resolve(false);
        }
        const name: string = (this.authoritiesValue !== 'ROLE_CLIENT') ? 'Nome' : 'Nome fantasia da empresa';
        if (StringUtil.isEmpty(this.entity.name)) {
            this._alertService.addWarningMessage(`Campo ${name} é obrigatório e não foi informado`);
            return Promise.resolve(false);
        }
        if (this.entity.name.length < 2) {
            this._alertService.addWarningMessage(`Campo ${name} deve conter no mínimo 2 caracteres`);
            return Promise.resolve(false);
        }
        if (StringUtil.isEmpty(this.entity.email)) {
            this._alertService.addWarningMessage('Campo e-mail é obrigatório e não foi informado');
            return Promise.resolve(false);
        }
        if (StringUtil.isEmpty(this.entity.email)) {
            this._alertService.addWarningMessage('Campo e-mail é obrigatório e não foi informado');
            return Promise.resolve(false);
        }
        if (this.entity.name.length < 2) {
            this._alertService.addWarningMessage('Campo e-mail deve conter no mínimo 2 caracteres');
            return Promise.resolve(false);
        }
        if (!EmailUtil.isValid(this.entity.email)) {
            this._alertService.addWarningMessage('E-mail informado não é válido');
            return Promise.resolve(false);
        }
        if (this.typeOperation === 'NEW') {
            const user: UserModel = await this.service.findByEmail(this.entity.email).toPromise();
            if (!ObjectUtil.isNull(user)) {
                this._alertService.addWarningMessage('Este e-mail já está sendo usado por outro usuário');
                return Promise.resolve(false);
            }
        }
        if (StringUtil.isEmpty(this.authoritiesValue)) {
            this._alertService.addWarningMessage('Campo tipo de usuário é obrigatório e não foi informado');
            return Promise.resolve(false);
        }

        return Promise.resolve(true);
    }

    /**
     * 1 = ROLE_REGISTER
     * 2 = ROLE_DEVELOPERS
     * 3 = ROLE_FINANCIAL
     * 4 = ROLE_SUPPORT
     * 5 = ROLE_CLIENT
     * 6 = ROLE_SELLER
     */
    private _loadRoles(): void {
        this.entity.roles = [];
        switch (this.authoritiesValue) {
            case 'ROLE_DEVELOPERS': {
                this.entity.roles.push(new RoleModel(2));
                break;
            }
            case 'ROLE_FINANCIAL': {
                this.entity.roles.push(new RoleModel(3));
                break;
            }
            case 'ROLE_SUPPORT': {
                this.entity.roles.push(new RoleModel(4));
                break;
            }
            case 'ROLE_CLIENT': {
                this.entity.roles.push(new RoleModel(5));
                this.permissionRegister = false;
                break;
            }
            case 'ROLE_SELLER': {
                this.entity.roles.push(new RoleModel(6));
                break;
            }
        }

        if (this.permissionRegister) {
            this.entity.roles.push(new RoleModel(1));
        }
    }

    private _loadTimesUsers(): void {
        if (this.authoritiesValue === 'ROLE_CLIENT') {
            this.entity.startTime = '00:00:00';
            this.entity.stopTime = '23:59:59';
        } else {
            const hourStart: string = this.startTimeValue.getHours().toString();
            const minuteStart: string = this.startTimeValue.getMinutes().toString();
            this.entity.startTime = (hourStart.length > 1 ? hourStart : '0' + hourStart) + ':' + (minuteStart.length > 1 ? minuteStart : '0' + minuteStart) + ':00';

            const hourStop: string = this.stopTimeValue.getHours().toString();
            const minuteStop: string = this.stopTimeValue.getMinutes().toString();
            this.entity.stopTime = (hourStop.length > 1 ? hourStop : '0' + hourStop) + ':' + (minuteStop.length > 1 ? minuteStop : '0' + minuteStop) + ':59';
        }
    }

    public async confirm(): Promise<void> {
        try {
            const isValid: boolean = await this._isValid();
            if (!isValid) {
                return;
            }
            this.loading = true;

            this._loadRoles();
            this._loadTimesUsers();
            this.entity.email = this.entity.email.toLowerCase();

            this.service.save(this.entity).toPromise().then(() => {
                this._alertService.addSuccessMessage('Operação realizada com sucesso');
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        }
    }

    private _getUserRolesByAuthorities(): TypeAuthorities {
        return this._authenticationService.getUserRolesByAuthorities();
    }

}
