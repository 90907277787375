<div *ngIf="(!loading) && (terminalList && (terminalList.length > 0))"
     class="template">
    <mat-list class="mat-selection-row"
              #machinesCustomers
              [multiple]="false"
              (mouseleave)="hoverbutton = null">

        <mat-list-item style="height: 150px;"
                       class="option-computer mat-elevation-z3"
                       *ngFor="let element of terminalList"
                       [value]="element.id"
                       (mouseenter)="hoverbutton = element.id"
                       (mouseleave)="hoverbutton = null">

            <div class="div-computer"
                 (click)="onClickOpenDialogClientInformation(element.id, element.apps, element.name, element)">
                <div mat-line
                     class="line-computer-name">
                    <mat-icon mat-list-icon
                              class="mat-icon-computer">desktop_windows</mat-icon>
                    <span class="{{ getTypeStatus(element.updateStatus) }}"
                          style="font-weight: bolder !important; font-size: 15px !important; line-height: 32px; width: 300px; margin-top: 5px;">
                        {{ element.name.toUpperCase() }}
                    </span>
                </div>

                <div mat-line
                     class="line-others">
                    <p class="title-line">Processador:</p>
                    {{element.processor}}
                </div>

                <div mat-line
                     style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    <div class="line-others">
                        <p class="title-line">Memória RAM:</p>
                        {{ element.memory }}
                    </div>

                    <div class="line-others"
                         style="margin-right: 20px;">
                        <p class="title-line">Último acesso:</p>
                        {{element.dateTimeLastAccess | date: "dd/MM/yyyy HH:mm:ss" }}
                    </div>

                    <div class="line-others line-unique"
                         style="margin-top: 17px;">
                        <p class="title-line">Status:</p>
                        <p class="{{ loadStatusColor(element.updateStatus) }}">
                            {{ loadStatus(element.updateStatus) }}
                        </p>
                    </div>
                </div>
            </div>

            <span class="computer-buttons span-buttons"
                  matTooltip="Deletar"
                  matRipple
                  [matRippleCentered]="true"
                  *ngIf="element.id===hoverbutton"
                  (click)="onClickDeleteTerminal(element.id)">
                <span class="icon-buttons">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="47%"
                         height="47%"
                         viewBox="0 0 12.347 17.628"
                         fit=""
                         preserveAspectRatio="xMidYMid meet"
                         focusable="false">
                        <path id="Caminho_45"
                              data-name="Caminho 45"
                              d="M264.1,75.269h9.617a.33.33,0,0,0,.142-.028.1.1,0,0,0,.043-.034.137.137,0,0,0,.014-.07.46.46,0,0,0-.056-.2l-.346-.693h0a1.215,1.215,0,0,0-.25-.362.384.384,0,0,0-.266-.1h-8.183a.385.385,0,0,0-.266.1,1.224,1.224,0,0,0-.248.363l-.346.693a.46.46,0,0,0-.056.2.137.137,0,0,0,.015.07.1.1,0,0,0,.043.034.331.331,0,0,0,.142.028Zm6.56,2.518a.582.582,0,1,1,1.161.082l-.583,8.2a.582.582,0,0,1-1.161-.082l.583-8.2Zm-4.663.082a.582.582,0,1,1,1.161-.082l.583,8.2a.582.582,0,0,1-1.161.082l-.583-8.2Zm2.329-.041a.583.583,0,0,1,1.166,0v8.2a.583.583,0,0,1-1.166,0v-8.2Zm-2.5-5.207a1.723,1.723,0,0,1,.584-1.282l.04-.033a1.986,1.986,0,0,1,1.289-.47h2.332a1.985,1.985,0,0,1,1.329.5q.021.019.039.039a1.715,1.715,0,0,1,.545,1.243H273a1.51,1.51,0,0,1,1.04.392,2.319,2.319,0,0,1,.515.713h0l.346.693a1.608,1.608,0,0,1,.179.718,1.282,1.282,0,0,1-.188.675,1.258,1.258,0,0,1-.393.4l-1.453,11.738a.583.583,0,0,1-.578.512h-7.125a.583.583,0,0,1-.58-.528l-1.451-11.722a1.256,1.256,0,0,1-.393-.4,1.282,1.282,0,0,1-.188-.675,1.608,1.608,0,0,1,.179-.718l.346-.693a2.308,2.308,0,0,1,.517-.713,1.509,1.509,0,0,1,1.04-.392Zm4.993,0a.561.561,0,0,0-.173-.4l-.024-.02a.82.82,0,0,0-.55-.2h-2.332a.826.826,0,0,0-.526.179l-.025.024a.565.565,0,0,0-.2.416Zm2.478,3.813h-8.783L265.857,87.3h6.095Z"
                              transform="translate(-262.731 -70.836)"
                              fill-rule="evenodd">
                        </path>
                    </svg>
                </span>
            </span>
        </mat-list-item>
    </mat-list>
</div>

<div *ngIf="loading"
     class="template loading">
    <mat-spinner [diameter]="50"></mat-spinner>
    Aguarde...
</div>

<div *ngIf="(!loading) && ((!terminalList) || (terminalList && (terminalList.length <= 0)))"
     class="template">
    <div class="no-records">
        <mat-icon class="mat-icon material-icons mat-icon-no-color"
                  role="img"
                  aria-hidden="true">
            sentiment_very_dissatisfied
        </mat-icon>
        <span class="">Nenhum registro foi encontrado</span>
    </div>
</div>
