<div [style.height.px]="(matTableHeigth | async)">

    <mat-table class="table"
               matSort
               multiTemplateDataRows
               [dataSource]="dataSource"
               [matSortDisabled]="!tableSort"
               [matSortDisableClear]="!tableClearSort"
               [matSortActive]="tableSortActive"
               [matSortDirection]="tableSortDirection?.toLocaleLowerCase()"
               (matSortChange)="handleTableSort($event)"
               (mouseleave)="_hoverCellActions = null">

        <ng-container *ngIf="expandedRowRef"
                      cdkColumnDef="expandedColumn">
            <mat-header-cell *cdkHeaderCellDef
                             fxLayoutAlign="center center"
                             fxFlex="5">
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row"
                      fxFlex="5"
                      fxLayoutAlign="center center">
                <mat-icon class="expand-row-icon"
                          [@indicatorRotate]="(isExpandedRow(row) | async) ? 'expanded' : 'collapsed'">
                    expand_less
                </mat-icon>
            </mat-cell>
        </ng-container>

        <ng-container *ngIf="tableCheckboxable"
                      cdkColumnDef="checkboxColumn">
            <mat-header-cell *cdkHeaderCellDef
                             [style.max-width.px]="50"
                             [style.justify-content]="'center'">
                <gipi-checkbox gipi-primary
                               (change)="toggleAllRows($event)"
                               [checked]="_selection.hasValue() && isAllSelected()"
                               [indeterminate]="_selection.hasValue() && !isAllSelected()">
                    <!-- [checked]="_allRowsSelectedChecked"
                               [indeterminate]="hasRowSelected && !allRowSelected"
                               (change)="handleSelectAllRows($event)"> -->
                </gipi-checkbox>
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row"
                      [style.max-width.px]="50"
                      [style.justify-content]="'center'">
                <div *ngIf="_selection.isSelected(row)"
                     class="border-row-selected"
                     [style.height]="'100%'">
                </div>

                <gipi-checkbox (click)="$event.stopPropagation()"
                               (change)="$event ? _selection.toggle(row) : null"
                               [checked]="_selection.isSelected(row)">
                    <!-- [checked]="isRowSelected(row)"
                               (change)="handleSelectRow(row)"> -->
                </gipi-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container *ngFor="let column of tableColumns; let i = index;">
            <ng-container *ngIf="column.visible"
                          [cdkColumnDef]="column.property">
                <mat-header-cell *cdkHeaderCellDef
                                 mat-sort-header
                                 [disabled]="!column.sortable"
                                 [style.padding-inline.px]="column.paddingInline"
                                 [style.max-width.px]="column.width"
                                 [style.justify-content]="column.align">
                    <ng-container *ngIf="column.templateHeader && !column.sortable"
                                  [ngTemplateOutlet]="column.templateHeader"
                                  [ngTemplateOutletContext]="{$implicit: column}">
                    </ng-container>
                    <ng-container *ngIf="!column.templateHeader">
                        {{ column.description }}
                    </ng-container>
                </mat-header-cell>

                <mat-cell *cdkCellDef="let row"
                          [style.padding-inline.px]="column.paddingInline"
                          [style.max-width.px]="column.width"
                          [style.justify-content]="column.align"
                          (click)="column.action ? column.action(row) : handleClickOnCell(row)">
                    <ng-container *ngIf="column.template"
                                  [ngTemplateOutlet]="column.template"
                                  [ngTemplateOutletContext]="{$implicit: row}">
                    </ng-container>
                    <ng-container *ngIf="(column.value && !column.template)">
                        {{
                        (column?.value(row)?.length > 50)
                        ? (column.value(row) | slice:0:(column.sliceLength || 50)) + '...'
                        : column.value(row)
                        }}
                    </ng-container>
                </mat-cell>
            </ng-container>
        </ng-container>

        <!-- Column actions -->
        <ng-container *ngIf="actionsRef"
                      cdkColumnDef="actionsColumn">
            <mat-header-cell fxFlex.gt-md="10"
                             fxLayoutAlign="center center"
                             *cdkHeaderCellDef>
                Ações
            </mat-header-cell>
            <mat-cell fxFlex="10"
                      fxLayoutAlign="center center"
                      *cdkCellDef="let row">
                <div *ngIf="_hoverCellActions === row"
                     class="cell-actions">
                    <ng-template [ngTemplateOutlet]="actionsRef"
                                 [ngTemplateOutletContext]="{$implicit: row}">
                    </ng-template>
                </div>
            </mat-cell>
        </ng-container>

        <!-- Expanded row -->
        <ng-container *ngIf="expandedRowRef"
                      matColumnDef="expandedRowColumn">
            <mat-cell *matCellDef="let row"
                      [attr.colspan]="_displayedColumns.length">
                <div class="detail-expanded-row"
                     [@rowExpand]="(isExpandedRow(row) | async) ? 'expanded' : 'collapsed'">
                    <ng-template [ngTemplateOutlet]="expandedRowRef"
                                 [ngTemplateOutletContext]="{$implicit: row}">
                    </ng-template>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row #matHeaderRow
                        *matHeaderRowDef="_displayedColumns"
                        (mouseenter)="rowHover.emit(null); _hoverCellActions = null">
        </mat-header-row>

        <mat-row #matRow
                 *matRowDef="let row; columns: _displayedColumns;"
                 [class.highlight]="getHighlightRow(row)"
                 [attr.id]="'row_'+ getIndexRow(row)"
                 (mouseover)="rowHover.emit(row); _hoverCellActions = row"
                 (click)="handleExpandRow(row)">
        </mat-row>

        <ng-container *ngIf="expandedRowRef">
            <mat-row #matExpandedRow
                     *matRowDef="let row; columns: ['expandedRowColumn'];"
                     class="expanded-row"
                     [class.expanded]="(isExpandedRow(row) | async)"
                     [attr.id]="'row_expanded_'+ getIndexRow(row)">
            </mat-row>
        </ng-container>
    </mat-table>

    <ng-container *ngIf="tableLoading">
        <gipi-progress-bar> </gipi-progress-bar>
    </ng-container>

    <ng-container *ngIf="(tableBackendPaginator && !tableLoading && (tableRows?.length > 0)) || tableFrontendPaginator">
        <mat-paginator #matPaginatorRow
                       [style.visibility]="tableFrontendPaginator && (tableRows?.length <= 0) ? 'hidden' : 'visible'"
                       [disabled]="tableLoading"
                       [pageIndex]="tablePaginatorPageIndex"
                       [length]="tablePaginatorPageLength"
                       [pageSize]="tablePaginatorPageSize"
                       [showFirstLastButtons]="true"
                       [hidePageSize]="true"
                       [pageSizeOptions]="[]"
                       (page)="pageChange.emit($event)">
        </mat-paginator>
    </ng-container>

    <ng-container *ngIf="(tableRows?.length <= 0) && !tableLoading">
        <div class="no-records">
            <mat-icon> sentiment_very_dissatisfied </mat-icon>
            <span> Nenhum registro foi encontrado </span>
        </div>
    </ng-container>

</div>
