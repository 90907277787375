import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AbstractFilterModel } from '@gipi-pages/abstract/models/abstract-filter.model';
import { AbstractService } from '@gipi-pages/abstract/services/abstract.service';
import { FunctionModel } from '../models/function.model';

@Injectable({ providedIn: 'root' })
export class FunctionService extends AbstractService<FunctionModel, AbstractFilterModel> {

    constructor(protected httpClient: HttpClient) {
        super('APOIO', 'function', httpClient);
    }

}
