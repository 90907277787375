import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalDialog } from './shared/modal-dialog';

@Component({
    selector: 'gipi-modal-dialog',
    exportAs: 'gipiModalDialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof ModalDialogComponent => ModalDialogComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-modal-dialog',
    },
})
export class ModalDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ModalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ModalDialog,
    ) { }

    ngOnInit(): void { }

}
