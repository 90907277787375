<!-- <button mat-raised-button (click)="onClickOpenDragAndDrop()">Abrir o drag and drop</button> -->

<div class="content">

    <div>
        <h2 class="title">Consulta das Tabelas IBPTax / NCM</h2>
    </div>
    <span style="flex: 1 1 0%; box-sizing: border-box;"></span>

    <div class="div-button">
        <div>
            <gipi-button-generic [inputAutoFocus]="false"
                                 [inputText]="'ATUALIZAR'"
                                 [inputIcon]="'rotate_right'"
                                 (click)="onClickRefreshNcmVersion()">
            </gipi-button-generic>
        </div>

        <div style="margin-left: 25px;">
            <gipi-button-generic [inputAutoFocus]="false"
                                 [inputText]="'DONWLOAD'"
                                 [inputIcon]="'downloading'"
                                 (click)="onClickOpenDownloadTables()">
            </gipi-button-generic>
        </div>

        <div style="margin-left: 25px;">
            <gipi-button-generic [inputAutoFocus]="false"
                                 [inputText]="'NOVO'"
                                 [inputIcon]="'add'"
                                 (click)="onClickNavigateNewRegister()">
            </gipi-button-generic>
        </div>

    </div>

    <div class="div-info">
        <gipi-list-ncm-version></gipi-list-ncm-version>
    </div>

</div>