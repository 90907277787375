import { ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { DialogPosition, DialogRole, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ObjectUtil } from '../utils/object.util';
import { RandomId } from '../utils/random-id.util';

export interface DialogConfig {
    disableClose?: boolean;
    width?: string;
    height?: string;
    minWidth?: number | string;
    maxWidth?: number | string;
    minHeight?: number | string;
    maxHeight?: number | string;
    scrollStrategy?: ScrollStrategy;
    position?: DialogPosition | null;
    role?: DialogRole;
}

/**
 * @param T Refer to dialog data;
 * @param R Refer to dialog result
 */
@Injectable({ providedIn: 'root' })
export class DialogService<T = any, R = any> {

    private _idDialog: string = RandomId();

    private _dialogRef!: MatDialogRef<T, R>;

    constructor(private _dialog: MatDialog) { }

    public dialogRef(): MatDialogRef<T> | null {
        if (ObjectUtil.isNull(this._dialogRef)) {
            return null;
        }

        return this._dialogRef;
    }

    public open(componentOrTemplateRef: ComponentType<any>, data?: T, config?: DialogConfig): MatDialogRef<T, R> {
        this._dialogRef = this._dialog.open(componentOrTemplateRef, {
            id: this._idDialog,
            role: config?.role ?? 'dialog',
            panelClass: 'dialog-panel',
            hasBackdrop: true,
            disableClose: config?.disableClose ?? false,
            width: config?.width ?? '40%',
            minWidth: config?.minWidth,
            maxWidth: config?.maxWidth,
            height: config?.height ?? '50vh',
            minHeight: config?.minHeight,
            maxHeight: config?.maxHeight,
            position: config?.position,
            data: data,
            autoFocus: true,
            restoreFocus: true,
            scrollStrategy: config?.scrollStrategy,
            closeOnNavigation: true,
        });

        return this._dialogRef;
    }

    public close(dialogResult?: R): void {
        if (ObjectUtil.isNull(this._dialogRef)) {
            const ref: MatDialogRef<T, R> | undefined = this._dialog.getDialogById(this._idDialog);
            if (ref) {
                ref.close(dialogResult);
            }
            return;
        }

        this._dialogRef.close(dialogResult);
    }

}
