<div class="alert"
     [ngClass]="typeIcon === 'ERROR' ? 'alert-error' : 'alert-success'"
     fxLayout="row"
     fxLayoutAlign=" center"
     fxLayoutGap="15px">
    <mat-icon *ngIf="typeIcon === 'SUCCESS'"> check_circle </mat-icon>
    <mat-icon *ngIf="typeIcon === 'ERROR'"> cancel </mat-icon>
    <div fxLayout="column"
         fxLayoutAlign="center start">
        <div class="title">{{ title }}</div>
        <span>{{ textInformation }}</span>
    </div>
</div>
