import { TypeAuthorities } from '../../../../ui/enums/enum-authorities.enum';

export class DecodedToken {

    authorities: TypeAuthorities[];

    exp: number;

    startTime: string;

    stopTime: string;

    user_name: string;

    name: string;

    client_id: string;

    jti: string;

    constructor() {
        this.authorities = [];
        this.exp = -1;
        this.startTime = '';
        this.stopTime = '';
        this.user_name = '';
        this.name = '';
        this.client_id = '';
        this.jti = '';
    }

}
