import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../core/guards/auth.guard';
import { EnumAuthorities } from '../ui/enums/enum-authorities.enum';

import { BackupListComponent } from '@gipi-pages/backup/components/backup-list/backup-list.component';
import { DelayBlockingListComponent } from '@gipi-pages/delay-blocking/components/delay-blocking-list/delay-blocking-list.component';
import { FunctionListComponent } from '@gipi-pages/function/components/function-list/function-list.component';
import { TypeVersionListComponent } from '@gipi-pages/type-version/components/type-version-list/type-version-list.component';
import { UserListComponent } from '@gipi-pages/user/components/user-list/user-list.component';
import { VersionListComponent } from '@gipi-pages/version/components/version-list/version-list.component';
import { ClientAreaComponent } from '../components/screens/client-area/client-area.component';
import { ConfirmAccessComponent } from '../components/screens/confirm-access/confirm-access.component';
import { LoginComponent } from '../components/screens/login/login.component';
import { RecoverPasswordComponent } from '../components/screens/recover-password/recover-password.component';
import { UpdateClientsComponent } from '../components/screens/update-clients/update-clients.component';
import { UpdatePasswordComponent } from '../components/screens/update-password/update-password.component';
import { DragAndDropComponent } from '../components/screens/update-tables/components/update-tables-ncm/components/drag-and-drop/drag-and-drop.component';
import { UpdateTablesNcmComponent } from '../components/screens/update-tables/components/update-tables-ncm/update-tables-ncm.component';
import { NotificationTypeListComponent } from '../pages/notification-type/components/notification-type-list/notification-type-list.component';
import { NotificationFormComponent } from '../pages/notification/components/notification-form/notification-form.component';
import { NotificationListComponent } from '../pages/notification/components/notification-list/notification-list.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        pathMatch: 'full',
        redirectTo: '/update-clients'
    },
    {
        path: 'update-clients',
        component: UpdateClientsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'consult-backup',
        component: BackupListComponent,
        canActivate: [AuthGuard],
        data: { roles: [EnumAuthorities.ROLE_SUPPORT, EnumAuthorities.ROLE_DEVELOPERS] }
    },
    {
        path: 'consult-delay-blocking',
        component: DelayBlockingListComponent,
        canActivate: [AuthGuard],
        data: { roles: [EnumAuthorities.ROLE_FINANCIAL, EnumAuthorities.ROLE_DEVELOPERS] }
    },
    {
        path: 'notification-type',
        component: NotificationTypeListComponent,
        canActivate: [AuthGuard],
        data: { roles: [EnumAuthorities.ROLE_DEVELOPERS, EnumAuthorities.ROLE_SUPPORT, EnumAuthorities.ROLE_SELLER, EnumAuthorities.ROLE_FINANCIAL] }
    },
    {
        path: 'notification',
        component: NotificationListComponent,
        canActivate: [AuthGuard],
        data: { roles: [EnumAuthorities.ROLE_DEVELOPERS, EnumAuthorities.ROLE_SUPPORT, EnumAuthorities.ROLE_SELLER, EnumAuthorities.ROLE_FINANCIAL] }
    },
    {
        path: 'notification/new',
        component: NotificationFormComponent,
        canActivate: [AuthGuard],
        data: { roles: [EnumAuthorities.ROLE_DEVELOPERS, EnumAuthorities.ROLE_SUPPORT, EnumAuthorities.ROLE_SELLER, EnumAuthorities.ROLE_FINANCIAL] }
    },
    {
        path: 'notification/clone/:id',
        component: NotificationFormComponent,
        canActivate: [AuthGuard],
        data: { roles: [EnumAuthorities.ROLE_DEVELOPERS, EnumAuthorities.ROLE_SUPPORT, EnumAuthorities.ROLE_SELLER, EnumAuthorities.ROLE_FINANCIAL] }
    },
    {
        path: 'users',
        component: UserListComponent,
        canActivate: [AuthGuard],
        data: { roles: [EnumAuthorities.ROLE_REGISTER] }
    },
    {
        path: 'update-tables',
        canActivate: [AuthGuard],
        data: { roles: [EnumAuthorities.ROLE_DEVELOPERS] },
        children: [
            {
                path: 'function',
                component: FunctionListComponent,
                canActivate: [AuthGuard],
                data: { roles: [EnumAuthorities.ROLE_DEVELOPERS] }
            },
            {
                path: 'ncm',
                component: UpdateTablesNcmComponent,
                canActivate: [AuthGuard],
                data: { roles: [EnumAuthorities.ROLE_DEVELOPERS] }
            },
            {
                path: 'ncm/synchronize',
                component: DragAndDropComponent,
                canActivate: [AuthGuard],
                data: { roles: [EnumAuthorities.ROLE_DEVELOPERS] }
            },
            {
                path: 'type-version',
                component: TypeVersionListComponent,
                canActivate: [AuthGuard],
                data: { roles: [EnumAuthorities.ROLE_DEVELOPERS] }
            },
            {
                path: 'version',
                component: VersionListComponent,
                canActivate: [AuthGuard],
                data: { roles: [EnumAuthorities.ROLE_DEVELOPERS] }
            },
        ]
    },
    {
        path: 'oauth',
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'recover-password',
                component: RecoverPasswordComponent,
            },
            {
                path: 'change-password/:token',
                component: UpdatePasswordComponent,
            },
            {
                path: 'confirm-access/:token',
                component: ConfirmAccessComponent,
            },
            {
                path: 'client-area',
                component: ClientAreaComponent,
            }
        ]
    },
    {
        path: '**',
        redirectTo: ''
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
