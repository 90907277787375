import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClientBase } from 'src/app/core/request/httpClientBase';

@Injectable({ providedIn: 'root' })
export class SyncronizeAppsService {

    private _baseURL: string = `${HttpClientBase.baseURLApoio}synchronize`;

    constructor(private _httpClient: HttpClient) { }

    syncronizeAll(): Observable<boolean> {
        return this._httpClient.post<void>(`${this._baseURL}/synchronize-all`, null).pipe(
            map(() => {
                return true;
            }),
            catchError(() => {
                return observableOf(false);
            })
        );
    }

}
