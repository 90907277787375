import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;

@Component({
    selector: 'gipi-toolbar',
    exportAs: 'gipiToolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof ToolbarComponent => ToolbarComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-toolbar'
    }
})
export class ToolbarComponent implements OnInit {

    private _name: string = `gipi-toolbar-${nextUniqueId++}`;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    @Input() ariaLabel: string;

    @Input() label: string;

    @Input() style: string;

    @Input() class: string;

    constructor() { }

    ngOnInit(): void { }

}
