import { NumberUtil } from './number.util';


export class ObjectUtil {

    public static isNull(value: Object): boolean {
        return value === undefined || value === null;
    }

    public static isNewModel(value: Object): boolean {
        let id: number = null;
        if (!ObjectUtil.isNull(value)) {
            id = Reflect.get(value, 'id') as number;
        }
        return !NumberUtil.isPositive(id);
    }

    public static clone(value: Object): any {
        if (ObjectUtil.isNull(value)) {
            return new Object();
        }
        return { ...value };
    }

}
