import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { MdePopoverTarget, MdePopoverTrigger } from '@material-extended/mde';
import { InputTextComponent } from '../input-text/input-text.component';

let nextUniqueId = 0;

/** A altura dos itens selecionados em unidades `px`. */
const OPTION_HEIGHT_PX = 40;

@Component({
    selector: 'gipi-input-select-enum',
    exportAs: 'gipiInputSelectEnum',
    templateUrl: './input-select-enum.component.html',
    styleUrls: ['./input-select-enum.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof InputSelectEnumComponent => InputSelectEnumComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-input-select-enum'
    }
})
export class InputSelectEnumComponent implements OnInit, OnDestroy, ControlValueAccessor {

    private _name: string = `gipi-input-select-enum-${nextUniqueId++}`;

    _enumList: any[] = [];

    _itemSizeScrollViewport: number = 5;

    _lastOptionSelected: any = null;

    @ViewChild(InputTextComponent, { static: true }) inputTextRef!: InputTextComponent;

    @ViewChild('popoverTrigger', { static: false }) popoverTrigger: MdePopoverTrigger;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    @Input() label: string = '';

    @Input() placeholder: string = '';

    @Input() help: string = '';

    private _required: boolean = false;
    @Input() get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    private _disabled: boolean = false;
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _lowerCase: boolean = false;
    @Input() get lowerCase(): boolean {
        return this._lowerCase;
    }
    set lowerCase(value: boolean) {
        this._lowerCase = coerceBooleanProperty(value);
    }

    private _upperCase: boolean = false;
    @Input() get upperCase(): boolean {
        return this._upperCase;
    }
    set upperCase(value: boolean) {
        this._upperCase = coerceBooleanProperty(value);
    }

    private _hideOnEscape: boolean = true;
    @Input() get hideOnEscape() {
        return this._hideOnEscape;
    }
    set hideOnEscape(value: any) {
        this._hideOnEscape = coerceBooleanProperty(value);
    }

    private _value!: any;
    @Input()
    get value(): any {
        return this._value;
    }
    set value(value: any) {
        this._value = value;
        this.onChange(this._value);
        this.onTouched(this._value);
    }

    @Input() enum: any;

    @Input() sort: (a: any, b: any) => number;

    @Input() valuesExclud: string[];

    @Input() valuesOmit: string[];

    @Input() mdePopoverTargetAt: MdePopoverTarget;

    onChange: Function = () => { };

    onTouched: Function = () => { };

    @Output() onOpenPopover: EventEmitter<void> = new EventEmitter<void>();
    @Output() onClosePopover: EventEmitter<void> = new EventEmitter<void>();
    @Output('click') onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);
    @Output('focus') onFocus: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);
    @Output('blur') onBlur: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);

    public get optionSelected(): string {
        if (this.value && !ArrayUtil.isEmpty(this._enumList)) {
            const selected = this._enumList.find(e => e.key === this.value);
            if (selected) {
                return selected.value;
            }
        }

        return '';
    }

    public get popoverContentWidth(): string {
        if (ObjectUtil.isNull(this.popoverTrigger) || (!ObjectUtil.isNull(this.popoverTrigger) && !this.popoverTrigger.popoverOpen)) {
            return;
        }
        if (ObjectUtil.isNull(this.inputTextRef)) {
            return 'auto';
        }
        const inputRef: HTMLElement = (this.inputTextRef.elementRef.nativeElement as HTMLElement);
        return `${inputRef.clientWidth}px`;
    }

    public get scrollViewportHeight(): number {
        let height: number = 0;
        if (this._enumList && this._enumList.length) {
            height = (this._enumList.length * OPTION_HEIGHT_PX);
        }

        return height;
    }

    constructor(
        public elementRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        const lEnumList = Object.keys(this.enum)
            .map(key => ({ key: key, value: this.enum[key] }))
            .sort(this.sort);

        if (!ObjectUtil.isNull(this.valuesExclud)) {
            for (let i = 0; i < lEnumList.length; i++) {
                const excludEnum: boolean = this.valuesExclud.some(e => e === lEnumList[i].key);
                if (!excludEnum) {
                    this._enumList.push(lEnumList[i]);
                }
            }
        } else {
            this._enumList = lEnumList;
        }

        this._changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        if (this.popoverTrigger) {
            this.popoverTrigger.destroyPopover();
        }
    }

    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }

    public validateOmitByKey(key: string): boolean {
        return !ObjectUtil.isNull(this.valuesOmit) ? !this.valuesOmit.some(el => el === key) : true;
    }

    public onButtonSelectOptionClick(key: string): void {
        this.value = key;
        this.closePopover();
        this._changeDetectorRef.detectChanges();
    }

    public showPopover(): void {
        if (this.disabled) {
            return;
        } else {
            this.popoverTrigger.openPopover();
        }
    }

    public closePopover(): void {
        if (this.disabled) {
            return;
        } else {
            this.popoverTrigger.closePopover();
        }
    }

    public handleKeydow(event: KeyboardEvent): void {
        if (this.disabled) {
            event.stopImmediatePropagation();
            return;
        }

        if (
            ((event.code.toUpperCase() === 'ESCAPE') && this.hideOnEscape) ||
            ((event.code.toUpperCase() === 'ENTER') || (event.code.toUpperCase() === 'NUMPADENTER')) ||
            (event.code.toUpperCase() === 'TAB')
        ) {
            if (!ObjectUtil.isNull(this.popoverTrigger) && this.popoverTrigger.popoverOpen) {
                this.closePopover();
                event.preventDefault();
            }
        }
    }

    public handleClick(event: MouseEvent): void {
        if (this.disabled) {
            this.closePopover();
            return;
        } else {
            this.onClick.emit(event);
            event.stopPropagation();
        }
    }

    public handleFocus(event: MouseEvent): void {
        if (this.disabled) {
            return;
        } else {
            this.onFocus.emit(event);
            event.stopPropagation();
        }
    }

    public handleBlur(event: MouseEvent): void {
        if (this.disabled) {
            return;
        } else {
            this.onBlur.emit(event);
            this.onTouched();
            event.stopPropagation();
        }
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: UIEvent): void {
        if (this.popoverTrigger) {
            this.popoverTrigger.closePopover();
            event.stopPropagation();
        }
    }

}
