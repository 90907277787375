import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SnackbarService } from 'src/app/components/global/snackbar/services/snackbar.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ObjectUtil } from 'src/app/ui/utils/object.util';

@Component({
    selector: 'gipi-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    public submitted: boolean = false;

    public hidePassword: boolean = true;

    public inputTextEmail: FormControl = new FormControl('', [Validators.email, Validators.required, Validators.minLength(1), Validators.maxLength(100)]);

    public inputTextPassword: FormControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]);

    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService,
        private _snackbarService: SnackbarService
    ) {
        if (!ObjectUtil.isNull(this._authenticationService.tokenValueLocalStorage)
            && this._authenticationService.isValidToken(this._authenticationService.tokenValue)) {
            this._router.navigate(['/']);
        }
    }

    ngOnInit(): void { }

    public keyDown($event: any): void {
        // Tecla ESC pressionada.
        if ($event.keyCode === 27) {
            this.clearInputs();
        }

        // Tecla ENTER pressionada.
        if ($event.keyCode === 13) {
            this.onClickSubmit();
        }
    }

    private validateUser(): boolean {
        if (this.inputTextEmail.value !== '') {
            if (!this.inputTextEmail.valid) {
                this._snackbarService.showMessage('"E-mail" inválido', true);
                return false;
            }
        } else {
            this._snackbarService.showMessage('O campo "e-mail" é obrigatório', true);
            return false;
        }

        if (this.inputTextPassword.value !== '') {
            if (!this.inputTextPassword.valid) {
                this._snackbarService.showMessage('"Senha" inválida', true);
                return false;
            }
        } else {
            this._snackbarService.showMessage('O campo "senha" é obrigatório', true);
            return false;
        }

        return true;
    }

    private async login(): Promise<boolean> {
        return new Promise(async resolve => {
            await this._authenticationService.login(String(this.inputTextEmail.value).toLowerCase(), this.inputTextPassword.value).toPromise().then(accessToken => {
                if (accessToken && this._authenticationService.isValidToken(accessToken)) {
                    return resolve(true);
                } else {
                    return resolve(false);
                }
            }, error => {
                if (String(error).toUpperCase().includes('LOGIN FORA')) {
                    this._snackbarService.showMessage(String(error), true);
                } else {
                    this._snackbarService.showMessage('Não foi possível realizar a autenticação do usuário', true);
                }
                this.submitted = false;
                return resolve(false);
            });
        });
    }

    public async onClickSubmit(): Promise<void> {
        this.submitted = true;
        if (this.validateUser()) {
            const resultLogin: boolean = await this.login();
            if (resultLogin) {
                this.submitted = false;
                location.reload();
            }
        }
        this.submitted = false;
    }

    private clearInputs(): void {
        this.inputTextEmail.setValue('');
        this.inputTextPassword.setValue('');
    }

}
