import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gipi-client-area',
    templateUrl: './client-area.component.html',
    styleUrls: ['./client-area.component.scss']
})
export class ClientAreaComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
