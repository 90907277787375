<div [attr.id]="id"
     [attr.name]="name"
     [attr.aria-label]="ariaLabel"
     role="menu"
     class="user-profile-content"
     (click)="click.emit($event)">
    <div class="user-photo"> {{ initialName }} </div>

    <span role="text"> {{ 'Olá, ' + username }} </span>

    <mat-icon> expand_more </mat-icon>
</div>