import { FilterListbox } from "./filter-listbox.enum";

export class OptionsSelectedListboxModel<T = any> {

    filter: FilterListbox;

    options: T[];

    constructor() {
        this.filter = 'ALL';
        this.options = [];
    }

}
