<gipi-abstract>
    <gipi-toolbar id="toolbarListClientList"
                  label="Consulta de clientes">
    </gipi-toolbar>
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="start center"
         fxLayoutGap="16px">
        <gipi-input-text placeholder="Pesquisar"
                         showSuffix="true"
                         [(ngModel)]="filter.researchField"
                         (keydown.enter)="findAll()"
                         fxFlex="50"
                         fxFlex.lt-md="100">
            <gipi-button tooltip="Pesquisar"
                         gipi-icon
                         suffix
                         id="btnSearch"
                         icon="search"
                         [forInput]="true"
                         (click)="findAll()">
            </gipi-button>
        </gipi-input-text>

        <gipi-button tooltip="Filtrar"
                     gipi-primary-icon
                     #searchButton
                     id="btnOptionsSearch"
                     svgIcon="filter"
                     [notification]="(migratedClientValue !== 'ALL_CLIENTS') || (blockedClientValue !== 'ALL_CLIENTS')"
                     [popover]="true"
                     [disabled]="loading">
            <div class="client-content-filter">
                <div class="header">
                    Filtrar por clientes
                </div>
                <div class="content">
                    <fieldset class="filter-by">
                        <legend>Migrados</legend>
                        <gipi-radio-group id="inputFilterByMigratedRadioGroup"
                                          name="inputFilterByMigratedRadioGroup"
                                          [align]="'column'"
                                          [enum]="migratedClientEnum"
                                          [(ngModel)]="migratedClientValue">
                        </gipi-radio-group>
                    </fieldset>

                    <fieldset class="filter-by">
                        <legend>Bloqueados</legend>
                        <gipi-radio-group id="inputFilterByBlockedRadioGroup"
                                          name="inputFilterByBlockedRadioGroup"
                                          [align]="'column'"
                                          [enum]="blockedClientEnum"
                                          [(ngModel)]="blockedClientValue">
                        </gipi-radio-group>
                    </fieldset>
                </div>

                <gipi-button label="Pesquisar"
                             gipi-primary
                             id="btnFind"
                             icon="search"
                             [disabled]="loading"
                             (click)="searchButton.closePopover(); findAll()"
                             fxFlex>
                </gipi-button>
            </div>
        </gipi-button>
    </div>

    <gipi-mat-table [columns]="columns"
                    [rows]="page.content"
                    [sort]="true"
                    [paginator]="true"
                    [pageIndex]="page.number"
                    [pageLength]="page.totalElements"
                    [loading]="loading"
                    (pageChange)="findAll($event)"
                    (rowChange)="rowChange($event)">
    </gipi-mat-table>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnConfirm"
                     name="btnConfirm"
                     (click)="confirm()"
                     [disabled]="entitiesSelectedIsEmpty || loading"
                     [loading]="loading"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract>

<ng-template #launchTemplate
             let-row>
    <mat-icon *ngIf="row.hasFinancial"
              class="launch-icon"
              svgIcon="person_has_financial"
              matTooltip="Cliente migrado">
    </mat-icon>
</ng-template>

<ng-template #checkboxAllTemplate
             let-row>
    <gipi-checkbox gipi-primary
                   [(ngModel)]="allRowsChecked"
                   [checked]="allRowsChecked"
                   [(indeterminate)]="indeterminateRowsChecked"
                   (change)="checkAllRows()">
    </gipi-checkbox>
</ng-template>

<ng-template #checkboxRowTemplate
             let-row>
    <gipi-checkbox gipi-secondary
                   [(ngModel)]="row.selected"
                   [checked]="row.selected"
                   (change)="selectRow(row, true)">
    </gipi-checkbox>
</ng-template>

<ng-template #statusTemplate
             let-row>
    <gipi-lozenge [label]="row.enabled ? 'Ativo' : 'Inativo'"
                  [type]="row.enabled ? 'success' : 'error'">
    </gipi-lozenge>
</ng-template>