<div class="background">
    <div
         style="flex-direction: column; box-sizing: border-box; display: flex; place-content: center; align-items: center;">
        <div
             style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center; align-items: center; flex: 1 1 100vh; max-height: 100vh; min-height: 100vh;">
            <div style="flex-direction: row; box-sizing: border-box; display: flex;">
                <mat-card class="mat-card"
                          style="flex: 1 1 0%; box-sizing: border-box;">
                    <mat-card-content>
                        <div
                             style="flex-direction: column; box-sizing: border-box; display: flex; place-content: center; align-items: center;">
                            <img src="assets/login/logo-login.png"
                                 alt="logo"
                                 width="180">
                        </div>
                        <div style="margin-bottom: 10px; margin-top: 30px;">
                            <p class="text-confirm-access">
                                Digite abaixo a nova senha que você irar utilizar para
                                acessar o sistema.
                            </p>
                        </div>

                        <div style="flex-direction: column; box-sizing: border-box; display: flex; margin-top: 40px;">

                            <!--Input Password-->
                            <div style="margin-bottom: 20px !important;">
                                <div style="width: 100%;">
                                    <mat-label class="mat-label">
                                        Senha <span style="color: #f44336!important;"> *</span>
                                    </mat-label>
                                    <mat-form-field floatLabel="always"
                                                    appearance="outline"
                                                    style="width: 100%;">
                                        <input matInput
                                               tabindex="0"
                                               [type]="hidePassword ? 'password' : 'text'"
                                               autocomplete="off"
                                               id="password"
                                               minlength="6"
                                               maxlength="16"
                                               required
                                               [formControl]="inputTextPassword" />
                                        <button mat-icon-button
                                                matSuffix
                                                class="button-input"
                                                tabindex="-1"
                                                matTooltip="{{ hidePassword ? 'Mostrar senha' : 'Ocultar senha' }}"
                                                onclick="this.blur()"
                                                (click)="hidePassword = !hidePassword">
                                            <mat-icon style="width: 15px; height: 15px;">
                                                {{ hidePassword ? 'lock' : 'lock_open' }}
                                            </mat-icon>
                                        </button>
                                        <mat-error
                                                   *ngIf="inputTextPassword.errors && (inputTextPassword.dirty || inputTextPassword.touched)">
                                            <p *ngIf="inputTextPassword.errors.required">
                                                A nova senha é obrigatória e não foi informada
                                            </p>
                                            <p *ngIf="!isValidCharacterPassword">
                                                Os caracteres %, +, -, * e / não são permitidos
                                            </p>
                                            <p *ngIf="inputTextPassword.value && inputTextPassword.value.length < 6">
                                                A nova senha precisa ter no mínimo 6 caracteres
                                            </p>
                                            <p *ngIf="inputTextPassword.value && inputTextPassword.value.length >= 16">
                                                A nova senha pode ter no máximo 16 caracteres
                                            </p>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <!--Input Confirm Password-->
                            <div style="margin-bottom: 0px !important;">

                                <div style="width: 100%;">

                                    <mat-label class="mat-label">
                                        Confirmar senha <span style="color: #f44336!important;"> *</span>
                                    </mat-label>
                                    <mat-form-field floatLabel="always"
                                                    appearance="outline"
                                                    style="width: 100%;">
                                        <input matInput
                                               tabindex="0"
                                               [type]="hideConfirmPassword ? 'password' : 'text'"
                                               id="confirm-password"
                                               autocomplete="off"
                                               minlength="6"
                                               maxlength="16"
                                               [formControl]="inputTextConfirmPassword" />
                                        <button mat-icon-button
                                                matSuffix
                                                class="button-input"
                                                tabindex="-1"
                                                matTooltip="{{ hideConfirmPassword ? 'Mostrar senha' : 'Ocultar senha' }}"
                                                onclick="this.blur()"
                                                (click)="hideConfirmPassword = !hideConfirmPassword">
                                            <mat-icon style="width: 15px; height: 15px;">
                                                {{ hideConfirmPassword ? 'lock' : 'lock_open' }}
                                            </mat-icon>
                                        </button>
                                        <mat-error
                                                   *ngIf="inputTextConfirmPassword.errors && (inputTextConfirmPassword.dirty || inputTextConfirmPassword.touched)">
                                            <p *ngIf="inputTextConfirmPassword.errors.required">
                                                A confirmação da nova senha é obrigatória e não foi informada
                                            </p>
                                            <p *ngIf="!isValidCharacterConfirmPassword">
                                                Os caracteres %, +, -, * e / não são permitidos
                                            </p>
                                            <p
                                               *ngIf="inputTextConfirmPassword.value && inputTextConfirmPassword.value.length < 6">
                                                A confirmação da nova senha precisa ter no mínimo 6 caracteres
                                            </p>
                                            <p
                                               *ngIf="inputTextConfirmPassword.value && inputTextConfirmPassword.value.length >= 16">
                                                A confirmação da nova senha pode ter no máximo 16 caracteres
                                            </p>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                        <div
                             style="margin-bottom: 10px; margin-top: 15px; flex-direction: row; display: flex; align-items: center; justify-content: center;">
                            <!--Button Confirmar-->
                            <button mat-raised-button
                                    class="button-confirm button-text"
                                    [disabled]="submitted"
                                    (click)="onClickUpdatePassword()">
                                <div class="div-spinner-text">
                                    <mat-spinner style="margin-right: 5px;"
                                                 [ngClass]="{'custom-spinner': submitted}"
                                                 [diameter]="22"
                                                 *ngIf="submitted"></mat-spinner>
                                    <span>Confirmar</span>
                                </div>
                            </button>

                            <!--Button cancelar-->
                            <button mat-raised-button
                                    class="button-cancel button-text"
                                    style="margin-left: 15px;"
                                    (click)="goBack()">
                                <span>Cancelar</span>
                            </button>
                        </div>

                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>