<button [attr.id]="id"
        [attr.name]="name"
        [attr.type]="type"
        [attr.aria-label]="ariaLabel ? ariaLabel : label"
        [attr.title]="title ? title : label"
        [disabled]="disabled || loading"
        [matTooltip]="tooltip"
        role="button"
        [class]="buttonClass"
        [ngClass]="{'button-base': true,
                    'button-primary': (isButtonPrimary || isButtonPrimaryIcon),
                    'button-primary-icon': isButtonPrimaryIcon,
                    'button-secondary': (isButtonSecondary || isButtonSecondaryIcon),
                    'button-secondary-icon': isButtonSecondaryIcon,
                    'button-tertiary': (isButtonTertiary || isButtonTertiaryIcon),
                    'button-tertiary-icon': isButtonTertiaryIcon,
                    'button-link': isButtonLink,
                    'button-only-icon': isButtonIcon,
                    'button-for-input': isForInput,
                    'button-notification': notification,
                    'button-action': isButtonAction}"
        [style.padding.px]="buttonPadding"
        #popoverTrigger="mdePopoverTrigger"
        [mdePopoverTargetAt]="mdePopoverTargetAt"
        [mdePopoverTriggerFor]="btnPopover"
        [mdePopoverTriggerOn]="popover ? 'click' : 'none'"
        [mdePopoverBackdropCloseOnClick]="true"
        (opened)="onOpenPopover.emit()"
        (closed)="onClosePopover.emit()"
        (click)="handleClick($event)"
        (focus)="handleFocus($event)"
        (blur)="handleBlur($event)">

    <span [ngClass]="{'button-content': true,
                      'button-disabled': disabled,
                      'button-icon-left': ((iconPos === 'left') && label),
                      'button-icon-right': ((iconPos === 'right') && label),
                      'button-icon-top': ((iconPos === 'top') && label),
                      'button-icon-bottom': ((iconPos === 'bottom') && label),
                      'button-icon-center': (iconPos === 'center')}">

        <mat-icon *ngIf="svgIcon || icon"
                  [svgIcon]="svgIcon"
                  [style.width.px]="iconWidth"
                  [style.height.px]="iconHeight">
            {{ (!svgIcon && icon) ? icon : '' }}
        </mat-icon>

        <span class="button-label"
              [attr.aria-hidden]="(icon || svgIcon) && !label"
              *ngIf="!isButtonIcon && label">
            {{ label }}
        </span>
    </span>

</button>

<mde-popover #btnPopover="mdePopover"
             [mdePopoverOverlapTrigger]="false"
             [mdePopoverCloseOnClick]="false"
             [mdeFocusTrapEnabled]="false"
             [mdeFocusTrapAutoCaptureEnabled]="false"
             [mdePopoverArrowWidth]="0"
             [mdePopoverOffsetY]="3">
    <div class="btn-popover-content"
         [style.min-width]="(popoverContentWidth | async)">
        <ng-content> </ng-content>
    </div>
</mde-popover>