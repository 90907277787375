<gipi-abstract>
    <gipi-toolbar id="toolbarListDelayBlocking"
                  label="Consulta de prorrogações de licença">
    </gipi-toolbar>
    <div fxLayout="column"
         fxLayoutGap="10px">
        <div fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="16px">
            <gipi-input-text placeholder="Pesquisar"
                             showSuffix="true"
                             [disabled]="loading"
                             [(ngModel)]="filter.researchField"
                             (keyup)="findAllByResearchField()"
                             fxFlex="50"
                             fxFlex.lt-md="">
                <gipi-button tooltip="Pesquisar"
                             gipi-icon
                             suffix
                             id="btnSearch"
                             icon="search"
                             [disabled]="loading"
                             [forInput]="true"
                             (click)="findAll(null)">
                </gipi-button>
            </gipi-input-text>

            <gipi-button tooltip="Filtrar"
                         gipi-primary-icon
                         #searchButton
                         id="btnOptionsSearch"
                         svgIcon="filter"
                         [notification]="filter?.startDate || filter?.endDate"
                         [popover]="true"
                         [disabled]="loading">
                <div class="delay-blocking-content-filter">
                    <div class="header">
                        Filtrar por data e tipo
                    </div>
                    <div class="content">
                        <fieldset class="filter-by">
                            <legend>Filtrar por data</legend>
                            <gipi-radio-group id="inputFilterByTypeDateRadioGroup"
                                              name="inputFilterByTypeDateRadioGroup"
                                              [align]="'row'"
                                              [enum]="filterTypeDateEnum"
                                              [(ngModel)]="filter.searchDate">
                            </gipi-radio-group>
                        </fieldset>

                        <gipi-date-range-picker label="Período"
                                                id="inputFilterPeriodDateRangePicker"
                                                name="inputFilterPeriodDateRangePicker"
                                                [(ngModel)]="rangeDateFilter"
                                                fxFlex
                                                fxFlex.lt-md="100">
                        </gipi-date-range-picker>

                        <div style="margin-top: 1rem;"
                             fxLayout="row"
                             fxLayoutGap="12px"
                             fxFlex>
                            <gipi-button label="Pesquisar"
                                         gipi-primary
                                         id="btnFind"
                                         icon="search"
                                         [disabled]="loading"
                                         (click)="searchButton.closePopover(); findAll()"
                                         fxFlex="50">
                            </gipi-button>
                            <gipi-button label="Limpar"
                                         gipi-secondary
                                         id="btnClear"
                                         icon="clear"
                                         [disabled]="loading"
                                         (click)="searchButton.closePopover(); clear()"
                                         fxFlex="50">
                            </gipi-button>
                        </div>
                    </div>
                </div>
            </gipi-button>
        </div>

        <mat-slide-toggle [disabled]="loading"
                          [(ngModel)]="filter.showDeleted"
                          (change)="findAll(null)">
            Exibir somente registros excluídos
        </mat-slide-toggle>
    </div>

    <gipi-mat-table [columns]="columns"
                    [rows]="page.content"
                    [sort]="true"
                    [paginator]="true"
                    [pageIndex]="page.number"
                    [pageLength]="page.totalElements"
                    [loading]="loading"
                    (pageChange)="findAll($event)">
    </gipi-mat-table>
</gipi-abstract>

<ng-template #statusTemplate
             let-row>
    <gipi-lozenge [label]="getLabelStatus(row.deleted, row.deadLine)"
                  [type]="getTypeStatus(row.deleted, row.deadLine)">
    </gipi-lozenge>
</ng-template>