import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RegisterSituation, RegisterSituationEnum } from '@gipi-ui/enums/register-situation.enum';
import { TypeOperationCloseDialog } from '@gipi-ui/enums/type-operation-close-dialog.enum';
import { TypeOperationDialog } from '@gipi-ui/enums/type-operation-dialog.enum';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { AbstractModel } from './models/abstract.model';
import { AbstractService } from './services/abstract.service';

export interface FormDialogData<T> {
    typeOperation: TypeOperationDialog;
    entity: T;
}

@Component({ template: '' })
export abstract class AbstractFormDialogComponent<TEntity extends AbstractModel> implements OnInit {

    public optionSituationEnum: typeof RegisterSituationEnum = RegisterSituationEnum;

    private _optionSituationValue: RegisterSituation = 'ENABLED'
    public set optionSituationValue(value: RegisterSituation) {
        this._optionSituationValue = value;
    }
    public get optionSituationValue(): RegisterSituation {
        return this._optionSituationValue;
    }

    private _loading: boolean = false;
    public set loading(value: boolean) {
        this._loading = value;
    }
    public get loading(): boolean {
        return this._loading;
    }

    private _typeOperation: TypeOperationDialog = 'NEW';
    public set typeOperation(value: TypeOperationDialog) {
        this._typeOperation = value;
    }
    public get typeOperation(): TypeOperationDialog {
        return this._typeOperation;
    }

    private _entity: TEntity = this.newEntity();
    public set entity(value: TEntity) {
        this._entity = value;
    }
    public get entity(): TEntity {
        return this._entity;
    }

    constructor(
        protected abstractCrudService: AbstractService<TEntity, any>,
        @Inject(MatDialogRef) protected dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: (FormDialogData<TEntity> | any) = { typeOperation: 'NEW', entity: null },
    ) { }

    ngOnInit(): void {
        this.typeOperation = this.data ? this.data.typeOperation || 'NEW' : 'NEW';
        if ((this.typeOperation !== 'NEW') && (!ObjectUtil.isNull(this.data.entity))) {
            this.entity = this.data.entity;

            if (!this.entity.enabled) {
                this.optionSituationValue = 'DISABLED';
            }
        }
    }

    protected abstract newEntity(): TEntity;

    protected abstract isValid(): boolean;

    public abstract confirm(): void;

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: TypeOperationCloseDialog): void {
        this.dialogRef.close(operation);
    }

}
