import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { SnackbarComponent } from 'src/app/components/global/snackbar/snackbar.component';

@Injectable({ providedIn: 'root' })
export class SnackbarService {

    constructor(private _snackBar: MatSnackBar) { }

    public showMessage(message: string, isError: boolean = false, title: string = ''): void {
        const snackbarComponent: MatSnackBarRef<SnackbarComponent> = this._snackBar.openFromComponent(SnackbarComponent, {
            direction: 'ltr',
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: isError ? ['error'] : ['success'],
            duration: 8000 // 8 segundos
        });

        snackbarComponent.instance.title = title;
        snackbarComponent.instance.textInformation = message;
        snackbarComponent.instance.showMessage(isError);
    }

}
