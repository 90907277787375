<gipi-abstract>
    <gipi-toolbar id="toolbarListNotificationType"
                  label="Consulta de tipos de notificação">
    </gipi-toolbar>
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="space-between center"
         fxLayoutGap="16px">
        <gipi-input-text placeholder="Pesquisar"
                         showSuffix="true"
                         fxFlex="50"
                         fxFlex.lt-md="100">
            <gipi-button tooltip="Pesquisar"
                         gipi-icon
                         suffix
                         id="btnSearch"
                         icon="search"
                         [forInput]="true"
                         (click)="findAll()">
            </gipi-button>
        </gipi-input-text>
        <div fxFlex="30"
             fxFlex.lt-md="100"
             fxLayoutGap="16px">
            <gipi-button label="Novo"
                         gipi-primary
                         id="btnNewRegister"
                         icon="add"
                         [disabled]="false"
                         (click)="showDialogNotificationType('NEW')"
                         fxFlex
                         fxFlex.lt-md="100">
            </gipi-button>
            <gipi-button label="Exibir"
                         gipi-tertiary
                         id="btnDisplay"
                         icon="expand_more"
                         #displayButton
                         [popover]="true"
                         fxFlex
                         fxFlex.lt-md="100">
                <div style="display: flex; flex-direction: column !important; min-width: 140px !important; gap: 16px;">
                    <gipi-checkbox label="Todos"
                                   gipi-primary
                                   [(ngModel)]="allStatusChecked"
                                   [(indeterminate)]="indeterminateStatusChecked"
                                   (change)="checkAllStatus()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <gipi-checkbox label="Ativo"
                                   gipi-primary
                                   [(ngModel)]="enabledStatusChecked"
                                   (change)="validateAllStatusSelected()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <gipi-checkbox label="Inativo"
                                   gipi-primary
                                   [(ngModel)]="disabledStatusChecked"
                                   (change)="validateAllStatusSelected()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <div>
                        <gipi-button label="Aplicar"
                                     gipi-primary
                                     id="btnApplyFilter"
                                     [disabled]="loading"
                                     (click)="findAll(null); displayButton.closePopover()"
                                     fxFlex>
                        </gipi-button>
                    </div>
                </div>
            </gipi-button>
        </div>
    </div>

    <gipi-mat-table [columns]="columns"
                    [rows]="page.content"
                    [sort]="true"
                    [paginator]="true"
                    [pageIndex]="page.number"
                    [pageLength]="page.totalElements"
                    [loading]="loading"
                    (pageChange)="findAll($event)"
                    (rowChange)="showDialogNotificationType('EDIT', $event)">
        <ng-template #actions
                     let-row>
            <gipi-button tooltip="Ativar | Inativar"
                         gipi-icon
                         id="btnEnableOrDisable"
                         icon="lock_open"
                         [forInput]="true"
                         (click)="enableOrDisable(row)">
            </gipi-button>
            <gipi-button tooltip="Excluir"
                         gipi-icon
                         id="btnEnableOrDisable"
                         svgIcon="delete"
                         [forInput]="true"
                         (click)="delete(row)">
            </gipi-button>
        </ng-template>
    </gipi-mat-table>
</gipi-abstract>

<ng-template #statusTemplate
             let-row>
    <gipi-lozenge [label]="row.enabled ? 'Ativo' : 'Inativo'"
                  [type]="row.enabled ? 'success' : 'error'">
    </gipi-lozenge>
</ng-template>