<div class="lozenge"
     [class.filled]="filled"
     [class.default]="isLozengeDefault || (type === 'default')"
     [class.new]="isLozengeNew || (type === 'new')"
     [class.information]="isLozengeInformation || (type === 'information')"
     [class.warning]="isLozengeWarning || (type === 'warning')"
     [class.error]="isLozengeError || (type === 'error')"
     [class.success]="isLozengeSuccess || (type === 'success')"
     [class.advance]="isLozengeAdvance || (type === 'advance')"
     [class.disabled]="isLozengeDisabled || (type === 'disabled')"
     [matTooltip]="tooltip">
    {{ label }}
</div>
