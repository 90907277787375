import { AbstractFilterModel } from "@gipi-pages/abstract/models/abstract-filter.model";
import { NotificationStatus } from "@gipi-pages/notification/enums/notification-status.enum";
import { TypeDate } from "@gipi-pages/notification/enums/type-date.enum";
import { GIPIUuid } from "@gipi-shared/types/uuid.type";

export class NotificationFilterDTO extends AbstractFilterModel {

    begin: Date;

    end: Date;

    filterByTypeDate: TypeDate;

    statusList: NotificationStatus[];

    typeId: GIPIUuid;

}
