import { AfterContentInit, Component, HostListener, OnInit, ViewEncapsulation, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BreakpointObserverService } from '@gipi-ui/services/breakpoint-observer.service';
import { SidenavMenuService } from '@gipi-ui/services/sidenav-menu.service';

@Component({
    selector: `gipi-sidenav-container`,
    exportAs: 'gipiSidenavContainer',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof SidenavContainerComponent => SidenavContainerComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-sidenav-container',
    },
})
export class SidenavContainerComponent implements OnInit, AfterContentInit {

    constructor(
        private _sidenavMenuService: SidenavMenuService,
        private _breakpointObserverService: BreakpointObserverService
    ) { }

    ngOnInit(): void { }

    ngAfterContentInit(): void {
        this.isScreenLarge.subscribe(isScreenLarge => {
            if (isScreenLarge) {
                this._sidenavMenuService.expand();
            }
        });
    }

    public get isExpanded(): Observable<boolean> {
        return this._sidenavMenuService.isExpanded;
    }

    public get isScreenLarge(): Observable<boolean> {
        return this._breakpointObserverService.size$.pipe(
            map(size => (size !== 'xs') && (size !== 'sm') && (size !== 'md'))
        );
    }

    public async onClickBackdrop(): Promise<void> {
        const isExpanded: boolean = await this._sidenavMenuService.isExpanded.toPromise();
        if (isExpanded) {
            this.isScreenLarge.subscribe(isScreenLarge => {
                if (!isScreenLarge) {
                    this._sidenavMenuService.retract();
                }
            });
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: UIEvent): void {
        this.isScreenLarge.subscribe(isScreenLarge => {
            if (isScreenLarge) {
                this._sidenavMenuService.expand();
            } else {
                this._sidenavMenuService.retract();
            }
        });
    }

}
