import { ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AbstractListDialogComponent } from '@gipi-pages/abstract/abstract-list-dialog.component';
import { SortModel } from '@gipi-pages/abstract/models/sort.model';
import { ClientModel } from '@gipi-pages/clients/models/client.model';
import { DelayBlockingModel } from '@gipi-pages/delay-blocking/models/delay-blocking.model';
import { DelayBlockingFilterDTO } from '@gipi-pages/delay-blocking/models/dto/delay-blocking-filter.dto';
import { DelayBlockingService } from '@gipi-pages/delay-blocking/services/delay-blocking.service';
import { LozengeComponent, LozengeType } from '@gipi-ui/components/lozenge/lozenge.component';
import { TablePaginatorEvent } from '@gipi-ui/components/mat-table/mat-table.component';
import { TableColumn } from '@gipi-ui/components/table/shared/table-column';
import { TableColumnBuilder } from '@gipi-ui/components/table/shared/table-column-builder';
import { TypeOperationCloseDialog } from '@gipi-ui/enums/type-operation-close-dialog.enum';
import { TypeOperationDialog } from '@gipi-ui/enums/type-operation-dialog.enum';
import { APP_MESSAGES } from '@gipi-ui/global/messages';
import { AlertService } from '@gipi-ui/services/alert.service';
import { DialogService } from '@gipi-ui/services/dialog.service';
import { ModalDialogService } from '@gipi-ui/services/modal-dialog.service';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { DateUtil } from '@gipi-ui/utils/date.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { DelayBlockingFormData, DelayBlockingFormDialogComponent } from '../delay-blocking-form-dialog/delay-blocking-form-dialog.component';

@Component({
    selector: 'gipi-delay-blocking-list-dialog',
    templateUrl: './delay-blocking-list-dialog.component.html',
    styleUrls: ['./delay-blocking-list-dialog.component.scss']
})
export class DelayBlockingListDialogComponent extends AbstractListDialogComponent<DelayBlockingModel, DelayBlockingFilterDTO> implements OnInit {

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<LozengeComponent>;

    private _refreshClient: boolean = false;

    public bringDeleted: boolean = false;

    public permittedDelayBlocking: boolean = false;

    public get documentClient(): string {
        if (!ObjectUtil.isNull(this.data) && !ObjectUtil.isNull(this.data.person)) {
            const isLegalPerson: boolean = !ObjectUtil.isNull(this.data.person.legalPerson) && !ObjectUtil.isNewModel(this.data.person.legalPerson);
            const document: string = isLegalPerson ? this.data.person.legalPerson.cnpj : this.data.person.naturalPerson.cpf;

            if (StringUtil.isEmpty(document)) {
                return '';
            }

            return StringUtil.format(document, isLegalPerson ? '00.000.000/0000-00' : '000.000.000-00');
        }

        return '';
    }

    public get nameClient(): string {
        if (!ObjectUtil.isNull(this.data) && !ObjectUtil.isNull(this.data.person)) {
            const isLegalPerson: boolean = !ObjectUtil.isNull(this.data.person.legalPerson) && !ObjectUtil.isNewModel(this.data.person.legalPerson);
            const name: string = isLegalPerson ? this.data.person.name : this.data.person.fantasyName;

            if (StringUtil.isEmpty(name)) {
                return this.data.person.name;
            }

            return name;
        }

        return '';
    }

    constructor(
        protected service: DelayBlockingService,
        protected dialogRef: MatDialogRef<DelayBlockingListDialogComponent>,
        private _changeDetectorRef: ChangeDetectorRef,
        private _alertService: AlertService,
        private _dialogService: DialogService,
        private _modalDialogService: ModalDialogService,
        @Inject(MAT_DIALOG_DATA) public data: ClientModel,
    ) {
        super(service, dialogRef, data);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected _newFilter(): DelayBlockingFilterDTO {
        const filter: DelayBlockingFilterDTO = new DelayBlockingFilterDTO();
        filter.sorts = [new SortModel('createdDate', 'DESC')];
        return new DelayBlockingFilterDTO();
    }

    protected _createTableColumns(): TableColumn[] {
        return [
            TableColumnBuilder
                .instance()
                .property('createdDate')
                .description('Cadastro')
                .sortable(true)
                .value((obj: DelayBlockingModel) => DateUtil.isValid(obj.createdDate) ? DateUtil.format(obj.createdDate, DateUtil.DATE_FORMAT) : '')
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('deadLine')
                .description('Vencimento')
                .sortable(true)
                .value((obj: DelayBlockingModel) => DateUtil.isValid(obj.deadLine) ? DateUtil.format(obj.deadLine, DateUtil.DATE_FORMAT) : '')
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('nameUser')
                .description('Usuário')
                .sliceLength(50)
                .value((obj: DelayBlockingModel) => !StringUtil.isEmpty(obj.nameUser) ? obj.nameUser : '')
                .width(150)
                .build(),
            TableColumnBuilder
                .instance()
                .property('reason')
                .description('Observação')
                .sliceLength(50)
                .value((obj: DelayBlockingModel) => !StringUtil.isEmpty(obj.reason) ? obj.reason : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('status')
                .description('Status')
                .template(this.statusTemplate)
                .width(150)
                .align('center')
                .build(),
        ];
    }

    private async _verifyIfExistDelayActive(): Promise<void> {
        const documentClient: string = (!ObjectUtil.isNull(this.data.person) && !ObjectUtil.isNull(this.data.person.legalPerson))
            ? this.data.person.legalPerson.cnpj
            : this.data.person.naturalPerson.cpf;

        this.permittedDelayBlocking = await this.service.verifyIfExistDelayActive(documentClient);
    }

    public confirm(): void {
        this.dialogRef.close();
    }

    public findAll(pageEvent?: TablePaginatorEvent): void {
        try {
            this.loading = true;

            this.page = this._newPage();
            if (ObjectUtil.isNull(this.filter)) {
                this.filter = this._newFilter();
            }

            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;

                if (!ObjectUtil.isNull(pageEvent.sort) && !StringUtil.isEmpty(pageEvent.sort.field)) {
                    this.filter.sorts = [new SortModel(pageEvent.sort.field, (pageEvent.sort.direction || 'DESC'))];
                }
            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
            }

            const documentClient: string = (!ObjectUtil.isNull(this.data.person) && !ObjectUtil.isNull(this.data.person.legalPerson))
                ? this.data.person.legalPerson.cnpj
                : this.data.person.naturalPerson.cpf;

            const sort: SortModel = (!ArrayUtil.isEmpty(this.filter.sorts)) ? this.filter.sorts[0] : null;

            this.service.findByCnpj(documentClient, this.bringDeleted, this.filter.pageNumber, 5, sort).toPromise().then(page => {
                this.page = page;
                this._verifyIfExistDelayActive();
                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            throw new Error(e);
        }

        this._changeDetectorRef.detectChanges();
    }

    public showDialogNewDelayBlocking(typeOperation: TypeOperationDialog, entity?: DelayBlockingModel): void {
        if (!this.permittedDelayBlocking) {
            return;
        }

        const data: DelayBlockingFormData = {
            typeOperation: typeOperation,
            entity: !ObjectUtil.isNull(entity) ? ObjectUtil.clone(entity) : null,
            client: ObjectUtil.clone(this.data),
        }

        this._dialogService
            .open(DelayBlockingFormDialogComponent, data, { width: '40%', height: 'auto' })
            .afterClosed().toPromise().then((operation: TypeOperationCloseDialog) => {
                if (operation === 'RELOAD_TABLE') {
                    this.findAll();

                    // Faz isso pois se criou algum delayBlocking novo terá que consultar o cliente
                    if (!this._refreshClient) {
                        this._refreshClient = true;
                    }
                }
            });
    }

    public getLabelStatus(deleted: boolean, deadLine: Date): string {
        if (deleted) {
            return 'Excluído';
        } else {
            if (this.service.delayBlockingIsActive(deadLine)) {
                return 'Ativo';
            } else {
                return 'Inativo';
            }
        }
    }

    public getTypeStatus(deleted: boolean, deadLine: Date): LozengeType {
        if (deleted) {
            return 'error';
        } else {
            if (this.service.delayBlockingIsActive(deadLine)) {
                return 'success'
            } else {
                return 'warning';
            }
        }
    }

    public validateDeleted(deleted: boolean, deadLine: Date): boolean {
        if (deleted) {
            return false;
        } else {
            return this.service.delayBlockingIsActive(deadLine);
        }
    }

    public deleteDelayBlocking(entity: DelayBlockingModel): void {
        if (ObjectUtil.isNull(entity)) {
            this._alertService.addErrorMessage('O registro selecionado não foi identificado');
            return;
        }

        this._modalDialogService.show({
            title: 'Confirmação',
            message: `Deseja realmente excluir este registro?`,
            showConfirm: true,
            showCancel: true,
            accept: () => {
                this.loading = true;
                this.service.delete(entity.id).toPromise().then(_ => {
                    this.loading = false;
                    this._alertService.addSuccessMessage(APP_MESSAGES.SUCCESS);
                    this.findAll();

                    // Faz isso pois se criou algum delayBlocking novo terá que consultar o cliente
                    if (!this._refreshClient) {
                        this._refreshClient = true;
                    }
                }, error => {
                    this.clear();
                    this.loading = false;
                    this._alertService.handleError(error);
                });
            },
        });
    }

    public close(): void {
        this.dialogRef.close(this._refreshClient);
    }

}
