<div class="dialog-container">

    <!--Title-->
    <div style="display: flex; flex-direction: row; align-items: center; margin-top: 5px;">
        <mat-icon style="width: 36px; height: 36px; fill: #F3465C;"
                  svgIcon="generation_license_off"
                  aria-hidden="false"
                  aria-label="Informações dos clientes"></mat-icon>
        <p class="title-page">LICENÇA OFFLINE</p>
    </div>

    <mat-divider></mat-divider>
    <mat-progress-bar mode="indeterminate"
                      *ngIf="isLoading"></mat-progress-bar>

    <!-- Client -->
    <div class="inf-client">
        <!-- CNPJ/CPF Client -->
        <div style="flex-direction: column; box-sizing: border-box; display: flex; width: 25%;">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                CNPJ / CPF
            </mat-label>
            <mat-form-field floatLabel="always"
                            appearance="outline"
                            style="width: 100%;">
                <input matInput
                       type="text"
                       id="cnpj-cpf"
                       autocomplete="off"
                       readonly="true"
                       value="{{ cnpjOrCpfClient | GIPICpfCnpj }}"
                       (click)="onClickSelectValueInput($event)" />
            </mat-form-field>
        </div>

        <!-- Name Client -->
        <div style="flex-direction: column; box-sizing: border-box; display: flex; width: 100%; margin-left: 10px;">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                Nome Fantasia
            </mat-label>
            <mat-form-field floatLabel="always"
                            appearance="outline"
                            style="width: 100%;">
                <input matInput
                       type="text"
                       id="fantasy-name"
                       autocomplete="off"
                       readonly="true"
                       value="{{ nameClient }}"
                       (click)="onClickSelectValueInput($event)" />
            </mat-form-field>
        </div>
    </div>

    <!--Input License -->
    <div style="margin-top: 10px; height: 75px;">
        <div style="flex-direction: column; box-sizing: border-box; display: flex;">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                Licença
            </mat-label>
            <mat-form-field floatLabel="always"
                            appearance="outline"
                            style="width: 100%;">
                <input matInput
                       type="text"
                       id="license"
                       autocomplete="off"
                       readonly="true"
                       [(ngModel)]="licenseNumber"
                       (click)="onClickSelectValueInput($event)"
                       mask="{{ typeLicense === 0 ? maskSummarized : maskComplete }}" />

                <!-- Button Copy -->
                <button mat-icon-button
                        matSuffix
                        class="button-input"
                        matTooltip="Copiar para área de tranferência"
                        onclick="this.blur()"
                        [cdkCopyToClipboard]="licenseNumber">
                    <mat-icon style="width: 15px; height: 15px;"
                              svgIcon="copy"></mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>

    <!--Input Observação -->
    <div style="margin-top: 10px;">
        <div style="flex-direction: column; box-sizing: border-box; display: flex;">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                Observação
            </mat-label>
            <mat-form-field appearance="outline"
                            hintLabel=""
                            style="width: 100%;">
                <textarea matInput
                          id="observation"
                          style="height: 70px;min-height: 70px; max-height: 180px;"
                          readonly="true"
                          value="{{ reason }}"
                          (click)="onClickSelectValueInput($event)"></textarea>
            </mat-form-field>
        </div>
    </div>

    <!--Button goBack-->
    <div style="display: flex; justify-content: flex-end; align-items: center; margin-top: 15px;">
        <button mat-raised-button
                [autofocus]="false"
                onclick="this.blur()"
                class="mat-raised-button-go-back"
                (click)="onClickGoBack()">
            <mat-icon class="mat-icon"
                      role="img"
                      aria-hidden="true">arrow_back</mat-icon>
            VOLTAR
        </button>
    </div>

</div>