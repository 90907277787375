import { AbstractModel } from "@gipi-pages/abstract/models/abstract.model";
import { GIPIUuid } from "@gipi-shared/types/uuid.type";

export class RoleModel extends AbstractModel {

    description: string;

    constructor(id?: GIPIUuid) {
        super();
        this.id = id;
    }

}
