import { Injectable } from '@angular/core';

import { DecodedToken } from '@gipi-components/screens/login/models/decoded-token.model';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { TypeAuthorities } from '../enums/enum-authorities.enum';

@Injectable({ providedIn: 'root' })
export class ValidateAccessService {

    constructor(private _authenticationService: AuthenticationService) { }

    public validateAccess(authorities: TypeAuthorities): boolean {
        const lDecodedToken: DecodedToken = this._authenticationService.decryptToken(this._authenticationService.tokenValue);
        if (lDecodedToken) {
            if (authorities === 'ROLE_DEVELOPERS') {
                return (lDecodedToken.authorities.indexOf('ROLE_DEVELOPERS') !== -1);
            } else if (authorities === 'ROLE_SUPPORT') {
                return (lDecodedToken.authorities.indexOf('ROLE_SUPPORT') !== -1);
            } else if (authorities === 'ROLE_FINANCIAL') {
                return (lDecodedToken.authorities.indexOf('ROLE_FINANCIAL') !== -1);
            } else if (authorities === 'ROLE_REGISTER') {
                return (lDecodedToken.authorities.indexOf('ROLE_REGISTER') !== -1);
            } else if (authorities === 'ROLE_SELLER') {
                return (lDecodedToken.authorities.indexOf('ROLE_SELLER') !== -1);
            }
        }

        // Se não for nenhum desse retorna false, ou não exista o token no localStorage, coloquei caso algum usuário
        // fique bugado e não tenha a definição de acesso para ele, só uma garantia mesmo.
        return false;
    }

}
