import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MessageModel } from '@gipi-ui/models/message.model';
import { AlertComponent } from '../components/alert/alert.component';

@Injectable({ providedIn: 'root' })
export class AlertService {

    constructor(protected snackBar: MatSnackBar) { }

    public show(message: MessageModel): void {
        this.snackBar.openFromComponent(AlertComponent, {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 8000, // 8 segundos
            data: message
        });
    }

    public handleError(error: any): void {
        if (String(error).includes('Unknown')) {
            error = new Error('Ocorreu um erro ao realizar essa operação, tente novamente');
        }
        if (error instanceof Error) {
            error = error.message;
        }
        this.addErrorMessage(error);
        throw error;
    }

    public addSuccessMessage(message: string): void {
        this.show({
            icon: 'check_circle',
            title: 'Sucesso',
            message: message,
            type: 'success'
        });
    }

    public addErrorMessage(message: string): void {
        this.show({
            icon: 'cancel',
            title: 'Ocorreu um erro',
            message: message,
            type: 'error'
        });
    }

    public addWarningMessage(message: string): void {
        this.show({
            icon: 'error',
            title: 'Atenção',
            message: message,
            type: 'warning'
        });
    }

    public addInfoMessage(message: string): void {
        this.show({
            icon: 'info',
            title: 'Notificação',
            message: message,
            type: 'info'
        });
    }

}
