import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { RegisterSituation, RegisterSituationEnum } from '@gipi-ui/enums/register-situation.enum';
import { APP_MESSAGES } from '@gipi-ui/global/messages';
import { ModalDialogService } from '@gipi-ui/services/modal-dialog.service';
import { AbstractModel } from './models/abstract.model';
import { AbstractService } from './services/abstract.service';

@Component({ template: '' })
export abstract class AbstractFormComponent<TEntity extends AbstractModel> implements OnInit {

    public optionSituationEnum: typeof RegisterSituationEnum = RegisterSituationEnum;

    private _optionSituationValue: RegisterSituation = 'ENABLED'
    public set optionSituationValue(value: RegisterSituation) {
        this._optionSituationValue = value;
    }
    public get optionSituationValue(): RegisterSituation {
        return this._optionSituationValue;
    }

    private _loading: boolean = false;
    public set loading(value: boolean) {
        this._loading = value;
    }
    public get loading(): boolean {
        return this._loading;
    }

    private _entity: TEntity = this._newEntity();
    public set entity(value: TEntity) {
        this._entity = value;
    }
    public get entity(): TEntity {
        return this._entity;
    }

    constructor(
        protected abstractCrudService: AbstractService<TEntity, any>,
        protected modalDialogService: ModalDialogService,
        protected router: Router,
    ) { }

    ngOnInit(): void { }

    protected abstract getPath(): string;

    protected abstract _newEntity(): TEntity;

    protected abstract _isValid(): boolean;

    protected onAfterGetOne(): void { }

    protected preSave(entity: TEntity): void { }

    public get isCreating(): boolean {
        return this.router.url.split('/').filter(path => (path === 'new')).length > 0;
    }

    public get isEditing(): boolean {
        return this.router.url.split('/').filter(path => (path === 'edit')).length > 0;
    }

    public get isViewing(): boolean {
        return this.router.url.split('/').filter(path => (path === 'view')).length > 0;
    }

    public get isListing(): boolean {
        return this.router.url.split('/').filter(path => (path === 'new') || (path === 'edit') || (path === 'view')).length === 0;
    }

    public save(): void {

    }

    public goBack(showConfirmation: boolean = true): void {
        if (!this.isViewing || showConfirmation) {
            this.modalDialogService.show({
                title: 'Confirmação',
                message: APP_MESSAGES.GO_BACK_CONFIRMATION,
                // type: 'danger',
                showConfirm: true,
                showCancel: true,
                accept: () => this.router.navigate([this.getPath()]),
            });
        } else {
            this.router.navigate([this.getPath()]);
        }
    }

}
