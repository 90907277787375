import { AbstractModel } from "@gipi-pages/abstract/models/abstract.model";
import { TypePerson } from "@gipi-pages/clients/enums/type-person.enum";
import { ObjectUtil } from "@gipi-ui/utils/object.util";
import { ClientModel } from "../client.model";

export class ClientConsultDTO extends AbstractModel {

    blocked: boolean;

    cpfOrCnpj: string;

    fantasyName: string;

    hasFinancial: boolean;

    name: string;

    type: TypePerson;

    public static clientToDTO(client: ClientModel): ClientConsultDTO {
        if (ObjectUtil.isNull(client)) {
            return null;
        }

        const clientConsultDTO: ClientConsultDTO = new ClientConsultDTO();
        clientConsultDTO.blocked = client.blocked;
        clientConsultDTO.cpfOrCnpj = !ObjectUtil.isNull(client.person.legalPerson) ? client.person.legalPerson.cnpj : client.person.naturalPerson.cpf;
        clientConsultDTO.createdDate = client.createdDate;
        clientConsultDTO.enabled = client.enabled;
        clientConsultDTO.fantasyName = client.person.fantasyName;
        clientConsultDTO.hasFinancial = client.hasFinancial;
        clientConsultDTO.id = client.id;
        clientConsultDTO.modifiedDate = client.modifiedDate;
        clientConsultDTO.name = client.person.name;
        clientConsultDTO.selected = client.selected;
        clientConsultDTO.type = !ObjectUtil.isNull(client.person.legalPerson) ? 'LEGAL_PERSON' : 'NATURAL_PERSON';
        return clientConsultDTO;
    }

}
