import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { MatDatetimepickerToggle } from '@mat-datetimepicker/core';

let nextUniqueId = 0;

@Component({
    selector: 'gipi-datetime-picker',
    exportAs: 'gipiDatetimePicker',
    templateUrl: './datetime-picker.component.html',
    styleUrls: ['./datetime-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof DatetimePickerComponent => DatetimePickerComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-datetime-picker',
    }
})
export class DatetimePickerComponent implements OnInit, ControlValueAccessor {

    @ViewChild('dateTimePickerToggle', { static: true }) matDatetimepickerToggleRef: MatDatetimepickerToggle<Date>;

    private _name: string = `gipi-datetime-picker-${nextUniqueId++}`;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    @Input() label: string = '';

    @Input() placeholder: string = '';

    @Input('min') minDate: Date;

    @Input('max') maxDate: Date;

    @Input() help: string = '';

    private _required: boolean = false;
    @Input() get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    private _disabled: boolean = false;
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _value!: any;
    @Input()
    get value(): any {
        return this._value;
    }
    set value(value: any) {
        if (value instanceof Date) {
            this._value = value;
        } else {
            this._value = new Date(value);
        }

        this.onChange(this._value);
        this.onTouched(this._value);
    }

    onChange: Function = () => { };

    onTouched: Function = () => { };

    constructor(
        public elementRef: ElementRef<HTMLElement>,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void { }

    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }

    public toggleDatetimePicker(event: Event): void {
        if (!ObjectUtil.isNull(this.matDatetimepickerToggleRef)) {
            this.matDatetimepickerToggleRef._open(event);
        }
    }

}
