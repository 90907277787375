import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import jwt_decode from 'jwt-decode';

import { SidenavService } from '@gipi-components/global/sidenav/services/sidenav.service';
import { SnackbarService } from '@gipi-components/global/snackbar/services/snackbar.service';
import { SearchInformationService } from '@gipi-shared/services/search-information.service';
import { TypeAuthorities } from '@gipi-ui/enums/enum-authorities.enum';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { environment } from '../../../environments/environment';
import { AccessTokenModel } from '../../components/screens/login/models/access-token.model';
import { DecodedToken } from '../../components/screens/login/models/decoded-token.model';
import { HttpClientBase } from '../request/httpClientBase';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private _baseUrl: string = HttpClientBase.baseURLAuth;

    private _accessTokenSubject: BehaviorSubject<AccessTokenModel> = new BehaviorSubject(null);

    constructor(
        private _httpClient: HttpClient,
        private _router: Router,
        private _sidenavService: SidenavService,
        private _snackbarService: SnackbarService,
        private _searchInformationService: SearchInformationService
    ) {
        this._accessTokenSubject.next(JSON.parse(localStorage.getItem('access_token')));
    }

    protected buildHeaders(): HttpHeaders {
        return new HttpHeaders()
            .append('Authorization', 'Basic ' + btoa(`${environment.client}:${environment.secret}`))
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('X-Requested-With', 'XMLHttpRequest');
    }

    public get tokenValueObservable(): Observable<AccessTokenModel> {
        return this._accessTokenSubject.asObservable();
    }

    public get tokenValue(): AccessTokenModel {
        return this._accessTokenSubject.value;
    }

    public get tokenValueLocalStorage(): AccessTokenModel {
        const token: string = localStorage.getItem('access_token');
        return !StringUtil.isEmpty(token) ? JSON.parse(token) : null;
    }

    private _setCurrentUser(accessToken: AccessTokenModel): AccessTokenModel {
        if (accessToken && accessToken.access_token) {
            this._getInfoUserByAccessToken(accessToken);
            localStorage.setItem('access_token', JSON.stringify(accessToken));
            this._accessTokenSubject.next(accessToken);
        }
        return accessToken;
    }

    private _getInfoUserByAccessToken(accessToken: AccessTokenModel): void {
        const decodedToken: DecodedToken = this.decryptToken(accessToken);
        if (decodedToken) {
            accessToken.authorities = decodedToken.authorities;
        } else {
            this._snackbarService.showMessage('Ocorreu um erro ao validar as permissões de acesso do usuário', true);
        }
    }

    public login(username: string, password: string): Observable<AccessTokenModel> {
        this._accessTokenSubject.next(null);

        const body: HttpParams = new HttpParams()
            .set('username', username)
            .set('password', password)
            .set('grant_type', 'password');

        return this._httpClient.post<AccessTokenModel>(this._baseUrl, body, { headers: this.buildHeaders() }).pipe(
            map(accessToken => this._setCurrentUser(accessToken))
        );
    }

    public logout(): void {
        this.removeToken();
        this._sidenavService.removeConfigSessionStorage();
        this._searchInformationService.removeSearchInformation();
        this._router.navigate(['oauth/login']);
        this._accessTokenSubject.next(null);
    }

    public removeToken(): void {
        localStorage.removeItem('access_token');
    }

    public isValidToken(accessToken: AccessTokenModel, activatedRouteData?: Data): boolean {
        if (accessToken && accessToken.access_token) {
            const decodedToken: DecodedToken = this.decryptToken(accessToken);

            // Converte a hora e os minutos atuais em segundos
            const lDateNow: Date = new Date();
            const lHourNow: number = Number(lDateNow.getHours());
            const lMinuteNow: number = Number(lDateNow.getMinutes());
            const lSecondsNow: number = Number(lDateNow.getSeconds());
            const lCalcTimeNow: number = (((lHourNow * 3600) + (lMinuteNow * 60)) + lSecondsNow);

            // Converte a hora de início em segundos
            const lStartTime: string = decodedToken.startTime;
            const lHourStart: number = Number(lStartTime.substring(0, 2));
            const lMinuteStart: number = Number(lStartTime.substring(3, 5));
            const lCalcTimeStart: number = ((lHourStart * 3600) + (lMinuteStart * 60));

            // Converte a hora de término em segundos
            const lStopTime: string = decodedToken.stopTime;
            const lHourStop: number = Number(lStopTime.substring(0, 2));
            const lMinuteStop: number = Number(lStopTime.substring(3, 5));
            const lCalcTimeStop: number = ((lHourStop * 3600) + (lMinuteStop * 60));

            // Verifica se a hora atual é maior que a hora de início e se é menor que a hora de término
            if ((lCalcTimeNow > lCalcTimeStart) && (lCalcTimeNow < lCalcTimeStop)) {
                const lRolesUser: TypeAuthorities[] = decodedToken.authorities;
                const lUserIsClient: boolean = lRolesUser.filter(role => role === 'ROLE_CLIENT').length > 0;

                if ((lRolesUser && (lRolesUser.length > 0)) && (lUserIsClient)) {
                    return false;
                }

                if (activatedRouteData && activatedRouteData.roles && activatedRouteData.roles.length > 0) {
                    const lRolesActivatedRoute: TypeAuthorities[] = activatedRouteData.roles;
                    let lCanPass: boolean = false;

                    if ((lRolesActivatedRoute && (lRolesActivatedRoute.length > 0))) {
                        for (let i: number = 0; i < lRolesActivatedRoute.length; i++) {
                            lCanPass = lRolesUser.filter(role => role === lRolesActivatedRoute[i]).length > 0;
                            if (lCanPass) {
                                break;
                            }
                        }
                        return lCanPass;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }
        }
        return false;
    }

    public userIsClient(accessToken: AccessTokenModel): boolean {
        const decodedToken: DecodedToken = this.decryptToken(accessToken);
        if (decodedToken && decodedToken.authorities && decodedToken.authorities.length > 0) {
            return decodedToken.authorities.filter(role => role === 'ROLE_CLIENT').length > 0;
        }
        return false;
    }

    public decryptToken(accessToken: AccessTokenModel): DecodedToken {
        if (accessToken && accessToken.access_token) {
            return jwt_decode(accessToken.access_token);
        }
    }

    public getUserRolesByAuthorities(): TypeAuthorities {
        const currentUserIsSupport: boolean = this.tokenValue.authorities.filter(role => role === 'ROLE_SUPPORT').length > 0;
        const currentUserIsFinancial: boolean = this.tokenValue.authorities.filter(role => role === 'ROLE_FINANCIAL').length > 0;
        const currentUserIsDeveloper: boolean = this.tokenValue.authorities.filter(role => role === 'ROLE_DEVELOPERS').length > 0;
        const currentUserIsSeller: boolean = this.tokenValue.authorities.filter(role => role === 'ROLE_SELLER').length > 0;

        if (currentUserIsSupport) {
            return 'ROLE_SUPPORT';
        } else if (currentUserIsFinancial) {
            return 'ROLE_FINANCIAL';
        } else if (currentUserIsDeveloper) {
            return 'ROLE_DEVELOPERS';
        } else if (currentUserIsSeller) {
            return 'ROLE_SELLER';
        }

        return 'ROLE_CLIENT';
    }

}
