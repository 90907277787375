import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SnackbarService } from 'src/app/components/global/snackbar/services/snackbar.service';
import { ContingencyBlockedModel } from './models/contingency-blocked.model';
import { ContingencyBlockedService } from './services/contingency-blocked.service';

export interface ClientContingencyBlockedModel {
    cnpjOrCpf: string;
    nameClient: string;
    listContingencyBlocked: ContingencyBlockedModel[];
}

@Component({
    selector: 'gipi-dialog-contingency-blocked',
    templateUrl: './dialog-contingency-blocked.component.html',
    styleUrls: ['./dialog-contingency-blocked.component.scss']
})
export class DialogContingencyBlockedComponent implements OnInit {

    public nameClient: string = null;

    public cnpjOrCpf: string = null;

    public dateBlockingContingency: Date;

    public isLoading: boolean = false;

    public submitted: boolean = false;

    public minDate: Date = new Date();

    public maxDate: Date = new Date();

    constructor(
        public dialogRef: MatDialogRef<DialogContingencyBlockedComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ClientContingencyBlockedModel = { cnpjOrCpf: null, nameClient: null, listContingencyBlocked: [] },
        private _contingencyBlockedService: ContingencyBlockedService,
        private _snackbarService: SnackbarService
    ) {
        // Valida a data minima para o bloqueio de contingência
        this.validateMinDate();
        this.validateMaxAndMinDate();
        dialogRef.disableClose = true;
        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close(false);
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.data.nameClient && this.data.cnpjOrCpf && (this.data.listContingencyBlocked.length === 1)) {
                this.nameClient = this.data.nameClient;
                this.cnpjOrCpf = this.data.cnpjOrCpf;
            } else {
                this.nameClient = null;
                this.cnpjOrCpf = null;
            }
        });
    }

    private updateDateBlockingContingency(): Promise<boolean> {
        return new Promise(async resolve => {
            for (let lCount: number = 0; lCount < this.data.listContingencyBlocked.length; lCount++) {
                const lDay: number = this.dateBlockingContingency.getDate();
                const lMonth: number = this.dateBlockingContingency.getMonth() + 1;
                const lYear: number = this.dateBlockingContingency.getFullYear();
                this.data.listContingencyBlocked[lCount].dateBlockingContingency = lYear.toString() + '-' + (lMonth < 10 ? '0' + lMonth : lMonth).toString() + '-' + (lDay < 10 ? '0' + lDay : lDay).toString();
            }

            await this._contingencyBlockedService.updateDateBlockingContingency(this.data.listContingencyBlocked).then(result => {
                resolve(result);
            }, (error) => {
                resolve(false);
            });
        });
    }

    public async onClickSaveBlockingContingency(): Promise<void> {
        this.isLoading = true;
        this.submitted = true;
        if (this.dateBlockingContingency) {
            const resultUpdateDateBlockingContingency: boolean = await this.updateDateBlockingContingency();
            if (resultUpdateDateBlockingContingency) {
                this._snackbarService.showMessage('Liberação realizada com sucesso');
                this.submitted = false;
                this.isLoading = false;
                this.dialogRef.close(true);
            }

            this.submitted = false;
        } else {
            this._snackbarService.showMessage('Data de liberação não informada', true);
        }

        this.submitted = false;
        this.isLoading = false;
    }

    private validateMaxAndMinDate(): void {
        const lNow = new Date();
        this.minDate = lNow;

        const lDayMax: number = lNow.getDate() + 10;
        const lMonthMax: number = lNow.getMonth() + 1;
        const lYearMax: number = lNow.getFullYear();
        const lDateMax: string = lYearMax.toString() + '/' + (lMonthMax < 10 ? '0' + lMonthMax.toString() : lMonthMax.toString()) + '/' + lDayMax.toString();
        this.maxDate = new Date(lDateMax + ' 00:00:00');
    }

    public onClickGoBack(): void {
        this.dialogRef.close(false);
    }

    private validateMinDate(): void {
        const lNow = new Date();
        this.minDate = lNow;
    }

    public onClickSelectValueInput(event: any): void {
        event.target.select();
    }

}
