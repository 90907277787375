import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'gipi-button-goback',
    templateUrl: './button-goback.component.html',
    styleUrls: ['./button-goback.component.scss']
})
export class ButtonGobackComponent implements OnInit {

    @Input() inputDisabled: boolean = false;
    @Input() inputIcon: string = 'arrow_back';
    @Input() inputText: string = 'VOLTAR';
    @Input() inputAutoFocus: boolean = false;

    constructor() { }

    ngOnInit(): void { }
}
