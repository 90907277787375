<button mat-raised-button
        class="mat-raised-button-generic"
        [ngStyle]="{'cursor': inputSubmitted || disabled ? 'not-allowed' : 'pointer'}"
        [disabled]="inputSubmitted || disabled"
        onclick="this.blur()">
    <div class="inf-button-generic">
        <mat-spinner style="margin-right: 5px;"
                     [ngClass]="{'custom-spinner': inputSubmitted}"
                     [diameter]="22"
                     *ngIf="inputSubmitted">
        </mat-spinner>

        <mat-icon class="icon-button-generic"
                  [ngStyle]="{'color': inputSubmitted || disabled ? '#afafaf' : ''}"
                  role="img"
                  aria-hidden="true"
                  *ngIf="(inputIcon !== '')"
                  [svgIcon]="inputSvgIcon">
            {{ inputIcon }}
        </mat-icon>

        <mat-icon class="svg-button-generic"
                  [ngStyle]="{'fill': inputSubmitted || disabled ? '#afafaf' : ''}"                  
                  style="width: 20px; height: 20px; line-height: 20px; margin-right: 5px; fill: #fff;"
                  role="img"
                  aria-hidden="true"
                  *ngIf="(inputSvgIcon !== '')"
                  [svgIcon]="inputSvgIcon">
        </mat-icon>

        <span>
            {{ inputText }}
        </span>
    </div>
</button>
