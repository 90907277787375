<gipi-abstract id="dialogRegisterFunction"
               name="dialogRegisterFunction"
               [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterFunction">
        Cadastrar função
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxFlexFill>
            <gipi-input-text label="Código"
                             id="functionCode"
                             name="functionCode"
                             [required]="true"
                             [mask]="'0*'"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [(ngModel)]="entity.code"
                             [maxLength]="9"
                             fxFlex="33"
                             fxFlex.lt-md="100">
            </gipi-input-text>
            <gipi-input-text label="Identificação da função"
                             id="functionItemMenu"
                             name="functionItemMenu"
                             [required]="true"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [(ngModel)]="entity.itemMenu"
                             [maxLength]="10"
                             fxFlex="33"
                             fxFlex.lt-md="100">
            </gipi-input-text>
            <gipi-input-select-enum label="Menu"
                                    id="functionIsMenu"
                                    name="functionIsMenu"
                                    [disabled]="loading || (typeOperation === 'VIEW')"
                                    [required]="true"
                                    [valuesExclud]="[]"
                                    [enum]="functionIsMenuEnum"
                                    [(ngModel)]="functionIsMenuValue"
                                    fxFlex="33"
                                    fxFlex.lt-md="100">
            </gipi-input-select-enum>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-text label="Nome da função"
                             id="functionName"
                             name="functionName"
                             [required]="true"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [(ngModel)]="entity.name"
                             [min]="3"
                             [max]="100"
                             [maxLength]="100"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-input-text>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract>