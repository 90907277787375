export class PageModel<TEntity> {

    content: TEntity[];

    number: number;

    totalElements: number;

    totalPages: number;

    constructor() {
        this.content = [];
        this.number = 0;
    }

}
