<mat-checkbox [class.primary]="isCheckboxPrimary"
              [class.secondary]="isCheckboxSecondary"
              [attr.id]="id"
              [name]="name"
              [(ngModel)]="value"
              [required]="required"
              [disabled]="disabled"
              [disableRipple]="disableRipple"
              [labelPosition]="labelPosition"
              [(checked)]="checked"
              [(indeterminate)]="indeterminate"
              (change)="change.emit($event)"
              (indeterminateChange)="indeterminateChange.emit($event)">
    {{ label }}
</mat-checkbox>