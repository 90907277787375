import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TypeFilterClientEnum } from './../../enums/type-filter-client.enum';

import * as moment from 'moment';

import { AbstractListComponent } from '@gipi-pages/abstract/abstract-list.component';
import { PageModel } from '@gipi-pages/abstract/models/page.model';
import { SortModel } from '@gipi-pages/abstract/models/sort.model';
import { NotificationTypeFilterDTO } from '@gipi-pages/notification-type/models/dto/notification-type-filter.dto';
import { NotificationTypeModel } from '@gipi-pages/notification-type/models/notification-type.model';
import { NotificationTypeService } from '@gipi-pages/notification-type/services/notification-type.service';
import { NotificationStatusEnum } from '@gipi-pages/notification/enums/notification-status.enum';
import { TypeDate, TypeDateEnum } from '@gipi-pages/notification/enums/type-date.enum';
import { NotificationConsultDTO } from '@gipi-pages/notification/models/dto/notification-consult.dto';
import { NotificationFilterDTO } from '@gipi-pages/notification/models/dto/notification-filter.dto';
import { NotificationViewFilterDTO } from '@gipi-pages/notification/models/dto/notification-view-filter.dto';
import { NotificationViewModel } from '@gipi-pages/notification/models/notification-view.model';
import { NotificationViewService } from '@gipi-pages/notification/services/notification-view.service';
import { NotificationService } from '@gipi-pages/notification/services/notification.service';
import { DateRange } from '@gipi-ui/components/date-range-picker/date-range-picker.component';
import { LozengeComponent, LozengeType } from '@gipi-ui/components/lozenge/lozenge.component';
import { TablePaginatorEvent } from '@gipi-ui/components/mat-table/mat-table.component';
import { TableColumn } from '@gipi-ui/components/table/shared/table-column';
import { TableColumnBuilder } from '@gipi-ui/components/table/shared/table-column-builder';
import { APP_MESSAGES } from '@gipi-ui/global/messages';
import { AlertService } from '@gipi-ui/services/alert.service';
import { ModalDialogService } from '@gipi-ui/services/modal-dialog.service';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { DateUtil } from '@gipi-ui/utils/date.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';

type TableColumnChildren = 'createdDate' | 'cnpj' | 'clientName' | 'clientFantasyName';

@Component({
    selector: 'gipi-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent extends AbstractListComponent<NotificationConsultDTO, NotificationFilterDTO> implements OnInit {

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<LozengeComponent>;
    @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<HTMLElement>;

    public typeDateEnum: typeof TypeDateEnum = TypeDateEnum;
    public typeDateValue: TypeDate = 'CREATED_DATE';

    private _typeFilterClient: typeof TypeFilterClientEnum = TypeFilterClientEnum;

    public rangeDateFilter: DateRange = {
        start: new Date(moment().startOf('month').toDate()),
        end: new Date(moment().endOf('month').toDate())
    };

    public _optionsNotificationType: NotificationTypeModel[] = [];
    public notificationTypeFilter: NotificationTypeModel = null;

    public notificationStatusEnum: typeof NotificationStatusEnum = NotificationStatusEnum;

    public allStatusChecked: boolean = false;
    public indeterminateStatusChecked: boolean = true;
    public enabledStatusChecked: boolean = true;
    public disabledStatusChecked: boolean = false;
    public runningStatusChecked: boolean = true;
    public finishedStatusChecked: boolean = false;

    public rowHover: NotificationConsultDTO = null;

    constructor(
        protected service: NotificationService,
        private _router: Router,
        private _changeDetectorRef: ChangeDetectorRef,
        private _alertService: AlertService,
        private _modalDialogService: ModalDialogService,
        private _notificationTypeService: NotificationTypeService,
        private _notificationViewService: NotificationViewService,
    ) {
        super(service);
        this._getNotificationTypeList();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected getPath(): string {
        return `notification`;
    }

    protected _newFilter(): NotificationFilterDTO {
        const filter: NotificationFilterDTO = new NotificationFilterDTO();
        filter.researchField = '';
        filter.statusList = ['ENABLED', 'RUNNING'];
        filter.sorts = [new SortModel('createdDate', 'DESC')];
        return filter;
    }

    protected _createTableColumns(): TableColumn[] {
        return [
            TableColumnBuilder
                .instance()
                .property('typeDescription')
                .description('Tipo')
                .sliceLength(50)
                .value((obj: NotificationConsultDTO) => !StringUtil.isEmpty(obj.typeDescription) ? obj.typeDescription : '')
                .width(150)
                .build(),
            TableColumnBuilder
                .instance()
                .property('title')
                .description('Título')
                .sortable(true)
                .sliceLength(50)
                .value((obj: NotificationConsultDTO) => !StringUtil.isEmpty(obj.title) ? obj.title : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('description')
                .description('Descrição')
                .sortable(true)
                .sliceLength(50)
                .value((obj: NotificationConsultDTO) => !StringUtil.isEmpty(obj.description) ? obj.description : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('filterClientType')
                .description('Seleção')
                .value((obj: NotificationConsultDTO) => !StringUtil.isEmpty(obj.filterClientType) ? this._typeFilterClient[obj.filterClientType] : '')
                .width(110)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('startDate')
                .description('Data inicial')
                .sortable(true)
                .value((obj: NotificationConsultDTO) => !ObjectUtil.isNull(obj.stateDate) ? DateUtil.format(obj.stateDate, DateUtil.DATE_TIME_FORMAT) : '')
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('endDate')
                .description('Data final')
                .sortable(true)
                .value((obj: NotificationConsultDTO) => !ObjectUtil.isNull(obj.endDate) ? DateUtil.format(obj.endDate, DateUtil.DATE_TIME_FORMAT) : '')
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('status')
                .description('Status')
                .template(this.statusTemplate)
                .width(150)
                .align('center')
                .build(),
        ];
    }

    private _getNotificationTypeList(): void {
        const notificationTypeFilter: NotificationTypeFilterDTO = new NotificationTypeFilterDTO();
        notificationTypeFilter.enabled = true;
        notificationTypeFilter.pageNumber = 0;
        notificationTypeFilter.pageSize = 200;
        notificationTypeFilter.researchField = '';
        notificationTypeFilter.sorts = [new SortModel('description', 'ASC')];

        this._optionsNotificationType = [];
        this._notificationTypeService.findAll(notificationTypeFilter).toPromise().then(page => {
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                this._optionsNotificationType = [...page.content];
            }
        }).catch(error => {
            this._alertService.handleError(error);
        });
    }

    public setTypeStatus(entity: NotificationConsultDTO): LozengeType {
        switch (entity.status) {
            case 'ENABLED': return 'success';
            case 'DISABLED': return 'error';
            case 'RUNNING': return 'information';
            case 'FINISHED': return 'new';
            default:
                break;
        }
    }

    public naviagtionNotification(): void {
        this._router.navigateByUrl(`${this.getPath()}/new`);
    }

    public clear(): void {
        if (!ObjectUtil.isNull(this.filter)) {
            this.rangeDateFilter = {
                start: new Date(moment().startOf('month').toDate()),
                end: new Date(moment().endOf('month').toDate())
            };

            this.filter.begin = new Date(this.rangeDateFilter.start);
            this.filter.end = new Date(this.rangeDateFilter.end);

            this.filter.filterByTypeDate = 'CREATED_DATE';
            this.filter.researchField = '';
            this.notificationTypeFilter = null;
            this.filter.typeId = '';
        }

        this.findAll();
    }

    public findAll(pageEvent?: TablePaginatorEvent): void {
        try {
            this.loading = true;

            this.page = this._newPage();
            if (ObjectUtil.isNull(this.filter)) {
                this.filter = this._newFilter();
            }

            this.filter.begin = null;
            if (!ObjectUtil.isNull(this.rangeDateFilter) && !ObjectUtil.isNull(this.rangeDateFilter.start)) {
                this.filter.begin = new Date(this.rangeDateFilter.start);
            }

            this.filter.end = null;
            if (!ObjectUtil.isNull(this.rangeDateFilter) && !ObjectUtil.isNull(this.rangeDateFilter.end)) {
                this.filter.end = new Date(this.rangeDateFilter.end);
            }

            this.filter.typeId = '';
            if (!ObjectUtil.isNull(this.notificationTypeFilter)) {
                this.filter.typeId = this.notificationTypeFilter.id;
            }

            this._setStatusInFilter();

            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;

                if (!ObjectUtil.isNull(pageEvent.sort) && !StringUtil.isEmpty(pageEvent.sort.field)) {
                    this.filter.sorts = [new SortModel(pageEvent.sort.field, (pageEvent.sort.direction || 'DESC'))];
                }

            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 10;
            }

            this.service.findAll(this.filter).toPromise().then(page => {
                this.page = page;
                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        } finally {
            this._changeDetectorRef.detectChanges();
        }
    }

    public checkAllStatus(): void {
        this.enabledStatusChecked = this.allStatusChecked;
        this.disabledStatusChecked = this.allStatusChecked;
        this.runningStatusChecked = this.allStatusChecked;
        this.finishedStatusChecked = this.allStatusChecked;
        this.indeterminateStatusChecked = false;
    }

    public validateAllStatusSelected(): void {
        if (
            this.enabledStatusChecked &&
            this.disabledStatusChecked &&
            this.runningStatusChecked &&
            this.finishedStatusChecked
        ) {
            this.allStatusChecked = true;
            this.indeterminateStatusChecked = false;
        } else if (
            this.enabledStatusChecked ||
            this.disabledStatusChecked ||
            this.runningStatusChecked ||
            this.finishedStatusChecked
        ) {
            this.indeterminateStatusChecked = true;
            this.allStatusChecked = false;
        } else {
            this.indeterminateStatusChecked = false;
            this.allStatusChecked = false;
        }
    }

    private _setStatusInFilter(): void {
        this.filter.statusList = [];
        if (this.allStatusChecked) {
            this.filter.statusList = ['ENABLED', 'DISABLED', 'RUNNING', 'FINISHED'];
        } else {
            if (this.enabledStatusChecked) {
                this.filter.statusList.push('ENABLED');
            }
            if (this.disabledStatusChecked) {
                this.filter.statusList.push('DISABLED');
            }
            if (this.runningStatusChecked) {
                this.filter.statusList.push('RUNNING');
            }
            if (this.finishedStatusChecked) {
                this.filter.statusList.push('FINISHED');
            }
        }
    }

    public clone(entity: NotificationTypeModel): void {
        if (ObjectUtil.isNull(entity)) {
            this._alertService.addErrorMessage('O registro selecionado não foi identificado');
            return;
        }

        this._modalDialogService.show({
            title: 'Confirmação',
            message: `Deseja realmente clonar este registro?`,
            showConfirm: true,
            showCancel: true,
            accept: () => {
                this.loading = true;
                this._router.navigateByUrl(`${this.getPath()}/clone/${entity.id}`);
            },
        });
    }

    public enableOrDisable(entity: NotificationTypeModel): void {
        if (ObjectUtil.isNull(entity)) {
            this._alertService.addErrorMessage('O registro selecionado não foi identificado');
            return;
        }

        this._modalDialogService.show({
            title: 'Confirmação',
            message: `Deseja realmente ${(entity.enabled) ? 'inativar' : 'ativar'} este registro?`,
            showConfirm: true,
            showCancel: true,
            accept: () => {
                this.loading = true;
                const oppositeStatus: 'ENABLE' | 'DISABLE' = (entity.enabled) ? 'DISABLE' : 'ENABLE';

                this.service.enableOrDisable(entity.id, oppositeStatus).toPromise().then(_ => {
                    this.findAll();
                    this.loading = false;
                    this._alertService.addSuccessMessage(APP_MESSAGES.SUCCESS);
                }, error => {
                    this.clear();
                    this.loading = false;
                    this._alertService.handleError(error);
                });
            },
        });
    }

    public delete(entity: NotificationTypeModel): void {
        if (ObjectUtil.isNull(entity)) {
            this._alertService.addErrorMessage('O registro selecionado não foi identificado');
            return;
        }

        this._modalDialogService.show({
            title: 'Confirmação',
            message: APP_MESSAGES.DELETE_CONFIRMATION,
            showConfirm: true,
            showCancel: true,
            accept: () => {
                this.loading = true;
                this.page = this._newPage();

                this.service.delete(entity.id).subscribe(() => {
                    this.findAll();
                    this.loading = false;
                    this._alertService.addSuccessMessage(APP_MESSAGES.SUCCESS);
                }, error => {
                    this.clear();
                    this.loading = false;
                    this._alertService.handleError(error);
                });
            }
        });
    }

    protected _newPageChildren(): PageModel<NotificationViewModel> {
        return new PageModel<NotificationViewModel>();
    }

    protected _newFilterChildren(): NotificationViewFilterDTO {
        const filter: NotificationViewFilterDTO = new NotificationViewFilterDTO();
        filter.researchField = '';
        filter.sorts = [new SortModel('createdDate', 'DESC')];
        return new NotificationViewFilterDTO();
    }

    public findAllChildren(entity: NotificationConsultDTO, pageEvent?: TablePaginatorEvent): void {
        try {
            if (ObjectUtil.isNull(entity)) {
                return;
            }
            entity.loadingChildren = true;

            entity.page = this._newPageChildren();
            if (ObjectUtil.isNull(entity.filter)) {
                entity.filter = this._newFilterChildren();
            }

            entity.filter.notificationId = entity.id;

            if (pageEvent) {
                entity.filter.pageNumber = pageEvent.pageIndex;
                entity.filter.pageSize = pageEvent.pageSize;

                if (!ObjectUtil.isNull(pageEvent.sort) && !StringUtil.isEmpty(pageEvent.sort.field)) {
                    entity.filter.sorts = [new SortModel(pageEvent.sort.field, (pageEvent.sort.direction || 'DESC'))];
                }

            } else {
                entity.filter.pageNumber = 0;
                entity.filter.pageSize = 10;
            }

            this._notificationViewService.findAll(entity.filter).toPromise().then(page => {
                entity.page = page;
                entity.loadingChildren = false;
            }, error => {
                entity.loadingChildren = false;
                throw new Error(error);
            });
        } catch (e) {
            if (!ObjectUtil.isNull(entity)) {
                entity.loadingChildren = false;
            }
            this._alertService.handleError(e);
        } finally {
            this._changeDetectorRef.detectChanges();
        }
    }

    public getColumnValue(row: NotificationViewModel, column: TableColumnChildren): string {
        if (column === 'createdDate') {
            return !ObjectUtil.isNull(row.createdDate) ? DateUtil.format(row.createdDate, DateUtil.DATE_TIME_FORMAT) : '';
        } else if (column === 'cnpj') {
            return !StringUtil.isEmpty(row.cnpj) ? StringUtil.format(row.cnpj, (row.cnpj.length > 11) ? '00.000.000/0000-00' : '000.000.000-00') : '';
        } else if (column === 'clientName') {
            return !StringUtil.isEmpty(row.clientName) ? row.clientName : '';
        } else if (column === 'clientFantasyName') {
            return !StringUtil.isEmpty(row.clientFantasyName) ? row.clientFantasyName : '';
        } else {
            return '';
        }
    }

}

