import { environment } from 'src/environments/environment';

export class HttpClientBase {

    private static _Port: number = 8765;
    private static _Resource: string = 'localhost'; // 192.168.10.109

    static typeEnvironment: number = environment.production ? 1 : 0; // 0 = HOMOLOGATION / 1 = PRODUCTION
    static typeLicense: number = 0;   // 0 = SUMMARIZED / 1 = COMPLETE

    static baseURLAuth: string = '/ms-gipi-oauth/oauth/token';
    static baseURLUser: string = '/ms-gipi-user/api/gipi/';
    static baseURLApoio: string = '/ms-gipi-apoio/api/gipi/';
    static baseURLLicense: string = '/ms-gipi-license/api/gipi/';

    // static baseURLAuth: string = `http://${HttpClientBase._Resource}:${HttpClientBase._Port}/ms-gipi-oauth/oauth/token`;
    // static baseURLUser: string = `http://${HttpClientBase._Resource}:${HttpClientBase._Port}/ms-gipi-user/api/gipi/`;
    // static baseURLApoio: string = `http://${HttpClientBase._Resource}:${HttpClientBase._Port}/ms-gipi-apoio/api/gipi/`;
    // static baseURLLicense: string = `http://${HttpClientBase._Resource}:${HttpClientBase._Port}/ms-gipi-license/api/gipi/`;

}
