import { AbstractModel } from "@gipi-pages/abstract/models/abstract.model";
import { ClientModel } from "@gipi-pages/clients/models/client.model";
import { NotificationModel } from "./notification.model";

export class NotificationClientFilterClient extends AbstractModel {

    /** @Required */
    client: ClientModel;

    /** @Required */
    notification: NotificationModel;

}
