import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/components/global/snackbar/services/snackbar.service';
import { DialogConfirmComponent } from 'src/app/components/shared/dialog-confirm/dialog-confirm.component';
import { TYPE_BUTTONS, TYPE_DIALOG } from 'src/app/components/shared/dialog-confirm/models/dialog-confirm.model';
import { ArrayUtil } from 'src/app/ui/utils/array.util';

import { TerminalAppModel } from '../../models/terminal-app.model';
import { TerminalAppService } from './services/terminal-app.service';

export interface ClientInformationModel {
    apps: TerminalAppModel[];
    id: number;
    nameTerminal: string;
    nameClient: string;
    cnpjOrCpfClient: string;
    idClient: number;
}

@Component({
    selector: 'gipi-dialog-client-information',
    templateUrl: './dialog-client-information.component.html',
    styleUrls: ['./dialog-client-information.component.scss'],
})
export class DialogClientInformationComponent implements OnInit {

    public nameTerminal: string = '';

    public apps: TerminalAppModel[];

    public nameClient: string = '';

    public cnpjOrCpfClient: string = '';

    public idClient: number = -1;

    public terminalAppListDelete: TerminalAppModel[] = [];

    // @Output() outputRefreshOneClient: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        public dialogRef: MatDialogRef<DialogClientInformationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ClientInformationModel,
        private _terminalAppService: TerminalAppService,
        private _dialogConfirm: MatDialog,
        private _snackbarService: SnackbarService,
    ) { }

    ngOnInit(): void {
        this.apps = this.data.apps;
        this.nameTerminal = this.data.nameTerminal;
        this.nameClient = this.data.nameClient;
        this.cnpjOrCpfClient = this.data.cnpjOrCpfClient;
        this.idClient = this.data.idClient;

        this.dialogRef.disableClose = true;
        this.dialogRef.backdropClick().subscribe(() => {
            this.dialogRef.close(-1);
        });
    }

    public setTerminalAppSelected(terminalAppList: TerminalAppModel[]): void {
        this.terminalAppListDelete = terminalAppList;
    }

    public async onClickDeleteApp(): Promise<void> {
        if (ArrayUtil.isEmpty(this.terminalAppListDelete)) {
            this._snackbarService.showMessage('Selecione ao menos aplicativo para excluir', true);
            return;
        }

        const resultDialogConfirm: boolean = await this.openDialogConfirm();
        if (resultDialogConfirm) {
            // Aqui emito um evento passando o retorno da função de deletar la para a tela de consulta de clientes (TableClientsUpdate), para dar refresh nos terminais,
            // pois a consulta é realizada lá. Assim sendo, os terminais irão ser atualizados também atualizando os apps.
            const resultDelete: boolean = await this._terminalAppService.deleteAll(this.terminalAppListDelete);
            if (resultDelete) {
                // this.outputRefreshOneClient.emit(this.idClient);
                // EventEmitterService.get('EventRefreshUpdateClientsOne').emit(this.idClient);
                this.onClickGoBack(true, this.idClient);
            }
        }
    }

    public openDialogConfirm(): Promise<boolean> {
        return new Promise(resolve => {
            const dialogRef: MatDialogRef<DialogConfirmComponent> = this._dialogConfirm.open(DialogConfirmComponent, {
                width: '300px',
                data: { type: TYPE_DIALOG.CONFIRMATION, message: 'Deseja realmente excluir este registro?', buttons: TYPE_BUTTONS.SI_NO }
            });

            dialogRef.afterClosed().subscribe(result => {
                resolve(result);
            });
        });
    }

    public onClickGoBack(value: boolean = false, idClient: number = -1): void {
        if (value) {
            this.dialogRef.close(idClient);
        }
    }

}
