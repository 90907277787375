import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;

@Component({
    selector: `gipi-footer`,
    exportAs: 'gipiFooter',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof FooterComponent => FooterComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-footer',
    }
})
export class FooterComponent implements OnInit {

    private _name: string = `gipi-toolbar-${nextUniqueId++}`;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    constructor() { }

    ngOnInit(): void { }

}
