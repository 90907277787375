<div class="background">

    <div class="content">
        <div class="content-card">
            <mat-card class="mat-card"
                      style="flex: 1 1 0%; box-sizing: border-box;">
                <mat-card-content>

                    <div class="content">
                        <img src="assets/login/logo-login.png"
                             alt="logo"
                             width="180">
                    </div>
                    <div style="margin-bottom: 10px; margin-top: 30px;">
                        <p class="text-confirm-access">
                            Para confirmar o acesso, digite abaixo o seu nome e a senha que você irar utilizar para
                            acessar o sistema.
                        </p>
                    </div>

                    <div class="div-inputs">

                        <!--Input Username-->
                        <div style="margin-bottom: 0px !important;">
                            <div style="width: 100%;">
                                <mat-label class="mat-label">
                                    Nome <span style="color: #f44336!important;"> *</span>
                                </mat-label>
                                <mat-form-field floatLabel="always"
                                                appearance="outline"
                                                style="width: 100%;">
                                    <input matInput
                                           tabindex="0"
                                           type="text"
                                           autocomplete="off"
                                           required
                                           maxlength="60"
                                           autofocus
                                           [formControl]="inputTextName" />
                                    <mat-error
                                               *ngIf="inputTextName.errors && (inputTextName.dirty || inputTextName.touched)">
                                        O nome é obrigatório e não foi informado
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!--Input Password-->
                        <div style="margin-bottom: 0px !important;">
                            <div style="width: 100%;">
                                <mat-label class="mat-label">
                                    Senha <span style="color: #f44336!important;"> *</span>
                                </mat-label>
                                <mat-form-field floatLabel="always"
                                                appearance="outline"
                                                style="width: 100%;">
                                    <input matInput
                                           tabindex="0"
                                           [type]="hidePassword ? 'password' : 'text'"
                                           autocomplete="off"
                                           minlength="6"
                                           maxlength="16"
                                           [formControl]="inputTextPassword" />
                                    <button mat-icon-button
                                            matSuffix
                                            tabindex="-1"
                                            class="button-input"
                                            matTooltip="{{ hidePassword ? 'Mostrar senha' : 'Ocultar senha' }}"
                                            (click)="hidePassword = !hidePassword">
                                        <mat-icon style="width: 15px; height: 15px;">
                                            {{ hidePassword ? 'lock' : 'lock_open' }}
                                        </mat-icon>
                                    </button>
                                    <mat-error
                                               *ngIf="inputTextPassword.errors && (inputTextPassword.dirty || inputTextPassword.touched)">
                                        <p *ngIf="inputTextPassword.errors.required">
                                            A senha é obrigatória e não foi informada
                                        </p>
                                        <p *ngIf="!isValidCharacterPassword">
                                            Os caracteres %, +, -, * e / não são permitidos
                                        </p>
                                        <p *ngIf="inputTextPassword.value.length < 6">
                                            A senha precisa ter no mínimo 6 caracteres
                                        </p>
                                        <p *ngIf="inputTextPassword.value.length >= 16">
                                            A senha pode ter no máximo 16 caracteres
                                        </p>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!--Input Confirm Password-->
                        <div style="margin-bottom: 0px !important;">
                            <div style="width: 100%;">
                                <mat-label class="mat-label">
                                    Confirmar senha <span style="color: #f44336!important;"> *</span>
                                </mat-label>
                                <mat-form-field floatLabel="always"
                                                appearance="outline"
                                                style="width: 100%;">
                                    <input matInput
                                           tabindex="0"
                                           [type]="hideConfirmPassword ? 'password' : 'text'"
                                           autocomplete="off"
                                           required
                                           minlength="6"
                                           maxlength="16"
                                           [formControl]="inputTextConfirmPassword" />
                                    <button mat-icon-button
                                            matSuffix
                                            tabindex="-1"
                                            class="button-input"
                                            matTooltip="{{ hideConfirmPassword ? 'Mostrar senha' : 'Ocultar senha' }}"
                                            (click)="hideConfirmPassword = !hideConfirmPassword">
                                        <mat-icon style="width: 15px; height: 15px;">
                                            {{ hideConfirmPassword ? 'lock' : 'lock_open' }}
                                        </mat-icon>
                                    </button>
                                    <mat-error
                                               *ngIf="inputTextConfirmPassword.errors && (inputTextConfirmPassword.dirty || inputTextConfirmPassword.touched)">
                                        <p *ngIf="inputTextConfirmPassword.errors.required">
                                            A confirmação de senha é obrigatória e não foi informada
                                        </p>
                                        <p *ngIf="!isValidCharacterConfirmPassword">
                                            Os caracteres %, +, -, * e / não são permitidos
                                        </p>
                                        <p *ngIf="inputTextConfirmPassword.value.length < 6">
                                            A confirmação de senha precisa ter no mínimo 6 caracteres
                                        </p>
                                        <p *ngIf="inputTextConfirmPassword.value.length >= 16">
                                            A confirmação de senha pode ter no máximo 16 caracteres
                                        </p>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>

                    <div class="div-buttons">
                        <!--Button Confirmar-->
                        <button mat-raised-button
                                class="button-confirm button-text"
                                [disabled]="submitted"
                                (click)="onClickConfirmAccess()">
                            <div class="div-spinner-text">
                                <mat-spinner style="margin-right: 5px;"
                                             [ngClass]="{'custom-spinner': submitted}"
                                             [diameter]="22"
                                             *ngIf="submitted">
                                </mat-spinner>
                                <span>Confirmar</span>
                            </div>
                        </button>

                        <!--Button cancelar-->
                        <button mat-raised-button
                                class="button-cancel button-text"
                                style="margin-left: 15px;"
                                (click)="goBack()">
                            <span>Cancelar</span>
                        </button>
                    </div>
                </mat-card-content>

            </mat-card>
        </div>
    </div>
</div>