<gipi-abstract>
    <gipi-toolbar id="toolbarListNotification"
                  label="Consulta de notificações">
    </gipi-toolbar>
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="space-between center"
         fxLayoutGap="16px">

        <div fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="16px"
             fxFlex="50">
            <gipi-input-text placeholder="Pesquisar"
                             showSuffix="true"
                             [(ngModel)]="filter.researchField"
                             (keydown.enter)="findAll()"
                             fxFlex
                             fxFlex.lt-md="100">
                <gipi-button tooltip="Pesquisar"
                             gipi-icon
                             suffix
                             id="btnSearch"
                             icon="search"
                             [forInput]="true"
                             (click)="findAll()">
                </gipi-button>
            </gipi-input-text>
            <gipi-button tooltip="Filtrar"
                         gipi-primary-icon
                         #searchButton
                         id="btnOptionsSearch"
                         svgIcon="filter"
                         [notification]="filter.begin || filter.end || filter.typeId"
                         [popover]="true"
                         [disabled]="loading">
                <div class="notification-content-filter">
                    <div class="header">
                        Filtrar por data e tipo
                    </div>
                    <div class="content">
                        <fieldset class="filter-by">
                            <legend>Filtrar por data</legend>
                            <gipi-radio-group id="inputFilterByTypeDateRadioGroup"
                                              name="inputFilterByTypeDateRadioGroup"
                                              [align]="'row'"
                                              [enum]="typeDateEnum"
                                              [(ngModel)]="typeDateValue">
                            </gipi-radio-group>
                        </fieldset>

                        <gipi-date-range-picker label="Período"
                                                id="inputFilterPeriodDateRangePicker"
                                                name="inputFilterPeriodDateRangePicker"
                                                [(ngModel)]="rangeDateFilter"
                                                fxFlex
                                                fxFlex.lt-md="100">
                        </gipi-date-range-picker>

                        <gipi-input-select label="Tipo de notificação"
                                           id="inputFilterNotificationType"
                                           name="inputFilterNotificationType"
                                           [valuesExclud]="[]"
                                           [options]="_optionsNotificationType"
                                           [property]="'description'"
                                           [(ngModel)]="notificationTypeFilter"
                                           fxFlex
                                           fxFlex.lt-md="100">
                        </gipi-input-select>

                        <div style="margin-top: 1rem;"
                             fxLayout="row"
                             fxLayoutGap="12px"
                             fxFlex>
                            <gipi-button label="Pesquisar"
                                         gipi-primary
                                         id="btnFind"
                                         icon="search"
                                         [disabled]="loading"
                                         (click)="searchButton.closePopover(); findAll()"
                                         fxFlex="50">
                            </gipi-button>
                            <gipi-button label="Limpar"
                                         gipi-secondary
                                         id="btnClear"
                                         icon="clear"
                                         [disabled]="loading"
                                         (click)="searchButton.closePopover(); clear()"
                                         fxFlex="50">
                            </gipi-button>
                        </div>
                    </div>
                </div>
            </gipi-button>
        </div>

        <div fxFlex="30"
             fxFlex.lt-md="100"
             fxLayoutGap="16px">
            <gipi-button label="Novo"
                         gipi-primary
                         id="btnNewRegister"
                         icon="add"
                         [disabled]="false"
                         (click)="naviagtionNotification()"
                         fxFlex
                         fxFlex.lt-md="100">
            </gipi-button>
            <gipi-button label="Exibir"
                         gipi-tertiary
                         id="btnDisplay"
                         icon="expand_more"
                         #displayButton
                         [popover]="true"
                         fxFlex
                         fxFlex.lt-md="100">
                <div style="display: flex; flex-direction: column !important; min-width: 140px !important; gap: 16px;">
                    <gipi-checkbox label="Todos"
                                   gipi-primary
                                   [(ngModel)]="allStatusChecked"
                                   [(indeterminate)]="indeterminateStatusChecked"
                                   (change)="checkAllStatus()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <gipi-checkbox label="Ativo"
                                   gipi-secondary
                                   [(ngModel)]="enabledStatusChecked"
                                   (change)="validateAllStatusSelected()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <gipi-checkbox label="Inativo"
                                   gipi-secondary
                                   [(ngModel)]="disabledStatusChecked"
                                   (change)="validateAllStatusSelected()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <gipi-checkbox label="Em progresso"
                                   gipi-secondary
                                   [(ngModel)]="runningStatusChecked"
                                   (change)="validateAllStatusSelected()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <gipi-checkbox label="Finalizado"
                                   gipi-secondary
                                   [(ngModel)]="finishedStatusChecked"
                                   (change)="validateAllStatusSelected()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <div>
                        <gipi-button label="Aplicar"
                                     gipi-primary
                                     id="btnApplyFilter"
                                     [disabled]="loading"
                                     (click)="displayButton.closePopover(); findAll(null)"
                                     fxFlex>
                        </gipi-button>
                    </div>
                </div>
            </gipi-button>
        </div>
    </div>

    <gipi-mat-table [columns]="columns"
                    [rows]="page.content"
                    [sort]="true"
                    [paginator]="true"
                    [pageIndex]="page.number"
                    [pageLength]="page.totalElements"
                    [loading]="loading"
                    (pageChange)="findAll($event)"
                    (rowHover)="rowHover = $event"
                    (rowExpanded)="findAllChildren($event)">
        <ng-template #actions
                     let-row>
            <gipi-button tooltip="Clonar"
                         gipi-icon
                         id="btnClone"
                         svgIcon="clone"
                         [forInput]="true"
                         (click)="clone(row)">
            </gipi-button>
            <gipi-button tooltip="Ativar | Inativar"
                         gipi-icon
                         id="btnEnableOrDisable"
                         icon="lock_open"
                         *ngIf="row.status !== 'FINISHED'"
                         [forInput]="true"
                         (click)="enableOrDisable(row)">
            </gipi-button>
            <gipi-button tooltip="Excluir"
                         gipi-icon
                         id="btnEnableOrDisable"
                         svgIcon="delete"
                         *ngIf="row.status !== 'FINISHED'"
                         [forInput]="true"
                         (click)="delete(row)">
            </gipi-button>
        </ng-template>
        <ng-template #expandedRow
                     let-row>
            <div class="notification-view-list">
                <gipi-progress-bar *ngIf="row?.loadingChildren"> </gipi-progress-bar>

                <div class="content"
                     *ngIf="!row?.loadingChildren && (row?.page?.content?.length > 0)">
                    <div class="row-detail"
                         role="row"
                         *ngFor="let rowChildren of row?.page?.content">
                        <div class="column-detail created-date"
                             role="cell">
                            <span class="detail-title"> Data </span>
                            <span class="detail-text"> {{ getColumnValue(rowChildren, 'createdDate') }} </span>
                        </div>
                        <div class="column-detail cnpj"
                             role="cell">
                            <span class="detail-title"> CNPJ | CPF </span>
                            <span class="detail-text"> {{ getColumnValue(rowChildren, 'cnpj') }} </span>
                        </div>
                        <div class="column-detail client-name"
                             role="cell">
                            <span class="detail-title"> Razão social </span>
                            <span class="detail-text"> {{ getColumnValue(rowChildren, 'clientName') }} </span>
                        </div>
                        <div class="column-detail client-fantasy-name"
                             role="cell">
                            <span class="detail-title"> Nome fantasia </span>
                            <span class="detail-text"> {{ getColumnValue(rowChildren, 'clientFantasyName') }}
                            </span>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="!row?.loadingChildren && (row?.page?.content?.length <= 0)">
                    <div class="no-records">
                        <mat-icon>sentiment_very_dissatisfied</mat-icon>
                        <span>Nenhum registro foi encontrado</span>
                    </div>
                </ng-container>

                <div class="footer"
                     *ngIf="!row?.loadingChildren && (row?.page?.content?.length > 0)">
                    <mat-paginator [style.visibility]="(row?.page?.content?.length <= 0) ? 'hidden' : 'visible'"
                                   [disabled]="row?.loadingChildren"
                                   [pageIndex]="row?.page?.number"
                                   [length]="row?.page?.totalElements"
                                   [pageSize]="10"
                                   [showFirstLastButtons]="true"
                                   [hidePageSize]="true"
                                   [pageSizeOptions]="[]"
                                   (page)="findAllChildren(row, $event)">
                    </mat-paginator>
                </div>
            </div>
        </ng-template>
    </gipi-mat-table>
</gipi-abstract>

<ng-template #statusTemplate
             let-row>
    <gipi-lozenge [label]="notificationStatusEnum[row.status]"
                  [type]="setTypeStatus(row)">
    </gipi-lozenge>
</ng-template>