import { Injectable } from '@angular/core';

import { SearchInformationModel } from '../models/search-information.model';

@Injectable({ providedIn: 'root' })
export class SearchInformationService {

    constructor() { }

    public saveSearchInformation(object: SearchInformationModel): void {
        // Grava na sessionStorage, dessa forma se pressionar (-F5-) para recarregar a página ainda assim,
        // irá retornar como antes.
        sessionStorage.setItem('search_information', JSON.stringify(object));
    }

    public getSearchInformation(): SearchInformationModel {
        const lSearchInformationModel: SearchInformationModel = JSON.parse(sessionStorage.getItem('search_information'));
        if (lSearchInformationModel) {
            return lSearchInformationModel;
        }

        return null;
    }

    public removeSearchInformation(): void {
        sessionStorage.removeItem('search_information');
    }

    public hasToken(): boolean {
        return !!sessionStorage.getItem('search_information');
    }

}
