export enum EnumAuthorities {
    ROLE_REGISTER = 'ROLE_REGISTER',
    ROLE_DEVELOPERS = 'ROLE_DEVELOPERS',
    ROLE_FINANCIAL = 'ROLE_FINANCIAL',
    ROLE_SUPPORT = 'ROLE_SUPPORT',
    ROLE_SELLER = 'ROLE_SELLER',
    ROLE_CLIENT = 'ROLE_CLIENT'
}

export enum EnumDepartmentAuthorities {
    ROLE_REGISTER = 'Permissão para cadastrar',
    ROLE_DEVELOPERS = 'Desenvolvimento',
    ROLE_FINANCIAL = 'Financeiro',
    ROLE_SUPPORT = 'Suporte',
    ROLE_SELLER = 'Vendedor',
    ROLE_CLIENT = 'Cliente'
}

export type TypeAuthorities = 'ROLE_REGISTER' | 'ROLE_DEVELOPERS' | 'ROLE_FINANCIAL' | 'ROLE_SUPPORT' | 'ROLE_SELLER' | 'ROLE_CLIENT';
