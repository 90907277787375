import { EventEmitter, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EventEmitterService {

    constructor() { }

    private static emitters: {
        [nameEvent: string]: EventEmitter<any>
    } = {};

    static get(nameEvent: string): EventEmitter<any> {

        if (!this.emitters[nameEvent]) {
            this.emitters[nameEvent] = new EventEmitter<any>();
        }

        return this.emitters[nameEvent];

    }

}
