<mat-sidenav #sidenav
             [mode]="(isScreenLarge | async) ? 'side' : 'over'"
             class="sidenav"
             [class.large-sidenav]="(isExpanded | async)"
             [class.small-sidenav]="!(isExpanded | async)"
             [disableClose]="(isScreenLarge | async)"
             opened="true">

    <div [ngClass]="{'sidenav-content-menu': true,
                     'show-bottom': (showConfiguration || showSyncFinancial)}">

        <!-- Botão expandir e retrair o menu -->
        <button id="buttonToogleSidenav"
                name="toggleSidenav"
                class="toggle-sidenav"
                (click)="toggleSidenav()">
            <mat-icon [@indicatorExpanded]="(isExpanded | async) ? 'expanded': 'collapsed'">
                chevron_right
            </mat-icon>

            {{ ((labelToggle) && (isExpanded | async)) ? labelToggle : '' }}
        </button>

        <mat-nav-list class="sidenav-menu-list">
            <div *ngFor="let menu of menuList"
                 class="sidenav-action-menu">
                <a *ngIf="(menu.type === 'ITEM') && validateAccess(menu.roles)"
                   [routerLink]="menu?.route || ''"
                   [class.menu-active]="isMenuActive(menu)"
                   (click)="onMenuItemSelected(menu)">
                    <mat-icon *ngIf="menu.icon">
                        {{ menu.icon }}
                    </mat-icon>
                    <mat-icon *ngIf="menu.svgIcon"
                              [svgIcon]="menu.svgIcon">
                    </mat-icon>
                    <span *ngIf="(isExpanded | async)">
                        {{ menu.description }}
                    </span>
                </a>

                <a *ngIf="(menu.type === 'COLLAPSABLE') && validateAccess(menu.roles)"
                   [class.menu-active]="isMenuCollapsableActive(menu)"
                   (click)="onMenuCollapsableSelected(menu); onMenuItemSelected(menu, false)">
                    <mat-icon *ngIf="menu.icon">
                        {{ menu.icon }}
                    </mat-icon>
                    <mat-icon *ngIf="menu.svgIcon"
                              [svgIcon]="menu.svgIcon">
                    </mat-icon>
                    <span *ngIf="(isExpanded | async)">
                        {{ menu.description }}
                    </span>
                    <mat-icon *ngIf="(isExpanded | async)"
                              [@indicatorOpened]="(menu.expanded) ? 'opened': 'closed'">
                        chevron_right
                    </mat-icon>
                </a>

                <div [@indicatorMenuOpened]="((isExpanded | async) && (menu?.childrenList?.length > 0) && menu.expanded) ? 'opened': 'closed'"
                     class="sidenav-action-menu childrens"
                     *ngIf="validateAccess(menu.roles)">

                    <a *ngFor="let menuChildren of menu?.childrenList"
                       [routerLink]="menuChildren?.route || ''"
                       [class.menu-active]="isMenuChildrenActive(menuChildren)"
                       (click)="onMenuItemSelected(menuChildren)">
                        <mat-icon *ngIf="menuChildren.icon">
                            {{ menu.icon }}
                        </mat-icon>
                        <mat-icon *ngIf="menuChildren.svgIcon"
                                  [svgIcon]="menuChildren.svgIcon">
                        </mat-icon>
                        <span>
                            {{ menuChildren.description }}
                        </span>
                    </a>

                </div>
            </div>
        </mat-nav-list>
    </div>

    <div class="sidenav-action-menu sidenav-bottom-menu">
        <a *ngIf="showSyncFinancial"
           [class.disabled]="disabledSync"
           (click)="onClickMenuItemSync()">
            <mat-icon *ngIf="iconSyncFinancial"
                      [class.rotating]="disabledSync">
                {{ iconSyncFinancial }}
            </mat-icon>
            <mat-icon *ngIf="svgIconSyncFinancial"
                      [class.rotating]="disabledSync"
                      [svgIcon]="svgIconSyncFinancial">
            </mat-icon>
            <span *ngIf="(labelSyncFinancial) && (isExpanded | async)">
                {{ labelSyncFinancial }}
            </span>
        </a>

        <a *ngIf="showConfiguration"
           (click)="onMenuItemSelected(null); onClickMenuConfiguration.emit()">
            <mat-icon *ngIf="iconConfiguration">
                {{ iconConfiguration }}
            </mat-icon>
            <mat-icon *ngIf="svgIconConfiguration"
                      [svgIcon]="svgIconConfiguration">
            </mat-icon>
            <span *ngIf="(labelConfiguration) && (isExpanded | async)">
                {{ labelConfiguration }}
            </span>
        </a>
    </div>
</mat-sidenav>