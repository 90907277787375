import { AbstractModel } from "@gipi-pages/abstract/models/abstract.model";

export class DelayBlockingModel extends AbstractModel {

    clientName: string;

    cnpj: string;

    deadLine: Date;

    deleted: boolean;

    nameUser: string;

    reason: string;

}
