<div class="dialog-container">
    <h1 class="mat-dialog-title"
        mat-dialog-title>
        {{ getTitle() }}
    </h1>

    <mat-dialog-content class="mat-dialog-content">
        <p class="p-message">{{ data.message }}</p>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="data.buttons === 0"
                        style="justify-content: center;">
        <button class="mat-button"
                style="min-width: 40%;"
                mat-raised-button
                (click)="onClickResult(true)"
                cdkFocusInitial>
            <span> OK </span>
        </button>
    </mat-dialog-actions>

    <mat-dialog-actions *ngIf="data.buttons === 1"
                        style="justify-content: flex-end;">
        <button class="mat-button"
                style="min-width: 20%;"
                mat-raised-button
                (click)="onClickResult(true)"
                cdkFocusInitial>
            <span> Sim </span>
        </button>
        <button class="mat-stroked-button"
                style="min-width: 20%;"
                mat-stroked-button
                (click)="onClickResult(false)">
            <span> Não </span>
        </button>
    </mat-dialog-actions>
</div>