<div class="input-wrapper">

    <label *ngIf="label"
           class="input-label"
           [attr.for]="id">
        {{ label }}
        <span *ngIf="required"> * </span>
    </label>

    <div class="input-content">
        <input inputRef
               matTimepicker
               #timeRef="matTimepicker"
               #time="ngModel"
               class="input-element"
               okLabel="Ok"
               cancelLabel="Cancelar"
               [attr.id]="id"
               [attr.name]="name"
               [type]="'text'"
               [strict]="false"
               [mode]="mode"
               [minDate]="minDate"
               [maxDate]="maxDate"
               [placeholder]="placeholder"
               [disabled]="disabled"
               [required]="required"
               [readOnly]="true"
               [autofocus]="autofocus"
               [(ngModel)]="value"
               (keyup)="onKeyup.emit($event)"
               (blur)="onBlur.emit($event)">

        <div class="suffix"
             (click)="timeRef.showDialog()">
            <mat-icon> access_time </mat-icon>
        </div>
    </div>

    <small *ngIf="help"
           class="input-help">
        {{ help }}
    </small>
</div>