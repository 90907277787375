import { Component, OnInit } from '@angular/core';

import { GIPIUuid } from '@gipi-shared/types/uuid.type';
import { TablePaginatorEvent } from '@gipi-ui/components/mat-table/mat-table.component';
import { TableColumn } from '@gipi-ui/components/table/shared/table-column';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { UUIDUtil } from '@gipi-ui/utils/uuid.util';
import { AbstractFilterModel } from './models/abstract-filter.model';
import { AbstractModel } from './models/abstract.model';
import { PageModel } from './models/page.model';
import { SortModel } from './models/sort.model';
import { AbstractService } from './services/abstract.service';

@Component({ template: '' })
export abstract class AbstractListComponent<TEntity extends AbstractModel, TFilter extends AbstractFilterModel> implements OnInit {

    private _loading: boolean = false;
    public set loading(value: boolean) {
        this._loading = value;
    }
    public get loading(): boolean {
        return this._loading;
    }

    private _columns: TableColumn[] = [];
    public set columns(value: TableColumn[]) {
        this._columns = value;
    }
    public get columns(): TableColumn[] {
        return this._columns;
    }

    private _filter: TFilter = null;
    public set filter(value: TFilter) {
        this._filter = value;
    }
    public get filter(): TFilter {
        return this._filter;
    }

    private _page: PageModel<TEntity> = null;
    public set page(value: PageModel<TEntity>) {
        this._page = value;
    }
    public get page(): PageModel<TEntity> {
        return this._page;
    }

    constructor(protected abstractCrudService: AbstractService<TEntity, TFilter>) {
        this.page = this._newPage();
        this.filter = this._newFilter();
    }

    ngOnInit(): void {
        this.columns = this._createTableColumns();
    }

    protected abstract getPath(): string;

    protected abstract _newFilter(): TFilter;

    protected abstract _createTableColumns(): TableColumn[];

    protected _newPage(): PageModel<TEntity> {
        return new PageModel<TEntity>();
    }

    protected _createTableSorts(): SortModel[] {
        return [];
    }

    public findAll(pageEvent?: TablePaginatorEvent): void {
        try {
            this.loading = true;

            this.page = this._newPage();
            if (ObjectUtil.isNull(this.filter)) {
                this.filter = this._newFilter();
            }

            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;
                // this.filter.offset = pageEvent.pageIndex * pageEvent.pageSize;

                if (!ObjectUtil.isNull(pageEvent.sort) && !StringUtil.isEmpty(pageEvent.sort.field)) {
                    this.filter.sorts = [new SortModel(pageEvent.sort.field, (pageEvent.sort.direction || 'DESC'))];
                }

            } else {
                this.filter.pageNumber = 0;
                // this.filter.offset = 0;
                this.filter.pageSize = 10;
            }

            this.abstractCrudService.findAll<TFilter, TEntity>(this.filter).toPromise().then(page => {
                this.page = page;
                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            throw new Error(e);
        }
    }

    public clear(): void {
        this.page = this._newPage();
        this.filter = this._newFilter();
        this.findAll();
    }

    public handleEntityId(id: GIPIUuid): GIPIUuid {
        if (!UUIDUtil.isValid(id)) {
            throw new Error('O identificador do registro não é válido');
        }

        if (ObjectUtil.isNull(id) && !ObjectUtil.isNull(this.page) && !ArrayUtil.isEmpty(this.page.content)) {
            const firstSeletected = this.page.content.filter(c => c.selected)[0];
            if (ObjectUtil.isNull(firstSeletected)) {
                return null;
            }

            return firstSeletected.id;
        }

        return id;
    }

    public handleEntityIdList(idList: GIPIUuid[]): GIPIUuid[] {
        if (!ArrayUtil.isEmpty(idList)) {
            for (let i = 0; i < idList.length; i++) {
                if (!UUIDUtil.isValid(idList[i])) {
                    throw new Error('O identificador do registro não é válido');
                }
            }
        }

        if (ArrayUtil.isEmpty(idList) && !ObjectUtil.isNull(this.page) && !ArrayUtil.isEmpty(this.page.content)) {
            const listSelected = this.page.content.filter(p => p.selected).map(p => p.id);
            if (ArrayUtil.isEmpty(listSelected)) {
                return null;
            }

            return listSelected;
        }

        return idList;
    }

}
