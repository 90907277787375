<div class="content">
    <div>
        <h2 class="title">Sincronizar as Tabelas IBPTax / NCM</h2>
    </div>
    <span style="flex: 1 1 0%; box-sizing: border-box;"> </span>
    <div class="div-info">

        <!-- Drag And Drop -->
        <div class="container"
             appDragAndDrop
             (fileDropped)="onFileDropped($event)">
            <input type="file"
                   style="cursor: pointer; width: 100%;"
                   #fileDropRef
                   id="fileDropRef"
                   multiple
                   accept='.csv'
                   aria-label=""
                   aria-invalid="false"
                   aria-placeholder="false"
                   (change)="fileBrowseHandler($event)" />

            <mat-icon style="width: 70px; height: 70px; fill: #696969; margin-left: 5px;"
                      svgIcon="upload"
                      aria-hidden="false"
                      aria-label="Icone upload de arquivos"></mat-icon>

            <h3>Arraste e solte o arquivo aqui</h3>
            <h3>ou</h3>
            <label style="cursor: pointer;"
                   for="fileDropRef">Procure o arquivo</label>
        </div>

        <!-- List -->
        <div class="files-list">

            <!-- Title -->
            <div class="title-files">
                <h4 class="file-list-achirve">Arquivos</h4>

                <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;"
                     *ngIf="listFilesModel.length > 1">
                    <!--Button delete all ncm-->
                    <div style="margin-right: 15px; margin-left: 15px; width: 26px; height: 26px;">
                        <button mat-icon-button
                                class="icon-button-send-all"
                                (click)="onClickRemoveFile()">
                            <mat-icon style="width: 26px; height: 26px; margin-left: 0px; fill: #afafaf"
                                      svgIcon="remove-ncm-all"
                                      class="mat-icon-ripple mat-icon-hover"
                                      aria-hidden="false"
                                      matTooltip="Remover todos os arquivos da lista">
                            </mat-icon>
                        </button>
                    </div>

                    <!--Button send all ncm-->
                    <div style="margin-right: 15px; width: 26px; height: 26px;">
                        <button mat-icon-button
                                class="icon-button-send-all"
                                (click)="onClickSaveNcm()">
                            <mat-icon style="width: 26px; height: 26px; margin-left: 0px; fill: #afafaf"
                                      svgIcon="send-ncm-all"
                                      class="mat-icon-ripple mat-icon-hover"
                                      aria-hidden="false"
                                      matTooltip="Sincronizar todos os arquivos">
                            </mat-icon>
                        </button>
                    </div>

                    <div class="divider-buttons"></div>

                    <!--Button release client-->
                    <div style="margin-right: 20px; margin-left: 15px; width: 26px; height: 26px;">
                        <button mat-icon-button
                                class="icon-button-send-all"
                                (click)="onClickSaveNcmVersion()">
                            <mat-icon style="width: 26px; height: 26px; margin-left: 0px; fill: #afafaf"
                                      svgIcon="release-client-all"
                                      class="mat-icon-ripple mat-icon-hover"
                                      aria-hidden="false"
                                      matTooltip="Liberar todos os arquivos para os clientes">
                            </mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <mat-progress-bar mode="indeterminate"
                              *ngIf="isLoading"></mat-progress-bar>

            <!-- Archives -->
            <div style="padding: 20px; overflow: auto; max-height: 289px !important;"
                 *ngIf="listFilesModel.length > 0">

                <div class="single-file"
                     *ngFor="let file of listFilesModel; let lIndex = index">

                    <!-- Title -->
                    <div class="file-icon"
                         style="width: 55px; margin-right: 10px">
                        <mat-icon style="width: 50px; height: 50px; margin-left: 0px; fill: #696969"
                                  svgIcon="files"
                                  aria-hidden="false"
                                  aria-label="Icone upload de arquivos"></mat-icon>
                    </div>

                    <!-- Information -->
                    <div class="info">
                        <h4 class="name">
                            {{ 'N°: ' + (lIndex + 1) + ' - ' + file?.nameFile }}
                        </h4>
                        <p class="size">
                            {{ file?.sizeFile }}
                        </p>
                        <p class="{{ (file.synchronized ? 'synchronized' : 'not-synchronized') }}">
                            {{ (file.synchronized ? 'Arquivo sincronizado' : 'Arquivo ainda não sincronizado') }}
                        </p>
                    </div>

                    <!-- Actions -->
                    <div style="display: flex; flex-direction: row;">

                        <!-- Action Estado (UF) -->
                        <div style="margin-top: 15px; width: 240px;">
                            <mat-form-field appearance="outline"
                                            style="width: 100%">
                                <mat-select placeholder="Estado"
                                            floatlLabel="never"
                                            [(ngModel)]="file.idState"
                                            [disabled]="file.synchronized"
                                            disableOptionCentering>
                                    <mat-option>-- SELECIONE --</mat-option>
                                    <mat-option *ngFor="let state of listStatesModel"
                                                [value]="state?.id">
                                        {{ state?.name + ' (' + state?.acronym + ')' }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- Buttons -->
                        <div
                             style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">

                            <!--Button remove ncm-->
                            <button mat-icon-button
                                    matRipple
                                    [matRippleCentered]="true"
                                    class="button-ripple"
                                    style="margin-left: 10px;"
                                    [disabled]="file.synchronized"
                                    (click)="onClickRemoveFile(lIndex)">
                                <mat-icon style="width: 26px; height: 26px; margin-left: 0px; fill: #afafaf"
                                          [ngStyle]="{'fill': file.synchronized ? '#696969' : '#afafaf' }"
                                          svgIcon="remove-ncm"
                                          class="mat-icon-ripple mat-icon-hover"
                                          aria-hidden="false"
                                          matTooltip="Remover o arquivo da lista">
                                </mat-icon>
                            </button>

                            <!--Button send ncm-->
                            <button mat-icon-button
                                    matRipple
                                    [matRippleCentered]="true"
                                    class="button-ripple"
                                    style="margin-left: 10px; margin-right: 10px;"
                                    [disabled]="file.synchronized"
                                    (click)="onClickSaveNcm(lIndex)">
                                <mat-icon matTooltip="Sincronizar o arquivo"
                                          class="mat-icon-ripple"
                                          [ngStyle]="{'fill': file.synchronized ? '#696969' : '#afafaf' }"
                                          svgIcon="send">
                                </mat-icon>
                            </button>

                            <div class="divider-buttons"></div>

                            <!--Button release-client-->
                            <button mat-icon-button
                                    matRipple
                                    [matRippleCentered]="true"
                                    class="button-ripple"
                                    style="margin-left: 10px; margin-right: 10px;"
                                    [disabled]="file.releasedClient"
                                    (click)="onClickSaveNcmVersion(lIndex)">
                                <mat-icon style="width: 26px; height: 26px; margin-left: 0px; fill: #afafaf"
                                          [ngStyle]="{'fill': file.releasedClient ? '#696969' : '#afafaf' }"
                                          svgIcon="release-client"
                                          class="mat-icon-ripple mat-icon-hover"
                                          aria-hidden="false"
                                          matTooltip="Liberar o arquivo para os clientes">
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="no-records"
                 *ngIf="((!isLoading) && (listFilesModel.length <= 0))">
                <mat-icon>
                    sentiment_very_dissatisfied
                </mat-icon>
                <span class="">Nenhum arquivo foi selecionado</span>
            </div>

        </div>

    </div>

    <div class="div-button">
        <div style="width: 300px;">
            <!-- Button VOLTAR -->
            <gipi-button-goback [inputDisabled]="false"
                                [inputText]="'VOLTAR'"
                                [inputAutoFocus]="false"
                                (click)="goBack()">
            </gipi-button-goback>
        </div>
    </div>
</div>