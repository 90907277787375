import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFilterFn } from '@angular/material/datepicker';

import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { InputTextComponent } from '../input-text/input-text.component';

export interface DateRange {
    start: Date;
    end: Date;
}

export let nextUniqueId = 0;

@Component({
    selector: 'gipi-date-range-picker',
    exportAs: 'gipiDateRangePicker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof DateRangePickerComponent => DateRangePickerComponent),
            multi: true
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'pt-BR',
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['l', 'LL', 'DD/MM/YYYY'],
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
    host: {
        'class': 'gipi-datepicker',
    }
})
export class DateRangePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

    @ViewChild('startDate', { static: true }) inputStartDateRef!: InputTextComponent;
    @ViewChild('endDate', { static: true }) inputEndDateRef!: InputTextComponent;

    private _name: string = `gipi-date-range-${nextUniqueId++}`;

    public startDate: Date;
    public endDate: Date;

    @Input('idStart') idStartDate: string = `${this._name}-start`;
    @Input('idEnd') idEndDate: string = `${this._name}-end`;

    @Input('nameStart') nameStartDate: string = `${this._name}-start`;
    @Input('nameEnd') nameEndDate: string = `${this._name}-end`;

    @Input() label: string = '';

    @Input('placeholderStart') placeholderStartDate: string = 'Inicial';
    @Input('placeholderEnd') placeholderEndDate: string = 'Final';

    @Input() help: string = '';

    @Input() separator: string = '-';

    private _maxRange: number = 30;
    @Input() get maxRange(): number {
        return this._maxRange;
    }
    set maxRange(value: number) {
        this._maxRange = coerceNumberProperty(value);
    }

    private _minDate: Date = new Date('01/01/2000');
    @Input('min') get minDate(): Date {
        return this._minDate;
    }
    set minDate(value: Date) {
        this._minDate = value;
        this._minDate.setHours(0, 0, 0, 0);
    }

    private _maxDate: Date;
    @Input('max') get maxDate(): Date {
        return this._maxDate;
    }
    set maxDate(value: Date) {
        this._maxDate = value;
        this._maxDate.setHours(0, 0, 0, 0);
    }

    private _required: boolean = false;
    @Input() get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    private _disabled: boolean = false;
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _dateFilter: DateFilterFn<Date> = null;
    @Input() get dateFilter(): DateFilterFn<Date> {
        return this._dateFilter;
    }
    set dateFilter(value: DateFilterFn<Date>) {
        this._dateFilter = value;
    }

    private _value: DateRange = { start: null, end: null };
    @Input() get value(): DateRange {
        return this._value;
    }
    set value(value: DateRange) {
        if (ObjectUtil.isNull(this._value)) {
            this._value = { start: null, end: null };
        } else {
            if (value.start instanceof Date) {
                this._value.start = value.start;
            } else {
                this._value.start = new Date(value.start);
            }

            if (value.end instanceof Date) {
                this._value.end = value.end;
            } else {
                this._value.end = new Date(value.end);
            }

            if (!ObjectUtil.isNull(this._value.start)) {
                this._value.start.setHours(0);
                this._value.start.setMinutes(0);
                this._value.start.setSeconds(0);
                this._value.start.setMilliseconds(0);
            }

            if (!ObjectUtil.isNull(this._value.end)) {
                this._value.end.setHours(23);
                this._value.end.setMinutes(59);
                this._value.end.setSeconds(59);
                this._value.end.setMilliseconds(999);
            }
        }

        this.onChange(this._value);
        this.onTouched(this._value);
    }

    onChange: Function = () => { };

    onTouched: Function = () => { };

    public get formattedValue(): string {
        return '';
    }

    constructor(
        public elementRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    writeValue(value: DateRange): void {
        if (!ObjectUtil.isNull(value) && !ObjectUtil.isNull(value.start)) {
            this.startDate = new Date(value.start);
        }
        if (!ObjectUtil.isNull(value) && !ObjectUtil.isNull(value.end)) {
            this.endDate = new Date(value.end);
        }

        this._value = value;

        this._changeDetectorRef.detectChanges();
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }

    public setStartValue(value: Date): void {
        this.value = { start: new Date(value), end: null };
    }

    public setEndValue(value: Date): void {
        this.value = { start: new Date(this.startDate), end: new Date(value) };
    }

}
