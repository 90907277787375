import { formatNumber, formatPercent } from '@angular/common';


export class NumberUtil {

    public static isPositive(value: number): boolean {
        return !(value === undefined) || !(value === null) && (value > 0);
    }

    public static isNegative(value: number): boolean {
        return !(value === undefined) || !(value === null) && (value < 0);
    }

    public static format(value: number, digitsInfo?: string, type?: 'NUMBER' | 'PERCENT'): string {
        if (!(value === undefined) || !(value === null) && (!type || type === 'NUMBER')) {
            return formatNumber(value, 'pt', digitsInfo);
        } else if (!(value === undefined) || !(value === null) && type === 'PERCENT') {
            return formatPercent(value / 100, 'pt', digitsInfo);
        }
        return '';
    }

}
