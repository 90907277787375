import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import * as moment from 'moment';

import { AbstractListComponent } from '@gipi-pages/abstract/abstract-list.component';
import { SortModel } from '@gipi-pages/abstract/models/sort.model';
import { FilterTypeDateEnum } from '@gipi-pages/delay-blocking/enums/filter-type-date.enum';
import { DelayBlockingModel } from '@gipi-pages/delay-blocking/models/delay-blocking.model';
import { DelayBlockingFilterDTO } from '@gipi-pages/delay-blocking/models/dto/delay-blocking-filter.dto';
import { DelayBlockingService } from '@gipi-pages/delay-blocking/services/delay-blocking.service';
import { DateRange } from '@gipi-ui/components/date-range-picker/date-range-picker.component';
import { LozengeComponent, LozengeType } from '@gipi-ui/components/lozenge/lozenge.component';
import { TablePaginatorEvent } from '@gipi-ui/components/mat-table/mat-table.component';
import { TableColumn } from '@gipi-ui/components/table/shared/table-column';
import { TableColumnBuilder } from '@gipi-ui/components/table/shared/table-column-builder';
import { AlertService } from '@gipi-ui/services/alert.service';
import { DialogService } from '@gipi-ui/services/dialog.service';
import { DateUtil } from '@gipi-ui/utils/date.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';

@Component({
    selector: 'gipi-delay-blocking-list',
    templateUrl: './delay-blocking-list.component.html',
    styleUrls: ['./delay-blocking-list.component.scss']
})
export class DelayBlockingListComponent extends AbstractListComponent<DelayBlockingModel, DelayBlockingFilterDTO> implements OnInit, OnDestroy {

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<LozengeComponent>;

    private _subject: Subject<string> = new Subject();

    public filterTypeDateEnum: typeof FilterTypeDateEnum = FilterTypeDateEnum;

    public rangeDateFilter: DateRange = {
        start: new Date(moment().startOf('month').toDate()),
        end: new Date()
    };

    constructor(
        protected service: DelayBlockingService,
        private _dialogService: DialogService,
        private _alertService: AlertService,
    ) {
        super(service);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this._subject.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(_ => {
            this.findAll(null);
        });
    }

    ngOnDestroy(): void {
        this._subject.unsubscribe();
    }

    protected getPath(): string {
        return `consult-delay-blocking`;
    }

    protected _newFilter(): DelayBlockingFilterDTO {
        const filter: DelayBlockingFilterDTO = new DelayBlockingFilterDTO();
        filter.showDeleted = false;
        filter.startDate = moment(new Date()).startOf('month').toDate();
        filter.endDate = new Date();
        filter.searchDate = 'CREATED_DATE';
        filter.sorts = [new SortModel('createdDate', 'DESC')];
        filter.researchField = '';
        return filter;
    }

    protected _createTableColumns(): TableColumn[] {
        return [
            TableColumnBuilder
                .instance()
                .property('cpfOrCnpj')
                .description('CPF | CNPJ')
                .value((obj: DelayBlockingModel) => this._getDocumentPerson(obj))
                .width(180)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('name')
                .description('Razão social')
                .sliceLength(50)
                .sortable(true)
                .value((obj: DelayBlockingModel) => !StringUtil.isEmpty(obj.clientName) ? obj.clientName : '')
                .paddingInline(10)
                .build(),
            TableColumnBuilder
                .instance()
                .property('createdDate')
                .description('Cadastro')
                .sortable(true)
                .value((obj: DelayBlockingModel) => DateUtil.isValid(obj.createdDate) ? DateUtil.format(obj.createdDate, DateUtil.DATE_FORMAT) : '')
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('deadLine')
                .description('Vencimento')
                .sortable(true)
                .value((obj: DelayBlockingModel) => DateUtil.isValid(obj.deadLine) ? DateUtil.format(obj.deadLine, DateUtil.DATE_FORMAT) : '')
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('nameUser')
                .description('Usuário')
                .sliceLength(50)
                .value((obj: DelayBlockingModel) => !StringUtil.isEmpty(obj.nameUser) ? obj.nameUser : '')
                .width(150)
                .build(),
            TableColumnBuilder
                .instance()
                .property('reason')
                .description('Observação')
                .sliceLength(50)
                .value((obj: DelayBlockingModel) => !StringUtil.isEmpty(obj.reason) ? obj.reason : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('status')
                .description('Status')
                .template(this.statusTemplate)
                .width(150)
                .align('center')
                .build(),
        ];
    }

    private _getDocumentPerson(entity: DelayBlockingModel): string {
        if (!StringUtil.isEmpty(entity.cnpj)) {
            if (entity.cnpj.trim().length === 14) {
                return StringUtil.format(entity.cnpj, '00.000.000/0000-00');
            } else if (entity.cnpj.trim().length === 11) {
                return StringUtil.format(entity.cnpj, '000.000.000-00');
            }
        }

        return '';
    }

    public clear(): void {
        if (!ObjectUtil.isNull(this.filter)) {
            this.rangeDateFilter = {
                start: new Date(moment().startOf('month').toDate()),
                end: new Date()
            };

            this.filter.startDate = new Date(this.rangeDateFilter.start);
            this.filter.endDate = new Date(this.rangeDateFilter.end);

            this.filter.searchDate = 'CREATED_DATE';
            this.filter.researchField = '';
        }

        this.findAll();
    }

    public findAll(pageEvent?: TablePaginatorEvent): void {
        try {
            this.loading = true;

            this.page = this._newPage();
            if (ObjectUtil.isNull(this.filter)) {
                this.filter = this._newFilter();
            }

            this.filter.startDate = null;
            if (!ObjectUtil.isNull(this.rangeDateFilter) && !ObjectUtil.isNull(this.rangeDateFilter.start)) {
                this.filter.startDate = new Date(this.rangeDateFilter.start);
            }

            this.filter.endDate = null;
            if (!ObjectUtil.isNull(this.rangeDateFilter) && !ObjectUtil.isNull(this.rangeDateFilter.end)) {
                this.filter.endDate = new Date(this.rangeDateFilter.end);
            }

            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;

                if (!ObjectUtil.isNull(pageEvent.sort) && !StringUtil.isEmpty(pageEvent.sort.field)) {
                    this.filter.sorts = [new SortModel(pageEvent.sort.field, (pageEvent.sort.direction || 'DESC'))];
                }

            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 10;
            }

            this.service.search(this.filter).toPromise().then(page => {
                this.page = page;
                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });

        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        } finally {
            this.loading = false;
        }
    }

    public findAllByResearchField(): void {
        if (!this.loading) {
            // if (StringUtil.isEmpty(this.filter.researchField)) {
            //     this.findAll(null);
            // } else {
            this._subject.next(this.filter.researchField);
            // }
        }
    }

    public getLabelStatus(deleted: boolean, deadLine: Date): string {
        if (deleted) {
            return 'Excluído';
        } else {
            if (this.service.delayBlockingIsActive(deadLine)) {
                return 'Ativo';
            } else {
                return 'Inativo';
            }
        }
    }

    public getTypeStatus(deleted: boolean, deadLine: Date): LozengeType {
        if (deleted) {
            return 'error';
        } else {
            if (this.service.delayBlockingIsActive(deadLine)) {
                return 'success'
            } else {
                return 'warning';
            }
        }
    }

}
