import { AfterViewInit, ChangeDetectorRef, Directive, DoCheck, ElementRef, HostListener, Optional } from '@angular/core';
import { NgModel } from '@angular/forms';

import { ObjectUtil } from '@gipi-ui/utils/object.util';

@Directive({
    selector: '[inputRef]',
})
export class InputRefDirective implements DoCheck, AfterViewInit {

    public filled: boolean;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        public elementRef: ElementRef,
        @Optional() public ngModel: NgModel,
    ) { }

    ngDoCheck(): void {
        this.updateFilledState();
    }

    ngAfterViewInit(): void {
        this.updateFilledState();
        this._changeDetectorRef.detectChanges();
    }

    @HostListener('input', ['$event'])
    onInput() {
        this.updateFilledState();
    }

    public updateFilledState(): void {
        this.filled = (this.elementRef.nativeElement.value && this.elementRef.nativeElement.value.length) || (this.ngModel && this.ngModel.model);
    }

    public setFocus(): void {
        if (!ObjectUtil.isNull(this.elementRef)) {
            this.elementRef.nativeElement.focus({ preventScroll: false });
        }
    }

    public selectText(): void {
        if (!ObjectUtil.isNull(this.elementRef)) {
            this.elementRef.nativeElement.select();
        }
    }

}
