import { AbstractFilterModel } from '@gipi-pages/abstract/models/abstract-filter.model';
import { FilterTypeDate } from '@gipi-pages/delay-blocking/enums/filter-type-date.enum';

export class DelayBlockingFilterDTO extends AbstractFilterModel {

    endDate: Date;

    searchDate: FilterTypeDate;

    showDeleted: boolean;

    startDate: Date;

}
