<div class="dialog-container">

    <!--Title-->
    <div class="title">
        <mat-icon style="width: 36px; height: 36px; fill: #F3465C;"
                  svgIcon="release_contingency">
        </mat-icon>
        <p class="title-client"> LIBERAR NOTAS EM CONTINGÊNCIA </p>
    </div>

    <mat-divider></mat-divider>
    <mat-progress-bar mode="indeterminate"
                      *ngIf="isLoading">
    </mat-progress-bar>

    <!-- Client -->
    <div class="inf-client"
         *ngIf="nameClient">
        <!-- Name Client -->
        <div style="flex-direction: column; box-sizing: border-box; display: flex; width: 100%;">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                Nome Fantasia
            </mat-label>
            <mat-form-field floatLabel="always"
                            appearance="outline"
                            style="width: 100%;">
                <input matInput
                       type="text"
                       id="fantasy-name"
                       autocomplete="off"
                       readonly="true"
                       value="{{ nameClient }}"
                       (click)="onClickSelectValueInput($event)" />
            </mat-form-field>
        </div>
    </div>

    <div
         style="margin-top: 10px; display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
        <!--Input CNPJ / CPF do cliente-->
        <div style="flex-direction: column; box-sizing: border-box; display: flex; width: 100%; margin-right: 5px;"
             *ngIf="cnpjOrCpf">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                CNPJ / CPF
            </mat-label>
            <mat-form-field floatLabel="always"
                            appearance="outline"
                            style="width: 100%;">
                <input matInput
                       type="text"
                       id="cnpj-cpf"
                       autocomplete="off"
                       readonly="true"
                       value="{{ (cnpjOrCpf) | GIPICpfCnpj }}"
                       (click)="onClickSelectValueInput($event)" />
            </mat-form-field>
        </div>

        <!--Input Liberar até -->
        <div style="flex-direction: column; box-sizing: border-box; display: flex; width: 100%; margin-left: 5px;">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                Liberar até <span style="color: #f44336!important;"> *</span>
            </mat-label>
            <mat-form-field floatLabel="always"
                            appearance="outline">
                <input matInput
                       id="due-date"
                       [(ngModel)]="dateBlockingContingency"
                       autocomplete="off"
                       readonly="true"
                       [matDatepicker]="picker"
                       [min]="minDate"
                       [max]="maxDate"
                       (click)="onClickSelectValueInput($event)" />
                <mat-datepicker-toggle [autofocus]="false"
                                       onclick="this.blur()"
                                       matSuffix
                                       [for]="picker">
                </mat-datepicker-toggle>
                <mat-datepicker #picker> </mat-datepicker>
            </mat-form-field>
        </div>
    </div>

    <!--Button Salvar e goBack-->
    <div style="display: flex; justify-content: flex-end; align-items: center; margin-top: 35px;">
        <button mat-raised-button
                [autofocus]="false"
                onclick="this.blur()"
                class="mat-raised-button-save"
                style="margin-right: 15px;"
                (click)="onClickSaveBlockingContingency()"
                [disabled]="submitted">
            SALVAR
        </button>

        <button mat-raised-button
                [autofocus]="false"
                onclick="this.blur()"
                class="mat-raised-button-go-back"
                (click)="onClickGoBack()">
            <mat-icon class="mat-icon"
                      role="img"
                      aria-hidden="true">
                arrow_back
            </mat-icon>
            VOLTAR
        </button>
    </div>
</div>