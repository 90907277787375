import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@gipi-pages/user/services/user.service';

import { SnackbarService } from 'src/app/components/global/snackbar/services/snackbar.service';

@Component({
    selector: 'gipi-recover-password',
    templateUrl: './recover-password.component.html',
    styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

    public inputTextEmail: FormControl = new FormControl('', [Validators.email, Validators.required, Validators.maxLength(100)]);

    public hide: boolean = true;

    public submitted: boolean = false;

    public wasSent: boolean = false;

    public emailUser: string = '';

    constructor(
        private _usersService: UserService,
        private _router: Router,
        private _snackbarService: SnackbarService
    ) { }

    ngOnInit(): void { }

    private recovery(): Promise<boolean> {
        return new Promise(async resolve => {
            await this._usersService.recovery(String(this.inputTextEmail.value).toLowerCase()).toPromise().then((result) => {
                resolve(result);
            }, (error) => {
                resolve(false);
            });
        });
    }

    public async recoverPassword(): Promise<void> {
        this.submitted = true;
        const resultRecovery: boolean = await this.recovery();
        if (resultRecovery) {
            this._snackbarService.showMessage('E-mail enviado com sucesso');
            this.emailUser = String(this.inputTextEmail.value).toLowerCase();
            this.wasSent = true;
        }
        this.submitted = false;
    }

    public async resendEmail(): Promise<void> {
        this.submitted = true;
        const resultRecovery: boolean = await this.recovery();
        if (resultRecovery) {
            this._snackbarService.showMessage('E-mail reenviado com sucesso');
        }
        this.submitted = false;
    }

    public goBack(): void {
        this._router.navigate(['oauth/login']);
    }

}
