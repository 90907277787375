import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserModel } from '@gipi-pages/user/models/user.model';

import { StringUtil } from '@gipi-ui/utils/string.util';

let nextUniqueId = 0;

@Component({
    selector: `gipi-user-profile`,
    exportAs: 'gipiUserProfile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof UserProfileComponent => UserProfileComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-user-profile',
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {

    private _name: string = `gipi-user-profile-${nextUniqueId++}`;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    @Input() ariaLabel: string;

    @Input() username: string;

    @Input() userLogged: UserModel;

    @Output('onClick') click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    public get initialName(): string {
        if (StringUtil.isEmpty(this.username)) {
            return '';
        }

        return this.username.charAt(0);
    }

    constructor() { }

    ngOnInit(): void { }

}
