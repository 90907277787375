import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { FormDialogData } from '@gipi-pages/abstract/abstract-form-dialog.component';
import { AbstractListComponent } from '@gipi-pages/abstract/abstract-list.component';
import { AbstractFilterModel } from '@gipi-pages/abstract/models/abstract-filter.model';
import { SortModel } from '@gipi-pages/abstract/models/sort.model';
import { FunctionModel } from '@gipi-pages/function/models/function.model';
import { FunctionService } from '@gipi-pages/function/services/function.service';
import { LozengeComponent } from '@gipi-ui/components/lozenge/lozenge.component';
import { TablePaginatorEvent } from '@gipi-ui/components/mat-table/mat-table.component';
import { TableColumn } from '@gipi-ui/components/table/shared/table-column';
import { TableColumnBuilder } from '@gipi-ui/components/table/shared/table-column-builder';
import { TypeOperationCloseDialog } from '@gipi-ui/enums/type-operation-close-dialog.enum';
import { TypeOperationDialog } from '@gipi-ui/enums/type-operation-dialog.enum';
import { Library } from '@gipi-ui/global/library';
import { APP_MESSAGES } from '@gipi-ui/global/messages';
import { AlertService } from '@gipi-ui/services/alert.service';
import { DialogService } from '@gipi-ui/services/dialog.service';
import { ModalDialogService } from '@gipi-ui/services/modal-dialog.service';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { DateUtil } from '@gipi-ui/utils/date.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { FunctionFormDialogComponent } from '../function-form-dialog/function-form-dialog.component';

@Component({
    selector: 'gipi-function-list',
    templateUrl: './function-list.component.html',
    styleUrls: ['./function-list.component.scss']
})
export class FunctionListComponent extends AbstractListComponent<FunctionModel, AbstractFilterModel> implements OnInit, OnDestroy {

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<LozengeComponent>;

    private _subject: Subject<string> = new Subject<string>();

    public _dataSource: MatTableDataSource<FunctionModel> = null;

    public _entities: FunctionModel[] = [];

    public valueInput: string = '';

    constructor(
        protected service: FunctionService,
        private _router: Router,
        private _changeDetectorRef: ChangeDetectorRef,
        private _alertService: AlertService,
        private _modalDialogService: ModalDialogService,
        private _dialogService: DialogService,
    ) {
        super(service);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this._subject.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            if (StringUtil.isEmpty(value)) {
                this._dataSource.filter = '*';
            } else {
                this._dataSource.filter = value.trim().toLowerCase();
            }
        });

        this.findAll();
    }

    ngOnDestroy(): void {
        this._subject.unsubscribe();
    }

    protected getPath(): string {
        return `update-tables/function`;
    }

    protected _newFilter(): AbstractFilterModel {
        return new AbstractFilterModel();
    }

    protected _createTableColumns(): TableColumn[] {
        return [
            TableColumnBuilder
                .instance()
                .property('code')
                .description('Código')
                .sortable(true)
                .sliceLength(50)
                .value((obj: FunctionModel) => !ObjectUtil.isNull(obj.code) ? obj.code : '')
                .align('center')
                .width(100)
                .build(),
            TableColumnBuilder
                .instance()
                .property('createdDate')
                .description('Data do cadastro')
                .value((obj: FunctionModel) => !ObjectUtil.isNull(obj.createdDate) ? DateUtil.format(obj.createdDate, DateUtil.DATE_FORMAT) : '')
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('name')
                .description('Nome da função')
                .sortable(true)
                .sliceLength(100)
                .value((obj: FunctionModel) => !StringUtil.isEmpty(obj.name) ? obj.name : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('itemMenu')
                .description('Identificação do menu')
                .sortable(true)
                .sliceLength(50)
                .value((obj: FunctionModel) => !StringUtil.isEmpty(obj.itemMenu) ? obj.itemMenu : '')
                .width(200)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('isMenu')
                .description('Menu')
                .value((obj: FunctionModel) => obj.isMenu ? 'SIM' : 'NÃO')
                .width(100)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('status')
                .description('Status')
                .template(this.statusTemplate)
                .width(150)
                .align('center')
                .build(),
        ];
    }

    public refreshFindAll(): void {
        this._dataSource.data = [];
        this.findAll();
    }

    public findAll(pageEvent?: TablePaginatorEvent): void {
        try {
            this.loading = true;

            this.service.findByModifiedDate().toPromise().then(entityList => {
                this._entities = ArrayUtil.clone(entityList);
                this._dataSource = new MatTableDataSource(entityList);

                this._dataSource.filterPredicate = (data, filter) => this.filterEntities(data, filter);

                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            throw new Error(e);
        }

        this._changeDetectorRef.detectChanges();
    }

    public filterEntitiesByInput(calledButton: boolean = false): void {
        if (calledButton) {
            if (StringUtil.isEmpty(this.valueInput)) {
                this._dataSource.filter = '*';
            } else {
                this._dataSource.filter = this.valueInput.trim().toLowerCase();
            }
        } else {
            this._subject.next(this.valueInput);
        }
    }

    public filterEntities(data: FunctionModel, filter: string): boolean {
        try {
            this.loading = true;

            // Se o input estiver vazio, o filtro do datasource não é acionado; por outro lado, ao inserir um "*", o filtro é ativado conforme o esperado.
            if (filter === '*') {
                return true;
            } else {
                let lFilter: string = filter;
                lFilter = lFilter.replace(/[^0-9]/g, '');

                return (
                    data.code.toString().includes(filter) ||
                    data.name.toLowerCase().includes(filter) ||
                    data.itemMenu.toLowerCase().includes(filter)
                );
            }
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        } finally {
            this.loading = false;
        }
    }

    public sortData(pageEvent: TablePaginatorEvent): void {
        if (ObjectUtil.isNull(pageEvent)) {
            return;
        }

        const sort: SortModel = ObjectUtil.clone(pageEvent.sort);
        const dataSourceDataAux: FunctionModel[] = ArrayUtil.clone(this._dataSource.data);

        if (ObjectUtil.isNull(sort) || (!ObjectUtil.isNull(sort) && (StringUtil.isEmpty(sort.field) || StringUtil.isEmpty(sort.direction)))) {
            this._dataSource.data = ArrayUtil.clone(this._entities);
            return;
        }

        this._dataSource.data = dataSourceDataAux.sort((a, b) => {
            const isAsk: boolean = sort.direction === 'ASC';
            switch (sort.field) {
                case 'code': {
                    const fieldA = (!ObjectUtil.isNull(a.code) ? a.code : -1);
                    const fieldB = (!ObjectUtil.isNull(b.code) ? b.code : -1);
                    return Library.compareSort(fieldA, fieldB, isAsk);
                }
                case 'name': {
                    const fieldA = (!StringUtil.isEmpty(a.name) ? a.name : -1);
                    const fieldB = (!StringUtil.isEmpty(b.name) ? b.name : -1);
                    return Library.compareSort(fieldA, fieldB, isAsk);
                }
                case 'itemMenu': {
                    const fieldA = (!StringUtil.isEmpty(a.itemMenu) ? a.itemMenu : -1);
                    const fieldB = (!StringUtil.isEmpty(b.itemMenu) ? b.itemMenu : -1);
                    return Library.compareSort(fieldA, fieldB, isAsk);
                }
                case 'isMenu': {
                    const fieldA = (a.enabled ? 1 : -1);
                    const fieldB = (b.enabled ? 1 : -1);
                    return Library.compareSort(fieldA, fieldB, isAsk);
                }
                case 'createdDate': {
                    const fieldA = (DateUtil.isValid(a.createdDate) ? a.createdDate : null);
                    const fieldB = (DateUtil.isValid(b.createdDate) ? b.createdDate : null);
                    return Library.compareSortDate(fieldA, fieldB, isAsk);
                }
            }
        });
    }

    public async showDialogFunction(typeOperation: TypeOperationDialog, entity?: FunctionModel): Promise<void> {
        const functionFormData: FormDialogData<FunctionModel> = {
            typeOperation: typeOperation,
            entity: !ObjectUtil.isNull(entity) ? ObjectUtil.clone(entity) : null,
        };

        this._dialogService
            .open(FunctionFormDialogComponent, functionFormData, { width: 'auto', height: 'auto' })
            .afterClosed().toPromise().then((operation: TypeOperationCloseDialog) => {
                if (operation === 'RELOAD_TABLE') {
                    this.refreshFindAll();
                }
            });
    }

    public enableOrDisable(entity: FunctionModel): void {
        if (ObjectUtil.isNull(entity)) {
            this._alertService.addErrorMessage('O registro selecionado não foi identificado');
            return;
        }

        this._modalDialogService.show({
            title: 'Confirmação',
            message: `Deseja realmente ${(entity.enabled) ? 'inativar' : 'ativar'} este registro?`,
            showConfirm: true,
            showCancel: true,
            accept: () => {
                this.loading = true;
                entity.enabled = !entity.enabled;

                this.service.save(entity).toPromise().then(_ => {
                    this.refreshFindAll();
                    this.loading = false;
                    this._alertService.addSuccessMessage(APP_MESSAGES.SUCCESS);
                }, error => {
                    this.clear();
                    this.loading = false;
                    this._alertService.handleError(error);
                });
            },
        });
    }

}
