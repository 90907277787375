import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MdePopoverTarget, MdePopoverTrigger } from '@material-extended/mde';
import { Observable, of } from 'rxjs';

import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { InputTextComponent } from '../input-text/input-text.component';
import { FilterListbox, FilterListboxEnum } from './shared/filter-listbox.enum';
import { OptionsSelectedListboxModel } from './shared/options-selected-listbox.model';

let nextUniqueId = 0;

/** A altura dos itens selecionados em unidades `px`. */
const OPTION_HEIGHT_PX = 40;

@Component({
    selector: 'gipi-input-select-listbox',
    exportAs: 'gipiInputSelectListbox',
    templateUrl: './input-select-listbox.component.html',
    styleUrls: ['./input-select-listbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof InputSelectListboxComponent => InputSelectListboxComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-input-select-listbox'
    }
})
export class InputSelectListboxComponent<T> implements OnInit, OnDestroy, ControlValueAccessor {

    private _name: string = `gipi-input-select-listbox-${nextUniqueId++}`;

    _itemSizeScrollViewport: number = 6;

    public _disableInternal: boolean = true;

    public clientsSelecteds: string = '';

    public _filterListboxEnum: typeof FilterListboxEnum = FilterListboxEnum;

    @ViewChild(InputTextComponent, { static: true }) inputTextRef!: InputTextComponent;

    @ViewChild('popoverTrigger', { static: false }) popoverTrigger: MdePopoverTrigger;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    @Input() label: string = '';

    @Input() placeholder: string = 'Selecionar';

    @Input() help: string = '';

    private _required: boolean = false;
    @Input() get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    private _disabled: boolean = false;
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _lowerCase: boolean = false;
    @Input() get lowerCase(): boolean {
        return this._lowerCase;
    }
    set lowerCase(value: boolean) {
        this._lowerCase = coerceBooleanProperty(value);
    }

    private _upperCase: boolean = false;
    @Input() get upperCase(): boolean {
        return this._upperCase;
    }
    set upperCase(value: boolean) {
        this._upperCase = coerceBooleanProperty(value);
    }

    private _hideOnEscape: boolean = true;
    @Input() get hideOnEscape() {
        return this._hideOnEscape;
    }
    set hideOnEscape(value: any) {
        this._hideOnEscape = coerceBooleanProperty(value);
    }

    private _value: OptionsSelectedListboxModel<T> = new OptionsSelectedListboxModel<T>();
    @Input() get value(): OptionsSelectedListboxModel<T> {
        return this._value;
    }
    set value(value: OptionsSelectedListboxModel<T>) {
        if (ObjectUtil.isNull(value)) {
            value = new OptionsSelectedListboxModel<T>();
        }

        this._value = value;
        this.onChange(this._value);
        this.onTouched(this._value);
    }

    @Input() property: string;

    @Input() propertyFn: (obj: T) => string;

    @Input() onSearchFn: (optionsSelected: T[]) => Promise<T[]>;

    @Input() mdePopoverTargetAt: MdePopoverTarget;

    onChange: Function = () => { };

    onTouched: Function = () => { };

    @Output() onOpenPopover: EventEmitter<void> = new EventEmitter<void>();
    @Output() onClosePopover: EventEmitter<void> = new EventEmitter<void>();
    @Output('click') onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);
    @Output('focus') onFocus: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);
    @Output('blur') onBlur: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);

    get popoverContentWidth(): Observable<string> {
        if (ObjectUtil.isNull(this.inputTextRef)) {
            return of('auto');
        }
        const inputRef: HTMLElement = (this.inputTextRef.elementRef.nativeElement as HTMLElement);

        return of(`${inputRef.clientWidth}px`);
    }

    get scrollViewportHeight(): number {
        let height: number = 0;
        if (!ObjectUtil.isNull(this.value) && !ArrayUtil.isEmpty(this.value.options)) {
            height = (this.value.options.length * OPTION_HEIGHT_PX);
        }

        return height;
    }

    constructor(
        public elementRef: ElementRef<HTMLElement>,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void { }

    ngOnDestroy(): void {
        if (this.popoverTrigger) {
            this.popoverTrigger.destroyPopover();
        }
    }

    writeValue(value: any): void {
        if (ObjectUtil.isNull(value)) {
            value = new OptionsSelectedListboxModel<T>();
        }

        this.value = value;
        if (this._value.filter === 'ALL') {
            this.clientsSelecteds = 'Todos os clientes selecionados';
        } else if (this._value.filter === 'WEB_ONLY') {
            this.clientsSelecteds = 'Somente clientes migrados';
        } else if (this._value.filter === 'LEGACY_ONLY') {
            this.clientsSelecteds = 'Somente clientes não migrados'
        } else {
            this.clientsSelecteds = !ObjectUtil.isNull(this.value) && !ArrayUtil.isEmpty(this.value.options) ? 'Clientes selecionados' : '';
        }

        this.validateFilter(this._value.filter);
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }

    public showPopover(): void {
        if (this.disabled) {
            return;
        } else {
            this.popoverTrigger.openPopover();
        }
    }

    public closePopover(): void {
        if (this.disabled) {
            return;
        } else {
            this.popoverTrigger.closePopover();
        }
    }

    public handleKeydow(event: KeyboardEvent): void {
        if (this.disabled) {
            event.stopImmediatePropagation();
            return;
        }

        if (
            ((event.code.toUpperCase() === 'ESCAPE') && this.hideOnEscape) ||
            ((event.code.toUpperCase() === 'ENTER') || (event.code.toUpperCase() === 'NUMPADENTER')) ||
            (event.code.toUpperCase() === 'TAB')
        ) {
            if (!ObjectUtil.isNull(this.popoverTrigger) && this.popoverTrigger.popoverOpen) {
                this.closePopover();
                event.preventDefault();
            }
        }
    }

    public handleClick(event: MouseEvent): void {
        if (this.disabled) {
            return;
        } else {
            this.onClick.emit(event);
            event.stopPropagation();
        }
    }

    public handleFocus(event: MouseEvent): void {
        if (this.disabled) {
            return;
        } else {
            this.onFocus.emit(event);
            event.stopPropagation();
        }
    }

    public handleBlur(event: MouseEvent): void {
        if (this.disabled) {
            return;
        } else {
            this.onBlur.emit(event);
            this.onTouched();
            event.stopPropagation();
        }
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: UIEvent): void {
        if (this.popoverTrigger) {
            this.popoverTrigger.closePopover();
            event.stopPropagation();
        }
    }

    public getPropertyValue(option: T): string {
        if (!ObjectUtil.isNull(option)) {
            if (!ObjectUtil.isNull(this.propertyFn)) {
                return this.propertyFn(option);
            } else if (this.property) {
                return this._getValue(option, this.property);
            }
        }
        return '';
    }

    private _getValue(option: T, property: string): string {
        const properties: string[] = property.split('.');
        let value: any = option;

        for (let i: number = 0; i < properties.length; i++) {
            value = Reflect.get(value, properties[i]);
            if (value && i === properties.length - 1) {
                return value;
            }
        }
    }

    public async searchOptions(): Promise<void> {
        if (this._disableInternal) {
            return;
        }
        if (ObjectUtil.isNull(this.onSearchFn)) {
            console.error('A função onSearchFn é obrigatória e não foi informada');
            return;
        }

        const optionsAux: T[] = !ObjectUtil.isNull(this.value) ? this.value.options : [];
        this.value.options = await this.onSearchFn(optionsAux);
        this._setValue(this.value);
        this._changeDetectorRef.detectChanges();
    }

    public validateFilter(filter: FilterListbox): void {
        this._disableInternal = (filter === 'ALL') || (filter === 'WEB_ONLY') || (filter === 'LEGACY_ONLY');
        if (this._disableInternal) {
            this.value.options = [];
        }
        this._setValue(this.value);
        this._changeDetectorRef.detectChanges();
    }

    public removeOption(option: T): void {
        const optionsAux: T[] = this.value.options.filter(o => o !== option);
        this.value.options = ArrayUtil.clone(optionsAux);
        this._changeDetectorRef.detectChanges();
    }

    private _setValue(value: OptionsSelectedListboxModel<T>): void {
        this.value = value;
        if (this._value.filter === 'ALL') {
            this.clientsSelecteds = 'Todos os clientes selecionados';
        } else if (this._value.filter === 'WEB_ONLY') {
            this.clientsSelecteds = 'Somente clientes migrados';
        } else if (this._value.filter === 'LEGACY_ONLY') {
            this.clientsSelecteds = 'Somente clientes não migrados'
        } else {
            this.clientsSelecteds = !ObjectUtil.isNull(this.value) && !ArrayUtil.isEmpty(this.value.options) ? 'Clientes selecionados' : '';
        }
    }

}
