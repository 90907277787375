import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NcmVersionService } from 'src/app/components/screens/update-tables/components/update-tables-ncm/services/ncm-version.service';
import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { GenericService } from 'src/app/shared/services/generic.service';
import { NotificationModel } from '../models/notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationService extends GenericService<NotificationModel> {

    private _listNotificationModel: NotificationModel[] = []

    constructor(
        protected httpClient: HttpClient,
        private _ncmVersionService: NcmVersionService
    ) {
        super(httpClient);
        this._baseUrl = HttpClientBase.baseURLApoio;
        this._resource = 'ncm-version';
        this._url = `${this._baseUrl}${this._resource}`;
    }

    public getNotifications(): Promise<NotificationModel[]> {
        return new Promise(async (resolve) => {
            await this._ncmVersionService.findAllPaginator(-1, -1).then((result) => {
                if (result && result.content && result.content.length > 0) {
                    this._listNotificationModel = [];

                    for (let lCount: number = 0; lCount < result.content.length; lCount++) {
                        if (result.content[lCount].daysToEnd < 10) {
                            const lNotificationModel: NotificationModel = {
                                ncmVersion: { endDate: null, state: null, version: null },
                                markedAsRead: false,
                                numberOfNotifications: 0
                            };

                            lNotificationModel.ncmVersion = result.content[lCount];
                            this._listNotificationModel.push(lNotificationModel);
                        }
                    }

                    resolve(this._listNotificationModel);
                } else {
                    resolve(null);
                }
            });
        });
    }

}
