import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

let nextUniqueId = 0;

@Component({
    selector: 'gipi-checkbox, gipi-checkbox[gipi-primary], gipi-checkbox[gipi-secondary]',
    exportAs: 'gipiCheckbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof CheckboxComponent => CheckboxComponent),
            multi: true,
        }
    ],
    host: {
        'class': 'gipi-input-checkbox',
    },
})
export class CheckboxComponent {

    readonly isCheckboxPrimary: boolean = this._hasHostAttributes('gipi-primary');
    readonly isCheckboxSecondary: boolean = this._hasHostAttributes('gipi-secondary');

    private _name: string = `gipi-checkbox-${nextUniqueId++}`;

    @Input() id: number | string = this._name;

    @Input() name: string = this._name;

    @Input() label: string;

    @Input() disabled: boolean = false;

    @Input() disableRipple: boolean = false;

    @Input() labelPosition: 'before' | 'after' = 'after';

    @Input() required: boolean;

    private _checked: boolean = false;
    @Input() get checked(): boolean {
        return this._checked;
    }
    set checked(value: boolean) {
        this._checked = coerceBooleanProperty(value);
    }

    private _indeterminate: boolean = false;
    @Input() get indeterminate(): boolean {
        return this._indeterminate;
    }
    set indeterminate(value: boolean) {
        this._indeterminate = coerceBooleanProperty(value);
    }

    private _value: any;
    @Input() set value(val: any) {
        this._value = val;
        this.onChange(val);
        this.onTouch();
    }
    get value() {
        return this._value;
    }

    @Output() change: EventEmitter<MatCheckboxChange> = new EventEmitter<MatCheckboxChange>();
    @Output() indeterminateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        public elementRef: ElementRef,
    ) { }

    ngOnInit(): void { }

    onChange: any = () => { };

    onTouch: any = () => { };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: any): void {
        this._value = value;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
        this._changeDetectorRef.markForCheck();
    }

    getHostElement(): any {
        return this.elementRef.nativeElement;
    }

    private _hasHostAttributes(...attributes: string[]): boolean {
        return attributes.some(attribute => this.getHostElement().hasAttribute(attribute));
    }

}
