import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { GenericService } from 'src/app/shared/services/generic.service';
import { ContingencyBlockedModel } from '../models/contingency-blocked.model';

@Injectable({ providedIn: 'root' })
export class ContingencyBlockedService extends GenericService<ContingencyBlockedModel> {

    constructor(protected httpClient: HttpClient) {
        super(httpClient);
        this._baseUrl = HttpClientBase.baseURLApoio;
        this._resource = 'client';
        this._url = `${this._baseUrl}${this._resource}`;
    }

    public updateDateBlockingContingency(listContingencyBlockedModel: ContingencyBlockedModel[]): Promise<boolean> {
        return new Promise((resolve) => {
            this._updateDateBlockingContingency(listContingencyBlockedModel).subscribe((result) => {
                resolve(result);
            }, (error) => {
                resolve(false);
            });
        });
    }

    private _updateDateBlockingContingency(listContingencyBlockedModel: ContingencyBlockedModel[]): Observable<boolean> {
        let lUrl = `${this._url}/update/date-blocking-contingency`;
        return this._httpClient.patch<boolean>(lUrl, listContingencyBlockedModel).pipe(
            map(() => {
                return true;
            }),
            catchError(() => {
                return observableOf(false);
            })
        );
    }

}
