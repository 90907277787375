<div class="input-wrapper">

    <label *ngIf="label"
           class="input-label"
           [attr.for]="id">
        {{ label }}
        <span *ngIf="required"> * </span>
    </label>

    <div class="input-content">

        <input inputRef
               class="input-element"
               [attr.id]="id"
               [attr.name]="name"
               [placeholder]="placeholder"
               [min]="minDate"
               [max]="maxDate"
               [maxLength]="10"
               [disabled]="disabled"
               [required]="required"
               [readOnly]="readOnly"
               [autocomplete]="'off'"
               [autofocus]="false"
               [(ngModel)]="value"
               [value]="value"
               [matDatepicker]="datepicker"
               [matDatepickerFilter]="dateFilter"
               (keyup)="generatedDate($event.target.value, true)"
               (change)="generatedDate($event.target.value, false)"
               (blur)="validateMinAndMaxDate($event.target.value); dateChangeEmit()" />

        <div class="suffix">
            <mat-datepicker-toggle matSuffix
                                   [for]="datepicker">
            </mat-datepicker-toggle>
        </div>
    </div>

    <small *ngIf="help"
           class="input-help">
        {{ help }}
    </small>
</div>

<mat-datepicker #datepicker
                (closed)="dateChangeEmit()">
</mat-datepicker>