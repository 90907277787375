import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { environment } from '../environments/environment';

import { DialogUserInformationsComponent, UserInformations } from '@gipi-components/screens/dialog-user-informations/dialog-user-informations.component';
import { AccessTokenModel } from '@gipi-components/screens/login/models/access-token.model';
import { DecodedToken } from '@gipi-components/screens/login/models/decoded-token.model';
import { FormDialogData } from '@gipi-pages/abstract/abstract-form-dialog.component';
import { ConfigurationFormDialogComponent } from '@gipi-pages/configuration/components/configuration-form-dialog/configuration-form-dialog.component';
import { ConfigurationModel } from '@gipi-pages/configuration/models/configuration.model';
import { ConfigurationService } from '@gipi-pages/configuration/services/configuration.service';
import { UserModel } from '@gipi-pages/user/models/user.model';
import { UserService } from '@gipi-pages/user/services/user.service';
import { EventEmitterService } from '@gipi-shared/services/event-emitter.service';
import { TypeAuthorities } from '@gipi-ui/enums/enum-authorities.enum';
import { SidenavMenuModel } from '@gipi-ui/models/sidenav-menu.model';
import { AlertService } from '@gipi-ui/services/alert.service';
import { DialogService } from '@gipi-ui/services/dialog.service';
import { SyncronizeAppsService } from '@gipi-ui/services/syncronize-apps.service';
import { ValidateAccessService } from '@gipi-ui/services/validate-access.service';
import { AuthenticationService } from './core/authentication/authentication.service';
import { GipiIconsService } from './shared/services/gipi-icons.service';
import { ObjectUtil } from './ui/utils/object.util';

@Component({
    selector: 'gipi-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private _intervalNotification: NodeJS.Timeout = null;

    private _hasNotification: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _quantityNotification: BehaviorSubject<number> = new BehaviorSubject(0);
    public hasNotification: Observable<boolean> = this._hasNotification.asObservable();
    public quantityNotification: Observable<number> = this._quantityNotification.asObservable();

    private _decodedToken: DecodedToken;

    public userLogged: UserModel;

    public loading: boolean = false;

    public menuList: SidenavMenuModel[] = [];

    public isSyncronize: boolean = false;

    public swingNotification: boolean = false;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _gipiIconsService: GipiIconsService,
        private _alertService: AlertService,
        private _dialogService: DialogService,
        private _authenticationService: AuthenticationService,
        private _validateAccessService: ValidateAccessService,
        private _syncronizeAppsService: SyncronizeAppsService,
        private _usersService: UserService,
        private _configurationService: ConfigurationService,
    ) {
        this._registerSvgs();
        this._setMenuList();
    }

    ngOnInit(): void {
        this.loading = true;
        const token: AccessTokenModel = this._authenticationService.tokenValue;
        if (this._authenticationService.isValidToken(token, this._activatedRoute.data)) {
            this._getUserLogged();
            this._loadScriptHotjar();
        }
        this.loading = false;
    }

    ngOnDestroy(): void {
        this._hasNotification.unsubscribe();
        this._quantityNotification.unsubscribe();
    }

    public get isAuthenticated(): Observable<boolean> {
        const token: AccessTokenModel = this._authenticationService.tokenValue || this._authenticationService.tokenValueLocalStorage;
        if (!ObjectUtil.isNull(token)) {
            return of(this._authenticationService.isValidToken(token, this._activatedRoute.data));
        }
        return of(false);
    }

    public get firstName(): Observable<string> {
        if (ObjectUtil.isNull(this.userLogged)) {
            return of('');
        }
        return of(this.userLogged.name.split(' ')[0]);
    }

    private _registerSvgs(): void {
        this._gipiIconsService.registerIcons();
    }

    private async _getUserLogged(): Promise<void> {
        this._decodedToken = this._authenticationService.decryptToken(this._authenticationService.tokenValue);
        this.userLogged = await this._usersService.findByEmail(this._decodedToken.user_name).toPromise();
    }

    private _setMenuList(): void {
        this.menuList = [
            { description: 'Gerenciador de cliente', type: 'ITEM', svgIcon: 'update_clients', route: '/update-clients', roles: ['ROLE_DEVELOPERS', 'ROLE_FINANCIAL', 'ROLE_SUPPORT'] },
            { description: 'Backup', type: 'ITEM', svgIcon: 'consult_backup', route: '/consult-backup', roles: ['ROLE_DEVELOPERS', 'ROLE_SUPPORT'] },
            { description: 'Prorrogação', type: 'ITEM', svgIcon: 'delay_blocking', route: '/consult-delay-blocking', roles: ['ROLE_DEVELOPERS', 'ROLE_FINANCIAL'] },
            { description: 'Tipo de notificação', type: 'ITEM', svgIcon: 'notification_type', route: '/notification-type', roles: ['ROLE_DEVELOPERS', 'ROLE_FINANCIAL', 'ROLE_SUPPORT', 'ROLE_SELLER'] },
            { description: 'Notificação', type: 'ITEM', svgIcon: 'marketing_notify', route: '/notification', roles: ['ROLE_DEVELOPERS', 'ROLE_FINANCIAL', 'ROLE_SUPPORT', 'ROLE_SELLER'] },
            { description: 'Usuário', type: 'ITEM', svgIcon: 'users', route: '/users', roles: ['ROLE_REGISTER'] },
            {
                description: 'Atualizar tabela',
                type: 'COLLAPSABLE',
                svgIcon: 'update_tables',
                childrenList: [
                    { description: 'Função', type: 'ITEM', svgIcon: 'up_tb_functions', route: '/update-tables/function', },
                    { description: 'NCM', type: 'ITEM', svgIcon: 'up_tb_table_ibptax', route: '/update-tables/ncm', },
                    { description: 'Tipo de versão', type: 'ITEM', svgIcon: 'up_tb_type_version', route: '/update-tables/type-version', },
                    { description: 'Versão', type: 'ITEM', svgIcon: 'up_tb_version', route: '/update-tables/version', },
                ],
                roles: ['ROLE_DEVELOPERS'],
            }
        ]
    }

    private _loadScriptHotjar(): void {
        const hotjarId: number = environment.hotjarId;
        const isProduction: boolean = environment.production;
        if ((hotjarId > 0) && isProduction) {
            let scriptElement: HTMLScriptElement = document.createElement('script');
            scriptElement.textContent = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${hotjarId},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `;

            document.getElementsByTagName('head')[0].appendChild(scriptElement);
        }
    }

    public updateNotifications(): void {
        EventEmitterService.get('EventUpdateNotifications').emit(true);
    }

    public setQuantityNotification(quantityNotification: number): void {
        if (this._hasNotification.isStopped) {
            this._hasNotification = new BehaviorSubject(quantityNotification > 0);
        } else {
            this._hasNotification.next(quantityNotification > 0);
        }

        if (this._quantityNotification.isStopped) {
            this._quantityNotification = new BehaviorSubject(quantityNotification);
        } else {
            this._quantityNotification.next(quantityNotification);
        }

        if (this._intervalNotification === null) {
            this._intervalNotification = setInterval(() => {
                if (this.hasNotification) {
                    this.swingNotification = true;
                    setTimeout(() => this.swingNotification = false, 8000); // 8 segundos em milissegundos
                }
            }, 180000); // 3 minutos em milissegundos
        }
    }

    public onClickUserInformations(): void {
        const data: UserInformations = {
            userModel: this.userLogged
        };

        this._dialogService
            .open(DialogUserInformationsComponent, data, { width: '35%', height: 'auto' })
            .afterClosed().toPromise().then();
    }

    public logout(): void {
        this._intervalNotification = null;

        this._hasNotification.complete();
        this._quantityNotification.complete();

        this._authenticationService.logout();

        sessionStorage.clear();
        localStorage.clear();
    }

    public validateAccess(authorities: TypeAuthorities): boolean {
        return this._validateAccessService.validateAccess(authorities);
    }

    public async showDialogConfiguration(): Promise<void> {
        const entity: ConfigurationModel = await this._configurationService.findEnabled().toPromise();

        const configurationFormData: FormDialogData<ConfigurationModel> = {
            typeOperation: ObjectUtil.isNull(entity) ? 'NEW' : 'EDIT',
            entity: !ObjectUtil.isNull(entity) ? ObjectUtil.clone(entity) : null,
        };

        this._dialogService
            .open(ConfigurationFormDialogComponent, configurationFormData, { width: '50rem', height: 'auto' })
            .afterClosed().toPromise().then();
    }

    public syncronizeApps(): void {
        this.isSyncronize = true;

        this._syncronizeAppsService.syncronizeAll().subscribe(result => {
            this.isSyncronize = false;
        }, error => {
            this._alertService.addErrorMessage('Houve um erro durante a sincronização com o sistema financeiro');
            this.isSyncronize = false;
        });
    }

}
