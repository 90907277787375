/**
 * Enum utilizado para definir os titulos e icones do componente dialog-confirm
 */
export enum TYPE_DIALOG {
    CONFIRMATION = 0,
    INFORMATION = 1,
    QUESTION = 2,
}

/**
 * Enum utilizado para definir os botões do componente dialog-confirm
 */
export enum TYPE_BUTTONS {
    OK = 0,
    SI_NO = 1,
}

/**
 * Model utilizado pra transferir as informações para o componente dialog-confirm, informe os valores através do data do Dialog
 * Exemplo de uso em: https://angular.io/guide/inputs-outputs
 * @param {TYPE_BUTTONS} buttons  Botões do componente.
 * @param {string} message  Mensagem apresentada no componente.
 * @param {string} title          Título do componente.
 */
export interface DialogConfirm {
    type: TYPE_DIALOG,
    message: string,
    buttons: TYPE_BUTTONS
}