import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[gipiHide]'
})
export class HideDirective implements OnChanges {

  @Input() hide: boolean = true;

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) { }

  ngOnChanges() {
    if (this.hide) {
      this._renderer.setStyle(this._elementRef.nativeElement, 'display', 'none');
    } else {
      // this._renderer.setStyle(this._elementRef.nativeElement, 'display', '');
    }
  }

}
