import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IConfig, NgxMaskModule } from 'ngx-mask';

// Modules
import { AppMaterialModule } from './modules/app-material.module';
import { AppRoutingModule } from './modules/app-routing.module';
import { AppUiModule } from './ui/app-ui.module';

// Métodos http
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpClientBase } from 'src/app/core/request/httpClientBase';

// Interceptors
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';

// Pipes GIPI
import { CepPipe } from './shared/pipes/cep.pipe';
import { CnpjPipe } from './shared/pipes/cnpj.pipe';
import { CpfCnpjPipe } from './shared/pipes/cpf-cnpj.pipe';
import { CpfPipe } from './shared/pipes/cpf.pipe';
import { PhonePipe } from './shared/pipes/phone.pipe';

// Directives GIPI
import { DragAndDropDirective } from './components/screens/update-tables/components/update-tables-ncm/directives/drag-and-drop.directive';
import { AutoFocusDirective } from './shared/directives/auto-focus.directive';
import { HideDirective } from './shared/directives/hide.directive';

// Services GIPI
import { SnackbarService } from './components/global/snackbar/services/snackbar.service';
import { AuthenticationService } from './core/authentication/authentication.service';
import { EventEmitterService } from './shared/services/event-emitter.service';
import { ExcelService } from './shared/services/excel.service';
import { SearchInformationService } from './shared/services/search-information.service';

// Components GIPI
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import { NotificationComponent } from './components/global/notification-ncm/notification.component';
import { SimpleStatusCellComponent } from './components/global/simple-status-cell/simple-status-cell.component';
import { SnackbarComponent } from './components/global/snackbar/snackbar.component';
import { ClientAreaComponent } from './components/screens/client-area/client-area.component';
import { ConfirmAccessComponent } from './components/screens/confirm-access/confirm-access.component';
import { DialogUserInformationsComponent } from './components/screens/dialog-user-informations/dialog-user-informations.component';
import { LoginComponent } from './components/screens/login/login.component';
import { RecoverPasswordComponent } from './components/screens/recover-password/recover-password.component';
import { TableClientInformationComponent } from './components/screens/update-clients/components/dialog-client-information/components/table-client-information/table-client-information.component';
import { DialogClientInformationComponent } from './components/screens/update-clients/components/dialog-client-information/dialog-client-information.component';
import { DialogContingencyBlockedComponent } from './components/screens/update-clients/components/dialog-contingency-blocked/dialog-contingency-blocked.component';
import { RegisterDelayBlockingComponent } from './components/screens/update-clients/components/dialog-delay-blocking/components/register-delay-blocking/register-delay-blocking.component';
import { TableDelayBlockingComponent } from './components/screens/update-clients/components/dialog-delay-blocking/components/table-delay-blocking/table-delay-blocking.component';
import { DialogDelayBlockingComponent } from './components/screens/update-clients/components/dialog-delay-blocking/dialog-delay-blocking.component';
import { DialogLicenseGeneratorComponent } from './components/screens/update-clients/components/dialog-license-generator/dialog-license-generator.component';
import { ListComputerClientsComponent } from './components/screens/update-clients/components/list-computer-clients/list-computer-clients.component';
import { TableClientsComponent } from './components/screens/update-clients/components/table-clients/table-clients.component';
import { UpdateClientsComponent } from './components/screens/update-clients/update-clients.component';
import { UpdatePasswordComponent } from './components/screens/update-password/update-password.component';
import { RegisterFunctionComponent } from './components/screens/update-tables/components/update-tables-function/components/register-function/register-function.component';
import { TableFunctionComponent } from './components/screens/update-tables/components/update-tables-function/components/table-function/table-function.component';
import { UpdateTablesFunctionComponent } from './components/screens/update-tables/components/update-tables-function/update-tables-function.component';
import { DragAndDropComponent } from './components/screens/update-tables/components/update-tables-ncm/components/drag-and-drop/drag-and-drop.component';
import { ListNcmVersionComponent } from './components/screens/update-tables/components/update-tables-ncm/components/list-ncm-version/list-ncm-version.component';
import { UpdateTablesNcmComponent } from './components/screens/update-tables/components/update-tables-ncm/update-tables-ncm.component';
import { RegisterTypeVersionComponent } from './components/screens/update-tables/components/update-tables-version/components/dialog-type-version/components/register-type-version/register-type-version.component';
import { TableTypeVersionComponent } from './components/screens/update-tables/components/update-tables-version/components/dialog-type-version/components/table-type-version/table-type-version.component';
import { DialogTypeVersionComponent } from './components/screens/update-tables/components/update-tables-version/components/dialog-type-version/dialog-type-version.component';
import { RegisterVersionComponent } from './components/screens/update-tables/components/update-tables-version/components/register-version/register-version.component';
import { TableVersionComponent } from './components/screens/update-tables/components/update-tables-version/components/table-version/table-version.component';
import { UpdateTablesVersionComponent } from './components/screens/update-tables/components/update-tables-version/update-tables-version.component';
import { UpdateTablesComponent } from './components/screens/update-tables/update-tables.component';
import { ButtonGenericComponent } from './components/shared/button-generic/button-generic.component';
import { ButtonGobackComponent } from './components/shared/button-goback/button-goback.component';
import { ButtonSaveComponent } from './components/shared/button-save/button-save.component';
import { DialogConfirmComponent } from './components/shared/dialog-confirm/dialog-confirm.component';

import { AppPagesModule } from './pages/app-pages.module';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
registerLocaleData(localePt);

@NgModule({
    declarations: [
        CpfPipe,
        CnpjPipe,
        CpfCnpjPipe,
        CepPipe,
        PhonePipe,
        AutoFocusDirective,
        HideDirective,
        DragAndDropDirective,
        AppComponent,
        SnackbarComponent,
        UpdateClientsComponent,
        TableClientsComponent,
        ListComputerClientsComponent,
        DialogClientInformationComponent,
        SimpleStatusCellComponent,
        DialogConfirmComponent,
        LoginComponent,
        ConfirmAccessComponent,
        RecoverPasswordComponent,
        UpdatePasswordComponent,
        DialogLicenseGeneratorComponent,
        DialogDelayBlockingComponent,
        TableDelayBlockingComponent,
        RegisterDelayBlockingComponent,
        DialogUserInformationsComponent,
        TableClientInformationComponent,
        DialogContingencyBlockedComponent,
        UpdateTablesComponent,
        UpdateTablesFunctionComponent,
        UpdateTablesNcmComponent,
        UpdateTablesVersionComponent,
        TableVersionComponent,
        TableFunctionComponent,
        RegisterFunctionComponent,
        DragAndDropComponent,
        ListNcmVersionComponent,
        RegisterVersionComponent,
        DialogTypeVersionComponent,
        TableTypeVersionComponent,
        RegisterTypeVersionComponent,
        ButtonSaveComponent,
        ButtonGobackComponent,
        ButtonGenericComponent,
        NotificationComponent,
        ClientAreaComponent,
    ],
    imports: [
        AppMaterialModule,
        AppUiModule,
        AppPagesModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxMaskModule.forRoot(options),
        FlexLayoutModule,
    ],
    providers: [
        SnackbarService,
        EventEmitterService,
        HttpClientBase,
        AuthenticationService,
        SearchInformationService,
        ExcelService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
    ],
    exports: [
        AutoFocusDirective,
        HideDirective
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})

export class AppModule { }
