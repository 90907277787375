import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { GenericService } from 'src/app/shared/services/generic.service';
import { NcmVersionPagedModel } from '../models/ncm-version-paged.model';
import { NcmVersionModel } from '../models/ncm-version.model';

@Injectable({
  providedIn: 'root'
})
export class NcmVersionService extends GenericService<NcmVersionModel> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this._baseUrl = HttpClientBase.baseURLApoio;
    this._resource = 'ncm-version';
    this._url = `${this._baseUrl}${this._resource}`;
  }

  public save(object: NcmVersionModel): Promise<NcmVersionModel> {

    return new Promise((resolve) => {

      this.genericSave(object).subscribe((result) => {
        resolve(result);
      }, (error) => {
        resolve(null);
      });

    });

  }

  public saveAll(listObject: NcmVersionModel[]): Promise<NcmVersionModel[]> {

    return new Promise((resolve) => {

      this.genericSaveAll(listObject).subscribe((result) => {
        resolve(result);
      }, (error) => {
        resolve(null);
      });

    });

  }

  public findAllPaginator(pageNumber: number, pageSize: number): Promise<NcmVersionPagedModel> {

    return new Promise((resolve) => {

      this._findAllPaginator(pageNumber, pageSize).subscribe((result) => {
        resolve(result);
      }, (error) => {
        resolve(null);
      });

    });

  }

  private _findAllPaginator(pageNumber: number, pageSize: number): Observable<NcmVersionPagedModel> {
    let lResourcePageNumber: string = (pageNumber >= 0 ? `?page=${pageNumber}` : '');
    let lResourcePageSize: string = (pageSize >= 0 ? `&size=${pageSize}` : '');

    let lUrl = `${this._url}${lResourcePageNumber}${lResourcePageSize}`;
    return this._httpClient.get<NcmVersionPagedModel>(lUrl).pipe(
      map((result) => {
        if (result) {
          return result;
        }
      })
    );
  }

}




