import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputRefDirective } from '../input-text/shared/input-ref.directive';

let nextUniqueId = 0;

@Component({
    selector: 'gipi-timepicker',
    exportAs: 'gipiTimepicker',
    templateUrl: './timepicker.component.html',
    styleUrls: ['./timepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof TimepickerComponent => TimepickerComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-timepicker'
    }
})
export class TimepickerComponent implements OnInit, ControlValueAccessor {

    @ViewChild(InputRefDirective, { static: true }) inputRef!: InputRefDirective;

    private _name: string = `gipi-timepicker-${nextUniqueId++}`;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    @Input() label: string = '';

    @Input() placeholder: string = '';

    @Input() mask: string = '';

    @Input() minDate: Date;

    @Input() maxDate: Date;

    @Input() mode: '12h' | '24h' = '24h';

    @Input() help: string = '';

    private _autofocus: boolean = false;
    @Input() get autofocus(): boolean {
        return this._autofocus;
    }
    set autofocus(value: boolean) {
        this._autofocus = coerceBooleanProperty(value);
    }

    private _required: boolean = false;
    @Input() get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    private _disabled: boolean = false;
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _value!: any;
    @Input()
    get value(): any {
        return this._value;
    }
    set value(value: any) {
        this._value = value;

        this.onChange(this._value);
        this.onTouched(this._value);
    }

    onChange: Function = () => { };

    onTouched: Function = () => { };

    @Output('keyup') onKeyup: EventEmitter<MouseEvent> = new EventEmitter();
    @Output('blur') onBlur: EventEmitter<MouseEvent> = new EventEmitter();

    constructor(
        public elementRef: ElementRef<HTMLElement>,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void { }

    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }

}
