
export class ArrayUtil {

    public static isEmpty(value: Object[]): boolean {
        return value === undefined || value === null || value.length === 0;
    }

    public static clone(value: Object[]): any[] {
        if (value === undefined || value === null) {
            return new Array();
        }
        return [...value];
    }

}
