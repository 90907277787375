import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AbstractService } from '@gipi-pages/abstract/services/abstract.service';
import { NotificationViewFilterDTO } from '../models/dto/notification-view-filter.dto';
import { NotificationViewModel } from '../models/notification-view.model';

@Injectable({ providedIn: 'root' })
export class NotificationViewService extends AbstractService<NotificationViewModel, NotificationViewFilterDTO> {

    constructor(protected httpClient: HttpClient) {
        super('APOIO', 'notification-view', httpClient);
    }

}
