<div class="container-table">
    <mat-table [dataSource]="dataSource"
               matSort
               matSortDisableClear
               matSortActive="softwarePath"
               matSortDirection="asc"
               (matSortChange)="sortData($event)"
               multiTemplateDataRows
               on-mouseleave="hoverbutton=null">

        <!-- select Column -->
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox class="checkbox-all"
                              (change)="$event ? masterToggle() : null"
                              (change)="onChangeSelectedCount()"
                              [checked]="selection.hasValue() && (selection?.selected?.length === dataSource?.data?.length)"
                              [indeterminate]="selection.hasValue() && (selection?.selected?.length !== dataSource?.data?.length)">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-checkbox class="checkbox-row"
                              (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(element) : null"
                              (change)="onChangeSelectedCount()"
                              [checked]="selection.isSelected(element)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="name">
                Software
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.app.name }}
            </mat-cell>
        </ng-container>

        <!-- softwareVersion Column -->
        <ng-container matColumnDef="softwareVersion">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="softwareVersion">
                Versão do cliente
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.softwareVersion }}
            </mat-cell>
        </ng-container>

        <!-- currentVersion Column -->
        <ng-container matColumnDef="currentVersion">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="currentVersion">
                Versão do software
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.app.currentVersion }}
            </mat-cell>
        </ng-container>

        <!-- availableVersion Column -->
        <ng-container matColumnDef="availableVersion">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="availableVersion">
                Versão download
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.softwareVersionForUpdate }}
            </mat-cell>
        </ng-container>

        <!-- dateTimeLastAccess Column -->
        <ng-container matColumnDef="dateTimeLastAccess">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="dateTimeLastAccess">
                Último acesso
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.dateTimeLastAccess | date: "dd/MM/yyyy HH:mm:ss" }}
            </mat-cell>
        </ng-container>

        <!-- dateTimeSoftwareUpdate Column -->
        <ng-container matColumnDef="dateTimeSoftwareUpdate">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="dateTimeSoftwareUpdate">
                Data da atualização
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.dateTimeSoftwareUpdate | date: "dd/MM/yyyy HH:mm:ss" }}
            </mat-cell>
        </ng-container>

        <!-- softwarePath Column -->
        <ng-container matColumnDef="softwarePath">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="softwarePath">
                Diretório
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.softwarePath.length > 50 ? (element.softwarePath | slice:0:50) + '...' : element.softwarePath
                }}
            </mat-cell>
        </ng-container>

        <!-- updateStatus Column -->
        <ng-container matColumnDef="updateStatus">
            <mat-header-cell *matHeaderCellDef>
                Status
            </mat-header-cell>
            <mat-cell *matCellDef="let element"
                      class="update-status-cell mat-cell-font">
                <!-- {{ loadStatus(element.updateStatus) }}
                <gipi-simple-status-cell [inputSimpleStatusCell]="simpleStatusCell"
                                         style="width: 90%;">
                </gipi-simple-status-cell> -->

                <gipi-lozenge [label]="getLabelStatus(element.updateStatus)"
                              [type]="getTypeStatus(element.updateStatus)">
                </gipi-lozenge>
            </mat-cell>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div
                     style="display: flex; flex-direction: row; align-items: center; width: 100%; justify-content: center !important;">
                    <!--Button delete-->
                    <div matRipple
                         [matRippleCentered]="true"
                         class="button-ripple"
                         style="margin-left: 10px;"
                         (click)="onClickDeleteApp(element.id)">
                        <mat-icon *ngIf="element === hoverbutton"
                                  class="mat-icon-ripple"
                                  matTooltip="Deletar"
                                  style="width: 20px; height: 20px; fill: #afafaf;"
                                  svgIcon="trash">
                        </mat-icon>
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay sticky: true">
        </mat-header-row>
        <mat-row *matRowDef="let element; columns: columnsToDisplay;"
                 class="element-row"
                 (mouseover)="hoverbutton=element">
        </mat-row>
    </mat-table>
</div>
