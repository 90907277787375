import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'gipi-button-generic',
    templateUrl: './button-generic.component.html',
    styleUrls: ['./button-generic.component.scss']
})
export class ButtonGenericComponent implements OnInit {

    @Input() inputSubmitted: boolean = false;
    @Input() inputText: string = '';
    @Input() inputIcon: string = '';
    @Input() inputSvgIcon: string = '';
    @Input() inputAutoFocus: boolean = false;
    @Input() disabled: boolean = false;

    constructor() { }

    ngOnInit(): void { }

}
