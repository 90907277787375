import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { SidenavMenuModel } from '@gipi-ui/models/sidenav-menu.model';

@Injectable({ providedIn: 'root' })
export class SidenavMenuService implements OnDestroy {

    private _sidenavMenuOpened$: BehaviorSubject<boolean>;

    private _sidenavMenuList$: BehaviorSubject<SidenavMenuModel[]>;

    private _currentUrl$: BehaviorSubject<string>;

    constructor(private _router: Router) {
        this._sidenavMenuOpened$ = new BehaviorSubject(false);
        this._sidenavMenuList$ = new BehaviorSubject([]);
        this._currentUrl$ = new BehaviorSubject<string>('')


        this._router.events.subscribe((event: NavigationEnd) => {
            if (event instanceof NavigationEnd) {
                this._currentUrl$.next(event.url);
            }
        });
    }

    ngOnDestroy(): void {
        this._sidenavMenuOpened$.complete();
        this._sidenavMenuList$.complete();
        this._currentUrl$.complete();
    }

    public get sidenavMenuList(): SidenavMenuModel[] {
        return this._sidenavMenuList$.value;
    }
    public set sidenavMenuList(value: SidenavMenuModel[]) {
        this._sidenavMenuList$.next(value);
    }

    public get isExpanded(): Observable<boolean> {
        return this._sidenavMenuOpened$.asObservable();
    }

    public retract(): void {
        this._sidenavMenuOpened$.next(false);
        this._retractAllChildrens();
    }

    public expand(): void {
        this._sidenavMenuOpened$.next(true);
    }

    public toggle(): void {
        this._sidenavMenuOpened$.next(!this._sidenavMenuOpened$.value);
        this._retractAllChildrens();
    }

    private _retractAllChildrens(): void {
        const sidenavMenuList: SidenavMenuModel[] = this._sidenavMenuList$.value;
        sidenavMenuList.map(snm => snm.expanded = false);
        this._sidenavMenuList$.next(sidenavMenuList);
    }

}
