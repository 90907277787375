import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as moment from 'moment';

// import { DelayBlockingFilterDTO } from 'src/app/components/screens/consult-delay-blocking/models/delay-blocking-filter.dto';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { GenericService } from 'src/app/shared/services/generic.service';
import { ArrayUtil } from 'src/app/ui/utils/array.util';
import { DateUtil } from 'src/app/ui/utils/date.util';
import { DelayBlockingPagedModel } from '../models/delay-blocking-paged.model';
import { DelayBlockingModel } from '../models/delay-blocking.model';

@Injectable({ providedIn: 'root' })
export class DelayBlockingService extends GenericService<DelayBlockingModel> {

    constructor(
        protected httpClient: HttpClient,
        protected _authenticationService: AuthenticationService
    ) {
        super(httpClient);
        this._baseUrl = HttpClientBase.baseURLLicense;
        this._resource = 'delay-blocking';
        this._url = `${this._baseUrl}${this._resource}`;
    }

    // POST - Salva a prorrogação de licença do cliente
    public save(object: DelayBlockingModel): Promise<DelayBlockingModel> {
        return new Promise((resolve) => {
            this.genericSave(object).subscribe((result) => {
                resolve(result);
            }, (error) => {
                resolve(null);
            });
        });
    }

    public findByCnpjOrCpf(cnpjOrCpf: string, bringDeleted: boolean = false, pageNumber: number, pageSize: number): Promise<DelayBlockingPagedModel> {
        return new Promise((resolve) => {
            this._findByCnpjOrCpf(cnpjOrCpf, bringDeleted, pageNumber, pageSize).subscribe((result) => {
                resolve(result);
            }, (error) => {
                resolve(null);
            });
        });
    }

    private _findByCnpjOrCpf(cnpjOrCpf: string, bringDeleted: boolean = false, pageNumber: number, pageSize: number): Observable<DelayBlockingPagedModel> {
        const lResourcePageNumber: string = (pageNumber >= 0 ? `&page=${pageNumber}` : '');
        const lResourcePageSize: string = (pageSize ? `&size=${pageSize}` : '');
        const lUrl = `${this._url}/cnpj/${cnpjOrCpf}?bringDeleted=${bringDeleted}${lResourcePageNumber}${lResourcePageSize}`;
        return this._httpClient.get<DelayBlockingPagedModel>(lUrl).pipe(
            map((object) => object)
        );
    }

    public deteleById(id: number): Promise<boolean> {
        return new Promise((resolve) => {
            this.genericDeleteById(id).subscribe((result) => {
                resolve(result);
            }, (error) => {
                resolve(false);
            });
        });
    }

    public findByCreatedDateAndCnpj(createdDate: Date, cnpjOrCpf: string): Promise<DelayBlockingModel[]> {
        return new Promise(resolve => {
            this._findByCreatedDateAndCnpj(createdDate, cnpjOrCpf).subscribe((result) => {
                resolve(result);
            }, (error) => {
                resolve(null);
            });
        });
    }

    private _findByCreatedDateAndCnpj(createdDate: Date, cnpjOrCpf: string): Observable<DelayBlockingModel[]> {
        const lUrl = `${this._url}/find-by-created-date-greater-than-equal-and-cnpj/${DateUtil.format(createdDate, 'yyyy-MM-dd')}/${cnpjOrCpf}`;
        return this._httpClient.get<DelayBlockingModel[]>(lUrl).pipe(
            map(listObject => listObject)
        );
    }

    // public search(delayBlockingFilterDTO: DelayBlockingFilterDTO): Promise<DelayBlockingPagedModel> {
    //     return new Promise(resolve => {
    //         this._search(delayBlockingFilterDTO).subscribe((result) => {
    //             resolve(result);
    //         }, (error) => {
    //             resolve(null);
    //         });
    //     });
    // }

    // private _search(delayBlockingFilterDTO: DelayBlockingFilterDTO): Observable<DelayBlockingPagedModel> {
    //     const lUrl = `${this._url}/search`;
    //     return this._httpClient.post<DelayBlockingPagedModel>(lUrl, delayBlockingFilterDTO).pipe(
    //         map(delayBlockingPaged => delayBlockingPaged)
    //     );
    // }

    public async verifyIfPermittedDelayBlockingSupport(cnpjOrCpf: string): Promise<boolean> {
        const initialPeriod: Date = new Date();
        const startDate: Date = new Date();
        startDate.setDate(1);

        initialPeriod.setDate(startDate.getDate() - 10);

        const lDelayBlockingList: DelayBlockingModel[] = await this.findByCreatedDateAndCnpj(initialPeriod, cnpjOrCpf);
        if (ArrayUtil.isEmpty(lDelayBlockingList)) {
            return true;
        }

        // Se existe uma prorrogação que está ativa, bloqueia para que não seja criada uma nova prorrogação. Agora se existe uma prorrogação inativa e
        // o usuário for do suporte, verifica se a prorrogação é correspondente ao mês atual, caso seja ai bloqueia para não criar uma nova.
        const existDelayActive: boolean = lDelayBlockingList.filter(d => this.delayBlockingIsActive(d.deadLine)).length > 0;
        if (existDelayActive) {
            return false;
        } else {
            if (this._authenticationService.getUserRolesByAuthorities() === 'ROLE_SUPPORT') {
                const delayInactiveList: DelayBlockingModel[] = lDelayBlockingList.filter(d => !this.delayBlockingIsActive(d.deadLine));
                const existDelayInactiveInCurrentMonth: boolean = delayInactiveList.filter(d => {
                    const currentMonth: number = new Date().getMonth();
                    const delayMonth: number = new Date(d.createdDate).getMonth();

                    if (currentMonth === delayMonth) {
                        return d;
                    } else {
                        return null;
                    }
                }).length > 0;

                if (existDelayInactiveInCurrentMonth) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }
    }

    public async verifyIfExistDelayActive(cnpjOrCpf: string): Promise<boolean> {
        const initialPeriod: Date = new Date();
        const startDate: Date = new Date();
        startDate.setDate(1);

        initialPeriod.setDate(startDate.getDate() - 10);

        const lDelayBlockingList: DelayBlockingModel[] = await this.findByCreatedDateAndCnpj(initialPeriod, cnpjOrCpf);
        if (ArrayUtil.isEmpty(lDelayBlockingList)) {
            return true;
        }

        // Se existe uma prorrogação que está ativa, bloqueia para que não seja criada uma nova prorrogação. Agora se existe uma prorrogação inativa e
        // o usuário for do suporte, verifica se a prorrogação é correspondente ao mês atual, caso seja ai bloqueia para não criar uma nova.
        const existDelayActive: boolean = lDelayBlockingList.filter(d => this.delayBlockingIsActive(d.deadLine)).length > 0;
        if (existDelayActive) {
            return false;
        }

        return true;
    }

    public delayBlockingIsActive(date: Date): boolean {
        const lDateDeadLine: string = moment(date).format('YYYY/MM/DD');
        const lDateNow: string = moment(new Date()).format('YYYY/MM/DD');

        let dateDeadline: moment.Moment = moment(lDateDeadLine, 'YYYY/MM/DD');
        let dateNow: moment.Moment = moment(lDateNow, 'YYYY/MM/DD');

        if (dateNow.isAfter(dateDeadline)) {
            return false;
        }

        return true;
    }

}
