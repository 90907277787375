export class BrowserUtil {

    public static getBrowser(): 'MSIE' | 'Firefox' | 'Safari' | 'Chrome' | 'Opera' | string {
        let browsers: string[] = ['MSIE', 'Firefox', 'Safari', 'Chrome', 'Opera'];
        let userAgent: string = navigator.userAgent;
        let indexBrowser: number = browsers.length - 1;

        for (indexBrowser; indexBrowser > -1 && userAgent.indexOf(browsers[indexBrowser]) === -1; indexBrowser--);

        return browsers[indexBrowser];
    }

}
