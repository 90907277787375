import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { MdePopoverTarget, MdePopoverTrigger } from '@material-extended/mde';
import { Observable, of } from 'rxjs';

type ButtonIconPosition = 'left' | 'right' | 'top' | 'bottom' | 'center';

@Component({
    selector: `gipi-button[gipi-primary], gipi-button[gipi-primary-icon], gipi-button[gipi-secondary], gipi-button[gipi-secondary-icon],
               gipi-button[gipi-tertiary], gipi-button[gipi-link], gipi-button[gipi-icon], gipi-button[gipi-action]`,
    exportAs: 'gipiButton',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof ButtonComponent => ButtonComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-button',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit, OnDestroy {

    readonly isButtonPrimary: boolean = this._hasHostAttributes('gipi-primary');
    readonly isButtonPrimaryIcon: boolean = this._hasHostAttributes('gipi-primary-icon');
    readonly isButtonSecondary: boolean = this._hasHostAttributes('gipi-secondary');
    readonly isButtonSecondaryIcon: boolean = this._hasHostAttributes('gipi-secondary-icon');
    readonly isButtonTertiary: boolean = this._hasHostAttributes('gipi-tertiary');
    readonly isButtonTertiaryIcon: boolean = this._hasHostAttributes('gipi-tertiary-icon');
    readonly isButtonLink: boolean = this._hasHostAttributes('gipi-link');
    readonly isButtonIcon: boolean = this._hasHostAttributes('gipi-icon');
    readonly isButtonAction: boolean = this._hasHostAttributes('gipi-action');

    @ViewChild('popoverTrigger', { static: false }) popoverTrigger: MdePopoverTrigger;

    @Input() id: string;

    @Input() name: string;

    @Input() type: 'button' | 'submit' | 'reset' = 'button';

    @Input() ariaLabel: string = '';

    @Input() title: string = '';

    @Input() label: string = '';

    @Input() buttonClass: string;

    @Input() buttonPadding: number | string;

    @Input() iconWidth: number | string;
    @Input() iconHeight: number | string;

    @Input() icon: string = null;

    @Input() svgIcon: string = null;

    @Input() iconPos: ButtonIconPosition = 'left';

    private _disabled: boolean = false;
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._changeDetectorRef.markForCheck();
    }

    private _loading: boolean = false;
    @Input() get loading(): boolean {
        return this._loading;
    }
    set loading(value: boolean) {
        this._loading = coerceBooleanProperty(value);
        this._changeDetectorRef.markForCheck();
    }

    private _notification: boolean = false;
    @Input() get notification(): boolean {
        return this._notification;
    }
    set notification(value: boolean) {
        this._notification = coerceBooleanProperty(value);
        this._changeDetectorRef.markForCheck();
    }

    @Input() tooltip: string = '';

    @Input() popover: boolean = false;

    @Input() mdePopoverTargetAt: MdePopoverTarget;

    @Input('forInput') isForInput: boolean = false;

    @Output() onOpenPopover: EventEmitter<void> = new EventEmitter<void>();
    @Output() onClosePopover: EventEmitter<void> = new EventEmitter<void>();
    @Output('click') onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);
    @Output('focus') onFocus: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);
    @Output('blur') onBlur: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(true);

    get popoverContentWidth(): Observable<string> {
        if (!this.popover) {
            return;
        }

        if (ObjectUtil.isNull(this.elementRef)) {
            return of('auto');
        }
        const buttonRef: HTMLElement = (this.elementRef.nativeElement as HTMLElement);

        return of(`${buttonRef.clientWidth}px`);
    }

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        public elementRef: ElementRef,
    ) { }

    ngOnInit(): void { }

    ngOnDestroy(): void {
        if (this.popoverTrigger) {
            this.popoverTrigger.destroyPopover();
        }
    }

    getHostElement(): any {
        return this.elementRef.nativeElement;
    }

    private _hasHostAttributes(...attributes: string[]): boolean {
        return attributes.some(attribute => this.getHostElement().hasAttribute(attribute));
    }

    showPopover(): void {
        if (this.disabled || this.loading) {
            return;
        } else {
            this.popoverTrigger.openPopover();
        }
    }

    closePopover(): void {
        if (this.disabled || this.loading) {
            return;
        } else {
            this.popoverTrigger.closePopover();
        }
    }

    handleClick(event: MouseEvent): void {
        if (this.disabled || this.loading) {
            return;
        } else {
            this.onClick.emit(event);
            event.stopPropagation();
        }
    }

    handleFocus(event: MouseEvent): void {
        if (this.disabled || this.loading) {
            return;
        } else {
            this.onFocus.emit(event);
            event.stopPropagation();
        }
    }

    handleBlur(event: MouseEvent): void {
        if (this.disabled || this.loading) {
            return;
        } else {
            this.onBlur.emit(event);
            event.stopPropagation();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: UIEvent): void {
        if (this.popoverTrigger) {
            this.popoverTrigger.closePopover();
            event.stopPropagation();
        }
    }

}
