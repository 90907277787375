import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gipi-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

    public title: string;

    public textInformation: string;

    public typeIcon: 'SUCCESS' | 'ERROR';

    constructor() { }

    ngOnInit(): void { }

    showMessage(isError: boolean): void {
        if (!isError) {
            this.title = (this.title === '' ? 'Sucesso' : this.title);
            this.typeIcon = 'SUCCESS';
        } else {
            this.title = (this.title === '' ? 'Ocorreu um erro' : this.title);
            this.typeIcon = 'ERROR';
        }
    }

}
