import { formatDate } from '@angular/common';

import * as moment_ from 'moment';

import { NumberUtil } from './number.util';
import { ObjectUtil } from './object.util';
import { StringUtil } from './string.util';

const moment = moment_;


export class DateUtil {

    public static DATE_FORMAT = 'dd/MM/yyyy';

    public static DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';

    public static format(value: Date, format: string): string {
        if (!ObjectUtil.isNull(value)) {
            return formatDate(value, format, 'pt');
        }
        return StringUtil.EMPTY;
    }

    public static isValid(value: Date): boolean {
        if (ObjectUtil.isNull(value)) {
            return false;
        }
        value = new Date(value);
        return !isNaN(value.getTime()) && DateUtil.isGreaterThan(value, new Date('1900-01-01Z00:00:00:000'));
    }

    public static isEqual(initial: Date, final: Date): boolean {
        if (!ObjectUtil.isNull(initial) && !ObjectUtil.isNull(final)) {
            return initial.getTime() === final.getTime();
        }
        return false;
    }

    public static isGreaterThan(initial: Date, final: Date): boolean {
        if (!ObjectUtil.isNull(initial) && !ObjectUtil.isNull(final)) {
            return initial.getTime() > final.getTime();
        }
        return false;
    }

    public static isLessThan(initial: Date, final: Date): boolean {
        if (!ObjectUtil.isNull(initial) && !ObjectUtil.isNull(final)) {
            return initial.getTime() < final.getTime();
        }
        return false;
    }

    public static isLessThanOrEqual(initial: Date, final: Date): boolean {
        return this.isLessThan(initial, final) || this.isEqual(initial, final);
    }

    public static isGreaterThanOrEqual(initial: Date, final: Date): boolean {
        return this.isGreaterThan(initial, final) || this.isEqual(initial, final);
    }

    public static isBetween(date: Date, initial: Date, final: Date): boolean {
        return this.isGreaterThanOrEqual(date, initial) && this.isLessThanOrEqual(date, final);
    }

    public static diffBetweenDates(initial: Date, final: Date, diff: 'MILLISECONDS' | 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS' | 'MONTHS'): number {
        const lDateInitial: Date = new Date(initial);
        const lDateFinal: Date = new Date(final);
        const lDiff: number = moment(lDateInitial, 'DD/MM/YYYY HH:mm:ss').diff(moment(lDateFinal, 'DD/MM/YYYY HH:mm:ss'));
        let lReturn = 0;
        switch (diff) {
            case 'MILLISECONDS': { lReturn = moment.duration(lDiff).asMilliseconds(); break; }
            case 'SECONDS': { lReturn = moment.duration(lDiff).asSeconds(); break; }
            case 'MINUTES': { lReturn = moment.duration(lDiff).asMinutes(); break; }
            case 'HOURS': { lReturn = moment.duration(lDiff).asHours(); break; }
            case 'DAYS': { lReturn = moment.duration(lDiff).asDays(); break; }
            case 'MONTHS': { lReturn = moment.duration(lDiff).asMonths(); break; }
        }
        return ((lReturn < 0) ? (lReturn * (-1)) : (lReturn));
    }

    public static getDayOfWeek(date: Date): string {
        switch (date.getDay() + 1) {
            case 1:
                return 'SUNDAY';
            case 2:
                return 'MONDAY';
            case 3:
                return 'TUESDAY';
            case 4:
                return 'WEDNESDAY';
            case 5:
                return 'THURSDAY';
            case 6:
                return 'FRIDAY';
            case 7:
                return 'SATURDAY';
        }
        return null;
    }

    public static isValidWithString(value: string, pattern: string): boolean {
        if (StringUtil.isEmpty(value) || value.length < pattern.length) {
            return false;
        }
        const date = this.stringToDate(value, pattern);
        return !isNaN(date.getTime());
    }

    public static stringToDate(date: string, format: string, zoned: boolean = false): Date {
        const dateItems = date.replace(/[^a-zA-Z0-9]/g, '-').split('-');
        const formatItems = format.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-').split('-');

        const yearIndex = formatItems.indexOf('yyyy');
        const monthIndex = formatItems.indexOf('mm');
        const dayIndex = formatItems.indexOf('dd');
        const hourIndex = formatItems.indexOf('hh');
        const minutesIndex = formatItems.indexOf('ii');
        const secondsIndex = formatItems.indexOf('ss');

        if (zoned) {
            return new Date(Date.UTC(
                (yearIndex > -1 ? +dateItems[yearIndex] : 0),
                (monthIndex > -1 ? +dateItems[monthIndex] - 1 : 0),
                (dayIndex > -1 ? +dateItems[dayIndex] : 1),
                (hourIndex > -1 ? +dateItems[hourIndex] : 0),
                (minutesIndex > -1 ? +dateItems[minutesIndex] : 0),
                (secondsIndex > -1 ? +dateItems[secondsIndex] : 0)));
        }
        return new Date(
            (yearIndex > -1 ? dateItems[yearIndex] : '1970') + '-' +
            (monthIndex > -1 ? dateItems[monthIndex] : '01') + '-' +
            (dayIndex > -1 ? dateItems[dayIndex] : '01') + 'T' +
            (hourIndex > -1 ? dateItems[hourIndex] : '00') + ':' +
            (minutesIndex > -1 ? dateItems[minutesIndex] : '00') + ':' +
            (secondsIndex > -1 ? dateItems[secondsIndex] : '00') + '.000Z');
    }

    public static dateAndStringHourToDate(date: Date, hour: string): Date {
        const list = String(date).split(' ');
        const stringDate = `${list[0]} ${list[1]} ${list[2]} ${list[3]} ${hour}:00`;
        return new Date(stringDate);
    }

    public static stringFormattedToDate(date: string): Date {
        const list = date.split('/').map(el => Number(el));
        return new Date(list[2], list[1] - 1, list[0]);
    }

    public static getLastDayMonth(month?: number): number {
        const date = new Date();
        let lastDay = new Date();
        if (!month) {
            lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        } else {
            lastDay = new Date(date.getFullYear(), month + 1, 0);
        }
        return lastDay.getDate();
    }

    /* Somente mês e ano */
    public static isValidMonth(date: Date): boolean {
        date = new Date(date);
        const year: number = date.getFullYear();
        const month: number = date.getMonth();
        if (NumberUtil.isNegative(year) || NumberUtil.isNegative(month) || (month < 0) || (month > 12) || (year <= 1900)) {
            return false;
        }
        return true;
    }

    public static isEqualMonth(initial: Date, final: Date): boolean {
        initial = new Date(initial);
        final = new Date(final);
        if (ObjectUtil.isNull(initial) || ObjectUtil.isNull(final) || !this.isValidMonth(initial) || !this.isValidMonth(final)) {
            return false;
        } else if (initial.getMonth() !== final.getMonth()) {
            return false;
        }
        return true;
    }

    public static isGreaterThanMonth(initial: Date, final: Date): boolean {
        initial = new Date(initial);
        final = new Date(final);
        if (ObjectUtil.isNull(initial) || ObjectUtil.isNull(final) || !this.isValidMonth(initial) || !this.isValidMonth(final)) {
            return false;
        } else if (initial.getFullYear() > final.getFullYear()) {
            return true;
        } else if ((initial.getFullYear() >= final.getFullYear()) && (initial.getMonth() > final.getMonth())) {
            return true;
        }
        return false;
    }

    public static isLessThanMonth(initial: Date, final: Date): boolean {
        initial = new Date(initial);
        final = new Date(final);
        if (ObjectUtil.isNull(initial) || ObjectUtil.isNull(final) || !this.isValidMonth(initial) || !this.isValidMonth(final)) {
            return false;
        } else if (initial.getFullYear() < final.getFullYear()) {
            return true;
        } else if (initial.getFullYear() <= final.getFullYear() && initial.getMonth() < final.getMonth()) {
            return true;
        }
        return false;
    }

    public static isLessThanOrEqualMonth(initial: Date, final: Date): boolean {
        initial = new Date(initial);
        final = new Date(final);
        return this.isLessThanMonth(initial, final) || this.isEqualMonth(initial, final);
    }

    public static isGreaterThanOrEqualMonth(initial: Date, final: Date): boolean {
        initial = new Date(initial);
        final = new Date(final);
        return this.isGreaterThanMonth(initial, final) || this.isEqualMonth(initial, final);
    }

    public static isBetweenMonth(date: Date, initial: Date, final: Date): boolean {
        date = new Date(date);
        initial = new Date(initial);
        final = new Date(final);
        return this.isGreaterThanOrEqualMonth(date, initial) && this.isLessThanOrEqualMonth(date, final);
    }

}
