import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GipiIcons } from '@gipi-ui/enums/gipi-icons.enum';

/**
 * Basta ir no Enum GipiIcon e informar o icone lá que o mesmo já poderá ser utilizado na aplicação.
 * Lembrando que para utilizar o icone é dessa forma, Ex: <mat-icon svgIcon="person_client"></mat-icon>
 * No caso o person_client é o value do enum, e também o nome do arquivo.svg.
 */
@Injectable({
    providedIn: 'root'
})
export class GipiIconsService {

    constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) { }

    public registerIcons(): void {
        Object.keys(GipiIcons).forEach(((gipiIcon) => {
            this._matIconRegistry.addSvgIcon(gipiIcon, this.setPath(GipiIcons[gipiIcon]));
        }));
    }

    private setPath(nameIcon: string): SafeResourceUrl {
        return this._domSanitizer.bypassSecurityTrustResourceUrl(`/assets/svg/${nameIcon}.svg`);
    }

}
