import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AbstractFilterModel } from '@gipi-pages/abstract/models/abstract-filter.model';
import { AbstractService } from '@gipi-pages/abstract/services/abstract.service';
import { VersionModel } from '../models/version.model';

@Injectable({ providedIn: 'root' })
export class VersionService extends AbstractService<VersionModel, AbstractFilterModel> {

    constructor(protected httpClient: HttpClient) {
        super('APOIO', 'version', httpClient);
    }

}
