<gipi-abstract>
    <gipi-toolbar id="toolbarListBackup"
                  label="Consulta de backups">
    </gipi-toolbar>
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="space-between center"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="16px"
             fxFlex="50">
            <gipi-input-text placeholder="Pesquisar"
                             showSuffix="true"
                             [disabled]="loading"
                             [(ngModel)]="valueInput"
                             (keyup)="filterEntitiesByInput()">
                <gipi-button tooltip="Pesquisar"
                             gipi-icon
                             suffix
                             id="btnSearch"
                             icon="search"
                             [disabled]="loading"
                             [forInput]="true"
                             (click)="filterEntitiesByInput(true)">
                </gipi-button>
            </gipi-input-text>
            <gipi-button tooltip="Filtrar"
                         gipi-primary-icon
                         #searchButton
                         id="btnOptionsSearch"
                         svgIcon="filter"
                         [notification]="backupNumberDays"
                         [popover]="true"
                         [disabled]="loading">
                <div class="backup-content-filter">
                    <div class="header">
                        Filtrar por dias do backup
                    </div>
                    <div class="content">
                        <fieldset class="filter-by">
                            <legend>Filtrar por</legend>
                            <gipi-radio-group id="inputFilterByRadioGroup"
                                              name="inputFilterByRadioGroup"
                                              [align]="'row'"
                                              [enum]="backupDaysFilterEnum"
                                              [(ngModel)]="backupDaysFilterValue">
                            </gipi-radio-group>
                        </fieldset>

                        <gipi-input-text label="Quantidade de dias"
                                         placeholder="Ex: 5"
                                         [pattern]="'[0-9]+'"
                                         [mask]="'0*'"
                                         [disabled]="loading"
                                         [(ngModel)]="backupNumberDays"
                                         fxFlex
                                         fxFlex.lt-md="100">
                        </gipi-input-text>

                        <div style="margin-top: 1rem;"
                             fxLayout="row"
                             fxLayoutGap="12px"
                             fxFlex>
                            <gipi-button label="Pesquisar"
                                         gipi-primary
                                         id="btnFind"
                                         icon="search"
                                         [disabled]="loading"
                                         (click)="searchButton.closePopover(); filterEntitiesByInput(true)"
                                         fxFlex="50">
                            </gipi-button>
                            <gipi-button label="Limpar"
                                         gipi-secondary
                                         id="btnClear"
                                         icon="clear"
                                         [disabled]="loading"
                                         (click)="searchButton.closePopover(); clearFilterDaysBackup()"
                                         fxFlex="50">
                            </gipi-button>
                        </div>
                    </div>
                </div>
            </gipi-button>
        </div>

        <div fxFlex="30"
             fxFlex.lt-md="100"
             fxLayoutAlign="end center"
             fxLayoutGap="16px">
            <gipi-button label="Atualizar"
                         gipi-primary
                         id="btnRefreshTable"
                         icon="sync"
                         [disabled]="loading"
                         (click)="refreshFindAll()"
                         fxFlex
                         fxFlex.lt-md="100">
            </gipi-button>
            <gipi-button label="Exibir"
                         gipi-tertiary
                         id="btnDisplay"
                         icon="expand_more"
                         #displayButton
                         [disabled]="loading"
                         [popover]="true"
                         fxFlex
                         fxFlex.lt-md="100">
                <div style="display: flex; flex-direction: column !important; min-width: 140px !important; gap: 1rem;">
                    <gipi-checkbox label="Todos"
                                   gipi-primary
                                   [(ngModel)]="allStatusChecked"
                                   [(indeterminate)]="indeterminateStatusChecked"
                                   (change)="checkAllStatus()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <gipi-checkbox label="0 a 2 dias"
                                   gipi-secondary
                                   [(ngModel)]="zeroToTwoDaysStatusChecked"
                                   (change)="validateAllStatusSelected()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <gipi-checkbox label="3 a 7 dias"
                                   gipi-secondary
                                   [(ngModel)]="threeToSevenDaysStatusChecked"
                                   (change)="validateAllStatusSelected()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <gipi-checkbox label="Mais de 7 dias"
                                   gipi-secondary
                                   [(ngModel)]="moreThanSevenDaysStatusChecked"
                                   (change)="validateAllStatusSelected()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <gipi-checkbox label="Não registrado"
                                   gipi-secondary
                                   [(ngModel)]="notRegisteredStatusChecked"
                                   (change)="validateAllStatusSelected()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <div style="margin-top: 1rem;">
                        <gipi-button label="Aplicar"
                                     gipi-primary
                                     id="btnApplyFilter"
                                     [disabled]="loading"
                                     (click)=" displayButton.closePopover(); filterEntitiesByInput(true)"
                                     fxFlex>
                        </gipi-button>
                    </div>
                </div>
            </gipi-button>
        </div>
    </div>

    <gipi-mat-table [(dataSource)]="_dataSource"
                    [controlDataSource]="true"
                    [columns]="columns"
                    [rows]="_entities"
                    [sort]="true"
                    [paginatorFrontend]="true"
                    [pageSize]="10"
                    [loading]="loading"
                    (pageChange)="sortData($event)">
    </gipi-mat-table>
</gipi-abstract>

<ng-template #statusTemplate
             let-row>
    <gipi-lozenge [label]="getLabelStatus(row.dateTimeLastBackup)"
                  [tooltip]="getTooltipStatus(row.dateTimeLastBackup)"
                  [type]="getTypeStatus(row.dateTimeLastBackup)">
    </gipi-lozenge>
</ng-template>