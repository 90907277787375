import { Component, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: `gipi-action-row`,
    exportAs: 'gipiActionRow',
    templateUrl: './action-row.component.html',
    styleUrls: ['./action-row.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof ActionRowComponent => ActionRowComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-action-row',
    }
})
export class ActionRowComponent implements OnInit {

    constructor() { }

    ngOnInit(): void { }

}
