import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[gipiAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  constructor(private _elementRef: ElementRef) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this._elementRef.nativeElement.focus();
    });
  }

}
