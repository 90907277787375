import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '@gipi-pages/user/services/user.service';
import { SnackbarService } from 'src/app/components/global/snackbar/services/snackbar.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { PasswordUtil } from 'src/app/ui/utils/password.util';
import { DialogConfirmComponent } from '../../shared/dialog-confirm/dialog-confirm.component';
import { TYPE_BUTTONS, TYPE_DIALOG } from '../../shared/dialog-confirm/models/dialog-confirm.model';

@Component({
    selector: 'gipi-confirm-access',
    templateUrl: './confirm-access.component.html',
    styleUrls: ['./confirm-access.component.scss']
})
export class ConfirmAccessComponent implements OnInit {

    public tokenAccess: string = '';

    public inputTextName: FormControl = new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(60)]);

    public inputTextPassword: FormControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]);

    public inputTextConfirmPassword: FormControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]);

    public hidePassword: boolean = true;

    public hideConfirmPassword: boolean = true;

    public submitted: boolean = false;

    get isValidCharacterPassword(): boolean {
        return PasswordUtil.isValidCharacter(this.inputTextPassword.value);
    }

    get isValidCharacterConfirmPassword(): boolean {
        return PasswordUtil.isValidCharacter(this.inputTextConfirmPassword.value);
    }

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _usersService: UserService,
        private _router: Router,
        private _snackbarService: SnackbarService,
        private _dialogConfirm: MatDialog,
        private _authenticationService: AuthenticationService,
    ) {
        this._authenticationService.removeToken();
    }

    ngOnInit(): void {
        this.tokenAccess = this._activatedRoute.snapshot.paramMap.get('token');
    }

    public async onClickConfirmAccess(): Promise<void> {
        try {
            this.submitted = true;

            if (this.inputTextName.value) {
                if (this.inputTextPassword.value === this.inputTextConfirmPassword.value) {
                    if (!PasswordUtil.isValidCharacter(this.inputTextPassword.value) ||
                        !PasswordUtil.isValidCharacter(this.inputTextConfirmPassword.value)
                    ) {
                        throw new Error('Os caracteres %, +, -, * e / não são permitidos');
                    } else {
                        await this._usersService.confirmAccess(this.inputTextName.value, this.inputTextPassword.value, this.tokenAccess).toPromise().then((result) => {
                            if (result) {
                                this._snackbarService.showMessage('Confirmação de acesso realizada com sucesso');
                                this.submitted = false;
                                this._router.navigate(['oauth/login']);
                            }
                        }, error => {
                            throw new Error(error);
                        });
                    }
                } else {
                    throw new Error('A senha não confere');
                }
            } else {
                throw new Error('Campo "Nome" é obrigatório e não foi preenchido');
            }
        } catch (e) {
            this.submitted = false;
            this._snackbarService.showMessage(e, true);
        }
    }

    public goBack(): void {
        this.openDialogConfirm(-1, TYPE_DIALOG.CONFIRMATION, 'Deseja realmente cancelar a confirmação do acesso?', TYPE_BUTTONS.SI_NO);
    }

    private async openDialogConfirm(id: number = -1, typeDialog: TYPE_DIALOG, message: string, buttons: TYPE_BUTTONS): Promise<void> {
        const dialogRef: MatDialogRef<DialogConfirmComponent> = this._dialogConfirm.open(DialogConfirmComponent, {
            width: '350px',
            data: { type: typeDialog, message: message, buttons: buttons }
        });

        await dialogRef.afterClosed().toPromise().then((result) => {
            if (result) {
                this._router.navigate(['oauth/login']);
            }
        });
    }

}
