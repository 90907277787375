import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractFormDialogComponent, FormDialogData } from '@gipi-pages/abstract/abstract-form-dialog.component';
import { TypeVersionModel } from '@gipi-pages/type-version/models/type-version.model';
import { TypeVersionService } from '@gipi-pages/type-version/services/type-version.service';
import { VersionModel } from '@gipi-pages/version/models/version.model';
import { VersionService } from '@gipi-pages/version/services/version.service';
import { AlertService } from '@gipi-ui/services/alert.service';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';

@Component({
    selector: 'gipi-version-form-dialog',
    templateUrl: './version-form-dialog.component.html',
    styleUrls: ['./version-form-dialog.component.scss']
})
export class VersionFormDialogComponent extends AbstractFormDialogComponent<VersionModel> implements OnInit {

    public _optionsTypeVersion: TypeVersionModel[] = [];

    constructor(
        protected service: VersionService,
        protected dialogRef: MatDialogRef<VersionFormDialogComponent>,
        private _alertService: AlertService,
        private _typeVersionService: TypeVersionService,
        @Inject(MAT_DIALOG_DATA) public data: FormDialogData<VersionModel> = { typeOperation: 'NEW', entity: null },
    ) {
        super(service, dialogRef, data);
        this._getTypeVersionList();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected newEntity(): VersionModel {
        return new VersionModel();
    }

    protected isValid(): boolean {
        if (ObjectUtil.isNull(this.entity)) {
            return false;
        }
        if (!this.entity.description) {
            this._alertService.addWarningMessage('Campo descrição é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.entity.version)) {
            this._alertService.addWarningMessage('Campo versão é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.entity.typeVersion)) {
            this._alertService.addWarningMessage('Campo tipo de versão é obrigatório e não foi informado');
            return false;
        }

        return true;
    }

    private _getTypeVersionList(): void {
        this._typeVersionService.findByModifiedDate().toPromise().then(typeVersionList => {
            if (!ArrayUtil.isEmpty(typeVersionList)) {
                this._optionsTypeVersion = [...typeVersionList];
            }
        }).catch(error => {
            this._alertService.handleError(error);
        });
    }

    public confirm(): void {
        try {
            if (!this.isValid()) {
                return;
            }

            this.loading = true;

            this.service.save(this.entity).toPromise().then(() => {
                this._alertService.addSuccessMessage('Operação realizada com sucesso');
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        }
    }

}
