<gipi-abstract id="dialogRegisterDelayBlocking"
               name="dialogRegisterDelayBlocking"
               [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterDelayBlocking">
        Cadastrar prorrogação de licença
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-text label="CPF | CNPJ"
                             id="delayBlockingClientDocument"
                             name="delayBlockingClientDocument"
                             [disabled]="true"
                             [value]="formattedDocumentClient(entity.cnpj)"
                             fxFlex="25"
                             fxFlex.lt-md="100">
            </gipi-input-text>
            <gipi-input-text label="Nome"
                             id="delayBlockingClientName"
                             name="delayBlockingClientName"
                             [disabled]="true"
                             [value]="entity.clientName"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-input-text>
            <gipi-datepicker label="Data de vencimento"
                             [required]="true"
                             [readOnly]="true"
                             [min]="minDate"
                             [max]="maxDate"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [dateFilter]="dateFilter"
                             [(ngModel)]="entity.deadLine"
                             fxFlex="25"
                             fxFlex.lt-md="100">
            </gipi-datepicker>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-textarea label="Observação"
                           id="delayBlockingReason"
                           name="delayBlockingReason"
                           [help]="'Máximo 250 caracteres'"
                           [required]="validateAccess('ROLE_SUPPORT')"
                           [disabled]="loading || (typeOperation === 'VIEW')"
                           [(ngModel)]="entity.reason"
                           [minLength]="0"
                           [maxLength]="250"
                           fxFlex
                           fxFlex.lt-md="100">
            </gipi-textarea>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract>