import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as moment_ from 'moment';
const moment = moment_;

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DateFilterFn } from '@angular/material/datepicker';
import { BrowserUtil } from '@gipi-ui/utils/browser.util';
import { DateUtil } from '@gipi-ui/utils/date.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { InputTextComponent } from '../input-text/input-text.component';

let nextUniqueId = 0;

@Component({
    selector: 'gipi-datepicker',
    exportAs: 'gipiDatepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof DatepickerComponent => DatepickerComponent),
            multi: true
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'pt-BR',
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['l', 'LL', 'DD/MM/YYYY'],
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
    host: {
        'class': 'gipi-datepicker',
    }
})
export class DatepickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

    private _name: string = `gipi-datepicker-${nextUniqueId++}`;

    _enumList: any[] = [];

    _itemSizeScrollViewport: number = 5;

    _lastOptionSelected: any = null;

    @ViewChild(InputTextComponent, { static: true }) inputTextRef!: InputTextComponent;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    @Input() label: string = '';

    @Input() placeholder: string = '';

    @Input() help: string = '';

    private _minDate: Date = new Date('01/01/2000');
    @Input('min') get minDate(): Date {
        return this._minDate;
    }
    set minDate(value: Date) {
        this._minDate = value;
        this._minDate.setHours(0, 0, 0, 0);
    }

    private _maxDate: Date = new Date('01/01/2000');
    @Input('max') get maxDate(): Date {
        return this._maxDate;
    }
    set maxDate(value: Date) {
        this._maxDate = value;
        this._maxDate.setHours(0, 0, 0, 0);
    }

    private _readOnly: boolean = false;
    @Input() get readOnly(): boolean {
        return this._readOnly;
    }
    set readOnly(value: boolean) {
        this._readOnly = coerceBooleanProperty(value);
    }

    private _required: boolean = false;
    @Input() get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    private _disabled: boolean = false;
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _dateFilter: DateFilterFn<Date> = null;
    @Input() get dateFilter(): DateFilterFn<Date> {
        return this._dateFilter;
    }
    set dateFilter(value: DateFilterFn<Date>) {
        this._dateFilter = value;
    }

    private _value!: Date;
    @Input()
    get value(): Date {
        return this._value;
    }
    set value(value: Date) {
        if (ObjectUtil.isNull(value)) {
            this._value = null;
        } else {
            let valueAux: Date = null;

            if (!ObjectUtil.isNull(this.minDate)) {
                const valueIsBeforeMin = moment(value).isBefore(moment(this.minDate), 'date');
                if (valueIsBeforeMin) {
                    valueAux = moment(this.minDate).toDate();
                }
            }

            if (!ObjectUtil.isNull(this.maxDate)) {
                const valueIsAfterMax = moment(value).isAfter(moment(this.maxDate), 'date');
                if (valueIsAfterMax) {
                    valueAux = moment(this.maxDate).toDate();
                }
            }

            if (ObjectUtil.isNull(valueAux)) {
                this._value = new Date(value);
            } else {
                this._value = moment(valueAux).toDate();
            }
        }

        if (!ObjectUtil.isNull(this._value)) {
            this._value.setHours(0);
            this._value.setMinutes(0);
            this._value.setSeconds(0);
            this._value.setMilliseconds(0);
        }

        this.onChange(this._value);
        this.onTouched(this._value);
    }

    onChange: Function = () => { };

    onTouched: Function = () => { };

    public get formattedValue(): string {
        return '';
    }

    constructor(
        public elementRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }

    public generatedDate(valueDate: string, keyUp: boolean): void {
        if (!keyUp && BrowserUtil.getBrowser() === 'Firefox') {
            return;
        }

        let match = false;
        if (valueDate.includes('/') && (!keyUp || valueDate.length === 10)) {
            this.value = moment(valueDate, 'DD/MM/YYYY').toDate();
            match = true;
        } else if (!valueDate.includes('/') && (!keyUp || valueDate.length === 8)) {
            this.value = moment(valueDate, 'DDMMYYYY').toDate();
            match = true;
        }

        if (BrowserUtil.getBrowser() !== 'Firefox') {
            if (match && !DateUtil.isValid(this.value)) {
                this.value = undefined;
            }
        } else {
            if (match && !this.value) {
                this.value = undefined;
            }
        }
    }

    public validateMinAndMaxDate(valueDate: string): void {
        const dateInput = moment(valueDate, 'DD/MM/YYYY').toDate();
        const dateMin = moment(this.minDate, 'DD/MM/YYYY').toDate();

        if (DateUtil.isLessThan(dateInput, dateMin)) {
            this.value = undefined;
        } else if (this.maxDate) {
            const dateMax = moment(this.maxDate, 'DD/MM/YYYY').toDate();
            this.value = DateUtil.isGreaterThan(dateInput, dateMax) ? undefined : moment(dateInput).toDate();
        }
    }

    public dateChangeEmit(): void {
        this.onChange(this.value);
    }

    // @HostListener('window:resize', ['$event'])
    // public onResize(event: UIEvent): void {
    //     if (this.popoverTrigger) {
    //         this.popoverTrigger.closePopover();
    //         event.stopPropagation();
    //     }
    // }

}
