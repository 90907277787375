<div class="dialog-container">

    <!--Title-->
    <div style="display: flex; flex-direction: row; align-items: center; margin-top: 0px;">
        <mat-icon style="width: 48px; height: 48px; margin-right: 10px; fill: #F3465C;"
                  svgIcon="user_registration"
                  aria-hidden="false"
                  aria-label="Registro do usuário"></mat-icon>
        <p class="title-client">Minhas informações</p>
    </div>

    <mat-divider></mat-divider>
    <mat-progress-bar mode="indeterminate"
                      *ngIf="isLoading"></mat-progress-bar>

    <div style="margin-top: 25px;">

        <!--Input Nome-->
        <div style="width: 100%;">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                Nome <span style="color: #f44336!important;"> *</span>
            </mat-label>
            <mat-form-field floatLabel="always"
                            appearance="outline"
                            style="width: 100%;">
                <input matInput
                       type="text"
                       id="inputName"
                       autocomplete="off"
                       maxlength="60"
                       autofocus
                       [formControl]="inputTextName" />
                <mat-error *ngIf="inputTextName.errors && (inputTextName.dirty || inputTextName.touched)">
                    O nome é obrigatório e não foi informado
                </mat-error>
            </mat-form-field>
        </div>

        <!--Input E-mail Atual-->
        <div style="width: 100%; margin-top: 10px;">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                E-mail
            </mat-label>
            <mat-form-field floatLabel="always"
                            appearance="outline"
                            style="width: 100%;">
                <input matInput
                       type="text"
                       id="inputEmail"
                       style="text-transform: lowercase;"
                       autocomplete="off"
                       readonly="true"
                       [formControl]="inputTextEmail" />
            </mat-form-field>
        </div>

        <div class="div-update-password">
            <span style="cursor: pointer; color: #f44336!important; font-size: 13px!important;"
                  (click)="viewNewPassword()">
                {{ hideUpdatePassword ? 'Alterar senha' : 'Desfazer alteração de senha'}}
            </span>
        </div>

        <!--Input Nova Senha-->
        <div style="margin-top: 10px; display: flex; flex-direction: row; align-items: center; justify-content: space-between;"
             *ngIf="!hideUpdatePassword">
            <div style="width: 100%; margin-right: 5px;">
                <mat-label class="mat-label"
                           style="margin: 0px;">
                    Nova senha <span style="color: #f44336!important;"> *</span>
                </mat-label>
                <mat-form-field floatLabel="always"
                                appearance="outline"
                                style="width: 100%;">
                    <input matInput
                           [type]="hidePassword ? 'password' : 'text'"
                           id="inputNewPassword"
                           autocomplete="off"
                           minlength="6"
                           maxlength="16"
                           required
                           [formControl]="inputTextPassword" />

                    <button mat-icon-button
                            matSuffix
                            class="button-input"
                            matTooltip="{{ (hidePassword ? 'Mostrar senha' : 'Ocultar senha') }}"
                            (click)="hidePassword = !hidePassword">
                        <mat-icon style="width: 15px; height: 15px;">
                            {{ hidePassword ? 'lock' : 'lock_open' }}
                        </mat-icon>
                    </button>

                    <mat-error
                               *ngIf="inputTextPassword.errors && (inputTextPassword.dirty || inputTextPassword.touched)">
                        <p *ngIf="inputTextPassword.errors.required">
                            A nova senha é obrigatória e não foi informada
                        </p>
                        <p *ngIf="!isValidCharacterPassword">
                            Os caracteres %, +, -, * e / não são permitidos
                        </p>
                        <p *ngIf="inputTextPassword.value.length < 6">
                            A nova senha precisa ter no mínimo 6 caracteres
                        </p>
                        <p *ngIf="inputTextPassword.value.length >= 16">
                            A nova senha pode ter no máximo 16 caracteres
                        </p>
                    </mat-error>
                </mat-form-field>
            </div>

            <!--Input Confirmar Nova Senha-->
            <div style="width: 100%; margin-left: 5px;">
                <mat-label class="mat-label"
                           style="margin: 0px;">
                    Confirmar nova senha <span style="color: #f44336!important;"> *</span>
                </mat-label>
                <mat-form-field floatLabel="always"
                                appearance="outline"
                                style="width: 100%;">
                    <input matInput
                           [type]="hideConfirmPassword ? 'password' : 'text'"
                           id="inputConfirmPassword"
                           autocomplete="off"
                           minlength="6"
                           maxlength="16"
                           required
                           [formControl]="inputTextConfirmPassword" />

                    <button mat-icon-button
                            matSuffix
                            class="button-input"
                            matTooltip="{{ hideConfirmPassword ? 'Mostrar senha' : 'Ocultar senha' }}"
                            (click)="hideConfirmPassword = !hideConfirmPassword">
                        <mat-icon style="width: 15px; height: 15px;">
                            {{ hideConfirmPassword ? 'lock' : 'lock_open' }}
                        </mat-icon>
                    </button>

                    <mat-error
                               *ngIf="inputTextConfirmPassword.errors && (inputTextConfirmPassword.dirty || inputTextConfirmPassword.touched)">
                        <p *ngIf="inputTextConfirmPassword.errors.required">
                            A confirmação da nova senha é obrigatória e não foi informada
                        </p>
                        <p *ngIf="!isValidCharacterConfirmPassword">
                            Os caracteres %, +, -, * e / não são permitidos
                        </p>
                        <p *ngIf="inputTextConfirmPassword.value && inputTextConfirmPassword.value.length < 6">
                            A confirmação da nova senha precisa ter no mínimo 6 caracteres
                        </p>
                        <p *ngIf="inputTextConfirmPassword.value && inputTextConfirmPassword.value.length >= 16">
                            A confirmação da nova senha pode ter no máximo 16 caracteres
                        </p>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div
             style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start; height: 20px; width: 100%; margin-top: 10px !important;">
            <span style="color: #707070!important; font-size: 13px!important;">
                Para alterar suas informações confirme a sua senha atual
            </span>
        </div>

        <!--Input Confirmar Senha Atual-->
        <div style="width: 100%; margin-top: 10px;">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                Senha atual <span style="color: #f44336!important;"> *</span>
            </mat-label>
            <mat-form-field floatLabel="always"
                            appearance="outline"
                            style="width: 100%;">
                <input matInput
                       [type]="hideActualPassword ? 'password' : 'text'"
                       id="inputActualPassword"
                       autocomplete="off"
                       minlength="6"
                       maxlength="16"
                       required
                       [formControl]="inputTextActualPassword" />

                <button mat-icon-button
                        matSuffix
                        class="button-input"
                        matTooltip="{{ hideActualPassword ? 'Mostrar senha' : 'Ocultar senha' }}"
                        (click)="hideActualPassword = !hideActualPassword">
                    <mat-icon style="width: 15px; height: 15px;">
                        {{ hideActualPassword ? 'lock' : 'lock_open' }}
                    </mat-icon>
                </button>

                <mat-error
                           *ngIf="inputTextActualPassword.errors && (inputTextActualPassword.dirty || inputTextActualPassword.touched)">
                    <p *ngIf="inputTextActualPassword.errors.required">
                        A senha atual é obrigatória e não foi informada
                    </p>
                    <p *ngIf="inputTextActualPassword.errors.minlength">
                        A senha atual precisa ter no mínimo 6 caracteres
                    </p>
                    <p *ngIf="inputTextActualPassword.errors.maxlength">
                        A senha atual pode ter no máximo 16 caracteres
                    </p>
                </mat-error>
            </mat-form-field>
        </div>

    </div>

    <!--Button goBack-->
    <div style="display: flex; justify-content: flex-end; align-items: center; margin-top: 35px;">

        <button mat-raised-button
                [autofocus]="false"
                onclick="this.blur()"
                class="mat-raised-button-save"
                style="margin-right: 15px;"
                (click)="onClickSaveUser()"
                [disabled]="submitted">
            SALVAR
        </button>

        <button mat-raised-button
                [autofocus]="false"
                onclick="this.blur()"
                class="mat-raised-button-go-back"
                (click)="onClickGoBack()">
            <mat-icon class="mat-icon"
                      role="img"
                      aria-hidden="true">arrow_back</mat-icon>
            VOLTAR
        </button>
    </div>

</div>