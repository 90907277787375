import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { FormDialogData } from '@gipi-pages/abstract/abstract-form-dialog.component';
import { SortModel } from '@gipi-pages/abstract/models/sort.model';
import { LozengeComponent } from '@gipi-ui/components/lozenge/lozenge.component';
import { TablePaginatorEvent } from '@gipi-ui/components/mat-table/mat-table.component';
import { TableColumn } from '@gipi-ui/components/table/shared/table-column';
import { TableColumnBuilder } from '@gipi-ui/components/table/shared/table-column-builder';
import { TypeOperationCloseDialog } from '@gipi-ui/enums/type-operation-close-dialog.enum';
import { TypeOperationDialog } from '@gipi-ui/enums/type-operation-dialog.enum';
import { APP_MESSAGES } from '@gipi-ui/global/messages';
import { AlertService } from '@gipi-ui/services/alert.service';
import { DialogService } from '@gipi-ui/services/dialog.service';
import { ModalDialogService } from '@gipi-ui/services/modal-dialog.service';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { AbstractListComponent } from '../../../abstract/abstract-list.component';
import { NotificationTypeFilterDTO } from '../../models/dto/notification-type-filter.dto';
import { NotificationTypeModel } from '../../models/notification-type.model';
import { NotificationTypeService } from '../../services/notification-type.service';
import { NotificationTypeFormDialogComponent } from '../notification-type-form-dialog/notification-type-form-dialog.component';

@Component({
    selector: 'gipi-notification-type-list',
    templateUrl: './notification-type-list.component.html',
    styleUrls: ['./notification-type-list.component.scss']
})
export class NotificationTypeListComponent extends AbstractListComponent<NotificationTypeModel, NotificationTypeFilterDTO> implements OnInit {

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<LozengeComponent>;

    public allStatusChecked: boolean = false;
    public indeterminateStatusChecked: boolean = true;
    public enabledStatusChecked: boolean = true;
    public disabledStatusChecked: boolean = false;

    constructor(
        protected service: NotificationTypeService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _dialogService: DialogService,
        private _alertService: AlertService,
        private _modalDialogService: ModalDialogService,
    ) {
        super(service);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected getPath(): string {
        return `notification-type`;
    }

    protected _newFilter(): NotificationTypeFilterDTO {
        const filter: NotificationTypeFilterDTO = new NotificationTypeFilterDTO();
        filter.enabled = true;
        filter.sorts = [new SortModel('createdDate', 'DESC')];
        return filter;
    }

    protected _createTableColumns(): TableColumn[] {
        return [
            TableColumnBuilder
                .instance()
                .property('description')
                .description('Descrição')
                .sortable(true)
                .value((obj: NotificationTypeModel) => !StringUtil.isEmpty(obj.description) ? obj.description : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('imageUrl')
                .description('URL da imagem')
                .sliceLength(80)
                .value((obj: NotificationTypeModel) => !StringUtil.isEmpty(obj.imageUrl) ? obj.imageUrl : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('status')
                .description('Status')
                .template(this.statusTemplate)
                .width(150)
                .align('center')
                .build(),
        ];
    }

    public findAll(pageEvent?: TablePaginatorEvent): void {
        try {
            this.loading = true;
            this._setStatusInFilter();

            super.findAll(pageEvent);
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        } finally {
            this.loading = false;
            this._changeDetectorRef.detectChanges();
        }
    }

    public async showDialogNotificationType(typeOperation: TypeOperationDialog, entity?: NotificationTypeModel): Promise<void> {
        const notificationTypeData: FormDialogData<NotificationTypeModel> = {
            typeOperation: typeOperation,
            entity: !ObjectUtil.isNull(entity) ? ObjectUtil.clone(entity) : null,
        };

        this._dialogService
            .open(NotificationTypeFormDialogComponent, notificationTypeData, { width: 'auto', height: 'auto' })
            .afterClosed().toPromise().then((operation: TypeOperationCloseDialog) => {
                if (operation === 'RELOAD_TABLE') {
                    this.findAll();
                }
            });
    }

    public checkAllStatus(): void {
        this.enabledStatusChecked = this.allStatusChecked;
        this.disabledStatusChecked = this.allStatusChecked;

        this.indeterminateStatusChecked = false;
    }

    public validateAllStatusSelected(): void {
        if (
            this.enabledStatusChecked &&
            this.disabledStatusChecked
        ) {
            this.allStatusChecked = true;
            this.indeterminateStatusChecked = false;
        } else if (
            this.enabledStatusChecked ||
            this.disabledStatusChecked
        ) {
            this.indeterminateStatusChecked = true;
            this.allStatusChecked = false;
        } else {
            this.indeterminateStatusChecked = false;
            this.allStatusChecked = false;
        }
    }

    private _setStatusInFilter(): void {
        if (this.allStatusChecked) {
            this.filter.enabled = null;
            return;
        }
        if (this.enabledStatusChecked) {
            this.filter.enabled = true;
            return;
        }
        if (this.disabledStatusChecked) {
            this.filter.enabled = false;
            return;
        }
    }

    public enableOrDisable(entity: NotificationTypeModel): void {
        if (ObjectUtil.isNull(entity)) {
            this._alertService.addErrorMessage('O registro selecionado não foi identificado');
            return;
        }

        this._modalDialogService.show({
            title: 'Confirmação',
            message: `Deseja realmente ${(entity.enabled) ? 'inativar' : 'ativar'} este registro?`,
            showConfirm: true,
            showCancel: true,
            accept: () => {
                this.loading = true;
                const oppositeStatus: 'ENABLE' | 'DISABLE' = (entity.enabled) ? 'DISABLE' : 'ENABLE';

                this.service.enableOrDisable(entity.id, oppositeStatus).toPromise().then(_ => {
                    this.findAll();
                    this.loading = false;
                    this._alertService.addSuccessMessage(APP_MESSAGES.SUCCESS);
                }, error => {
                    this.clear();
                    this.loading = false;
                    this._alertService.handleError(error);
                });
            },
        });
    }

    public delete(entity: NotificationTypeModel): void {
        if (ObjectUtil.isNull(entity)) {
            this._alertService.addErrorMessage('O registro selecionado não foi identificado');
            return;
        }

        this._modalDialogService.show({
            title: 'Confirmação',
            message: APP_MESSAGES.DELETE_CONFIRMATION,
            showConfirm: true,
            showCancel: true,
            accept: () => {
                this.loading = true;
                this.page = this._newPage();

                this.service.delete(entity.id).subscribe(() => {
                    this.findAll();
                    this.loading = false;
                    this._alertService.addSuccessMessage(APP_MESSAGES.SUCCESS);
                }, error => {
                    this.clear();
                    this.loading = false;
                    this._alertService.handleError(error);
                });
            }
        });
    }

}
