import { SortDirection } from "../enums/sort-direction.enum";

export class SortModel {

    direction: SortDirection;

    field: string;

    constructor(
        field: string,
        direction: SortDirection,
    ) {
        this.field = field;
        this.direction = direction;
    }

}
