import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { FormDialogData } from '@gipi-pages/abstract/abstract-form-dialog.component';
import { AbstractListComponent } from '@gipi-pages/abstract/abstract-list.component';
import { SortModel } from '@gipi-pages/abstract/models/sort.model';
import { UserStatus } from '@gipi-pages/user/enums/user-status.enum';
import { UserConsultDTO } from '@gipi-pages/user/models/dto/user-consult.dto';
import { UserFilterDTO } from '@gipi-pages/user/models/dto/user-filter.dto';
import { UserModel } from '@gipi-pages/user/models/user.model';
import { UserService } from '@gipi-pages/user/services/user.service';
import { LozengeComponent, LozengeType } from '@gipi-ui/components/lozenge/lozenge.component';
import { TablePaginatorEvent } from '@gipi-ui/components/mat-table/mat-table.component';
import { TableColumn } from '@gipi-ui/components/table/shared/table-column';
import { TableColumnBuilder } from '@gipi-ui/components/table/shared/table-column-builder';
import { TypeAuthorities } from '@gipi-ui/enums/enum-authorities.enum';
import { TypeOperationCloseDialog } from '@gipi-ui/enums/type-operation-close-dialog.enum';
import { TypeOperationDialog } from '@gipi-ui/enums/type-operation-dialog.enum';
import { APP_MESSAGES } from '@gipi-ui/global/messages';
import { AlertService } from '@gipi-ui/services/alert.service';
import { DialogService } from '@gipi-ui/services/dialog.service';
import { ModalDialogService } from '@gipi-ui/services/modal-dialog.service';
import { ValidateAccessService } from '@gipi-ui/services/validate-access.service';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { UserFormDialogComponent } from '../user-form-dialog/user-form-dialog.component';

@Component({
    selector: 'gipi-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends AbstractListComponent<UserConsultDTO, UserFilterDTO> implements OnInit {

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<LozengeComponent>;

    public showOnlyClients: boolean = false;

    constructor(
        protected service: UserService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _dialogService: DialogService,
        private _alertService: AlertService,
        private _modalDialogService: ModalDialogService,
        private _authenticationService: AuthenticationService,
        private _validateAccessService: ValidateAccessService,
    ) {
        super(service);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected getPath(): string {
        return `users`;
    }

    protected _newFilter(): UserFilterDTO {
        const filter: UserFilterDTO = new UserFilterDTO();
        filter.roleDescriptionList = [];
        filter.sorts = [new SortModel('createdDate', 'DESC')];
        filter.researchField = '';
        return filter;
    }

    protected _createTableColumns(): TableColumn[] {
        return [
            TableColumnBuilder
                .instance()
                .property('name')
                .description('Nome')
                .sortable(true)
                .value((obj: UserConsultDTO) => !StringUtil.isEmpty(obj.name) ? obj.name : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('email')
                .description('E-mail')
                .sortable(true)
                .value((obj: UserConsultDTO) => !StringUtil.isEmpty(obj.email) ? obj.email : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('startTime')
                .description('Horário de início')
                .sortable(true)
                .value((obj: UserConsultDTO) => !StringUtil.isEmpty(obj.startTime) ? obj.startTime : '')
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('stopTime')
                .description('Horário de saída')
                .sortable(true)
                .value((obj: UserConsultDTO) => !StringUtil.isEmpty(obj.startTime) ? obj.stopTime : '')
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('status')
                .description('Status')
                .template(this.statusTemplate)
                .width(150)
                .align('center')
                .build(),
        ];
    }

    public findAll(pageEvent?: TablePaginatorEvent): void {
        try {
            this.loading = true;
            this.filter.roleDescriptionList = this._getRolesPermitted();

            super.findAll(pageEvent);
            this._changeDetectorRef.detectChanges();
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        }
    }

    private _getRolesPermitted(): TypeAuthorities[] {
        const role: TypeAuthorities = this._authenticationService.getUserRolesByAuthorities();
        let rolesPermitted: TypeAuthorities[] = [];

        if ((this.showOnlyClients) && ((role === 'ROLE_SUPPORT') || (role === 'ROLE_DEVELOPERS'))) {
            rolesPermitted.push('ROLE_CLIENT');
        } else if (role === 'ROLE_DEVELOPERS') {
            rolesPermitted.push('ROLE_DEVELOPERS');
            rolesPermitted.push('ROLE_SUPPORT');
            rolesPermitted.push('ROLE_FINANCIAL');
            rolesPermitted.push('ROLE_SELLER');
        } else {
            rolesPermitted.push(role);
        }

        return rolesPermitted;
    }

    public getLabelStatus(status: UserStatus): string {
        switch (status) {
            case 'ENABLED': return 'Ativo';
            case 'DISEABLED': return 'Inativo';
            case 'NOT_CONFIRMED': return 'Não confirmado';
            default: return 'Não identificado';
        }
    }

    public getTypeStatus(status: UserStatus): LozengeType {
        switch (status) {
            case 'ENABLED': return 'success';
            case 'DISEABLED': return 'error';
            case 'NOT_CONFIRMED': return 'warning';
            default: return 'default';
        }
    }

    public getTooltipStatus(status: UserStatus): string {
        switch (status) {
            case 'ENABLED': return 'Usuário ativo';
            case 'DISEABLED': return 'Usuário inativo';
            case 'NOT_CONFIRMED': return 'Usuário não confirmou o convite';
            default: return 'Não identificado';
        }
    }

    public async showDialogUser(typeOperation: TypeOperationDialog, entity?: UserConsultDTO): Promise<void> {
        let entityAux: UserModel = null;
        if (!ObjectUtil.isNull(entity)) {
            entityAux = await this.service.getOne(entity.id).toPromise();
        }

        const userData: FormDialogData<UserModel> = {
            typeOperation: typeOperation,
            entity: !ObjectUtil.isNull(entityAux) ? ObjectUtil.clone(entityAux) : null,
        };

        this._dialogService
            .open(UserFormDialogComponent, userData, { width: '80rem', height: '40rem' })
            .afterClosed().toPromise().then((operation: TypeOperationCloseDialog) => {
                if (operation === 'RELOAD_TABLE') {
                    this.findAll();
                }
            });
    }

    public enableOrDisable(entity: UserConsultDTO): void {
        if (ObjectUtil.isNull(entity)) {
            this._alertService.addErrorMessage('O registro selecionado não foi identificado');
            return;
        }

        this._modalDialogService.show({
            title: 'Confirmação',
            message: `Deseja realmente ${(entity.status === 'ENABLED') ? 'inativar' : 'ativar'} este registro?`,
            showConfirm: true,
            showCancel: true,
            accept: () => {
                this.loading = true;
                const oppositeStatus: 'ENABLE' | 'DISABLE' = (entity.status === 'ENABLED') ? 'DISABLE' : 'ENABLE';

                this.service.enableOrDisable(entity.email, oppositeStatus).toPromise().then(_ => {
                    this.findAll();
                    this.loading = false;
                    this._alertService.addSuccessMessage(APP_MESSAGES.SUCCESS);
                }, error => {
                    this.clear();
                    this.loading = false;
                    this._alertService.handleError(error);
                });
            },
        });
    }

    public delete(entity: UserConsultDTO): void {
        if (ObjectUtil.isNull(entity)) {
            this._alertService.addErrorMessage('O registro selecionado não foi identificado');
            return;
        }

        this._modalDialogService.show({
            title: 'Confirmação',
            message: APP_MESSAGES.DELETE_CONFIRMATION,
            showConfirm: true,
            showCancel: true,
            accept: () => {
                this.loading = true;
                this.page = this._newPage();

                this.service.delete(entity.id).subscribe(() => {
                    this.filter = this._newFilter();
                    this.findAll();
                    this.loading = false;
                    this._alertService.addSuccessMessage(APP_MESSAGES.SUCCESS);
                }, error => {
                    this.clear();
                    this.loading = false;
                    this._alertService.handleError(error);
                });
            }
        });
    }

    public sendInvitation(entity: UserConsultDTO): void {
        if (ObjectUtil.isNull(entity)) {
            this._alertService.addErrorMessage('O registro selecionado não foi identificado');
            return;
        }

        this._modalDialogService.show({
            title: 'Confirmação',
            message: `Deseja realmente reenviar o convite?`,
            showConfirm: true,
            showCancel: true,
            accept: () => {
                this.loading = true;
                this.page = this._newPage();

                this.service.sendInvitation(entity).subscribe((isConfirmed) => {
                    this.loading = false;
                    if (!isConfirmed) {
                        this._alertService.addErrorMessage('Não foi possível reenviar o convite. Por favor, verifique e tente novamente!');
                    } else {
                        this.findAll();
                        this._alertService.addSuccessMessage(APP_MESSAGES.SUCCESS);
                    }
                }, error => {
                    this.clear();
                    this.loading = false;
                    this._alertService.handleError(error);
                });
            }
        });
    }

    public validateAccess(authorities: TypeAuthorities): boolean {
        return this._validateAccessService.validateAccess(authorities);
    }
}
