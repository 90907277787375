import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { GIPIUuid } from '@gipi-shared/types/uuid.type';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { UUIDUtil } from '@gipi-ui/utils/uuid.util';
import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { AbstractFilterModel } from '../models/abstract-filter.model';
import { AbstractModel } from '../models/abstract.model';
import { PageModel } from '../models/page.model';
import { SortModel } from '../models/sort.model';

@Injectable()
export class AbstractService<TEntity extends AbstractModel, TFilter extends AbstractFilterModel> implements OnDestroy {

    protected handleDestroy: Subject<void> = new Subject<void>();

    constructor(
        @Inject(String) protected urlBase: 'APOIO' | 'OAUTH' | 'LICENSE' | 'USER',
        @Inject(String) protected path: string,
        protected httpClient: HttpClient,
    ) { }

    ngOnDestroy(): void {
        this.handleDestroy.next();
        this.handleDestroy.complete();
    }

    protected url(resource?: string, baseUrl?: 'APOIO' | 'OAUTH' | 'LICENSE' | 'USER'): string {
        let pathAux: string = this.path;
        if (!StringUtil.isEmpty(resource)) {
            const startsWithSlash = resource.startsWith('/') ? '' : '/';
            pathAux = `${this.path}${startsWithSlash}`;
        }

        if (StringUtil.isEmpty(baseUrl)) {
            baseUrl = this.urlBase;
        }

        let baseUrlAux: string = HttpClientBase.baseURLApoio;
        if (baseUrl === 'APOIO') {
            baseUrlAux = HttpClientBase.baseURLApoio;
        } else if (baseUrl === 'OAUTH') {
            baseUrlAux = HttpClientBase.baseURLAuth;
        } else if (baseUrl === 'LICENSE') {
            baseUrlAux = HttpClientBase.baseURLLicense;
        } else if (baseUrl === 'USER') {
            baseUrlAux = HttpClientBase.baseURLUser;
        }

        return `${baseUrlAux}${pathAux}${resource}`;
    }

    protected options(params?: HttpParams, headers?: HttpHeaders): { headers: HttpHeaders; params: HttpParams; } {
        return {
            headers: headers ? headers : this.buildHeaders(),
            params: params ? params : this.buildParams()
        };
    }

    protected buildHeaders(): HttpHeaders {
        return new HttpHeaders();
    }

    protected buildParams(): HttpParams {
        return new HttpParams();
    }

    protected handleMapper<T = void>(jsonObj: Object): (T extends void ? any : T) {
        return jsonObj as (T extends void ? any : T);
    }

    protected handleError<T = void>(error: string): Observable<(T extends void ? any : T)> {
        return throwError(error);
    }

    /**
     * Refers to the save endpoint
     * @type POST
     * @path resource/version
     */
    public save<T = any>(entity: T): Observable<T>;
    public save(entity: TEntity): Observable<TEntity> {
        this.validate(entity);
        return this.httpClient.post(this.url(''), entity, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    /**
     * Refers to the save-all endpoint
     * @type POST
     * @path resource/save-all
     */
    public saveAll<T = any>(entities: T[]): Observable<T[]>;
    public saveAll(entities: TEntity[]): Observable<TEntity[]> {
        for (let i = 0; i < entities.length; i++) {
            this.validate(entities[i]);
        }

        return this.httpClient.post(this.url('save-all'), entities, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    /**
     * Refers to the delete endpoint
     * @type DELETE
     * @path resource/version/{id}
     */
    public delete<T = any>(id: number | string): Observable<T>;
    public delete(id: number | string): Observable<TEntity> {
        return this.httpClient.delete(this.url(`${id}`)).pipe(
            map(() => null),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    /**
     * Refers to the delete-all endpoint
     * @type POST
     * @path resource/version/delete-all
     */
    public deleteAll(idList: GIPIUuid[]): Observable<boolean> {
        for (let i = 0; i < idList.length; i++) {
            if (!UUIDUtil.isValid(idList[i])) {
                return of(false);
            }
        }

        return this.httpClient.post(this.url('delete-all'), idList, this.options()).pipe(
            map(() => { return true; }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    /**
     * Refers to the get-one endpoint
     * @type GET
     * @path resource/version/{id}
     */
    public getOne<T = any>(id: GIPIUuid): Observable<T>;
    public getOne(id: GIPIUuid): Observable<TEntity> {
        return this.httpClient.get(this.url(`${id}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    /**
     * Refers to the find-all endpoint without the filters
     * @type GET
     * @path resource/version
     */
    public find<T = any>(): Observable<PageModel<T>>;
    public find(): Observable<PageModel<TEntity>> {
        return this.httpClient.get(this.url(''), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    /**
     * Refers to the find-all endpoint without the filters
     * @type GET
     * @path resource/version
     */
    public findByModifiedDate<T = any>(modifiedDate?: Date): Observable<T[]>;
    public findByModifiedDate(modifiedDate?: Date): Observable<TEntity[]> {
        let lLastModified: string = `2000-01-01T00:00:00Z`;
        if (!ObjectUtil.isNull(modifiedDate)) {
            const fullYear: number = modifiedDate.getFullYear();
            const month: string = (modifiedDate.getMonth() <= 8) ? `0${(modifiedDate.getMonth() + 1)}` : modifiedDate.getMonth().toString();
            const day: string = modifiedDate.getDate().toString();
            lLastModified = `${fullYear}-${month}-${day}T${modifiedDate.getHours()}:${modifiedDate.getSeconds()}:00Z`;
        }
        return this.httpClient.get(this.url(`find-all?lastModifiedDate=${lLastModified}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    /**
     * Refers to the find-all endpoint with filters
     * @type POST
     * @path resource/version/find-all
     */
    public findAll<F extends AbstractFilterModel = any, T = any>(filter: F): Observable<PageModel<T>>;
    public findAll(filter: TFilter): Observable<PageModel<TEntity>> {
        return this.httpClient.post(this.url('find-all'), filter, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    /**
     * Refers to the find-by-value endpoint
     * @type GET
     * @path resource/version/find-by-value
     */
    public findByValue<T = any>(value: string, page: number, size?: number, sort?: SortModel): Observable<PageModel<T>>;
    public findByValue(value: string, page: number, size?: number, sort?: SortModel): Observable<PageModel<TEntity>> {
        let lValue: string = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        } else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort: string = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }

        return this.httpClient.get(this.url(`find-by-value?page=${page}&size=${size}${lSort}&value=${lValue}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    /**
     * Refers to the enable-or-disable endpoint
     * @type PATCH
     * @path resource/version/enable-or-disable/{id}/{action}
     */
    public enableOrDisable(id: GIPIUuid, action: 'DISABLE' | 'ENABLE'): Observable<boolean> {
        if (!UUIDUtil.isValid(id)) {
            return of(false);
        }
        return this.httpClient.patch(this.url(`/enable-or-disable/${id}/${action}`), {}, this.options()).pipe(
            map(() => { return true; }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        )
    }

    /**
     * Refers to the enable-or-disable-all endpoint
     * @type PATCH
     * @path resource/version/enable-or-disable-all/{action}
     */
    public enableOrDisableAll(idList: GIPIUuid[], action: 'DISABLE' | 'ENABLE'): Observable<boolean> {
        for (let i = 0; i < idList.length; i++) {
            if (!UUIDUtil.isValid(idList[i])) {
                return of(false);
            }
        }

        return this.httpClient.patch(this.url(`/enable-or-disable-all/${action}`), idList, this.options()).pipe(
            map(() => { return true; }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        )
    }

    public validate<T = any>(entity: T): string;
    public validate(entity: TEntity): string {
        return '';
    }


}
