/**
 * Enum utilizado para definir a cor de preenhcimento da linha do componente simple-status-cell
 */
export enum COLOR {
    yellow = 'yellow',
    red = 'red',
    blue = 'blue',
    orange = 'orange',
    green = 'green',
    gray = 'gray',
    purple = 'purple',
    darkGreen = 'dark-green'
}

/**
 * Model utilizada pra transferir as informações para o componente simple-status-cell, informe os valores através do @Input
 * Exemplo de uso em: https://angular.io/guide/inputs-outputs
 * @param {COLOR} color    Cor do componente.
 * @param {string} text     Texto do componente.
 */
export interface SimpleStatusCell {
    color: COLOR,
    text: string
}