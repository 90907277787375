import { AbstractModel } from "@gipi-pages/abstract/models/abstract.model";
import { TypeVersionModel } from "@gipi-pages/type-version/models/type-version.model";

export class VersionModel extends AbstractModel {

    /** @Required */
    description: string;

    /** @Required */
    typeVersion: TypeVersionModel;

    /** @Required */
    version: string;

}
