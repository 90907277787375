import { AbstractModel } from "@gipi-pages/abstract/models/abstract.model";

export class FunctionModel extends AbstractModel {

    code: number;

    isMenu: boolean;

    itemMenu: string;

    name: string;

}
