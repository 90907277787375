import { AbstractFilterModel } from "@gipi-pages/abstract/models/abstract-filter.model";
import { GIPIUuid } from "@gipi-shared/types/uuid.type";

export class ClientFilterDTO extends AbstractFilterModel {

    blocked: boolean;

    clientIdsExcluded: GIPIUuid[];

    enabled: boolean;

    hasFinancial: boolean;

}
