<div class="textarea-wrapper">

    <label *ngIf="label"
           class="textarea-label"
           [attr.for]="id">
        {{ label }}
        <span *ngIf="required"> * </span>
    </label>

    <textarea class="textarea-element"
              [attr.id]="id"
              [attr.name]="name"
              [placeholder]="placeholder"
              [cols]="cols"
              [rows]="rows"
              [minLength]="minLength"
              [maxLength]="maxLength"
              [disabled]="disabled"
              [required]="required"
              [readOnly]="readOnly"
              [attr.autocomplete]="autocomplete"
              [autofocus]="autofocus"
              [(ngModel)]="value"
              [value]="value">
    </textarea>

    <small *ngIf="help"
           class="textarea-help">
        {{ help }}
    </small>
</div>