import { animate, state, style, transition, trigger } from '@angular/animations';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatHeaderRowDef, MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';

import { MatCheckboxChange } from '@angular/material/checkbox';
import { SortDirection } from '@gipi-pages/abstract/enums/sort-direction.enum';
import { SortModel } from '@gipi-pages/abstract/models/sort.model';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { TableColumn } from '../table/shared/table-column';

export class TablePaginatorEvent extends PageEvent {

    sort?: SortModel;

    constructor() {
        super();
        this.pageIndex = 0;
        this.pageSize = 0;
        this.length = 0;
        this.previousPageIndex = 0;
    }
}

@Component({
    selector: 'gipi-mat-table',
    exportAs: 'gipiMatTable',
    templateUrl: './mat-table.component.html',
    styleUrls: ['./mat-table.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof MatTableComponent => MatTableComponent),
            multi: true,
        }
    ],
    animations: [
        trigger('rowExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('indicatorRotate', [
            state('collapsed', style({ transform: 'rotate(90deg)' })),
            state('expanded', style({ transform: 'rotate(180deg)' })),
            transition('expanded <=> collapsed', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ],
})
export class MatTableComponent<T> implements OnInit, AfterViewInit, OnChanges {

    @ViewChild(MatSort, { static: true }) private _matSort: MatSort;
    @ViewChild(MatPaginator, { static: false }) private _matPaginator: MatPaginator;

    @ViewChild('matHeaderRow', { static: true }) private _matHeaderRowRef: TemplateRef<MatHeaderRowDef>;
    @ViewChild('matRow', { static: true }) private _matRowRef: TemplateRef<any>;
    @ViewChild('matExpandedRow', { static: true }) private _matExpandedRowRef: TemplateRef<any>;
    @ViewChild('matPaginatorRow', { static: true }) private _matPaginatorRowRef: TemplateRef<any>;

    @ContentChild('actions', { static: false }) actionsRef: TemplateRef<any>;
    @ContentChild('expandedRow', { static: false }) expandedRowRef: TemplateRef<any>;

    private _allSelectionRows: boolean = false;
    public _selection = new SelectionModel<T>(true, []);
    public _expandedRows: T[] = [];
    public _displayedColumns: string[] = [];
    public _hoverCellActions: T = null;

    /** Sort -------------------------------------------------------------------------------------------------------------------------------------- */
    private _tableSort: boolean = false;
    @Input('sort') get tableSort(): boolean {
        return this._tableSort;
    }
    set tableSort(value: boolean) {
        this._tableSort = coerceBooleanProperty(value);
    }

    private _tableClearSort: boolean = false;
    @Input('clearSort') get tableClearSort(): boolean {
        return this._tableClearSort;
    }
    set tableClearSort(value: boolean) {
        this._tableClearSort = coerceBooleanProperty(value);
    }

    private _tableSortActive: string = '';
    @Input('sortActive') get tableSortActive(): string {
        return this._tableSortActive;
    }
    set tableSortActive(value: string) {
        this._tableSortActive = value;
    }

    private _tableSortDirection: SortDirection = '';
    @Input('sortDirection') get tableSortDirection(): SortDirection {
        return this._tableSortDirection;
    }
    set tableSortDirection(value: SortDirection) {
        this._tableSortDirection = value;
    }
    /** ------------------------------------------------------------------------------------------------------------------------------------------- */

    /** Paginator --------------------------------------------------------------------------------------------------------------------------------- */
    private _tableBackendPaginator: boolean = false;
    @Input('paginator') get tableBackendPaginator(): boolean {
        return this._tableBackendPaginator;
    }
    set tableBackendPaginator(value: boolean) {
        this._tableBackendPaginator = coerceBooleanProperty(value);
    }

    private _tableFrontendPaginator: boolean = false;
    @Input('paginatorFrontend') get tableFrontendPaginator(): boolean {
        return this._tableFrontendPaginator;
    }
    set tableFrontendPaginator(value: boolean) {
        this._tableFrontendPaginator = coerceBooleanProperty(value);
    }

    private _tablePaginatorPageIndex: number = 0;
    @Input('pageIndex') get tablePaginatorPageIndex(): number {
        return this._tablePaginatorPageIndex;
    }
    set tablePaginatorPageIndex(value: number) {
        this._tablePaginatorPageIndex = coerceNumberProperty(value);
    }

    private _tablePaginatorPageLength: number = 0;
    @Input('pageLength') get tablePaginatorPageLength(): number {
        return this._tablePaginatorPageLength;
    }
    set tablePaginatorPageLength(value: number) {
        this._tablePaginatorPageLength = coerceNumberProperty(value);
    }

    private _tablePaginatorPageSize: number = 10;
    @Input('pageSize') get tablePaginatorPageSize(): number {
        return this._tablePaginatorPageSize;
    }
    set tablePaginatorPageSize(value: number) {
        this._tablePaginatorPageSize = coerceNumberProperty(value);
    }
    /** ------------------------------------------------------------------------------------------------------------------------------------------- */

    public _dataSource: MatTableDataSource<T> = null;
    @Input('dataSource') get dataSource(): MatTableDataSource<T> {
        return this._dataSource;
    }
    set dataSource(value: MatTableDataSource<T>) {
        this._dataSource = value;
    }

    private _tableControlDataSource: boolean = false;
    @Input('controlDataSource') get tableControlDataSource(): boolean {
        return this._tableControlDataSource;
    }
    set tableControlDataSource(value: boolean) {
        this._tableControlDataSource = coerceBooleanProperty(value);
    }

    private _tableLoading: boolean = false;
    @Input('loading') get tableLoading(): boolean {
        return this._tableLoading;
    }
    set tableLoading(value: boolean) {
        this._tableLoading = coerceBooleanProperty(value);
    }

    private _tableCheckboxable: boolean = false;
    @Input('checkboxable') get tableCheckboxable(): boolean {
        return this._tableCheckboxable;
    }
    set tableCheckboxable(value: boolean) {
        this._tableCheckboxable = coerceBooleanProperty(value);
    }

    private _tablePostionCheckbox: number = 0;
    @Input('postionCheckbox') get tablePostionCheckbox(): number {
        return this._tablePostionCheckbox;
    }
    set tablePostionCheckbox(value: number) {
        this._tablePostionCheckbox = coerceNumberProperty(value);
    }

    private _tableColumns: TableColumn[] = [];
    @Input('columns') get tableColumns(): TableColumn[] {
        return this._tableColumns;
    }
    set tableColumns(value: TableColumn[]) {
        this._tableColumns = value;
    }

    private _tableRows: T[] = [];
    @Input('rows') get tableRows(): T[] {
        return this._tableRows;
    }
    set tableRows(value: T[]) {
        this._tableRows = value;
    }

    private _tableClickOnRow: boolean = false;
    @Input('clickOnRow') get tableClickOnRow(): boolean {
        return this._tableClickOnRow;
    }
    set tableClickOnRow(value: boolean) {
        this._tableClickOnRow = coerceBooleanProperty(value);
    }

    @Input() highlightRowFn: (row: T) => boolean;

    @Output() allRowsSelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() rowsSelectedChange: EventEmitter<T[]> = new EventEmitter<T[]>();
    @Output() rowChange: EventEmitter<T> = new EventEmitter<T>();
    @Output() pageChange: EventEmitter<TablePaginatorEvent> = new EventEmitter<TablePaginatorEvent>();
    @Output() rowHover: EventEmitter<T> = new EventEmitter();
    @Output() rowExpanded: EventEmitter<T> = new EventEmitter();

    public get matTableHeigth(): Observable<number> {
        let headerRowHeight: number;
        if (!ObjectUtil.isNull(this._matHeaderRowRef)) {
            headerRowHeight = (this._matHeaderRowRef.elementRef.nativeElement as HTMLElement).clientHeight;
        }

        let paginatorRowHeight: number;
        if (!ObjectUtil.isNull(this._matPaginatorRowRef)) {
            paginatorRowHeight = (this._matPaginatorRowRef.elementRef.nativeElement as HTMLElement).clientHeight;
        }

        const matHeaderwHeight: number = headerRowHeight || 56;
        const matPaginatorHeight: number = paginatorRowHeight || 56;

        // Tamanho do header
        return of((49 * this.tablePaginatorPageSize) + matHeaderwHeight + matPaginatorHeight);
    }

    constructor(private _changeDetectorRef: ChangeDetectorRef) {
        if (this.tableBackendPaginator && this.tableFrontendPaginator) {
            throw new Error('Pagination control must be via the backend "or" frontend');
        }
    }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        if (!ObjectUtil.isNull(this.dataSource)) {
            if (ObjectUtil.isNull(this.dataSource.sort) && this.tableSort) {
                this.dataSource.sort = this._matSort;
            }

            if (ObjectUtil.isNull(this.dataSource.paginator) && this.tableFrontendPaginator) {
                this.dataSource.paginator = this._matPaginator;
            }
        }

        if (this.tableBackendPaginator) {
            const tablePaginatorEvent: TablePaginatorEvent = new TablePaginatorEvent();
            tablePaginatorEvent.pageSize = this.tablePaginatorPageSize;
            tablePaginatorEvent.sort = new SortModel(this.tableSortActive, this.tableSortDirection);

            this.pageChange.emit(tablePaginatorEvent);
        }

        let displayedColumnsAux: string[] = this.tableColumns.map(c => c.property);
        if (!ObjectUtil.isNull(this.expandedRowRef)) {
            displayedColumnsAux = ['expandedColumn', ...displayedColumnsAux];
        }
        if (this.tableCheckboxable) {
            displayedColumnsAux.splice(1, 0, 'checkboxColumn');
        }
        if (!ObjectUtil.isNull(this.actionsRef)) {
            displayedColumnsAux = [...displayedColumnsAux, 'actionsColumn'];
        }
        this._displayedColumns = displayedColumnsAux;

        this._changeDetectorRef.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.tableControlDataSource) {
            if (ObjectUtil.isNull(this.dataSource)) {
                this._handleTable();
            }
        } else {
            if (!changes.tableRows) {
                this.dataSource = new MatTableDataSource([]);
                this._selection.clear();
                return;
            }

            if (changes.tableRows.isFirstChange() || ObjectUtil.isNull(this.dataSource)) {
                this._handleTable();
            } else {
                this.dataSource.data = ArrayUtil.clone(this.tableRows);
            }
        }

        if (ObjectUtil.isNull(this.dataSource.sort) && this.tableSort && !ObjectUtil.isNull(this._matSort)) {
            this.dataSource.sort = this._matSort;
        }

        if (ObjectUtil.isNull(this.dataSource.paginator) && this.tableFrontendPaginator && !ObjectUtil.isNull(this._matPaginator)) {
            this.dataSource.paginator = this._matPaginator;
        }

        this._changeDetectorRef.detectChanges();
    }

    public isAllSelected(): boolean {
        const numSelected: number = this._selection.selected.length;
        const numRows: number = this.tablePaginatorPageLength;
        return numSelected === numRows;
    }

    public toggleAllRows(event: MatCheckboxChange): void {
        if (ObjectUtil.isNull(event)) {
            return;
        }

        if (this.isAllSelected()) {
            this._selection.clear();
            return;
        }

        this._allSelectionRows = event.checked;

        if (this.tableControlDataSource) {
            this._selection.select(...this.dataSource.data);
        } else {
            this._selection.select(...this.tableRows);
        }
    }

    public handleSelectionRow(row: T): void {
        // if (this.tableClickOnRow) {
        //     this._selection.toggle(row);
        // }

        this.rowChange.emit(row);
    }

    public handleClickOnCell(row: T): void {
        if (this.tableCheckboxable && this.tableClickOnRow) {
            this.handleSelectionRow(row);
        }

        this.rowChange.emit(row);
    }

    public getHighlightRow(row: T): boolean {
        if (!ObjectUtil.isNull(row)) {
            if (!ObjectUtil.isNull(this.highlightRowFn)) {
                return this.highlightRowFn(row);
            }
            return false;
        }
        return false;
    }

    public getIndexRow(row: T): number {
        if (!ObjectUtil.isNull(this.dataSource) && !ArrayUtil.isEmpty(this.dataSource.data) && !ObjectUtil.isNull(row)) {
            return this.dataSource.data.findIndex(r => r === row);
        }
    }

    private _handleTable(): void {
        // Columns
        let displayedColumnsAux: string[] = this.tableColumns.map(c => c.property);
        if (!ObjectUtil.isNull(this.expandedRowRef)) {
            displayedColumnsAux = ['expandedColumn', ...displayedColumnsAux];
        }
        if (this.tableCheckboxable) {
            displayedColumnsAux.splice(1, 0, 'checkboxColumn');
        }
        if (!ObjectUtil.isNull(this.actionsRef)) {
            displayedColumnsAux = [...displayedColumnsAux, 'actionsColumn'];
        }
        this._displayedColumns = displayedColumnsAux;

        // Rows
        if (ObjectUtil.isNull(this.dataSource)) {
            this.dataSource = new MatTableDataSource([]);
        }

        if (!this.tableControlDataSource) {
            this.dataSource.data = ArrayUtil.clone(this.tableRows);
        }

        if (this.tableSort && !ObjectUtil.isNull(this._matSort)) {
            this.dataSource.sort = this._matSort;
        }

        if (this.tableFrontendPaginator && !ObjectUtil.isNull(this._matPaginator)) {
            this.dataSource.paginator = this._matPaginator;
        }
    }

    public handleTableSort(sort: Sort): void {
        if (StringUtil.isEmpty(sort.active) || StringUtil.isEmpty(sort.direction)) {
            return;
        }

        const tablePaginatorEvent: TablePaginatorEvent = new TablePaginatorEvent();
        tablePaginatorEvent.pageSize = this.tablePaginatorPageSize;
        tablePaginatorEvent.sort = new SortModel(sort.active, (sort.direction === 'asc') ? 'ASC' : 'DESC');

        this.pageChange.emit(tablePaginatorEvent);
    }

    public isExpandedRow(row: T): Observable<boolean> {
        if (ArrayUtil.isEmpty(this._expandedRows)) {
            return of(false);
        }

        const index: number = this._expandedRows.findIndex(r => r === row);
        return of(index >= 0);

    }

    public handleExpandRow(row: T): void {
        const index: number = this._expandedRows.findIndex(r => r === row);
        if (ArrayUtil.isEmpty(this._expandedRows) || (index < 0)) {
            this._expandedRows = [row];
            this.rowExpanded.emit(row);
            return;
        }

        const _expandedRowsAux: T[] = ArrayUtil.clone(this._expandedRows);
        _expandedRowsAux.splice(index, 1);
        this._expandedRows = ArrayUtil.clone(_expandedRowsAux);
        this.rowExpanded.emit(null);
    }

}
