import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { NgxMaskModule } from 'ngx-mask';

import { AppMaterialModule } from '../modules/app-material.module';

import { MaxRangeDirective } from './components/date-range-picker/shared/max-range.directive';
import { InputRefDirective } from './components/input-text/shared/input-ref.directive';

import { AbstractComponent } from './components/abstract/abstract.component';
import { ActionRowComponent } from './components/action-row/action-row.component';
import { AlertComponent } from './components/alert/alert.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ClockDialComponent } from './components/clock/clock-dial/clock-dial.component';
import { ClockComponent } from './components/clock/clock.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker.component';
import { FooterComponent } from './components/footer/footer.component';
import { InputSelectEnumComponent } from './components/input-select-enum/input-select-enum.component';
import { InputSelectListboxComponent } from './components/input-select-listbox/input-select-listbox.component';
import { InputSelectComponent } from './components/input-select/input-select.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { LozengeComponent } from './components/lozenge/lozenge.component';
import { MatTableComponent } from './components/mat-table/mat-table.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { SidenavContainerComponent } from './components/sidebar/container/container.component';
import { SidenavComponent } from './components/sidebar/sidenav/sidenav.component';
import { TableComponent } from './components/table/table.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';

@NgModule({
    declarations: [
        ToolbarComponent,
        TopNavComponent,
        UserProfileComponent,
        FooterComponent,
        SidenavContainerComponent,
        SidenavComponent,
        AbstractComponent,
        ActionRowComponent,
        ButtonComponent,
        InputTextComponent,
        InputRefDirective,
        PaginationComponent,
        TableComponent,
        CheckboxComponent,
        InputSelectEnumComponent,
        AlertComponent,
        ProgressBarComponent,
        MatTableComponent,
        LozengeComponent,
        ModalDialogComponent,
        TextareaComponent,
        ClockComponent,
        ClockDialComponent,
        DatetimePickerComponent,
        InputSelectComponent,
        RadioGroupComponent,
        InputSelectListboxComponent,
        DatepickerComponent,
        DateRangePickerComponent,
        MaxRangeDirective,
        TimepickerComponent,
    ],
    exports: [
        ToolbarComponent,
        TopNavComponent,
        UserProfileComponent,
        FooterComponent,
        SidenavContainerComponent,
        SidenavComponent,
        AbstractComponent,
        ActionRowComponent,
        ButtonComponent,
        InputTextComponent,
        InputRefDirective,
        PaginationComponent,
        TableComponent,
        CheckboxComponent,
        InputSelectEnumComponent,
        AlertComponent,
        ProgressBarComponent,
        MatTableComponent,
        LozengeComponent,
        ModalDialogComponent,
        TextareaComponent,
        ClockComponent,
        ClockDialComponent,
        DatetimePickerComponent,
        InputSelectComponent,
        RadioGroupComponent,
        InputSelectListboxComponent,
        DatepickerComponent,
        DateRangePickerComponent,
        MaxRangeDirective,
        TimepickerComponent,
    ],
    imports: [
        AppMaterialModule,
        CommonModule,
        RouterModule,
        FormsModule,
        FlexLayoutModule,
        ScrollingModule,
        NgxMaskModule.forChild(),
        MatDatetimepickerModule,
        MatMomentDatetimeModule,
    ],
    providers: [],
})

export class AppUiModule { }
