import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { NcmVersionModel } from '../../models/ncm-version.model';
import { NcmVersionService } from '../../services/ncm-version.service';

@Component({
    selector: 'gipi-list-ncm-version',
    templateUrl: './list-ncm-version.component.html',
    styleUrls: ['./list-ncm-version.component.scss']
})
export class ListNcmVersionComponent implements OnInit {

    private _pageEvent: PageEvent;

    public dataSource: NcmVersionModel[] = [];

    public isLoading: boolean = true;

    public containsElements: boolean = false;

    public totalElements: number = 0;

    public pageNumber: number = 0; // É o pageIndex

    public pageSize: number = 8; // É o size

    constructor(private _ncmVersionService: NcmVersionService) { }

    ngOnInit(): void {
        setTimeout(() => {
            EventEmitterService.get('EventRefreshNcmVersion').subscribe((data) => {
                this.refreshListNcm(data);
                this.handlePaginator(this._pageEvent);
            });
            this.handlePaginator(this._pageEvent);
        });
    }

    public async handlePaginator(event: PageEvent): Promise<void> {
        this._pageEvent = event;
        if (event) {
            this.pageNumber = event.pageIndex;
        }
        const resultFind: boolean = await this.findNcmVersion();
        if (resultFind) {
            this.containsElements = (this.totalElements > 0);
        } else {
            this.containsElements = false;
        }
    }

    private findNcmVersion(): Promise<boolean> {
        return new Promise(async resolve => {
            // Busca os atrasos de bloqueio do cliente na API
            await this._ncmVersionService.findAllPaginator(this.pageNumber, this.pageSize).then((ncmVersionPagedModel) => {
                this.isLoading = true;

                if (ncmVersionPagedModel) {
                    this.dataSource = ncmVersionPagedModel.content
                    this.totalElements = ncmVersionPagedModel.totalElements;
                }
                this.isLoading = false;
                resolve(true);

            }, (error) => {
                this.isLoading = false;
                resolve(false);
            });
        });
    }

    private refreshListNcm(isRefresh: boolean): void {
        if (isRefresh) {
            this.dataSource = [];
            this.handlePaginator(this._pageEvent);
        }
    }

    public loadStatusColor(daysToEnd: number): string {
        if (daysToEnd > 5) {
            return 'green';
        } else if ((daysToEnd >= 0) && (daysToEnd <= 5)) {
            return 'orange';
        } else if (daysToEnd < 0) {
            return 'red';
        }

        return '';
    }

    public loadStatusIcon(daysToEnd: number): string {
        if (daysToEnd > 5) {
            return 'check_circle_outline';
        } else if ((daysToEnd >= 0) && (daysToEnd <= 5)) {
            return 'notifications_none';
        } else if (daysToEnd < 0) {
            return 'error_outline';
        }

        return '';
    }

    public loadTooltip(daysToEnd: number): string {
        if (daysToEnd === 0) {
            return 'Hoje é o último dia vigente da tabela';
        } else if (daysToEnd === 1) {
            return 'Ainda resta 1 dia para o fim da vigência';
        } if (daysToEnd > 5) {
            return 'Ainda restam ' + daysToEnd.toString() + ' dias para o fim da vigência';
        } else if ((daysToEnd >= 0) && (daysToEnd <= 5)) {
            return 'Ainda restam ' + daysToEnd.toString() + ' dias para o fim da vigência';
        } else if (daysToEnd < 0) {
            return 'Tabela expirou a ' + (daysToEnd * -1).toString() + ' ' + ((daysToEnd * -1) === 1 ? 'dia' : 'dias');
        }

        return '';
    }

}