import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AbstractFormComponent } from '@gipi-pages/abstract/abstract-form.component';
import { ListDialogData } from '@gipi-pages/abstract/abstract-list-dialog.component';
import { SortModel } from '@gipi-pages/abstract/models/sort.model';
import { ClientListDialogComponent } from '@gipi-pages/clients/components/client-list-dialog/client-list-dialog.component';
import { ClientModel } from '@gipi-pages/clients/models/client.model';
import { ClientConsultDTO } from '@gipi-pages/clients/models/dto/client-consult.dto';
import { NotificationTypeFilterDTO } from '@gipi-pages/notification-type/models/dto/notification-type-filter.dto';
import { NotificationTypeModel } from '@gipi-pages/notification-type/models/notification-type.model';
import { NotificationTypeService } from '@gipi-pages/notification-type/services/notification-type.service';
import { NotificationClientFilterClient } from '@gipi-pages/notification/models/notification-client-filter-client.model';
import { NotificationClientFilterModel } from '@gipi-pages/notification/models/notification-client-filter.model';
import { NotificationModel } from '@gipi-pages/notification/models/notification.model';
import { NotificationService } from '@gipi-pages/notification/services/notification.service';
import { GIPIUuid } from '@gipi-shared/types/uuid.type';
import { OptionsSelectedListboxModel } from '@gipi-ui/components/input-select-listbox/shared/options-selected-listbox.model';
import { AlertService } from '@gipi-ui/services/alert.service';
import { DialogService } from '@gipi-ui/services/dialog.service';
import { ModalDialogService } from '@gipi-ui/services/modal-dialog.service';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { DateUtil } from '@gipi-ui/utils/date.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { UUIDUtil } from '@gipi-ui/utils/uuid.util';

@Component({
    selector: 'gipi-notification-form',
    templateUrl: './notification-form.component.html',
    styleUrls: ['./notification-form.component.scss']
})
export class NotificationFormComponent extends AbstractFormComponent<NotificationModel> implements OnInit, OnDestroy {

    private _cloneId: GIPIUuid = '';

    public minStartDate = new Date();

    public _optionsNotificationType: NotificationTypeModel[] = [];

    public predefinedDescription: string = `Confira nossa nova tela de notificações! Personalize cada detalhe: imagem, título, descrição e até o botão
    'Saiba Mais'. Utilize-a para notificar nossos clientes sobre novidades, lembretes e muito mais!`;

    public clientsSelecteds: OptionsSelectedListboxModel<any>;

    clientNameFn = (client: ClientConsultDTO) => {
        return !StringUtil.isEmpty(client.fantasyName) ? client.fantasyName : client.name;
    }

    onSearchClientsFn = (clientsSelected: ClientConsultDTO[]) => {
        const clientListData: ListDialogData<ClientConsultDTO> = {
            selectSingle: false,
            entitiesSelected: clientsSelected,
        };

        const data = this._dialogService.open(ClientListDialogComponent, clientListData, { width: '70%', height: 'auto' });
        return data.afterClosed().toPromise();
    };

    constructor(
        protected service: NotificationService,
        protected router: Router,
        protected modalDialogService: ModalDialogService,
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _alertService: AlertService,
        private _dialogService: DialogService,
        private _notificationTypeService: NotificationTypeService,
    ) {
        super(service, modalDialogService, router);
        this._getNotificationTypeList();

        this._cloneId = this._activatedRoute.snapshot.paramMap.get('id');
        this._verifyClone();
    }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    protected getPath(): string {
        return 'notification';
    }

    protected _newEntity(): NotificationModel {
        const entity: NotificationModel = new NotificationModel();
        entity.startDate = new Date();
        // entity.endDate = new Date();
        // entity.endDate.setHours(entity.startDate.getHours() + 1);
        return entity;
    }

    protected _isValid(): boolean {
        if (ObjectUtil.isNull(this.entity)) {
            return false;
        }

        if (ObjectUtil.isNull(this.entity.type)) {
            this._alertService.addWarningMessage('Campo tipo de notificação é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.entity.title)) {
            this._alertService.addWarningMessage('Campo título é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.entity.description)) {
            this._alertService.addWarningMessage('Campo descrição é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.entity.startDate)) {
            this._alertService.addWarningMessage('Campo data inicial é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.entity.endDate)) {
            this._alertService.addWarningMessage('Campo data final é obrigatório e não foi informado');
            return false;
        }
        if (DateUtil.isGreaterThan(this.entity.startDate, this.entity.endDate)) {
            this._alertService.addWarningMessage('Campo data inicial não pode ser posterior a data final');
            return false;
        }

        return true;
    }

    private async _verifyClone(): Promise<void> {
        try {
            if (UUIDUtil.isValid(this._cloneId)) {
                this.loading = true;
                const entityToClone: NotificationModel = await this.service.getOne(this._cloneId).toPromise();

                if (ObjectUtil.isNull(entityToClone)) {
                    this._alertService.addErrorMessage('Ocorreu um erro ao clonar o registro')
                    this.loading = false;
                    return;
                }

                this.entity = this._newEntity();
                this.entity.type = entityToClone.type;
                this.entity.title = entityToClone.title;
                this.entity.enabled = true;
                this.optionSituationValue = 'ENABLED';
                this.entity.description = entityToClone.description;
                this.entity.detailUrl = entityToClone.detailUrl;

                if (!ObjectUtil.isNull(entityToClone.notificationClientFilter)) {
                    this.clientsSelecteds = new OptionsSelectedListboxModel<any>();
                    this.clientsSelecteds.filter = entityToClone.notificationClientFilter.filterType;
                    this.clientsSelecteds.options = [];

                    if (!ArrayUtil.isEmpty(entityToClone.notificationClientFilter.clientFilterList)) {
                        const clientFilterList: NotificationClientFilterClient[] = ArrayUtil.clone(entityToClone.notificationClientFilter.clientFilterList);
                        for (let i = 0; i < clientFilterList.length; i++) {
                            this.clientsSelecteds.options.push(ClientConsultDTO.clientToDTO(clientFilterList[i].client));
                        }
                    }
                }

                this.entity.startDate = new Date();
                this.entity.prioritizeVisualization = entityToClone.prioritizeVisualization;
            }

            this.loading = false;
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        }
    }

    private _getNotificationTypeList(): void {
        const notificationTypeFilter: NotificationTypeFilterDTO = new NotificationTypeFilterDTO();
        notificationTypeFilter.enabled = true;
        notificationTypeFilter.pageNumber = 0;
        notificationTypeFilter.pageSize = 200;
        notificationTypeFilter.researchField = '';
        notificationTypeFilter.sorts = [new SortModel('description', 'ASC')];

        this._notificationTypeService.findAll(notificationTypeFilter).toPromise().then(page => {
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                this._optionsNotificationType = [...page.content];
            }
        }).catch(error => {
            this._alertService.handleError(error);
        });
    }

    public noRedirect(event: MouseEvent): void {
        if (
            ObjectUtil.isNull(this.entity) ||
            (!ObjectUtil.isNull(this.entity) && StringUtil.isEmpty(this.entity.detailUrl))
        ) {
            event.preventDefault();
        }
    }

    public validateEndDate(): void {
        if (!ObjectUtil.isNull(this.entity.startDate) && !ObjectUtil.isNull(this.entity.endDate)) {
            if (DateUtil.isGreaterThan(this.entity.startDate, this.entity.endDate)) {
                this.entity.endDate = null;
            }
        }
    }

    public validateStartDate(): void {
        if (!ObjectUtil.isNull(this.entity.startDate) && !ObjectUtil.isNull(this.entity.endDate)) {
            if (DateUtil.isLessThan(this.entity.endDate, this.entity.startDate)) {
                this.entity.endDate = null;
            }
        }
    }

    public save(): void {
        try {
            if (!this._isValid()) {
                return;
            }

            if (!ObjectUtil.isNull(this.clientsSelecteds)) {
                this.entity.notificationClientFilter = new NotificationClientFilterModel();
                this.entity.notificationClientFilter.filterType = this.clientsSelecteds.filter;
                this.entity.notificationClientFilter.clientFilterList = [];

                if (!ArrayUtil.isEmpty(this.clientsSelecteds.options)) {
                    for (let i = 0; i < this.clientsSelecteds.options.length; i++) {
                        const clientFilter: NotificationClientFilterClient = new NotificationClientFilterClient();
                        clientFilter.client = new ClientModel(this.clientsSelecteds.options[i].id);
                        this.entity.notificationClientFilter.clientFilterList.push(clientFilter);
                    }
                }
            }

            this.service.save(this.entity).toPromise().then(() => {
                this._alertService.addSuccessMessage('Operação realizada com sucesso');
                this.loading = false;
                this.router.navigateByUrl(this.getPath());
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        }
    }

}
