import { AbstractModel } from "@gipi-pages/abstract/models/abstract.model";
import { UserStatus } from "../enums/user-status.enum";
import { RoleModel } from "./role.model";

export class UserModel extends AbstractModel {

    email: string;

    name: string;

    password: string;

    roles: RoleModel[];

    startTime: string;

    status: UserStatus;

    stopTime: string;

}
