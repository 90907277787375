import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '@gipi-ui/services/alert.service';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private isAccessDenied: boolean = false;

    constructor(
        private _alertService: AlertService,
        private _authenticationService: AuthenticationService,
        private _activatedRoute: ActivatedRoute
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // 401 - Unauthorized
        // 400 - Bad Request
        // 408 - Request Timeout
        // 429 - Too Many Requests
        // 500 - Internal Server Error
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {

            let lErrorMessage: string = '';

            if (err.error instanceof ErrorEvent) {
                // Erro do lado do cliente
                lErrorMessage = `Erro: ${err.error.message}`;
            } else {

                // Ocorreu erro no lado do servidor
                if (err.error.errors !== undefined) {

                    if (err.status.toString() === '500') {
                        lErrorMessage = 'Ops! Ocorreu um erro ao tentar acessar a API';
                    } else {
                        const lError: string = err.error.errors[0];
                        if (lError.toUpperCase().includes('[404 NOT FOUND] DURING [POST]')) {
                            lErrorMessage = 'O recurso solicitado não foi encontrado. Tente novamente em alguns instantes.';
                        } else {
                            lErrorMessage = lError.replace('.', '');
                        }
                    }

                } else {

                    switch (err.status) {
                        case 200: {
                            lErrorMessage = 'A requisição foi bem sucedida';
                            break;
                        }
                        case 201: {
                            lErrorMessage = 'A requisição foi bem sucedida';
                            break;
                        }
                        case 202: {
                            lErrorMessage = 'A requisição foi recebida mas nenhuma ação foi tomada sobre ela';
                            break;
                        }
                        case 400: {
                            lErrorMessage = 'A requisição está com uma sintaxe inválida';
                            break;
                        }
                        case 401: {
                            lErrorMessage = 'Usuário ou senha inválidos';
                            break;
                        }
                        case 403: {
                            // Valida nessa função pois se o retorno for o 403 e o motivo for acesso fora do horário
                            // previsto ele deverá ser redirecionado para o login. E se o retorno for 403 e o motivo
                            // for apenas por tentar acessar uma tela que não tem permissão, o mesmo deverá ser redirecionado
                            // para a tela principal.
                            const { error } = err.error;

                            if (error !== undefined) {
                                if (error === 'invalid_request') {
                                    lErrorMessage = 'Login fora do horário previsto';
                                    this.isAccessDenied = false;
                                } else if (error === 'access_denied') {
                                    lErrorMessage = 'Você não possui permissão acesso';
                                    this.isAccessDenied = true;
                                }
                            }

                            break;
                        }
                        case 404: {
                            lErrorMessage = 'Não é possível encontrar o recurso solicitado';
                            break;
                        }
                        case 408: {
                            lErrorMessage = 'Conexão ociosa, portanto será derrubada';
                            break;
                        }
                        case 412: {
                            lErrorMessage = 'Foi indicado nos seus cabeçalhos pré-condições que o servidor não atende';
                            break;
                        }
                        case 429: {
                            lErrorMessage = 'Foi enviado muitas requisições num dado tempo';
                            break;
                        }
                        case 500: {
                            lErrorMessage = 'Ops! Ocorreu um erro ao tentar acessar a API';
                            break;
                        }
                        case 504: {
                            lErrorMessage = 'Resposta não obtida a tempo, portanto será derrubada';
                            break;
                        }
                        default: {
                            lErrorMessage = 'Ocorreu um erro inesperado';
                            break;
                        }
                    }

                }

            }

            if (!lErrorMessage.toUpperCase().includes('OBJETO NÃO ENCONTRADO')) {
                this._alertService.addErrorMessage(lErrorMessage);

                // Só entra se o isAccessDenied === false, pois ai trata erro 401 e 403.
                // Se for isAccessDenied === true, só mostra a mensagem.
                if (!this.isAccessDenied) {

                    if ([401, 403].indexOf(err.status) !== -1) {
                        // logout automático se a resposta 401 for retornada da api
                        this._authenticationService.logout();
                    }

                }

                const error = !StringUtil.isEmpty(lErrorMessage) ? lErrorMessage : (err.error.message || err.statusText);
                return throwError(error);
            } else {
                return throwError('');
            }

        }));

    }

}
