import { AbstractModel } from "@gipi-pages/abstract/models/abstract.model";

export class TypeVersionModel extends AbstractModel {

    /** @Required */
    description: string;

    /** @Required */
    initials: string;

}
