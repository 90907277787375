import { AbstractModel } from "@gipi-pages/abstract/models/abstract.model";
import { TypeFilterClient } from "../enums/type-filter-client.enum";
import { NotificationClientFilterClient } from "./notification-client-filter-client.model";

export class NotificationClientFilterModel extends AbstractModel {

    clientFilterList: NotificationClientFilterClient[];

    filterType: TypeFilterClient;

    constructor() {
        super();
        this.clientFilterList = [];
    }

}
