import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface HeaderExcel {
    name: string;
    property: string;
    superiorProperty?: string;
}

@Injectable({ providedIn: 'root' })
export class ExcelService {

    constructor() { }

    public exportExcelFile(header: HeaderExcel[], json: any[], excelFileName: string): void {
        const heading: string[][] = [header.map(h => h.name)];

        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, heading);

        const range: XLSX.Range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let i: number = range.s.c; i <= range.e.c; ++i) {
            const column: string = XLSX.utils.encode_col(i) + 1;
            if (!worksheet[column]) {
                continue;
            }

            const cell = {
                v: worksheet[column].v.toUpperCase(),
                s: {
                    fill: {
                        foregroundColor: { rgb: 'FF6666' }
                    },
                    font: {
                        size: 16,
                        bold: true,
                    }
                }
            }
            worksheet[column] = cell;
        }

        const wscols: { wch: number; }[] = [
            { wch: 30 },
            { wch: 30 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
        ];
        worksheet['!cols'] = wscols;

        XLSX.utils.sheet_add_json(worksheet, json, { origin: 'A2', skipHeader: true, cellStyles: true });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        this._saveAsExcelFile(excelBuffer, excelFileName);
    }

    private _saveAsExcelFile(excelBuffer: any, excelFileName: string): void {
        const file: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });

        const now: Date = new Date();
        const month: string = ((now.getMonth() + 1) <= 9) ? String('0' + (now.getMonth() + 1)) : String(now.getMonth() + 1);
        const minutes: string = (now.getMinutes() <= 9) ? String('0' + now.getMinutes()) : String(now.getMinutes());
        const fileName: string = `${excelFileName}-gipi-apoio-${now.getDate()}-${month}-${now.getFullYear()}-${now.getHours()}-${minutes}-${now.getSeconds()}${EXCEL_EXTENSION}`;

        const htmlAnchorElement: HTMLAnchorElement = window.document.createElement('a');
        htmlAnchorElement.href = window.URL.createObjectURL(file);
        htmlAnchorElement.download = fileName;
        document.body.appendChild(htmlAnchorElement);
        htmlAnchorElement.click();
        document.body.removeChild(htmlAnchorElement);
    }

}
