import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ObjectUtil } from '@gipi-ui/utils/object.util';

let nextUniqueId = 0;

@Component({
    selector: 'gipi-radio-group',
    exportAs: 'gipiRadioGroup',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof RadioGroupComponent => RadioGroupComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-radio-group'
    }
})
export class RadioGroupComponent implements OnInit, ControlValueAccessor {

    private _name: string = `gipi-radio-group-${nextUniqueId++}`;

    public _enumList: any[] = [];

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    @Input() label: string = '';

    @Input() placeholder: string = '';

    @Input() help: string = '';

    private _required: boolean = false;
    @Input() get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    private _disabled: boolean = false;
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _value: any;
    @Input() get value(): any {
        return this._value;
    }
    set value(value: any) {
        this._value = value;
        this.onChange(this._value);
        this.onTouched(this._value);
    }

    private _enum: any;
    @Input() get enum(): any {
        return this._enum;
    }
    set enum(value: any) {
        this._enum = value;

        const enumAux = Object.keys(value).map(key => ({ key: key, value: value[key] }));
        if (!ObjectUtil.isNull(this.valuesExclud)) {
            for (let i = 0; i < enumAux.length; i++) {
                const excludEnum: boolean = this.valuesExclud.some(e => e === enumAux[i].key);
                if (!excludEnum) {
                    this._enumList.push(enumAux[i]);
                }
            }
        } else {
            this._enumList = enumAux;
        }
    }

    @Input() valuesExclud: string[];

    @Input() valuesOmit: string[];

    @Input() align: 'row' | 'column' = 'row';

    onChange: Function = () => { };

    onTouched: Function = () => { };

    constructor(
        public elementRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }

    public validateOmitByKey(key: string): boolean {
        return !ObjectUtil.isNull(this.valuesOmit) ? !this.valuesOmit.some(el => el === key) : true;
    }

    public onButtonSelectOptionClick(key: string): void {
        this.value = key;
        this._changeDetectorRef.detectChanges();
    }

}
