import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractFormDialogComponent, FormDialogData } from '@gipi-pages/abstract/abstract-form-dialog.component';
import { FunctionIsMenu, FunctionIsMenuEnum } from '@gipi-pages/function/enum/function-is-menu.enum';
import { FunctionModel } from '@gipi-pages/function/models/function.model';
import { FunctionService } from '@gipi-pages/function/services/function.service';
import { AlertService } from '@gipi-ui/services/alert.service';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';

@Component({
    selector: 'gipi-function-form-dialog',
    templateUrl: './function-form-dialog.component.html',
    styleUrls: ['./function-form-dialog.component.scss']
})
export class FunctionFormDialogComponent extends AbstractFormDialogComponent<FunctionModel> implements OnInit {

    public functionIsMenuEnum: typeof FunctionIsMenuEnum = FunctionIsMenuEnum;
    public functionIsMenuValue: FunctionIsMenu = 'YES';

    constructor(
        protected service: FunctionService,
        protected dialogRef: MatDialogRef<FunctionFormDialogComponent>,
        private _alertService: AlertService,
        @Inject(MAT_DIALOG_DATA) public data: FormDialogData<FunctionModel> = { typeOperation: 'NEW', entity: null },
    ) {
        super(service, dialogRef, data);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if ((this.typeOperation !== 'NEW') && !ObjectUtil.isNull(this.entity)) {
            this.functionIsMenuValue = this.entity.isMenu ? 'YES' : 'NO';
        }
    }

    protected newEntity(): FunctionModel {
        return new FunctionModel();
    }

    protected isValid(): boolean {
        if (ObjectUtil.isNull(this.entity)) {
            return false;
        }
        if (!this.entity.code) {
            this._alertService.addWarningMessage('Campo código é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.entity.name)) {
            this._alertService.addWarningMessage('Campo nome da função é obrigatório e não foi informado');
            return false;
        }
        if (this.entity.name.length < 3) {
            this._alertService.addWarningMessage('Campo descrição deve conter no mínimo 3 caracteres');
            return false;
        }
        if (StringUtil.isEmpty(this.entity.itemMenu)) {
            this._alertService.addWarningMessage('Campo identificação da função é obrigatório e não foi informado');
            return false;
        }

        return true;
    }

    public confirm(): void {
        try {
            if (!this.isValid()) {
                return;
            }

            this.loading = true;

            this.entity.isMenu = (this.functionIsMenuValue === 'YES');
            this.entity.enabled = (this.optionSituationValue === 'ENABLED');

            this.service.save(this.entity).toPromise().then(() => {
                this._alertService.addSuccessMessage('Operação realizada com sucesso');
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        }
    }

}
