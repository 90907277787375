import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AbstractFormDialogComponent, FormDialogData } from '@gipi-pages/abstract/abstract-form-dialog.component';
import { ConfigurationModel } from '@gipi-pages/configuration/models/configuration.model';
import { ConfigurationService } from '@gipi-pages/configuration/services/configuration.service';
import { AlertService } from '@gipi-ui/services/alert.service';
import { ObjectUtil } from '@gipi-ui/utils/object.util';

@Component({
    selector: 'gipi-configuration-form-dialog',
    templateUrl: './configuration-form-dialog.component.html',
    styleUrls: ['./configuration-form-dialog.component.scss']
})
export class ConfigurationFormDialogComponent extends AbstractFormDialogComponent<ConfigurationModel> implements OnInit {

    constructor(
        private _alertService: AlertService,
        protected service: ConfigurationService,
        protected dialogRef: MatDialogRef<ConfigurationFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FormDialogData<ConfigurationModel> = { typeOperation: 'NEW', entity: null },
    ) {
        super(service, dialogRef, data);
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected newEntity(): ConfigurationModel {
        return new ConfigurationModel();
    }

    protected isValid(): boolean {
        if (ObjectUtil.isNull(this.entity)) {
            return false;
        }
        if (ObjectUtil.isNull(this.entity.maximumQuantityOfMonthlyLicenseExtension) || (this.entity.maximumQuantityOfMonthlyLicenseExtension < 0)) {
            this._alertService.addWarningMessage('Campo quantidade máxima é obrigatório e não foi informado');
            return false;
        }

        return true;
    }

    public confirm(): void {
        try {
            if (!this.isValid()) {
                return;
            }
            this.loading = true;

            this.service.save(this.entity).toPromise().then(() => {
                this._alertService.addSuccessMessage('Operação realizada com sucesso');
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        }
    }

    public isValidImageLink(link: string): boolean {
        const regex = /^(ftp|http|https):\/\/[^ "]+$/;
        return regex.test(link);
    }

}
