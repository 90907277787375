import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  constructor() { }

  getShowSubmenuUpdateTables(): boolean {
    if (sessionStorage.getItem('showSubmenuUpdateTables') === 'true') {
      return true;
    } else {
      return false;
    }
  }

  setShowSubmenuUpdateTables(value: boolean): void {
    if (value) {
      sessionStorage.setItem('showSubmenuUpdateTables', 'true');
    } else {
      sessionStorage.setItem('showSubmenuUpdateTables', 'false');
    }
  }

  getSubmenuRouterActive(): boolean {
    if (sessionStorage.getItem('isSubmenuUpdateTablesRouterActive') === 'true') {
      return true;
    } else {
      return false;
    }
  }

  setSubmenuRouterActive(value: boolean): void {
    if (value) {
      sessionStorage.setItem('isSubmenuUpdateTablesRouterActive', 'true');
    } else {
      sessionStorage.setItem('isSubmenuUpdateTablesRouterActive', 'false');
    }
  }

  public removeConfigSessionStorage(): void {
    sessionStorage.removeItem('showSubmenuUpdateTables');
    sessionStorage.removeItem('isSubmenuUpdateTablesRouterActive');
  }

}
