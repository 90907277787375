import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { ClientModel } from '../../models/client.model';
import { LicenseGeneratorModel } from './models/license-generator.model';
import { LicenseGeneratorService } from './services/license-generator.service';

export interface ClientLicenseGeneratorModel {
    client: ClientModel;
}

@Component({
    selector: 'gipi-dialog-license-generator',
    templateUrl: './dialog-license-generator.component.html',
    styleUrls: ['./dialog-license-generator.component.scss']
})
export class DialogLicenseGeneratorComponent implements OnInit {

    public typeLicense: number = HttpClientBase.typeLicense;

    public maskSummarized: string = 'A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4}';

    public maskComplete: string = 'A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4} - A{4}';

    public nameClient: string = '';

    public cnpjOrCpfClient: string = '';

    public licenseNumber: string = '';

    public reason: string = '';

    public generationTimeDate: Date;

    public valid: boolean = true;

    public isLoading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DialogLicenseGeneratorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ClientLicenseGeneratorModel,
        private _licenseGeneratorService: LicenseGeneratorService
    ) {
        this.isLoading = true;
        dialogRef.disableClose = true;
        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close(false);
        });
    }

    ngOnInit(): void {
        setTimeout(() => this.license());
    }

    private license(): void {
        if (this.data.client) {
            this.nameClient = this.data.client.person.name;
            this.cnpjOrCpfClient = (this.data.client.person.legalPerson ? this.data.client.person.legalPerson.cnpj : this.data.client.person.naturalPerson.cpf);
            this.licenseGenerator();
        }
        this.isLoading = false;
    }

    private licenseGenerator(): Promise<LicenseGeneratorModel> {
        return new Promise(async resolve => {
            // TypeLicense === 0 ---> SUMMARIZED
            // TypeLicense === 1 ---> COMPLETE
            await this._licenseGeneratorService.licenseGenerator(this.cnpjOrCpfClient, this.typeLicense).then((resultLicenseGeneratorModel) => {
                if (resultLicenseGeneratorModel) {
                    this.licenseNumber = resultLicenseGeneratorModel.licenseNumber;
                    this.reason = resultLicenseGeneratorModel.reason;
                    this.generationTimeDate = resultLicenseGeneratorModel.generationTimeDate;
                    this.valid = resultLicenseGeneratorModel.valid;
                } else {
                    resolve(null);
                }
            }, (error) => {
                resolve(null);
            });
        });
    }

    public onClickSelectValueInput(event: any): void {
        event.target.select();
    }

    public onClickGoBack(): void {
        this.dialogRef.close(false);
    }

}
