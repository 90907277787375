<gipi-abstract id="dialogRegisterNotificationType"
               name="dialogRegisterNotificationType"
               [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterNotificationType">
        Cadastrar tipo de notificação
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-text label="Descrição"
                             id="notificationTypeDescription"
                             name="notificationTypeDescription"
                             [required]="true"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [(ngModel)]="entity.description"
                             [min]="3"
                             [max]="100"
                             [maxLength]="100"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-input-text>
            <gipi-input-select-enum label="Situação"
                                    id="notificationTypeEnabled"
                                    name="notificationTypeEnabled"
                                    [disabled]="loading || (typeOperation !== 'EDIT')"
                                    [required]="true"
                                    [valuesExclud]="[]"
                                    [enum]="optionSituationEnum"
                                    [(ngModel)]="optionSituationValue"
                                    fxFlex="30"
                                    fxFlex.lt-md="100">
            </gipi-input-select-enum>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-text label="URL da imagem"
                             id="notificationTypeImageUrl"
                             name="notificationTypeImageUrl"
                             [iconSuffix]="!entity?.imageUrl && 'link'"
                             [showSuffix]="entity?.imageUrl"
                             [required]="true"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [pattern]="'https?://.+'"
                             [(ngModel)]="entity.imageUrl"
                             [min]="3"
                             [max]="200"
                             [maxLength]="200"
                             fxFlex
                             fxFlex.lt-md="100">

                <gipi-button tooltip="Remover"
                             gipi-icon
                             suffix
                             *ngIf="entity?.imageUrl"
                             id="btnClearImageUrl"
                             icon="close"
                             [forInput]="true"
                             [disabled]="(typeOperation === 'VIEW') || (typeOperation === 'EDIT')"
                             (click)="entity.imageUrl = ''">
                </gipi-button>

            </gipi-input-text>
        </div>
        <div class="image-type">
            <img [attr.src]="entity?.imageUrl || '../../../../../assets/logo/type-notification.svg'"
                 alt="Imagem para notificação"
                 width="550"
                 height="260"
                 style="object-fit: cover;">

            <button class="button-close">
                <mat-icon>close</mat-icon>
            </button>

            <div class="img-dimension"
                 matTooltip="Imagens de dimensões menores podem ficar pixeladas">
                550 x 260
            </div>
        </div>

    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract>