import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TypeAuthorities } from '@gipi-ui/enums/enum-authorities.enum';
import { MdePopoverTrigger } from '@material-extended/mde';

import { ValidateAccessService } from '@gipi-ui/services/validate-access.service';
import { TaskCheckbox } from 'src/app/shared/models/task-checkbox.model';
import { TaskRadiobutton } from 'src/app/shared/models/task-radiobutton.model';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';

@Component({
    selector: 'gipi-update-clients',
    templateUrl: './update-clients.component.html',
    styleUrls: ['./update-clients.component.scss']
})
export class UpdateClientsComponent implements OnInit {

    public allComplete: boolean = true;

    public taskCheckBox: TaskCheckbox = {
        name: 'Todos',
        completed: true,
        nameStatus: '',
        subtasks: [
            { name: 'Atualizados', nameStatus: 'UPDATED', completed: true },
            { name: 'Desatualizados', nameStatus: 'NOTUPDATED', completed: true },
            { name: 'A atualizar', nameStatus: 'UPDATE', completed: true },
            { name: 'A liberar', nameStatus: 'RELEASE', completed: true },
            { name: 'Sem terminal', nameStatus: 'NOTERMINAL', completed: true },
            { name: 'Sem aplicativos', nameStatus: 'NOAPPLICATION', completed: true },
            { name: 'Versão superior', nameStatus: 'TOPVERSION', completed: true },
            // { name: 'Bloqueados', nameStatus: 'BLOCKED', completed: true },
            // { name: 'Clientes migrados', nameStatus: 'MIGRATED_CLIENT', completed: true },
        ]
    };

    public listTaskRadiobuttonMigrated: TaskRadiobutton[] = [
        { id: 1, name: 'Todos', nameStatus: 'ALL_CLIENTS', checked: true },
        { id: 2, name: 'Migrados', nameStatus: 'HAS_FINANCIAL', checked: false },
        { id: 3, name: 'Não migrados', nameStatus: 'NOT_HAS_FINANCIAL', checked: false },
    ];
    public taskRadiobuttonMigrated: TaskRadiobutton = this.listTaskRadiobuttonMigrated[0];

    public listTaskRadiobuttonBlocked: TaskRadiobutton[] = [
        { id: 1, name: 'Todos', nameStatus: 'ALL_CLIENTS', checked: true },
        { id: 2, name: 'Bloqueados', nameStatus: 'BLOCKED', checked: false },
        { id: 3, name: 'Não bloqueados', nameStatus: 'NOT_BLOCKED', checked: false },
    ];
    public taskRadiobuttonBlocked: TaskRadiobutton = this.listTaskRadiobuttonBlocked[0];

    public valueSelectAction: FormControl = new FormControl();

    public taskActions = [
        { name: 'Liberar atualização', action: 'RELEASE_VERSION' },
        { name: 'Cancelar atualização', action: 'PREVIOUS_VERSION' },
        { name: 'Liberar Contingência', action: 'RELEASE_CONTINGENCY' }
    ]

    public flag: boolean = false;

    public valueInput: string = '';

    public valueInputSelectedCount: number;

    public valueLoadingTable: boolean = true;

    @ViewChild(MdePopoverTrigger) mdePopoverTrigger: MdePopoverTrigger;

    constructor(private _validateAccessService: ValidateAccessService) { }

    ngOnInit(): void { }

    public validateAccess(authorities: TypeAuthorities): boolean {
        return this._validateAccessService.validateAccess(authorities);
    }

    public filterValue(value: string): void {
        EventEmitterService.get('EventRadiobuttonObjectClientsMigrated').emit(this.taskRadiobuttonMigrated);
        EventEmitterService.get('EventRadiobuttonObjectClientsBlocked').emit(this.taskRadiobuttonBlocked);
        EventEmitterService.get('EventCheckBoxObjectClients').emit(this.taskCheckBox);
        EventEmitterService.get('EventInputValueClients').emit(value);
    }

    public onClickExportExcelClients(): void {
        EventEmitterService.get('EventExportExcelClients').emit(true);
    }

    public onClickRefreshUpdateClients(): void {
        // this.taskRadiobuttonMigrated = this.listTaskRadiobuttonMigrated[0];
        // this.taskRadiobuttonBlocked = this.listTaskRadiobuttonBlocked[0];

        EventEmitterService.get('EventRefreshUpdateClients').emit(true);
        EventEmitterService.get('EventRadiobuttonObjectClientsMigrated').emit(this.taskRadiobuttonMigrated);
        EventEmitterService.get('EventRadiobuttonObjectClientsBlocked').emit(this.taskRadiobuttonBlocked);
        EventEmitterService.get('EventCheckBoxObjectClients').emit(this.taskCheckBox);
        const inputFindClients = document.getElementById('inputFindClients') as HTMLInputElement;
        EventEmitterService.get('EventInputValueClients').emit(inputFindClients.value);

        // inputFindClients.value = '';
    }

    public onClickAction(): void {
        EventEmitterService.get('EventActionUpdateClients').emit(this.valueSelectAction.value);
        this.flag = true;
        this.valueSelectAction.setValue('');
        this.mdePopoverTrigger.closePopover();
    }

    public cancelPopover(): void {
        this.flag = true;
    }

    public refreshReadOnlyInput(value: boolean): void {
        this.valueLoadingTable = value;
    }

    public selectCheckbox(value: string): void {
        EventEmitterService.get('EventInputValueCheckboxClients').emit(Number(value));
    }

    public refreshValueSelectedCount(value: number): void {
        this.valueInputSelectedCount = value;
    }

    public updateAllComplete(): void {
        this.allComplete = this.taskCheckBox.subtasks !== null && this.taskCheckBox.subtasks.every((task) => task.completed);
    }

    public someComplete(): boolean {
        if (this.taskCheckBox.subtasks === null) {
            return false;
        }
        return this.taskCheckBox.subtasks.filter((task) => task.completed).length > 0 && !this.allComplete;
    }

    public setAll(completed: boolean): void {
        this.allComplete = completed;
        if (this.taskCheckBox.subtasks === null) {
            return;
        }
        this.taskCheckBox.subtasks.forEach(task => task.completed = completed);
    }

    public filterOptionsValue(value: string): void {
        this.listTaskRadiobuttonMigrated.forEach(r => r.checked = false);
        this.taskRadiobuttonMigrated.checked = true;

        this.listTaskRadiobuttonBlocked.forEach(r => r.checked = false);
        this.taskRadiobuttonBlocked.checked = true;

        this.mdePopoverTrigger.closePopover();
        this.filterValue(value);
    }

}
