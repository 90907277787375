<div class="input-wrapper">

    <label *ngIf="label"
           class="input-label"
           [attr.for]="id">
        {{ label }}
        <span *ngIf="required"> * </span>
    </label>

    <div class="input-content"
         [class.input-select]="type === 'select'">
        <div *ngIf="(showPrefix || iconPrefix || svgIconPrefix) && (type !== 'select')"
             class="prefix">
            <ng-container *ngIf="iconPrefix || svgIconPrefix; else templatePrefix">
                <mat-icon *ngIf="iconPrefix">
                    {{ iconPrefix }}
                </mat-icon>
                <mat-icon *ngIf="svgIconPrefix"
                          svgIcon="svgIconPrefix">
                    {{ svgIconPrefix }}
                </mat-icon>
            </ng-container>

            <ng-template #templatePrefix>
                <ng-content select="[prefix]"> </ng-content>
            </ng-template>
        </div>

        <input inputRef
               class="input-element"
               [attr.id]="id"
               [attr.name]="name"
               [type]="type"
               [placeholder]="placeholder"
               [mask]="mask"
               [min]="min"
               [max]="max"
               [minLength]="minLength"
               [maxLength]="maxLength"
               [disabled]="disabled"
               [required]="required"
               [readOnly]="readOnly"
               [autocomplete]="autocomplete"
               [autofocus]="autofocus"
               [pattern]="pattern"
               [(ngModel)]="value"
               [value]="value"
               (keyup)="onKeyup.emit($event)"
               (blur)="onBlur.emit($event)" />

        <div *ngIf="(showSuffix || iconSuffix || svgIconSuffix) && (type !== 'select')"
             class="suffix">
            <ng-container *ngIf="iconSuffix || svgIconSuffix; else templateSuffix">
                <mat-icon *ngIf="iconSuffix">
                    {{ iconSuffix }}
                </mat-icon>
                <mat-icon *ngIf="svgIconSuffix"
                          [svgIcon]="svgIconSuffix">
                </mat-icon>
            </ng-container>

            <ng-template #templateSuffix>
                <ng-content select="[suffix]"> </ng-content>
            </ng-template>
        </div>

        <div *ngIf="type === 'select'"
             class="suffix">
            <mat-icon>
                keyboard_arrow_down
            </mat-icon>
        </div>

    </div>

    <small *ngIf="help"
           class="input-help">
        {{ help }}
    </small>
</div>