import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;

@Component({
    selector: 'gipi-textarea',
    exportAs: 'gipiTextarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof TextareaComponent => TextareaComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-textarea'
    }
})
export class TextareaComponent implements OnInit, ControlValueAccessor {

    private _name: string = `gipi-textarea-${nextUniqueId++}`;

    @Input() id: string = this._name;

    @Input() name: string = this._name;

    @Input() label: string = '';

    @Input() placeholder: string = '';

    @Input() cols: number = 20;

    @Input() rows: number = 4;

    @Input() help: string = '';

    @Input() maxLength: number = 254;

    @Input() minLength: number = 0;

    private _autocomplete: 'on' | 'off' = 'off';
    @Input() get autocomplete(): 'on' | 'off' {
        return this._autocomplete;
    }
    set autocomplete(value: 'on' | 'off') {
        this._autocomplete = value;
    }

    private _autofocus: boolean = false;
    @Input() get autofocus(): boolean {
        return this._autofocus;
    }
    set autofocus(value: boolean) {
        this._autofocus = coerceBooleanProperty(value);
    }

    private _readOnly: boolean = false;
    @Input() get readOnly(): boolean {
        return this._readOnly;
    }
    set readOnly(value: boolean) {
        this._readOnly = coerceBooleanProperty(value);
    }

    private _required: boolean = false;
    @Input() get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    private _disabled: boolean = false;
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _lowerCase: boolean = false;
    @Input() get lowerCase(): boolean {
        return this._lowerCase;
    }
    set lowerCase(value: boolean) {
        this._lowerCase = coerceBooleanProperty(value);
    }

    private _upperCase: boolean = false;
    @Input() get upperCase(): boolean {
        return this._upperCase;
    }
    set upperCase(value: boolean) {
        this._upperCase = coerceBooleanProperty(value);
    }

    private _spaceDrop: boolean = false;
    @Input() get spaceDrop(): boolean {
        return this._spaceDrop;
    }
    set spaceDrop(value: boolean) {
        this._spaceDrop = coerceBooleanProperty(value);
    }

    private _value!: any;
    @Input()
    get value(): any {
        return this._value;
    }
    set value(value: any) {
        let valueAux: any = value;
        valueAux = this._checkSpaceDrop(valueAux);
        valueAux = this._checkLowercase(valueAux);
        valueAux = this._checkUppercase(valueAux);
        this._value = valueAux;

        this.onChange(this._value);
        this.onTouched(this._value);
    }

    onChange: Function = () => { };

    onTouched: Function = () => { };

    constructor(
        public elementRef: ElementRef<HTMLElement>,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void { }

    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }

    private _checkSpaceDrop(value: any): any {
        if (this.spaceDrop && value) {
            return value.replace(/\s/g, '');
        }
        return value;
    }

    private _checkUppercase(value: any): any {
        if (this.upperCase && value) {
            return value.toUpperCase();
        }
        return value;
    }

    private _checkLowercase(value: any): any {
        if (this.lowerCase && value) {
            return value.toLowerCase();
        }
        return value;
    }

}
