import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AbstractService } from '../../abstract/services/abstract.service';
import { NotificationFilterDTO } from '../models/dto/notification-filter.dto';
import { NotificationModel } from '../models/notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationService extends AbstractService<NotificationModel, NotificationFilterDTO> {

    constructor(protected httpClient: HttpClient) {
        super('APOIO', 'notification', httpClient);
    }

}
