import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { GenericService } from 'src/app/shared/services/generic.service';
import { TerminalModel } from '../models/terminal.model';

@Injectable({
  providedIn: 'root'
})
export class TerminalsService extends GenericService<TerminalModel> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this._baseUrl = HttpClientBase.baseURLApoio;
    this._resource = 'terminal';
    this._url = `${this._baseUrl}${this._resource}`;
  }

  public deleteById(id: number): Promise<boolean> {
    return new Promise((resolve) => {
      this.genericDeleteById(id).subscribe((result) => {
        resolve(result);
      }, (error) => {
        resolve(false);
      });

    });
  }

  public findByCnpjClient(cnpjClient: string): Promise<TerminalModel[]> {
    return new Promise((resolve) => {
      this._findByCnpjClient(cnpjClient).subscribe((result) => {
        resolve(result);
      }, (error) => {
        resolve(null);
      })
    });
  }

  private _findByCnpjClient(cnpjClient: string): Observable<TerminalModel[]> {
    let lUrl = `${this._url}/cnpj/${cnpjClient}`;
    return this.httpClient.get<TerminalModel[]>(lUrl).pipe(
      map((result) => result)
    );
  }

}
