<gipi-abstract>
    <gipi-toolbar id="toolbarListUpdateClients"
                  label="Gerenciador de clientes">
    </gipi-toolbar>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="space-between center"
         fxLayoutGap="16px">

        <mat-form-field fxFlex="40"
                        floatLabel="always"
                        appearance="outline"
                        style="height: 5rem !important;">
            <input matInput
                   type="text"
                   id="inputFindClients"
                   placeholder="Pesquisar"
                   autocomplete="off"
                   [disabled]="valueLoadingTable"
                   gipiAutoFocus
                   #input
                   (keyup)="filterValue(input.value)" />

            <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; padding-right: 3px;"
                 matSuffix>
                <button mat-icon-button
                        class="button-input"
                        matTooltip="Pesquisar"
                        [disabled]="valueLoadingTable"
                        onclick="this.blur()"
                        (click)="filterValue(input.value)">
                    <mat-icon style="width: 15px; height: 15px;"
                              svgIcon="search">
                    </mat-icon>
                </button>

                <div class="input-divider-buttons"></div>

                <!-- Button Filter -->
                <button mat-icon-button
                        class="button-input"
                        [disabled]="valueLoadingTable"
                        onclick="this.blur()"
                        [mdePopoverTriggerFor]="appPopoverOptionsFilter"
                        mdePopoverTriggerOn="click"
                        [mdePopoverCloseOnClick]="flag">
                    <mat-icon style="width: 15px; height: 15px;"
                              svgIcon="options_filter"></mat-icon>
                </button>

                <mde-popover #appPopoverOptionsFilter="mdePopover"
                             [mdePopoverOverlapTrigger]="false">

                    <mat-card style="padding: 0 !important;">
                        <mat-card-header class="card-header">
                            <mat-card-title class="card-title">Filtrar por clientes</mat-card-title>
                        </mat-card-header>

                        <mat-card-content class="card-content">
                            <!-- Radiobuttons Filter -->
                            <fieldset class="fieldset-filter">
                                <legend>Migrados</legend>
                                <mat-radio-group [(ngModel)]="taskRadiobuttonMigrated">
                                    <mat-radio-button *ngFor="let radiobutton of listTaskRadiobuttonMigrated"
                                                      class="radio-button-options-filter"
                                                      [value]="radiobutton">
                                        {{ radiobutton.name }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </fieldset>

                            <fieldset class="fieldset-filter">
                                <legend>Bloqueados</legend>
                                <mat-radio-group [(ngModel)]="taskRadiobuttonBlocked">
                                    <mat-radio-button *ngFor="let radiobutton of listTaskRadiobuttonBlocked"
                                                      class="radio-button-options-filter"
                                                      [value]="radiobutton">
                                        {{ radiobutton.name }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </fieldset>
                        </mat-card-content>

                        <mat-card-actions class="card-actions">
                            <gipi-button-generic class="button-apply-filter-input"
                                                 [inputAutoFocus]="false"
                                                 [inputText]="'APLICAR'"
                                                 (click)="filterOptionsValue(input.value)">
                            </gipi-button-generic>
                        </mat-card-actions>
                    </mat-card>
                </mde-popover>
            </div>
        </mat-form-field>

        <div fxFlex="40"
             fxFlex.lt-md="100"
             fxLayoutGap="16px">
            <gipi-button label="Exportar"
                         gipi-primary
                         id="btnExportExcel"
                         svgIcon="export_excel"
                         [disabled]="valueLoadingTable"
                         (click)="onClickExportExcelClients()"
                         fxFlex
                         fxFlex.lt-md="100">
            </gipi-button>

            <gipi-button label="Atualizar"
                         gipi-primary
                         id="btnRefreshTable"
                         icon="sync"
                         [disabled]="valueLoadingTable"
                         (click)="onClickRefreshUpdateClients()"
                         fxFlex
                         fxFlex.lt-md="100">
            </gipi-button>

            <gipi-button label="Ações"
                         gipi-secondary
                         id="btnActions"
                         icon="expand_more"
                         #actionsButton
                         *ngIf="validateAccess('ROLE_SUPPORT') || validateAccess('ROLE_DEVELOPERS')"
                         [disabled]="valueLoadingTable"
                         [popover]="true"
                         fxFlex
                         fxFlex.lt-md="100">
                <div style="display: flex; flex-direction: column !important; min-width: 140px !important; gap: 16px;">
                    <div style="width: 100%;">
                        <mat-label class="mat-label">
                            Quantidade a selecionar
                        </mat-label>
                        <mat-form-field appearance="outline"
                                        style="width: 100%;">
                            <input matInput
                                   type="number"
                                   min="1"
                                   max="1000000"
                                   value="1"
                                   spacedrop
                                   autofocus
                                   autocomplete="off"
                                   aria-invalid="false"
                                   aria-required="false"
                                   oninput="(validity.valid) || (value='')"
                                   [(ngModel)]="valueInputSelectedCount"
                                   #inputSelecion
                                   (keyup)="selectCheckbox(inputSelecion.value)" />
                        </mat-form-field>
                    </div>

                    <div style="width: 100%;">
                        <mat-label class="mat-label">
                            Ação a ser realizada
                        </mat-label>
                        <mat-form-field appearance="outline"
                                        style="width: 100%">
                            <mat-select floatlLabel="never"
                                        [formControl]="valueSelectAction"
                                        disableOptionCentering>
                                <mat-option>-- SELECIONE --</mat-option>
                                <mat-option *ngFor="let actions of taskActions"
                                            [value]="actions.action">
                                    {{ actions.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div>
                        <gipi-button label="Aplicar"
                                     gipi-primary
                                     id="btnApplyActions"
                                     [disabled]="valueLoadingTable"
                                     (click)="actionsButton.closePopover(); onClickAction()"
                                     fxFlex>
                        </gipi-button>
                    </div>
                </div>
            </gipi-button>

            <gipi-button label="Exibir"
                         gipi-tertiary
                         id="btnDisplay"
                         icon="expand_more"
                         #displayButton
                         *ngIf="validateAccess('ROLE_SUPPORT') || validateAccess('ROLE_DEVELOPERS')"
                         [disabled]="valueLoadingTable"
                         [popover]="true"
                         fxFlex
                         fxFlex.lt-md="100">
                <div style="display: flex; flex-direction: column !important; min-width: 140px !important; gap: 10px;">
                    <gipi-checkbox [label]="taskCheckBox.name"
                                   gipi-primary
                                   [ngModel]="allComplete"
                                   [indeterminate]="someComplete()"
                                   (change)="setAll($event.checked)"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <gipi-checkbox *ngFor="let subtask of taskCheckBox.subtasks"
                                   gipi-secondary
                                   [label]="subtask.name"
                                   [(ngModel)]="subtask.completed"
                                   (change)="updateAllComplete()"
                                   fxFlex="4">
                    </gipi-checkbox>
                    <div>
                        <gipi-button label="Aplicar"
                                     gipi-primary
                                     id="btnApplyFilter"
                                     [disabled]="valueLoadingTable"
                                     (click)="displayButton.closePopover(); filterValue(input.value)"
                                     fxFlex>
                        </gipi-button>
                    </div>
                </div>
            </gipi-button>
        </div>
    </div>

    <gipi-table-clients (outputSelectedCount)="refreshValueSelectedCount($event)"
                        (outputLoading)="refreshReadOnlyInput($event)">
    </gipi-table-clients>
</gipi-abstract>