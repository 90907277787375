import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';

import { ModalDialogComponent } from '@gipi-ui/components/modal-dialog/modal-dialog.component';
import { ModalDialog } from '@gipi-ui/components/modal-dialog/shared/modal-dialog';
import { ObjectUtil } from '@gipi-ui/utils/object.util';

@Injectable({ providedIn: 'root' })
export class ModalDialogService {

    constructor(private _dialog: MatDialog) { }

    public async show(modalDialog: ModalDialog): Promise<boolean> {
        const matDialogConfig: MatDialogConfig = new MatDialogConfig();
        matDialogConfig.disableClose = true;
        matDialogConfig.autoFocus = true;
        matDialogConfig.data = modalDialog;
        matDialogConfig.minWidth = '20%';
        matDialogConfig.maxWidth = modalDialog.width || '40%';
        matDialogConfig.width = modalDialog.width;

        const result = await this._dialog
            .open(ModalDialogComponent, matDialogConfig)
            .afterClosed()
            .pipe(
                tap((accepted: boolean) => {
                    if (accepted && !ObjectUtil.isNull(modalDialog.accept)) {
                        modalDialog.accept();
                    } else if (!accepted && !ObjectUtil.isNull(modalDialog.cancel)) {
                        modalDialog.cancel();
                    }
                })
            )
            .toPromise();

        return result;
    }

}
