<gipi-abstract id="dialogRegisterConfiguration"
               name="dialogRegisterConfiguration"
               [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterConfiguration">
        Configuração
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="30px">
        <div class="observation">
            <span>
                <strong> Obs: </strong> Essa configuração estabelecerá o limite máximo para prorrogações de licença
                mensal, mas não se aplicará aos usuários do departamento financeiro.
            </span>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-text label="Quantidade máxima"
                             id="configurationMaximumQuantity"
                             name="configurationMaximumQuantity"
                             [required]="true"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [mask]="'0*'"
                             [min]="0"
                             [max]="365"
                             [maxLength]="3"
                             [(ngModel)]="entity.maximumQuantityOfMonthlyLicenseExtension"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-input-text>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract>