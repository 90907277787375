import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { MessageModel } from '@gipi-ui/models/message.model';

@Component({
    selector: 'gipi-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof AlertComponent => AlertComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-alert'
    }
})
export class AlertComponent implements OnInit {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public message: MessageModel) { }

    ngOnInit(): void { }

}
