import { SortModel } from "./sort.model";

export class AbstractFilterModel {

    // offset: number;

    pageNumber: number;

    pageSize: number;

    researchField: string;

    sorts: SortModel[];

}
