import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { AbstractService } from '@gipi-pages/abstract/services/abstract.service';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { UserConsultDTO } from '../models/dto/user-consult.dto';
import { UserDTO } from '../models/dto/user.dto';
import { UserModel } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class UserService extends AbstractService<UserModel, any> {

    constructor(protected httpClient: HttpClient) {
        super('USER', 'users', httpClient);
    }

    public enableOrDisable(email: string, action: 'DISABLE' | 'ENABLE'): Observable<boolean> {
        if (StringUtil.isEmpty(email)) {
            return of(false);
        }
        return this.httpClient.patch(this.url(`/enable-or-disable/${email}/${action}`), {}, this.options()).pipe(
            map(() => { return true; }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        )
    }

    public update(entity: UserDTO, currentPassword: string): Observable<boolean> {
        return this.httpClient.put<UserDTO>(this.url(currentPassword), entity, this.options()).pipe(
            map(json => {
                const entity: UserModel = this.handleMapper(json);
                return !ObjectUtil.isNull(entity);
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        )
    }

    public findByEmail(email: string): Observable<UserModel> {
        return this.httpClient.get<UserModel>(this.url(`search?email=${email}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(_ => { return of(null); }),
        );
    }

    public confirmAccess(name: string, password: string, token: string): Observable<boolean> {
        return this.httpClient.post(this.url(`public/confirm-access/${name}/${password}/${token}`), this.options()).pipe(
            map(_ => { return true; }),
            takeUntil(this.handleDestroy),
            catchError(_ => { return of(false); })
        );
    }

    public recovery(email: string): Observable<boolean> {
        return this.httpClient.get(this.url(`public/recovery/${email}`), this.options()).pipe(
            map(_ => { return true; }),
            takeUntil(this.handleDestroy),
            catchError(_ => { return of(false); })
        );
    }

    public updatePassword(password: string, token: string): Observable<boolean> {
        return this.httpClient.patch(this.url(`public/update-password/${password}/${token}`), this.options()).pipe(
            map(_ => { return true; }),
            takeUntil(this.handleDestroy),
            catchError(_ => { return of(false); })
        );
    }

    public sendInvitation(entity: UserConsultDTO): Observable<boolean> {
        return this.httpClient.post<UserConsultDTO>(this.url(`send-invitation`), entity, this.options()).pipe(
            map(json => {
                const userModel: UserModel = this.handleMapper(json);
                return !ObjectUtil.isNull(userModel);
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
