import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SnackbarService } from '@gipi-components/global/snackbar/services/snackbar.service';
import { UserModel } from '@gipi-pages/user/models/user.model';
import { UserService } from '@gipi-pages/user/services/user.service';
import { PasswordUtil } from '@gipi-ui/utils/password.util';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { UserModelDTO } from './models/user.model.dto';

export interface UserInformations {
    userModel: UserModel;
}

@Component({
    selector: 'gipi-dialog-user-informations',
    templateUrl: './dialog-user-informations.component.html',
    styleUrls: ['./dialog-user-informations.component.scss']
})
export class DialogUserInformationsComponent implements OnInit {

    private _countAttempt: number = 0;

    public inputTextName: FormControl = new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(60)]);

    public inputTextEmail: FormControl = new FormControl('', [Validators.email, Validators.required, Validators.maxLength(100)]);

    public inputTextPassword: FormControl = new FormControl('', [Validators.minLength(6), Validators.maxLength(16)]);

    public inputTextConfirmPassword: FormControl = new FormControl('', [Validators.minLength(6), Validators.maxLength(16)]);

    public inputTextActualPassword: FormControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]);

    public hidePassword: boolean = true;

    public hideConfirmPassword: boolean = true;

    public hideActualPassword: boolean = true;

    public hideUpdatePassword: boolean = true;

    public isLoading: boolean = false;

    public submitted: boolean = false;

    public userModel: UserModelDTO = {
        id: -1,
        email: '',
        name: '',
        password: '',
    }

    get isValidCharacterPassword(): boolean {
        return PasswordUtil.isValidCharacter(this.inputTextPassword.value);
    }

    get isValidCharacterConfirmPassword(): boolean {
        return PasswordUtil.isValidCharacter(this.inputTextConfirmPassword.value);
    }

    constructor(
        public dialogRef: MatDialogRef<DialogUserInformationsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UserInformations,
        private _authenticationService: AuthenticationService,
        private _usersService: UserService,
        private _snackbarService: SnackbarService
    ) {
        dialogRef.disableClose = true;
        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close(false);
        });
    }

    ngOnInit(): void {
        this.userModel.id = this.data.userModel.id;
        this.userModel.email = this.data.userModel.email;
        this.userModel.name = this.data.userModel.name;
        this.userModel.password = this.data.userModel.password;

        this.inputTextName.setValue(this.userModel.name);
        this.inputTextEmail.setValue(this.userModel.email);
    }

    public viewNewPassword(): void {
        this.hideUpdatePassword = !this.hideUpdatePassword;

        if (this.hideUpdatePassword) {
            this.inputTextPassword.setValue('');
            this.inputTextConfirmPassword.setValue('');
        }
    }

    private validateUser(): boolean {
        if (this.inputTextName.value !== '') {
            if (this.inputTextName.valid) {
                return true;
            } else {
                this._snackbarService.showMessage('"Nome" inválido', true);
                this.submitted = false;
                this.isLoading = false;
                return false;
            }
        } else {
            this._snackbarService.showMessage('Campo "Nome" é obrigatório e não foi preenchido', true);
            this.submitted = false;
            this.isLoading = false;
            return false;
        }
    }

    private validadePassword(): boolean {
        if ((this.inputTextPassword.value.trim() !== '') && (this.inputTextConfirmPassword.value.trim() !== '')) {
            if (!PasswordUtil.isValidCharacter(this.inputTextPassword.value) ||
                !PasswordUtil.isValidCharacter(this.inputTextConfirmPassword.value)
            ) {
                this._snackbarService.showMessage('Os caracteres %, +, -, * e / não são permitidos', true);
                this.submitted = false;
                this.isLoading = false;
                return false;
            } else {
                if (this.inputTextPassword.valid && this.inputTextConfirmPassword.valid) {
                    if (this.inputTextPassword.value.trim() === this.inputTextConfirmPassword.value.trim()) {
                        return true;
                    } else {
                        this._snackbarService.showMessage('A nova senha não confere', true);
                        this.submitted = false;
                        this.isLoading = false;
                        return false;
                    }
                } else {
                    this._snackbarService.showMessage('"Nova senha" inválida', true);
                    this.submitted = false;
                    this.isLoading = false;
                    return false;
                }
            }
        } else {
            return true;
        }
    }

    public async onClickSaveUser(): Promise<void> {
        this.submitted = true;
        this.isLoading = true;

        if (this._countAttempt === 3) {
            this._authenticationService.logout();
            this.onClickGoBack();
            return;
        } else {
            if (this.inputTextActualPassword.value !== '') {
                if (this.validateUser()) {
                    if (this.validadePassword()) {
                        this.userModel.name = this.inputTextName.value;
                        if (this.inputTextPassword.value) {
                            this.userModel.password = this.inputTextPassword.value;
                        }

                        await this._usersService.update(this.userModel, this.inputTextActualPassword.value).toPromise().then(resultUpdateUser => {
                            if (resultUpdateUser) {
                                this._snackbarService.showMessage('Informações alteradas com sucesso');
                                setTimeout(() => {
                                    this._authenticationService.logout();
                                    this.onClickGoBack();
                                }, 5);
                            } else {
                                this.submitted = false;
                                this.isLoading = false;
                                this._countAttempt = this._countAttempt + 1;
                            }
                        });
                    }
                }
            } else {
                this._snackbarService.showMessage('Campo "Senha atual" é obrigatória e não foi preenchido', true);
                this.submitted = false;
                this.isLoading = false;
            }
        }
    }

    public onClickGoBack(): void {
        this.dialogRef.close();
    }

}
