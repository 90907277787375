<gipi-input-text [id]="id"
                 [name]="name"
                 [type]="'select'"
                 [label]="label"
                 [placeholder]="placeholder"
                 [help]="help"
                 [readOnly]="true"
                 [(required)]="required"
                 [(disabled)]="disabled"
                 [(lowerCase)]="lowerCase"
                 [(upperCase)]="upperCase"
                 [ngModel]="optionSelected"
                 #popoverTrigger="mdePopoverTrigger"
                 [mdePopoverTargetAt]="mdePopoverTargetAt"
                 [mdePopoverTriggerFor]="inputSelectEnumPopover"
                 [mdePopoverTriggerOn]="'click'"
                 [mdePopoverBackdropCloseOnClick]="true"
                 (opened)="onOpenPopover.emit()"
                 (closed)="onClosePopover.emit()"
                 (keydown)="handleKeydow($event)"
                 (click)="handleClick($event)"
                 (focus)="handleFocus($event)"
                 (blur)="handleBlur($event)">
</gipi-input-text>

<mde-popover #inputSelectEnumPopover="mdePopover"
             [mdePopoverOverlapTrigger]="false"
             [mdePopoverCloseOnClick]="false"
             [mdeFocusTrapEnabled]="false"
             [mdeFocusTrapAutoCaptureEnabled]="false"
             [mdePopoverArrowWidth]="0"
             [mdePopoverOffsetY]="0">
    <div class="input-popover-content"
         [style.width]="popoverContentWidth">
        <cdk-virtual-scroll-viewport *ngIf="(_enumList && _enumList.length)"
                                     class="select-virtual-scroll-viewport"
                                     [style.height.px]="scrollViewportHeight"
                                     [itemSize]="_itemSizeScrollViewport">
            <div *cdkVirtualFor="let e of _enumList"
                 class="select-row"
                 [style.height.px]="40"
                 [class.disabled]="!validateOmitByKey(e.key) && (disabled)"
                 (click)="onButtonSelectOptionClick(e.key)">
                {{ e.value }}
            </div>
        </cdk-virtual-scroll-viewport>

        <div *ngIf="(!_enumList || (_enumList && _enumList.length <= 0))"
             class="select-not-found">
            <mat-icon>sentiment_very_dissatisfied</mat-icon>
            Nenhum registro foi encontrado
        </div>
    </div>
</mde-popover>