import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { AbstractService } from '@gipi-pages/abstract/services/abstract.service';
import { ConfigurationModel } from '../models/configuration.model';

@Injectable({ providedIn: 'root' })
export class ConfigurationService extends AbstractService<ConfigurationModel, any> {

    constructor(protected httpClient: HttpClient) {
        super('LICENSE', 'configuration', httpClient);
    }

    public findEnabled(): Observable<ConfigurationModel> {
        return this.httpClient.get<ConfigurationModel>(this.url('find-enabled', 'LICENSE'), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        )
    }

}
