<div>
    <mat-table [dataSource]="dataSource"
               matSort
               (matSortChange)="sortData($event)"
               multiTemplateDataRows
               on-mouseleave="hoverbutton=null">

        <!-- isFinancial Column -->
        <ng-container matColumnDef="isFinancial">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.hasFinancial"
                          class="launch-icon"
                          svgIcon="person_has_financial"
                          matTooltip="Cliente migrado">
                </mat-icon>
            </mat-cell>
        </ng-container>

        <!-- select Column -->
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox class="checkbox-all"
                              (change)="$event ? masterToggle() : null"
                              (change)="onChangeSelectedCount()"
                              [checked]="selection.hasValue() || selection?.selected?.length === dataSource?.data?.length"
                              [indeterminate]="selection.hasValue() && !(selection?.selected?.length === dataSource?.data?.length)">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-checkbox class="checkbox-row"
                              (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(element) : null"
                              (change)="onChangeSelectedCount()"
                              [checked]="selection.isSelected(element)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <!-- corporateName Column -->
        <ng-container matColumnDef="corporateName">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="corporateName"
                             [ngStyle]="{'min-width': validateAccess('ROLE_FINANCIAL') ? '30%' : '20%' }">
                Razão social
            </mat-header-cell>
            <mat-cell *matCellDef="let element"
                      data-label="Razão social"
                      [ngStyle]="{'min-width': validateAccess('ROLE_FINANCIAL') ? '30%' : '20%' }">
                {{ (element.person.name ? element.person.name : element.person.fantasyName) }}
            </mat-cell>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="name"
                             [ngStyle]="{'min-width': validateAccess('ROLE_FINANCIAL') ? '30%' : '20%' }">
                Nome fantasia
            </mat-header-cell>
            <mat-cell *matCellDef="let element"
                      data-label="Nome fantasia"
                      [ngStyle]="{'min-width': validateAccess('ROLE_FINANCIAL') ? '30%' : '20%' }">
                {{ (element.person.fantasyName ? element.person.fantasyName : element.person.name) }}
            </mat-cell>
        </ng-container>

        <!-- cpfOrCnpj Column -->
        <ng-container matColumnDef="cpfOrCnpj">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="cpfOrCnpj">
                CNPJ / CPF
            </mat-header-cell>
            <mat-cell *matCellDef="let element"
                      data-label="CNPJ / CPF">
                {{ validateCpfOrCnpj(element.person) }}
            </mat-cell>
        </ng-container>

        <!-- blocked Column -->
        <ng-container matColumnDef="blocked">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="blocked">
                Bloqueado
            </mat-header-cell>
            <mat-cell *matCellDef="let element"
                      data-label="Bloqueado">
                {{ element.blocked ? 'SIM' : 'NÃO' }}
            </mat-cell>
        </ng-container>

        <!-- dateBlockingContingency Column -->
        <ng-container matColumnDef="dateBlockingContingency">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header="dateBlockingContingency"
                             gipiHide
                             [hide]="validateAccess('ROLE_FINANCIAL')">
                Bloq. Contingência
            </mat-header-cell>
            <mat-cell *matCellDef="let element"
                      data-label="Bloq. Contingência"
                      gipiHide
                      [hide]="validateAccess('ROLE_FINANCIAL')">
                {{ element.dateBlockingContingency ? (element.dateBlockingContingency | date:'dd/MM/yyyy') : '' }}
            </mat-cell>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef
                             gipiHide
                             [hide]="validateAccess('ROLE_FINANCIAL')">
                Status
            </mat-header-cell>
            <mat-cell *matCellDef="let element"
                      data-label="Status"
                      gipiHide
                      [hide]="validateAccess('ROLE_FINANCIAL')">
                <!-- <gipi-simple-status-cell style="width: 90%;"
                                         [inputSimpleStatusCell]="loadStatus(element.updateStatus)"
                                         [matTooltip]="loadTooltip(element.updateStatus)">
                </gipi-simple-status-cell> -->
                <gipi-lozenge [label]="getLabelStatus(element.updateStatus)"
                              [type]="getTypeStatus(element.updateStatus)"
                              [tooltip]="getTooltipStatus(element.updateStatus)">
                </gipi-lozenge>
            </mat-cell>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element"
                      style="cursor: pointer;"
                      data-label="Ações">
                <div class="button-action"
                     *ngIf="element === hoverbutton">

                    <!-- Button Release -->
                    <button mat-icon-button
                            *ngIf="validateButtonReleaseVersion(element.updateStatus)"
                            [matTooltip]="validateIconButtonReleaseVersion(element) ? 'Liberar atualização' : 'Cancelar atualização'"
                            (click)="onClickActionClients(element, element.updateStatus)">
                        <mat-icon style="width: 20px; height: 20px; fill: #afafaf;"
                                  [svgIcon]="validateIconButtonReleaseVersion(element) ? 'play_version_client' : 'backspace_version_client'">
                        </mat-icon>
                    </button>

                    <!-- Button Delay Blocking -->
                    <button mat-icon-button
                            *ngIf="((validateAccess('ROLE_SUPPORT') || validateAccess('ROLE_FINANCIAL') || validateAccess('ROLE_DEVELOPERS')) && (selection?.selected?.length <= 0))"
                            matTooltip="Prorrogar licença"
                            (click)="onClickDelayBlocking(element)">
                        <mat-icon style="width: 20px; height: 20px; fill: #afafaf;"
                                  svgIcon="delay_blocking"
                                  aria-hidden="false"
                                  aria-label="">
                        </mat-icon>
                    </button>

                    <!-- Button License Generator -->
                    <button mat-icon-button
                            matTooltip="Licença offline"
                            (click)="onClickLicenseGenerator(element)"
                            *ngIf="(selection?.selected?.length <= 0)">
                        <mat-icon style="width: 26px; height: 26px; fill: #afafaf; stroke: #afafaf;"
                                  svgIcon="generation_license_off">
                        </mat-icon>
                    </button>

                    <!-- Button BlockingContingency -->
                    <button mat-icon-button
                            *ngIf="((validateAccess('ROLE_SUPPORT') || validateAccess('ROLE_DEVELOPERS')) && (selection?.selected?.length <= 0))"
                            matTooltip="Liberar notas em contingência"
                            (click)="onClickUpdateBlockedContingency(element)">
                        <mat-icon style="width: 20px; height: 20px; fill: #afafaf;"
                                  svgIcon="release_contingency">
                        </mat-icon>
                    </button>

                    <!-- Button MigrateClientWeb -->
                    <button mat-icon-button
                            *ngIf="((validateAccess('ROLE_SUPPORT') || validateAccess('ROLE_DEVELOPERS')) && (selection?.selected?.length <= 0) && (!element.hasFinancial))"
                            matTooltip="Migrar para o sistema WEB"
                            (click)="migrateClientToWeb(element)">
                        <mat-icon style="width: 24px; height: 24px; fill: #afafaf;"
                                  svgIcon="migrate_client_web">
                        </mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>

        <!-- Coluna que expande - A linha que mostra os computadores do cliente é composta por esta coluna que se estende por todas as colunas -->
        <ng-container matColumnDef="expandedDetail">
            <mat-cell class="div-expanded"
                      *matCellDef="let element"
                      [attr.colspan]="columnsToDisplay.length"
                      [ngStyle]="{ 'justify-content': element.terminals.length === 0 ? 'center' : 'flex-start' }">
                <div class="element-detail"
                     [@detailExpand]="(element == expandedElement) && (validateAccess('ROLE_SUPPORT') || validateAccess('ROLE_DEVELOPERS')) ? 'expanded' : 'collapsed'">
                    <gipi-list-computer-clients [(terminalList)]="terminalList"
                                                [loading]="loadingTerminal"
                                                [inputPersonClient]="element.person"
                                                [inputIdClient]="element.id"
                                                (outputRefreshOneClient)="refreshClients(false, $event)"
                                                style="width: 100%;">
                    </gipi-list-computer-clients>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"> </mat-header-row>

        <mat-row class="element-row"
                 *matRowDef="let element; columns: columnsToDisplay;"
                 [class.expanded-row]="expandedElement === element"
                 (mouseover)="hoverbutton = element"
                 (click)="expandElement(element); onClickTerminal(element)">
        </mat-row>

        <mat-row *matRowDef="let row; columns: ['expandedDetail']"
                 class="detail-row">
        </mat-row>
    </mat-table>

    <mat-progress-bar mode="indeterminate"
                      *ngIf="(isLoading)">
    </mat-progress-bar>

    <div class="no-records"
         *ngIf="((!isLoading) && (!containsElements))">
        <mat-icon>
            sentiment_very_dissatisfied
        </mat-icon>
        <span>Nenhum registro foi encontrado</span>
    </div>

    <mat-paginator [pageSize]="10"
                   showFirstLastButtons
                   [hidePageSize]="true"
                   matBadge="{{ 'Quantidade de registros selecionados: ' + selection?.selected?.length.toString()  }}"
                   matBadgePosition="after">
    </mat-paginator>
</div>
