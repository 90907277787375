
export class EmailUtil {

    // public static isValid(value: string): boolean {
    //     const regex: RegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    //     return regex.test(value)
    //         && (value.includes('org')
    //             || value.includes('.com')
    //             || value.includes('.br')
    //             || value.includes('.net')
    //             || value.includes('.pt')
    //             || value.includes('.io')
    //             || value.includes('.ru')
    //             || value.includes('.uk')
    //             || value.includes('.fr')
    //             || value.includes('.br')
    //             || value.includes('.pt')
    //             || value.includes('.de')
    //             || value.includes('.tk')
    //             || value.includes('.info')
    //             || value.includes('.nl')
    //             || value.includes('.eu')
    //             || value.includes('.it')
    //             || value.includes('.au')
    //             || value.includes('.fr'));
    // }

    public static isValid(value: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    };

}
