import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { NcmModel } from '../models/ncm.model';
import { StatePagedModel } from '../models/state-paged.model';
import { GenericService } from 'src/app/shared/services/generic.service';


@Injectable({
  providedIn: 'root'
})
export class UpdateTablesNcmService extends GenericService<NcmModel> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this._baseUrl = HttpClientBase.baseURLApoio;
    this._resource = 'ncm';
    this._url = `${this._baseUrl}${this._resource}`;
  }

  public save(object: NcmModel): Promise<NcmModel> {

    return new Promise((resolve) => {

      this.genericSave(object).subscribe((result) => {
        resolve(result);
      }, (error) => {
        resolve(null);
      });

    });

  }

  public saveAll(listObject: NcmModel[]): Promise<NcmModel[]> {

    return new Promise((resolve) => {

      this.genericSaveAll(listObject).subscribe((result) => {
        resolve(result);
      }, (error) => {
        resolve(null);
      });

    });

  }

  public findAllStates(): Promise<StatePagedModel> {

    return new Promise((resolve) => {

      this._findAllStates().subscribe((result) => {
        resolve(result);
      }, (error) => {
        resolve(null);
      });

    });

  }

  private _findAllStates(): Observable<StatePagedModel> {

    let lUrl = `${this._baseUrl}state?size=30`;
    return this._httpClient.get<StatePagedModel>(lUrl).pipe(
      map((result) => {
        if (result) {
          return result;
        }
      })
    );

  }

}