<gipi-abstract id="dialogRegisterTypeVersion"
               name="dialogRegisterTypeVersion"
               [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterTypeVersion">
        Cadastrar tipo de versão
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-text label="Descrição"
                             id="typeVersionDescription"
                             name="typeVersionDescription"
                             [required]="true"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [(ngModel)]="entity.description"
                             [min]="3"
                             [max]="100"
                             [maxLength]="100"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-input-text>
            <gipi-input-text label="Iniciais"
                             id="typeVersionInitials"
                             name="typeVersionInitials"
                             [required]="true"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [(ngModel)]="entity.initials"
                             [minLength]="3"
                             [maxLength]="3"
                             fxFlex="33"
                             fxFlex.lt-md="100">
            </gipi-input-text>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract>