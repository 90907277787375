<gipi-input-text [id]="id"
                 [name]="name"
                 [type]="'select'"
                 [label]="label"
                 [placeholder]="placeholder"
                 [help]="help"
                 [readOnly]="true"
                 [(required)]="required"
                 [(disabled)]="disabled"
                 [(lowerCase)]="lowerCase"
                 [(upperCase)]="upperCase"
                 [value]="getPropertyValue(value)"
                 #popoverTrigger="mdePopoverTrigger"
                 [mdePopoverTargetAt]="mdePopoverTargetAt"
                 [mdePopoverTriggerFor]="inputSelectPopover"
                 [mdePopoverTriggerOn]="'click'"
                 [mdePopoverBackdropCloseOnClick]="true"
                 (opened)="onOpenPopover.emit()"
                 (closed)="onClosePopover.emit()"
                 (keydown)="handleKeydow($event)"
                 (click)="handleClick($event)"
                 (focus)="handleFocus($event)"
                 (blur)="handleBlur($event)">
</gipi-input-text>

<mde-popover #inputSelectPopover="mdePopover"
             [mdePopoverOverlapTrigger]="false"
             [mdePopoverCloseOnClick]="false"
             [mdeFocusTrapEnabled]="false"
             [mdeFocusTrapAutoCaptureEnabled]="false"
             [mdePopoverArrowWidth]="0"
             [mdePopoverOffsetY]="0">
    <div class="input-popover-content"
         [style.width]="(popoverContentWidth | async)">
        <cdk-virtual-scroll-viewport *ngIf="_optionList?.length > 0"
                                     class="select-virtual-scroll-viewport"
                                     [style.height.px]="scrollViewportHeight"
                                     [itemSize]="_itemSizeScrollViewport">
            <div *cdkVirtualFor="let option of _optionList"
                 class="select-row"
                 [style.height.px]="40"
                 [class.disabled]="!validateOmitByKey(option) && (disabled)"
                 (click)="onButtonSelectOptionClick(option)">
                {{ getPropertyValue(option) }}
            </div>
        </cdk-virtual-scroll-viewport>

        <div *ngIf="_optionList?.length <= 0"
             class="select-not-found">
            <mat-icon>sentiment_very_dissatisfied</mat-icon>
            Nenhum registro foi encontrado
        </div>
    </div>
</mde-popover>
