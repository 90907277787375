<!-- <div *ngIf="(isAuthenticated | async); else loginTemplate">
    <gipi-sidenav></gipi-sidenav>
</div>
<ng-template #loginTemplate>
    <router-outlet></router-outlet>
</ng-template> -->

<div *ngIf="(isAuthenticated | async); else loginTemplate">
    <gipi-top-nav id="topNav"
                  class="top-nav">
        <div class="section-images">
            <img alt="Logo da GIPI Sistemas"
                 id="bigLogo"
                 src="../assets/logo/logo.svg"
                 width="220"
                 height="40" />
        </div>
        <div class="section-content">
            <button matTooltip="Notificações"
                    class="notification"
                    *ngIf="(hasNotification | async)"
                    #popoverNotificationTrigger="mdePopoverTrigger"
                    [mdePopoverTriggerFor]="popoverNotification"
                    [mdePopoverTriggerOn]="'click'"
                    [mdePopoverBackdropCloseOnClick]="true"
                    (click)="updateNotifications()">
                <mat-icon [class.swing]="swingNotification">
                    notifications_none
                </mat-icon>
                <div class="badge"
                     *ngIf="((quantityNotification | async) > 0)">
                </div>
            </button>
            <mde-popover #popoverNotification="mdePopover"
                         [mdePopoverOverlapTrigger]="false"
                         [mdePopoverCloseOnClick]="false"
                         [mdeFocusTrapEnabled]="false"
                         [mdeFocusTrapAutoCaptureEnabled]="false"
                         [mdePopoverArrowWidth]="0"
                         [mdePopoverOffsetY]="3">
                <gipi-notification (outputQuantityNoitfication)="setQuantityNotification($event)">
                </gipi-notification>
            </mde-popover>

            <gipi-user-profile id="userProfile"
                               name="userProfile"
                               arialLabel="Perfil de usuário"
                               [username]="(firstName | async)"
                               [userLogged]="userLogged"
                               (onClick)="onClickUserInformations()">
            </gipi-user-profile>

            <button matTooltip="Sair"
                    class="logout"
                    (click)="logout()">
                <mat-icon svgIcon="logout"> </mat-icon>
            </button>
        </div>
    </gipi-top-nav>

    <gipi-sidenav-container>
        <gipi-sidenav id="sidenav"
                      labelSyncFinancial="Sincronizar"
                      svgIconSyncFinancial="syncronize_web"
                      [showSyncFinancial]="validateAccess('ROLE_SUPPORT') || validateAccess('ROLE_FINANCIAL') || validateAccess('ROLE_DEVELOPERS')"
                      [disabledSync]="isSyncronize"
                      labelConfiguration="Configuração"
                      svgIconConfiguration="configuration"
                      [showConfiguration]="(validateAccess('ROLE_FINANCIAL') && validateAccess('ROLE_REGISTER')) || validateAccess('ROLE_DEVELOPERS')"
                      [menuList]="menuList"
                      (onClickMenuConfiguration)="showDialogConfiguration()"
                      (onClickMenuSync)="syncronizeApps()">
        </gipi-sidenav>
        <router-outlet> </router-outlet>
    </gipi-sidenav-container>

    <gipi-footer> </gipi-footer>
</div>

<ng-template #loginTemplate>
    <router-outlet> </router-outlet>
</ng-template>