<div class="dialog-container">
    <!--Title-->
    <div style="display: flex; flex-direction: row; align-items: center; margin-top: 5px;">
        <div style="width: 100%; display: flex; flex-direction: row; align-items: center;">
            <mat-icon mat-list-icon
                      class="title-icon">
                desktop_windows
            </mat-icon>
            <p class="title-client">{{ nameTerminal }}</p>
        </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Client -->
    <div class="inf-client">
        <!-- CNPJ/CPF Client -->
        <div style="flex-direction: column; box-sizing: border-box; display: flex; width: 25%;">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                CNPJ / CPF
            </mat-label>
            <mat-form-field floatLabel="always"
                            appearance="outline"
                            style="width: 100%;">
                <input matInput
                       type="text"
                       id="cnpj-cpf"
                       autocomplete="off"
                       readonly="true"
                       value="{{ cnpjOrCpfClient | GIPICpfCnpj }}" />
            </mat-form-field>
        </div>

        <!-- Name Client -->
        <div style="flex-direction: column; box-sizing: border-box; display: flex; width: 100%; margin-left: 10px;">
            <mat-label class="mat-label"
                       style="margin: 0px;">
                Nome Fantasia
            </mat-label>
            <mat-form-field floatLabel="always"
                            appearance="outline"
                            style="width: 100%;">
                <input matInput
                       type="text"
                       id="fantasy-name"
                       autocomplete="off"
                       readonly="true"
                       value="{{ nameClient }}" />
            </mat-form-field>
        </div>
    </div>

    <!--table-->
    <div style="margin-top: 5px;">
        <gipi-table-client-information [inputApps]="apps"
                                       [inputIdClient]="idClient"
                                       (outputTerminalAppSelected)="setTerminalAppSelected($event)"
                                       (outputRefreshOneClient)="onClickGoBack(true, $event)">
        </gipi-table-client-information>
    </div>

    <!--Button goBack-->
    <div style="display: flex; justify-content: flex-end; align-items: center; margin-top: 20px;">
        <gipi-button-generic style="margin-right: 16px;"
                             [inputAutoFocus]="false"
                             [inputText]="'EXCLUIR'"
                             [inputSvgIcon]="'trash'"
                             onclick="this.blur()"
                             [disabled]="terminalAppListDelete && terminalAppListDelete.length <= 0"
                             (click)="onClickDeleteApp()">
        </gipi-button-generic>

        <button mat-raised-button
                [autofocus]="true"
                onclick="this.blur()"
                class="mat-raised-button-go-back"
                [mat-dialog-close]="true"
                (click)="onClickGoBack(true, -1)">
            <mat-icon class="mat-icon"
                      role="img"
                      aria-hidden="true">
                arrow_back
            </mat-icon>
            VOLTAR
        </button>
    </div>

</div>