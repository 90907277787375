import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AbstractService } from '@gipi-pages/abstract/services/abstract.service';
import { ClientModel } from '../models/client.model';
import { ClientFilterDTO } from '../models/dto/client-filter.dto';

@Injectable({ providedIn: 'root' })
export class ClientService extends AbstractService<ClientModel, ClientFilterDTO> {

    constructor(protected httpClient: HttpClient) {
        super('APOIO', 'client', httpClient);
    }

}
