import { GIPIUuid } from '@gipi-shared/types/uuid.type';
import { ObjectUtil } from './object.util';
import { StringUtil } from './string.util';

/** @dynamic */
export class UUIDUtil {

    public static isValid(id: GIPIUuid): boolean {
        if (ObjectUtil.isNull(id)) {
            return false;
        }
        if (typeof id === 'number') {
            return id && id > 0;
        } else if (typeof id === 'string') {
            return !StringUtil.isEmpty(id) && (id.length > 0);
        }
    }

}
