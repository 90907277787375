<div class="alert"
     [ngClass]="message.type"
     fxLayout="row"
     fxLayoutAlign=" center"
     fxLayoutGap="15px">
    <mat-icon *ngIf="message.icon">
        {{ message.icon }}
    </mat-icon>

    <div fxLayout="column"
         fxLayoutAlign="center start">
        <div class="title">
            {{ message.title }}
        </div>
        <span>
            {{ message.message }}
        </span>
    </div>
</div>
