import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AbstractFormDialogComponent, FormDialogData } from '@gipi-pages/abstract/abstract-form-dialog.component';
import { NotificationTypeModel } from '@gipi-pages/notification-type/models/notification-type.model';
import { NotificationTypeService } from '@gipi-pages/notification-type/services/notification-type.service';
import { AlertService } from '@gipi-ui/services/alert.service';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';

@Component({
    selector: 'gipi-notification-type-form-dialog',
    templateUrl: './notification-type-form-dialog.component.html',
    styleUrls: ['./notification-type-form-dialog.component.scss']
})
export class NotificationTypeFormDialogComponent extends AbstractFormDialogComponent<NotificationTypeModel> implements OnInit {

    constructor(
        private _alertService: AlertService,
        protected service: NotificationTypeService,
        protected dialogRef: MatDialogRef<NotificationTypeFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FormDialogData<NotificationTypeModel> = { typeOperation: 'NEW', entity: null },
    ) {
        super(service, dialogRef, data);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected newEntity(): NotificationTypeModel {
        return new NotificationTypeModel();
    }

    protected isValid(): boolean {
        if (ObjectUtil.isNull(this.entity)) {
            return false;
        }
        if (StringUtil.isEmpty(this.entity.description)) {
            this._alertService.addWarningMessage('Campo descrição é obrigatório e não foi informado');
            return false;
        }
        if (this.entity.description.length < 3) {
            this._alertService.addWarningMessage('Campo descrição deve conter no mínimo 3 caracteres');
            return false;
        }
        if (StringUtil.isEmpty(this.entity.imageUrl)) {
            this._alertService.addWarningMessage('Campo url da imagem é obrigatório e não foi informado');
            return false;
        }
        if (this.entity.imageUrl.length < 3) {
            this._alertService.addWarningMessage('Campo url da imagem deve conter no mínimo 3 caracteres');
            return false;
        }
        if (!this.isValidImageLink(this.entity.imageUrl)) {
            this._alertService.addWarningMessage('O url da imagem informada não é válida');
            return false;
        }

        return true;
    }

    public confirm(): void {
        try {
            if (!this.isValid()) {
                return;
            }

            this.loading = true;

            this.entity.enabled = (this.optionSituationValue === 'ENABLED');

            this.service.save(this.entity).toPromise().then(() => {
                this._alertService.addSuccessMessage('Operação realizada com sucesso');
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        }
    }

    public isValidImageLink(link: string): boolean {
        const regex = /^(ftp|http|https):\/\/[^ "]+$/;
        return regex.test(link);
    }

}
