/**
 * Somente icones e imagens SVG
 * O value do enum deve ser exatamente igual ao nome do arquivo.svg
 * Basta informar o enum aqui e já poderá utilizar o icone em qualquer local da aplicação.
 * Lembrando que para utilizar o icone é dessa forma, Ex: <mat-icon svgIcon="person_client"></mat-icon>
 */
export enum GipiIcons {
    /* Icones globals */
    user_information = 'user_information',
    user_registration = 'user_registration',
    delete = 'delete',
    activate = 'activate',
    deactivate = 'deactivate',
    send = 'send',
    logout = 'logout',
    notification = 'notification',
    table_notification = 'table_notification',
    search = 'search',
    options_filter = 'options_filter',
    clear = 'clear',
    copy = 'copy',
    trash = 'trash',
    export_excel = 'export_excel',
    datetime = 'datetime',
    filter = 'filter',
    clone = 'clone',

    /* Icones Sidenav */
    dashboard = 'dashboard',
    update_clients = 'update_clients',
    consult_backup = 'consult_backup',
    users = 'users',
    update_tables = 'update_tables',
    up_tb_functions = 'up_tb_functions',
    up_tb_table_ibptax = 'up_tb_table_ibptax',
    up_tb_version = 'up_tb_version',
    up_tb_type_version = 'up_tb_type_version',
    delay_blocking = 'delay_blocking',
    syncronize_web = 'syncronize_web',
    marketing_notify = 'marketing_notify',
    configuration = 'configuration',
    notification_type = 'notification_type',

    /* Icones update-tables-ncm */
    file = 'file',
    upload_file = 'upload_file',
    send_file = 'send_file',

    /* Icones update-clients */
    person_client = 'person_client',
    release_version = 'release_version',
    generation_license_off = 'generation_license_off',
    release_contingency = 'release_contingency',
    blocked_contingency = 'blocked_contingency',
    play_version_client = 'play_version_client',
    backspace_version_client = 'backspace_version_client',
    migrate_client_web = 'migrate_client_web',
    person_has_financial = 'person_has_financial',

    /** Icones componente table */
    sort_ask = 'sort_ask',
    sort_desk = 'sort_desk',
    sort_unset = 'sort_unset',
    mood_bad = 'mood_bad',

    /** Icones modal */
    modal_warning = 'modal_warning',
    modal_danger = 'modal_danger',
    modal_information = 'modal_information',
}
