<gipi-input-text [id]="id"
                 [name]="name"
                 [type]="'select'"
                 [label]="label"
                 [placeholder]="placeholder"
                 [help]="help"
                 [readOnly]="true"
                 [(required)]="required"
                 [(disabled)]="disabled"
                 [(lowerCase)]="lowerCase"
                 [(upperCase)]="upperCase"
                 [ngModel]="clientsSelecteds"
                 #popoverTrigger="mdePopoverTrigger"
                 [mdePopoverTargetAt]="mdePopoverTargetAt"
                 [mdePopoverTriggerFor]="inputSelectListboxPopover"
                 [mdePopoverTriggerOn]="'click'"
                 [mdePopoverBackdropCloseOnClick]="true"
                 (opened)="onOpenPopover.emit()"
                 (closed)="onClosePopover.emit()"
                 (keydown)="handleKeydow($event)"
                 (click)="handleClick($event)"
                 (focus)="handleFocus($event)"
                 (blur)="handleBlur($event)">
    <div class="trigger"
         *ngIf="value?.options?.length > 0"
         suffix>
        {{ value?.options?.length }}
    </div>
</gipi-input-text>

<mde-popover #inputSelectListboxPopover="mdePopover"
             [mdePopoverOverlapTrigger]="false"
             [mdePopoverCloseOnClick]="false"
             [mdeFocusTrapEnabled]="false"
             [mdeFocusTrapAutoCaptureEnabled]="false"
             [mdePopoverArrowWidth]="0"
             [mdePopoverOffsetY]="0">
    <div class="listbox-popover-content"
         [style.width]="(popoverContentWidth | async)">
        <div class="listbox-header"
             fxLayout="row"
             fxLayout.lt-md="column">
            <gipi-radio-group id="inputSelectListboxRadioGroup"
                              name="inputSelectListboxRadioGroup"
                              [align]="'row'"
                              [enum]="_filterListboxEnum"
                              [(ngModel)]="value.filter"
                              (ngModelChange)="validateFilter($event)">
            </gipi-radio-group>

            <button class="btn-search"
                    *ngIf="!_disableInternal"
                    [disabled]="_disableInternal"
                    (click)="searchOptions()"
                    fxFlex>
                Buscar
            </button>
        </div>

        <div class="listbox-content"
             *ngIf="!_disableInternal">
            <span class="title"> Clientes selecionadas </span>

            <ng-container *ngIf="value?.options?.length <= 0">
                <div class="no-records">
                    <mat-icon>sentiment_very_dissatisfied</mat-icon>
                    <span>Nenhum registro foi encontrado</span>
                </div>
            </ng-container>

            <div class="options"
                 *ngIf="value?.options?.length > 0">
                <cdk-virtual-scroll-viewport class="select-virtual-scroll-viewport"
                                             [style.height.px]="scrollViewportHeight"
                                             [itemSize]="_itemSizeScrollViewport">
                    <div *cdkVirtualFor="let option of value?.options"
                         class="options-row"
                         [style.height.px]="40"
                         [class.disabled]="disabled">
                        <span class="option-label">
                            {{ getPropertyValue(option) }}
                        </span>
                        <mat-icon (click)="removeOption(option)">
                            delete_outline
                        </mat-icon>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
    </div>
</mde-popover>
