import { GIPIUuid } from "@gipi-shared/types/uuid.type";

export class AbstractModel {

    /** Id do registro */
    id: GIPIUuid;

    /** Ativo */
    enabled: boolean;

    /** Data de criação */
    createdDate: Date;

    /** Data de modificação */
    modifiedDate: Date;

    /** Selecionado */
    selected: boolean;

}
