import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import * as moment from 'moment';
import { Moment } from 'moment';

import { AbstractListComponent } from '@gipi-pages/abstract/abstract-list.component';
import { SortModel } from '@gipi-pages/abstract/models/sort.model';
import { BackupDaysFilter, BackupDaysFilterEnum } from '@gipi-pages/backup/enums/backup-days-filter.enum';
import { BackupService } from '@gipi-pages/backup/services/backup.service';
import { ClientModel } from '@gipi-pages/clients/models/client.model';
import { ClientFilterDTO } from '@gipi-pages/clients/models/dto/client-filter.dto';
import { LozengeComponent, LozengeType } from '@gipi-ui/components/lozenge/lozenge.component';
import { TablePaginatorEvent } from '@gipi-ui/components/mat-table/mat-table.component';
import { TableColumn } from '@gipi-ui/components/table/shared/table-column';
import { TableColumnBuilder } from '@gipi-ui/components/table/shared/table-column-builder';
import { Library } from '@gipi-ui/global/library';
import { AlertService } from '@gipi-ui/services/alert.service';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { DateUtil } from '@gipi-ui/utils/date.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';

@Component({
    selector: 'gipi-backup-list',
    templateUrl: './backup-list.component.html',
    styleUrls: ['./backup-list.component.scss']
})
export class BackupListComponent extends AbstractListComponent<ClientModel, ClientFilterDTO> implements OnInit, OnDestroy {

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<LozengeComponent>;

    private _subject: Subject<string> = new Subject<string>();

    public _dataSource: MatTableDataSource<ClientModel> = null;

    public _entities: ClientModel[] = [];

    public valueInput: string = '';

    public backupNumberDays: string = '';
    public backupDaysFilterEnum: typeof BackupDaysFilterEnum = BackupDaysFilterEnum;
    public backupDaysFilterValue: BackupDaysFilter = 'EQUAL';

    public allStatusChecked: boolean = true;
    public indeterminateStatusChecked: boolean = false;
    public zeroToTwoDaysStatusChecked: boolean = true;
    public threeToSevenDaysStatusChecked: boolean = true;
    public moreThanSevenDaysStatusChecked: boolean = true;
    public notRegisteredStatusChecked: boolean = true;

    constructor(
        protected service: BackupService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _alertService: AlertService,
    ) {
        super(service);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this._subject.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            if (StringUtil.isEmpty(value)) {
                this._dataSource.filter = '*';
            } else {
                this._dataSource.filter = value.trim().toLowerCase();
            }
        });

        this.findAll();
    }

    ngOnDestroy(): void {
        this._subject.unsubscribe();
    }

    protected getPath(): string {
        return `consult-backup`;
    }

    protected _newFilter(): ClientFilterDTO {
        return new ClientFilterDTO();
    }

    protected _createTableColumns(): TableColumn[] {
        return [
            TableColumnBuilder
                .instance()
                .property('name')
                .description('Razão social')
                .sliceLength(50)
                .value((obj: ClientModel) => !ObjectUtil.isNull(obj.person) && !StringUtil.isEmpty(obj.person.name) ? obj.person.name : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('fantasyName')
                .description('Nome fantasia')
                .sortable(true)
                .sliceLength(50)
                .value((obj: ClientModel) => !ObjectUtil.isNull(obj.person) && !StringUtil.isEmpty(obj.person.fantasyName) ? obj.person.fantasyName : '')
                .build(),
            TableColumnBuilder
                .instance()
                .property('cpfOrCnpj')
                .description('CPF | CNPJ')
                .sortable(true)
                .value((obj: ClientModel) => this._getDocumentPerson(obj))
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('dateTimeLastBackup')
                .description('Último backup')
                .sortable(true)
                .value((obj: ClientModel) => DateUtil.isValid(obj.dateTimeLastBackup) ? DateUtil.format(obj.dateTimeLastBackup, 'dd/MM/yyyy HH:mm:ss') : '')
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('numberDaysBackup')
                .description('Qtd. de dias')
                .sortable(true)
                .value((obj: ClientModel) => DateUtil.isValid(obj.dateTimeLastBackup) ? this._getNumberDaysBackup(obj.dateTimeLastBackup) : '')
                .width(150)
                .align('center')
                .build(),
            TableColumnBuilder
                .instance()
                .property('status')
                .description('Status')
                .template(this.statusTemplate)
                .width(190)
                .align('center')
                .build(),
        ];
    }

    private _getDocumentPerson(entity: ClientModel): string {
        if (!ObjectUtil.isNull(entity) && !ObjectUtil.isNull(entity.person)) {
            const isLegalPerson: boolean = !ObjectUtil.isNull(entity.person.legalPerson) && !ObjectUtil.isNewModel(entity.person.legalPerson);
            const document: string = isLegalPerson ? entity.person.legalPerson.cnpj : entity.person.naturalPerson.cpf;

            if (StringUtil.isEmpty(document)) {
                return '';
            }

            return StringUtil.format(document, isLegalPerson ? '00.000.000/0000-00' : '000.000.000-00');
        }

        return '';
    }

    private _getNumberDaysBackup(dateTimeLastBackup: Date): string {
        const numberDays: number = this._calcNumberDaysBackup(dateTimeLastBackup);
        if (numberDays < 0) {
            return '';
        }

        return `${numberDays.toString()} ${(numberDays >= 2) ? 'Dias' : 'Dia'}`;
    }

    private _calcNumberDaysBackup(dateTimeLastBackup: Date): number {
        if (DateUtil.isValid(dateTimeLastBackup)) {
            const now: Moment = moment();
            const past: Moment = moment(new Date(dateTimeLastBackup));
            return now.diff(past, 'days');
        }

        return -1;
    }

    public getLabelStatus(dateTimeLastBackup: Date): string {
        const numberDays: number = this._calcNumberDaysBackup(dateTimeLastBackup);
        if ((numberDays >= 0) && (numberDays <= 2)) {
            return '0 à 2 dias';
        } else if ((numberDays >= 3) && (numberDays <= 7)) {
            return '3 à 7 dias';
        } else if (numberDays > 7) {
            return 'Mais de 7 dias'
        } else if (numberDays < 0) {
            return 'Não registrado';
        }

        return 'Não registrado';
    }

    public getTypeStatus(dateTimeLastBackup: Date): LozengeType {
        const numberDays: number = this._calcNumberDaysBackup(dateTimeLastBackup);
        if ((numberDays >= 0) && (numberDays <= 2)) {
            return 'success';
        } else if ((numberDays >= 3) && (numberDays <= 7)) {
            return 'warning';
        } else if (numberDays > 7) {
            return 'error';
        } else if (numberDays < 0) {
            return 'default';
        }

        return 'default';
    }

    public getTooltipStatus(dateTimeLastBackup: Date): string {
        const numberDays: number = this._calcNumberDaysBackup(dateTimeLastBackup);
        if ((numberDays >= 0) && (numberDays <= 2)) {
            return 'Backup realizado entre 0 e 2 dias';
        } else if ((numberDays >= 3) && (numberDays <= 7)) {
            return 'Backup realizado entre 3 e 7 dias';
        } else if (numberDays > 7) {
            return 'Backup realizado a mais de 7 dias';
        } else if (numberDays < 0) {
            return 'Backup ainda não foi registrado';
        }

        return 'Backup ainda não foi registrado';
    }

    public checkAllStatus(): void {
        this.zeroToTwoDaysStatusChecked = this.allStatusChecked;
        this.threeToSevenDaysStatusChecked = this.allStatusChecked;
        this.moreThanSevenDaysStatusChecked = this.allStatusChecked;
        this.notRegisteredStatusChecked = this.allStatusChecked;
        this.indeterminateStatusChecked = false;
    }

    public validateAllStatusSelected(): void {
        if (
            this.zeroToTwoDaysStatusChecked &&
            this.threeToSevenDaysStatusChecked &&
            this.moreThanSevenDaysStatusChecked &&
            this.notRegisteredStatusChecked
        ) {
            this.allStatusChecked = true;
            this.indeterminateStatusChecked = false;
        } else if (
            this.zeroToTwoDaysStatusChecked ||
            this.threeToSevenDaysStatusChecked ||
            this.moreThanSevenDaysStatusChecked ||
            this.notRegisteredStatusChecked
        ) {
            this.indeterminateStatusChecked = true;
            this.allStatusChecked = false;
        } else {
            this.indeterminateStatusChecked = false;
            this.allStatusChecked = false;
        }
    }

    public refreshFindAll(): void {
        this._dataSource.data = [];
        this.findAll();
    }

    public findAll(pageEvent?: TablePaginatorEvent): void {
        try {
            this.loading = true;

            this.service.findByTypeEnvironment().toPromise().then(clientList => {
                this._entities = ArrayUtil.clone(clientList);
                this._dataSource = new MatTableDataSource(clientList);

                this._dataSource.filterPredicate = (data, filter) => this.filterEntities(data, filter);

                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            throw new Error(e);
        }

        this._changeDetectorRef.detectChanges();
    }

    public clearFilterDaysBackup(): void {
        this.backupNumberDays = '';
        this.backupDaysFilterValue = 'EQUAL';
        this.filterEntitiesByInput(true);
    }

    public filterEntitiesByInput(calledButton: boolean = false): void {
        if (calledButton) {
            if (StringUtil.isEmpty(this.valueInput)) {
                this._dataSource.filter = '*';
            } else {
                this._dataSource.filter = this.valueInput.trim().toLowerCase();
            }
        } else {
            this._subject.next(this.valueInput);
        }
    }

    public filterEntities(data: ClientModel, filter: string): boolean {
        try {
            this.loading = true;

            const checkNumberDays: boolean = (!StringUtil.isEmpty(this.backupNumberDays));

            // Se o input estiver vazio, o filtro do datasource não é acionado; por outro lado, ao inserir um "*", o filtro é ativado conforme o esperado.
            if (filter === '*') {
                return (
                    this._checkStatus(data.dateTimeLastBackup) &&
                    (checkNumberDays ? this._checkNumberDaysBackup(data.dateTimeLastBackup) : true)
                );
            } else {
                let lFilter: string = filter;
                lFilter = lFilter.replace(/[^0-9]/g, '');

                return (
                    (this._checkStatus(data.dateTimeLastBackup)) &&
                    (checkNumberDays ? this._checkNumberDaysBackup(data.dateTimeLastBackup) : true) &&
                    (
                        data.id.toString().includes(filter) ||
                        data.person.name.toLowerCase().includes(filter) ||
                        (!StringUtil.isEmpty(data.person.fantasyName) ? data.person.fantasyName.toLowerCase().includes(filter) : false) ||
                        (!ObjectUtil.isNull(data.person.legalPerson) ? data.person.legalPerson.cnpj.toLowerCase().includes((lFilter ? lFilter : filter)) : false) ||
                        (!ObjectUtil.isNull(data.person.naturalPerson) ? data.person.naturalPerson.cpf.toLowerCase().includes((lFilter ? lFilter : filter)) : false)
                    )
                );
            }
        } catch (e) {
            this.loading = false;
            this._alertService.handleError(e);
        } finally {
            this.loading = false;
        }
    }

    private _checkNumberDaysBackup(dateTimeLastBackup: Date): boolean {
        if (ObjectUtil.isNull(this.backupNumberDays) || StringUtil.isEmpty(this.backupNumberDays)) {
            return true;
        }

        const numberDays: number = this._calcNumberDaysBackup(dateTimeLastBackup);

        // Se a data do backup tiver vazia já retorna false
        if (!DateUtil.isValid(dateTimeLastBackup)) {
            return false; // Não registrado
        }

        return (
            /** Igual */ ((this.backupDaysFilterValue === 'EQUAL') && (numberDays === Number(this.backupNumberDays.trim()))) ||
            /** Maior */ ((this.backupDaysFilterValue === 'BIGGER') && (numberDays > Number(this.backupNumberDays.trim()))) ||
            /** Menor */ ((this.backupDaysFilterValue === 'SMALLER') && (numberDays < Number(this.backupNumberDays.trim())))
        );
    }

    private _checkStatus(dateTimeLastBackup: Date): boolean {
        const numberDays: number = this._calcNumberDaysBackup(dateTimeLastBackup);

        // Se a data do backup tiver vazia já valida o não confirmado
        if (!DateUtil.isValid(dateTimeLastBackup)) {
            return (this.notRegisteredStatusChecked && (numberDays < 0)); // Não registrado
        }

        return (
            /** De 1 a 2 dias */  (this.zeroToTwoDaysStatusChecked && ((numberDays >= 0) && (numberDays <= 2))) ||
            /** De 3 a 7 dias */  (this.threeToSevenDaysStatusChecked && ((numberDays >= 3) && (numberDays <= 7))) ||
            /** Mais de 7 dias */ (this.moreThanSevenDaysStatusChecked && (numberDays > 7)) ||
            /** Não registrado */ (this.notRegisteredStatusChecked && (numberDays < 0))
        );
    }

    public sortData(pageEvent: TablePaginatorEvent): void {
        if (ObjectUtil.isNull(pageEvent)) {
            return;
        }

        const sort: SortModel = ObjectUtil.clone(pageEvent.sort);
        const dataSourceDataAux: ClientModel[] = ArrayUtil.clone(this._dataSource.data);

        if (ObjectUtil.isNull(sort) || (!ObjectUtil.isNull(sort) && (StringUtil.isEmpty(sort.field) || StringUtil.isEmpty(sort.direction)))) {
            this._dataSource.data = ArrayUtil.clone(this._entities);
            return;
        }

        this._dataSource.data = dataSourceDataAux.sort((a, b) => {
            const isAsk: boolean = sort.direction === 'ASC';
            switch (sort.field) {
                case 'corporateName': {
                    const fieldA = (!ObjectUtil.isNull(a.person.legalPerson) ? a.person.name : a.person.fantasyName);
                    const fieldB = (!ObjectUtil.isNull(b.person.legalPerson) ? b.person.name : b.person.fantasyName);
                    return Library.compareSort(fieldA, fieldB, isAsk);
                }
                case 'name': {
                    const fieldA = (!StringUtil.isEmpty(a.person.fantasyName) ? a.person.fantasyName : a.person.name);
                    const fieldB = (!StringUtil.isEmpty(b.person.fantasyName) ? b.person.fantasyName : b.person.name);
                    return Library.compareSort(fieldA, fieldB, isAsk);
                }
                case 'cpfOrCnpj': {
                    const fieldA = (!ObjectUtil.isNull(a.person.legalPerson) ? a.person.legalPerson.cnpj : a.person.naturalPerson.cpf);
                    const fieldB = (!ObjectUtil.isNull(b.person.legalPerson) ? b.person.legalPerson.cnpj : b.person.naturalPerson.cpf);
                    return Library.compareSort(fieldA, fieldB, isAsk);
                }
                case 'dateTimeLastBackup': {
                    const fieldA = (DateUtil.isValid(a.dateTimeLastBackup) ? a.dateTimeLastBackup : null);
                    const fieldB = (DateUtil.isValid(b.dateTimeLastBackup) ? b.dateTimeLastBackup : null);
                    return Library.compareSortDate(fieldA, fieldB, isAsk);
                }
                case 'numberDays': {
                    const fieldA = (DateUtil.isValid(a.dateTimeLastBackup) ? this._calcNumberDaysBackup(a.dateTimeLastBackup) : -1);
                    const fieldB = (DateUtil.isValid(b.dateTimeLastBackup) ? this._calcNumberDaysBackup(b.dateTimeLastBackup) : -1);
                    return Library.compareSort(fieldA, fieldB, isAsk);
                }
            }
        });
    }

}
